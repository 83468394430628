import React from "react";
import { useDispatch, useSelector } from "react-redux";
import NumberInput from "../../../components/sub-components/input-number";
import ModalDefault from "../../../components/sub-components/modal-default";
import {
  addStockHistory,
  reduceStockFB,
} from "../../../redux/products/ProductsAction";
import { RootStore } from "../../../redux/Store";
import { addStockHistoryDTO, reduceStockDTO } from "../../../utils/requestDto";
import {
  productI,
  settingsI,
  stockHistoryI,
  userI,
} from "../../../utils/responseEntities";
import { capitalizeWords } from "../../../utils/utils";

interface ModalReduceStockProductProps {
  product: productI;
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalReduceStockProduct: React.FunctionComponent<
  ModalReduceStockProductProps
> = ({
  product,
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalReduceStockProductProps) => {
  let defaultValue: any;
  const settingsAuth: settingsI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.settings
  );
  const userAuth: userI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.user
  );

  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);
  const [state, setState] = React.useState<reduceStockDTO>({
    key: "",
    reduceStock: defaultValue,
  });
  const [errorStock, setErrorStock] = React.useState(false);

  const dispatch = useDispatch();

  const pastToNegative = (past: number) => {
    return -Math.abs(past);
  };

  const updateProductData = (name: any, value: any) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateStockData = () => {
    if (!state.reduceStock || state.reduceStock > product.stock) {
      setErrorStock(
        !state.reduceStock || state.reduceStock > product.stock ? true : false
      );
      return false;
    } else {
      return true;
    }
  };

  const reduceStock = () => {
    setLoadingAction(true);
    //REDUCE STOCK
    dispatch(
      addStockHistory(
        {
          stockHistoryI: {
            product_key: product?.key || "",
            stock: state.reduceStock,
            total_previo: product.stock,
            total_actual: product.stock - state.reduceStock,
            usuario: userAuth?.nombre || "",
            fecha_creacion: new Date(),
            local_cod: settingsAuth?.local_cod || 0,
          } as stockHistoryI,
        } as addStockHistoryDTO,
        () => {
          dispatch(
            reduceStockFB(
              {
                key: state.key,
                reduceStock: pastToNegative(state.reduceStock),
              } as reduceStockDTO,
              () => {
                setLoadingAction(false);
                if (successFun) successFun("Stock reducido satisfactoriamente");
                setModalShow(false);
              },
              () => {
                setLoadingAction(false);
                if (errorFun) errorFun("Ups! Algo salio mal");
                setModalShow(false);
              }
            )
          );
        },
        () => {
          setLoadingAction(false);
          if (errorFun) errorFun("Ups! Algo salio mal");
          setModalShow(false);
        }
      )
    );
  };

  React.useEffect(() => {
    if (product) {
      setState((prev) => ({
        ...prev,
        key: product.key || "",
      }));
    }
  }, [product]);

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-products-reduce"
      title={"Reducir Producto"}
      showModalHeader={true}
      showModalFooter={true}
      showReduce={true}
      showCancel={true}
      reduceAction={() => {
        if (validateStockData()) {
          reduceStock();
        }
      }}
      size="sm"
    >
      <React.Fragment>
        <NumberInput
          key={"reduce-product-stock"}
          horizontal={false}
          placeholder={""}
          value={state.reduceStock}
          onChange={(value: any) => {
            updateProductData("reduceStock", value);
            setErrorStock(false);
          }}
          className={"reduce-stockproduct-input"}
          viewTitle={true}
          viewErrorMessage={errorStock}
          title={`${capitalizeWords(product.nombre)}`}
          errorMessage={"Incorrecto"}
        ></NumberInput>
      </React.Fragment>
    </ModalDefault>
  );
};

export default ModalReduceStockProduct;
