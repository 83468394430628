import { filterI, TT_FILTER_LIST } from "../../utils/utils";
import { bannedI } from "../../utils/responseEntities";
import {
  BANNED_LOADING,
  BANNED_FAIL,
  BANNED_UPDATE_FILTER,
  BANNED_SEARCH,
  BANNED_LIST,
  //=====DISPATCH TYPES
  BannedDispatchTypes,
} from "./BannedTypes";

interface BannedState {
  loading: boolean;
  filters: Array<filterI>;
  search: string;
  banneds: Array<bannedI>;
}

const defaultState: BannedState = {
  loading: false,
  filters: [
    { name: TT_FILTER_LIST.NOMBRE, value: true },
    { name: TT_FILTER_LIST.DNI, value: false },
  ],
  search: "",
  banneds: [],
};

const bannedReducer = (
  state: BannedState = defaultState,
  action: BannedDispatchTypes
): BannedState => {
  switch (action.type) {
    case BANNED_LOADING:
      return {
        ...state,
        loading: true,
      };
    case BANNED_FAIL:
      return {
        ...state,
        loading: false,
      };
    case BANNED_UPDATE_FILTER:
      return {
        ...state,
        filters: action.payload,
      };
    case BANNED_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case BANNED_LIST:
      return {
        ...state,
        banneds: action.payload,
      };
    default:
      return state;
  }
};

export default bannedReducer;
