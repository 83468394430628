import React from "react";
import List from "./components/List";
import TopToolbar from "./components/TopToolbar";
import "./style.scss";

const Products: React.FunctionComponent = () => {
  return (
    <div className="products-container">
      <TopToolbar></TopToolbar>
      <List></List>
    </div>
  );
};

export default Products;
