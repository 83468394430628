import moment from "moment";
import { ColumnBodyOptions } from "primereact/column";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ModalDefault from "../../../components/sub-components/modal-default";
import { rentI } from "../../../utils/responseEntities";
import { capitalizeWords } from "../../../utils/utils";

interface ModalDetailHistoryProps {
  roomHistory: rentI;
  modalShow: any;
  setModalShow: any;
  successFun: any;
}

export const ModalDetailHistory: React.FunctionComponent<
  ModalDetailHistoryProps
> = ({
  roomHistory,
  modalShow,
  setModalShow,
  successFun,
}: ModalDetailHistoryProps) => {
  let defaultValue: any;

  const [dateIn, setDateIn] = React.useState<any>(defaultValue);

  const [dateOutReal, setDateOutReal] = React.useState<any>(defaultValue);

  useEffect(() => {
    setDateIn(roomHistory.detalle_alquiler.hora_ingreso);
    setDateOutReal(roomHistory.detalle_alquiler.hora_salida_real);
  }, [roomHistory]);

  return (
    <ModalDefault
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      showModalHeader={true}
      title={"Detalles de renta"}
      showModalFooter={true}
      showEdit={false}
      showClose={true}
      customClass="modal-rent-detail"
    >
      <div className="flex flex-col w-full h-full p-[5px]">
        <div className="flex flex-row items-center mb-[10px]">
          <p className="pr-[10px] title">{`Cliente:`}</p>
          <p className="text-[#5d6b83] info">{`
          ${capitalizeWords(roomHistory?.usuarios?.nombres || "")} 
          ${capitalizeWords(roomHistory?.usuarios?.apellido_pat || "")} 
          ${capitalizeWords(roomHistory?.usuarios?.apellido_mat || "")}`}</p>
        </div>
        <div className="flex flex-row items-center mb-[10px]">
          <p className="pr-[10px] title">{`DNI:`}</p>
          <p className="text-[#5d6b83] info">{`${roomHistory?.usuarios?.dni}`}</p>
        </div>
        <div className="flex flex-row items-center mb-[10px]">
          <p className="pr-[10px] title">{`Habitación:`}</p>
          <p className="bg-[#e9ecf1] text-[#5d6b83] rounded-md px-1 info">
            {`#${roomHistory?.detalle_habitacion?.numero}`}
          </p>
        </div>
        <div className="flex flex-row items-center mb-[10px]">
          <p className="pr-[10px] title">{`Tipo:`}</p>
          <p className="text-[#5d6b83] info">{`${roomHistory?.detalle_habitacion?.tipo}`}</p>
        </div>
        <div className="flex flex-row items-center mb-[10px]">
          <p className="pr-[10px] title">{`Recepcionista:`}</p>
          <p className="text-[#5d6b83] info">{`${capitalizeWords(
            roomHistory.recepcionista
          )}`}</p>
        </div>
        <div className="flex flex-row items-center mb-[10px]">
          <p className="pr-[10px] title">{`Ingreso:`}</p>
          <p className="bg-[#00a35a] text-[#fff] rounded-md px-1 info">
            {`${moment(dateIn?.toDate() || "").format("DD/MM/YYYY HH:mm")}`}
          </p>
        </div>
        {dateOutReal && (
          <div className="flex flex-row items-center mb-[10px]">
            <p className="pr-[10px] title">{`Salida Real:`}</p>
            <p className="bg-[#5887ff] text-[#fff] rounded-md px-1 info">
              {`${moment(dateOutReal?.toDate() || "").format(
                "DD/MM/YYYY HH:mm"
              )}`}
            </p>
          </div>
        )}
        <div className="flex flex-row justify-end items-center w-full pt-3">
          <p className="font-bold text-[16px] pr-[10px]">{`Total:`}</p>
          <span className="bg-[#c8e6c9] text-[#256029] text-[16px] font-bold rounded-md px-1">
            {`S/.${Number(
              roomHistory?.detalle_alquiler?.precio_total || "0"
            ).toFixed(2)}`}
          </span>
        </div>
      </div>
    </ModalDefault>
  );
};

export default ModalDetailHistory;
