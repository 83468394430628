import { rentI } from "../../utils/responseEntities";
import { filterI, timeSearchI, TIME_SEARCH, TT_FILTER_LIST } from "../../utils/utils";
import {
  ROOMS_SEARCH_LOADING,
  ROOMS_SEARCH_FAIL,
  ROOMS_SEARCH_UPDATE_FILTER,
  ROOMS_SEARCH_UPDATE_TIME,
  ROOMS_SEARCH_SEARCH,
  ROOMS_SEARCH_LIST,
  RoomsDispatchTypes,
}from "./RoomsSearchTypes";

interface RoomsState {
  loadingSearch: boolean;
  filtersSearch: Array<filterI>;
  searchSearch: string;
  timeSearch: Array<timeSearchI>;
  rentSearch: Array<rentI>;
} 

const defaultState: RoomsState = {
  loadingSearch: false,
  filtersSearch: [
    { name: TT_FILTER_LIST.HABITACION, value: true },
    { name: TT_FILTER_LIST.DNI, value: false },
  ],
  searchSearch: "",
  timeSearch: [
    { name: TIME_SEARCH.WEEK, value:true },
    { name: TIME_SEARCH.MONTH, value:false },
    { name: TIME_SEARCH.YEAR, value:false },
  ],
  rentSearch: [],
}

const roomsSearchReducer = (
  state: RoomsState = defaultState,
  action: RoomsDispatchTypes
): RoomsState => {
  switch (action.type) {
    case ROOMS_SEARCH_LOADING:
      return {
        ...state,
        loadingSearch: true,
      };
    case ROOMS_SEARCH_FAIL:
      return {
        ...state,
        loadingSearch: false,
      };
    case ROOMS_SEARCH_UPDATE_FILTER:
      return {
        ...state,
        filtersSearch: action.payload,
      };  
    case ROOMS_SEARCH_UPDATE_TIME:
      return {
        ...state,
        timeSearch: action.payload,
      };
    case ROOMS_SEARCH_SEARCH:
      return {
        ...state,
        searchSearch: action.payload,
      };
    case ROOMS_SEARCH_LIST:
      return {
        ...state,
        rentSearch: action.payload,
      };  
    default:
      return state;
  }
}

export default roomsSearchReducer;