import {
  PREFERENT_LOADING,
  PREFERENT_FAIL,
  PREFERENT_LIST,
} from "./PreferentTypes";
import {
  CollectionReference,
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  QuerySnapshot,
  FirestoreError,
  QueryConstraint,
  QueryDocumentSnapshot,
  deleteDoc,
  doc,
  updateDoc,
  getDocs,
} from "firebase/firestore";
import { preferentI, rentI } from "../../utils/responseEntities";
import { db } from "../../lib/init-firebase";
import {
  addPreferentsDTO,
  deletePreferentsDTO,
  fetchPreferentsDTO,
  GetByDniRequest,
  topPreferentsDTO,
  updatePreferentsDTO,
} from "../../utils/requestDto";
import { rentCountI, TT_FILTER_LIST } from "../../utils/utils";
import moment from "moment";

const dbPathPreferents = "/preferentes";
let preferentRef = collection(
  db,
  dbPathPreferents
) as CollectionReference<preferentI>;

const dbPathRent = "/alquiler";
let rentRef = collection(db, dbPathRent) as CollectionReference<rentI>;

export const getPreferentListFB = (
  data: fetchPreferentsDTO,
  funDone?: any,
  funError?: any
) => {
  console.log("getPreferentListFB DATA:", data);
  return (dispatch: any) => {
    dispatch({ type: PREFERENT_LOADING });

    let queryConst: Array<QueryConstraint> = [];

    if (data.type === TT_FILTER_LIST.NOMBRE) {
      queryConst.push(where("nombres", ">=", data.search.toLowerCase()));
      queryConst.push(where("nombres", "<=", data.search.toLowerCase() + "~"));
    } else if (data.type === TT_FILTER_LIST.AP_PATERNO) {
      queryConst.push(where("apellido_pat", ">=", data.search.toLowerCase()));
      queryConst.push(
        where("apellido_pat", "<=", data.search.toLowerCase() + "~")
      );
    } else if (data.type === TT_FILTER_LIST.AP_MATERNO) {
      queryConst.push(where("apellido_mat", ">=", data.search.toLowerCase()));
      queryConst.push(
        where("apellido_mat", "<=", data.search.toLowerCase() + "~")
      );
    } else if (data.type === TT_FILTER_LIST.DNI) {
      queryConst.push(where("dni", ">=", data.search));
      queryConst.push(where("dni", "<=", data.search + "~"));
    }

    const q = query(preferentRef, ...queryConst);

    onSnapshot(
      q,
      (snapshot: QuerySnapshot<preferentI>) => {
        let auxClients: Array<preferentI> = [];

        snapshot.docs.forEach((change: QueryDocumentSnapshot<preferentI>) => {
          // console.log(change.data());
          auxClients.push({ key: change.id, ...change.data() });
        });

        dispatch({ type: PREFERENT_LIST, payload: auxClients });
        dispatch({ type: PREFERENT_FAIL });

        if (funDone) {
          funDone();
        }
      },
      (error: FirestoreError) => {
        console.log("error on getPreferentListFB:", error);
        dispatch({ type: PREFERENT_FAIL });

        if (funError) {
          funError();
        }
      }
    );
  };
};

export const addPreferentFB = (
  data: addPreferentsDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("addPreferentFB DATA:", data);
    await addDoc(preferentRef, data.preferent)
      .catch((error: any) => {
        console.log("error on addPreferentFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("addPreferentFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const updatePreferentFB = (
  data: updatePreferentsDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("updatePreferentFB DATA:", data);
    await updateDoc(doc(db, dbPathPreferents + "/" + data.preferent.key), {
      nombres: data.preferent.nombres,
      apellido_pat: data.preferent.apellido_pat,
      apellido_mat: data.preferent.apellido_mat,
      dni: data.preferent.dni,
      fecha_nacimiento: data.preferent.fecha_nacimiento,
    })
      .catch((error: any) => {
        console.log("error on updatePreferentFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("updatePreferentFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const deletePreferentFB = (
  data: deletePreferentsDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("deletePreferentFB DATA:", data);
    await deleteDoc(doc(db, dbPathPreferents, data.key))
      .catch((error: any) => {
        console.log("error on deletePreferentFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("deletePreferentFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const topPreferentFB = (data: topPreferentsDTO, funDone?: any) => {
  return async () => {
    console.log("topPreferentFB DATA:", data);
    let queryConst: Array<QueryConstraint> = [];

    let today = moment().subtract(data.time, "months").toDate();
    let enddate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0,
      0
    );

    queryConst.push(where("detalle_alquiler.hora_ingreso", ">=", enddate));

    const q = query(rentRef, ...queryConst);

    const querySnapshot = await getDocs(q);

    let auxRent: Array<rentCountI> = [];
    querySnapshot.forEach((doc: QueryDocumentSnapshot<rentI>) => {
      auxRent.push({
        dni: doc.data().usuarios.dni.trim(),
        usuario: {
          nombres: doc.data().usuarios.nombres.toLowerCase(),
          apellido_pat: doc.data().usuarios.apellido_pat.toLowerCase(),
          apellido_mat: doc.data().usuarios.apellido_mat.toLowerCase(),
          fecha_nacimiento: doc.data().usuarios.fecha_nacimiento,
          dni: doc.data().usuarios.dni,
        },
        occurences: 0,
      });
    });

    let count: any = {};

    auxRent.forEach((element: rentCountI) => {
      if (count[element.dni]) {
        count[element.dni] = {
          ...count[element.dni],
          occurences: count[element.dni].occurences + 1,
        };
      } else {
        count[element.dni] = {
          usuario: element.usuario,
          dni: element.dni,
          occurences: 1,
        };
      }
    });

    let topN = Object.values(count)
      .sort((a: any, b: any) => b.occurences - a.occurences)
      .slice(0, data.qty);

    topN.forEach((pref: any) => {
      if (
        data.preferents.findIndex((p: preferentI) => {
          return p.dni === pref.dni;
        }) === -1
      )
        addDoc(preferentRef, pref.usuario);
    });

    if (funDone) {
      funDone();
    }
  };
};

export const cleanPreferentFB = (funDone?: any) => {
  return async () => {
    console.log("cleanPreferentFB");

    const q = query(preferentRef);

    const querySnapshot = await getDocs(q);

    let auxPreferent: Array<preferentI> = [];
    querySnapshot.forEach((doc: QueryDocumentSnapshot<preferentI>) => {
      auxPreferent.push({ key: doc.id, ...doc.data() });
    });

    auxPreferent.forEach((pref: preferentI) => {
      deleteDoc(doc(db, dbPathPreferents + "/" + pref.key));
    });

    if (funDone) {
      funDone();
    }
  };
};

export const checkDniPreferentFB = (data: GetByDniRequest, funDone?: any) => {
  return async (dispatch: any) => {
    console.log("checkDniPreferentFB DATA:", data);
    let queryConst: Array<QueryConstraint> = [];

    queryConst.push(where("dni", "==", data.dni));

    const q = query(preferentRef, ...queryConst);

    const querySnapshot = await getDocs(q);

    let auxPreferent: Array<preferentI> = [];
    querySnapshot.forEach((doc: QueryDocumentSnapshot<preferentI>) => {
      auxPreferent.push({ key: doc.id, ...doc.data() });
    });

    if (funDone) {
      funDone(auxPreferent.length > 0);
    }
  };
};
