import React from "react";
import { RootStore } from "../../../redux/Store";
import { useSelector } from "react-redux";

import { userI } from "../../../utils/responseEntities";

import {
  dateCreateTemplate,
  dateModifyTemplate,
  textCapitalizeTemplate,
  textLowercaseTemplate,
  textTemplate,
  userTypeTemplate,
} from "../../../components/sub-components/table-components";
import ActionOverlay from "../../../components/sub-components/actions-overlay";
import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ModalDeleteUser from "./ModalDeleteUser";
import ModalEditUser from "./ModalEditUser";
import { Toast } from "primereact/toast";
import { USER_TYPE } from "../../../utils/utils";

const List: React.FunctionComponent = () => {
  let defaultValue: any;
  const toastRef = React.useRef<Toast>(defaultValue);

  const [modalShowEdit, setModalShowEdit] = React.useState(false);
  const [userEdit, setUserEdit] = React.useState<userI>(defaultValue);

  const [modalShowDelete, setModalShowDelete] = React.useState(false);
  const [userDelete, setUserDelete] = React.useState<userI>(defaultValue);

  const permissionsAuth: Array<string> = useSelector(
    (globalState: RootStore) => globalState.auth.permissions
  );
  const userAuth: userI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.user
  );
  const usersU: Array<userI> = useSelector(
    (globalState: RootStore) => globalState.users.users
  );
  const loadingU: boolean = useSelector(
    (globalState: RootStore) => globalState.users.loading
  );

  const actionsTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    return (
      <React.Fragment>
        <div className="table-action-buttons">
          <ActionOverlay
            showEdit={
              permissionsAuth.includes("user.addedit") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            editAction={() => {
              let auxUser: userI =
                usersU.find(
                  (user: userI) => rowData[field || ""] === user.key
                ) || defaultValue;
              setUserEdit({ ...auxUser } as userI);
              setModalShowEdit(true);
            }}
            showDelete={
              permissionsAuth.includes("user.delete") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            deleteAction={() => {
              let auxUser: userI =
                usersU.find(
                  (user: userI) => rowData[field || ""] === user.key
                ) || defaultValue;
              setUserDelete({ ...auxUser } as userI);
              setModalShowDelete(true);
            }}
          ></ActionOverlay>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="user-list">
        <DataTable
          //GENERAL
          dataKey="_id"
          loading={loadingU}
          className="p-datatable-main"
          value={usersU.filter((user: userI) => {
            return user.key !== userAuth?.key;
          })}
          //SORTING
          sortField="nombre"
          sortMode="single"
          sortOrder={1}
          //SCROLLING
          scrollable
          scrollDirection="vertical"
          //PAGINATION
          alwaysShowPaginator
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
        >
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="nombre"
            header="Nombre"
            body={textCapitalizeTemplate}
            headerClassName="text-align-left nombre-field"
            className="text-align-left nombre-field"
            sortable
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="password"
            header="Password"
            body={textTemplate}
            headerClassName="text-align-left password-field"
            className="text-align-left password-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="correo"
            header="Correo"
            body={textLowercaseTemplate}
            headerClassName="text-align-left correo-field"
            className="text-align-left correo-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="rol"
            header="Rol"
            body={userTypeTemplate}
            headerClassName="text-align-center rol-field"
            className="text-align-center rol-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="fecha_creacion"
            header="Creación"
            body={dateCreateTemplate}
            headerClassName="text-align-center creacion-field"
            className="text-align-center creacion-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="fecha_modificacion"
            header="Modificación"
            body={dateModifyTemplate}
            headerClassName="text-align-center modificacion-field"
            className="text-align-center modificacion-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="key"
            header="Acciones"
            body={actionsTemplate}
            headerClassName="text-align-center acciones-field"
            className="text-align-center acciones-field"
          ></Column>
        </DataTable>
      </div>
      {modalShowEdit && (
        <ModalEditUser
          user={userEdit}
          modalShow={modalShowEdit}
          setModalShow={setModalShowEdit}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Usuarios - Editar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            console.log("message");
            toastRef.current.show({
              severity: "error",
              summary: "Usuarios - Editar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalEditUser>
      )}
      {modalShowDelete && (
        <ModalDeleteUser
          user={userDelete}
          modalShow={modalShowDelete}
          setModalShow={setModalShowDelete}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Usuarios - Eliminar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            console.log("message");
            toastRef.current.show({
              severity: "error",
              summary: "Usuarios - Eliminar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalDeleteUser>
      )}
      <Toast ref={toastRef} />
    </React.Fragment>
  );
};

export default List;
