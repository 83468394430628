import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";

import {
  ROOMS_RENT_UPDATE_CONTENT_FILTER,
  ROOMS_RENT_UPDATE_FLOOR_SELECTOR,
  ROOMS_RENT_UPDATE_STATE_FILTER,
  ROOMS_RENT_UPDATE_TYPE_SELECTOR,
} from "../../../redux/roomsRent/RoomsRentTypes";
import {
  FLOOR_LIST,
  ROOM_CONTENT,
  ROOM_CONTENT_CLASS,
  ROOM_STATUS,
  ROOM_STATUS_CLASS,
  ROOM_TYPE,
} from "../../../utils/utils";

import SidebarSubelement from "../../../components/sub-components/sidebar-subelement";
import SelectOption from "../../../components/sub-components/select-option";
import { BiSearch } from "react-icons/bi";

interface LeftToolbarProps {
  active: any;
  setActive: any;
}

const LeftToolbar: React.FunctionComponent<LeftToolbarProps> = ({
  active,
  setActive,
}: LeftToolbarProps) => {
  const selectOptionRoomR: any = useSelector(
    (globalState: RootStore) => globalState.roomsRent.selectOptionRoom
  );
  const selectOptionFloorR: any = useSelector(
    (globalState: RootStore) => globalState.roomsRent.selectOptionFloor
  );
  const selectorRoomR: ROOM_TYPE = useSelector(
    (globalState: RootStore) => globalState.roomsRent.selectorRoom
  );
  const selectorFloorR: FLOOR_LIST = useSelector(
    (globalState: RootStore) => globalState.roomsRent.selectorFloor
  );
  const filterStateR: Array<ROOM_STATUS> = useSelector(
    (globalState: RootStore) => globalState.roomsRent.filterState
  );
  const filterContentR: Array<ROOM_CONTENT> = useSelector(
    (globalState: RootStore) => globalState.roomsRent.filterContent
  );

  const dispatch = useDispatch();

  const updateType = (type: string) => {
    dispatch({ type: ROOMS_RENT_UPDATE_TYPE_SELECTOR, payload: type });
  };

  const updateFloor = (floor: string) => {
    dispatch({ type: ROOMS_RENT_UPDATE_FLOOR_SELECTOR, payload: floor });
  };

  const updateState = (state: ROOM_STATUS) => {
    let filtersArray = [...filterStateR];
    let index = filtersArray.indexOf(state);

    if (index !== -1) {
      filtersArray.splice(index, 1);
    } else {
      filtersArray.push(state);
    }

    dispatch({ type: ROOMS_RENT_UPDATE_STATE_FILTER, payload: filtersArray });
  };

  const updateContent = (content: ROOM_CONTENT) => {
    let filtersArray = [...filterContentR];
    let index = filtersArray.indexOf(content);

    if (index !== -1) {
      filtersArray.splice(index, 1);
    } else {
      filtersArray.push(content);
    }

    dispatch({ type: ROOMS_RENT_UPDATE_CONTENT_FILTER, payload: filtersArray });
  };

  return (
    <div
      className={
        active ? "room-rent-lefttoolbar active" : "room-rent-lefttoolbar"
      }
    >
      <div
        className="body-tab"
        onClick={() => {
          setActive(!active);
        }}
      >
        <BiSearch></BiSearch>
        <p className="title-tab">{"Filtros"}</p>
      </div>
      <div className="body-sidebar">
        <div className="superior-sidebar">
          <div className="sidebar-element" key={"rent-tipo"}>
            <p className="title-sidebar-element">{"Tipo"}</p>
            <div className="sidebar-sub-element" key={"rent-tipo-sub"}>
              <SelectOption
                clickAction={(type: string) => {
                  updateType(type);
                }}
                optionArray={selectOptionRoomR}
                selected={selectorRoomR}
              ></SelectOption>
            </div>
          </div>
          <div className="sidebar-element" key={"rent-piso"}>
            <p className="title-sidebar-element">{"Piso"}</p>
            <div className="sidebar-sub-element" key={"rent-piso-sub"}>
              <SelectOption
                clickAction={(floor: string) => {
                  updateFloor(floor);
                }}
                optionArray={selectOptionFloorR}
                selected={selectorFloorR}
              ></SelectOption>
            </div>
          </div>
          <div className="sidebar-element" key={"rent-estado"}>
            <p className="title-sidebar-element">{"Estado"}</p>
            <div className="sidebar-sub-element" key={"rent-estado-sub"}>
              <SidebarSubelement
                key={"state-sidebar-subelement"}
                clickAction={(value: any) => {
                  updateState(value);
                }}
                selectedList={filterStateR}
                option={[
                  { label: ROOM_STATUS.LIBRE, class: ROOM_STATUS_CLASS.LIBRE },
                  {
                    label: ROOM_STATUS.OCUPADO,
                    class: ROOM_STATUS_CLASS.OCUPADO,
                  },
                ]}
              ></SidebarSubelement>
            </div>
          </div>
          <div className="sidebar-element" key={"rent-contenido"}>
            <p className="title-sidebar-element">{"Contenido"}</p>
            <div className="sidebar-sub-element" key={"rent-contenido-sub"}>
              <SidebarSubelement
                key={"content-sidebar-subelement"}
                clickAction={(value: any) => {
                  updateContent(value);
                }}
                selectedList={filterContentR}
                option={[
                  { label: ROOM_CONTENT.LED, class: ROOM_CONTENT_CLASS.LED },
                  {
                    label: ROOM_CONTENT.SMART,
                    class: ROOM_CONTENT_CLASS.SMART,
                  },
                  {
                    label: ROOM_CONTENT.SIMPLE,
                    class: ROOM_CONTENT_CLASS.SIMPLE,
                  },
                  {
                    label: ROOM_CONTENT.ESPEJO,
                    class: ROOM_CONTENT_CLASS.ESPEJO,
                  },
                  {
                    label: ROOM_CONTENT.JACUZZI,
                    class: ROOM_CONTENT_CLASS.JACUZZI,
                  },
                  {
                    label: ROOM_CONTENT.SILLON,
                    class: ROOM_CONTENT_CLASS.SILLON,
                  },
                ]}
              ></SidebarSubelement>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftToolbar;
