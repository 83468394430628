import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";

import {
  filterI,
  timeSearchI,
  TIME_SEARCH,
  TT_FILTER_LIST,
} from "../../../utils/utils";
import {
  ROOMS_SEARCH_SEARCH,
  ROOMS_SEARCH_UPDATE_FILTER,
  ROOMS_SEARCH_UPDATE_TIME,
} from "../../../redux/roomsSearch/RoomsSearchTypes";
import { getListSearchFB } from "../../../redux/roomsSearch/RoomsSearchAction";
import { fetchRoomsSearchDTO } from "../../../utils/requestDto";
import { settingsI } from "../../../utils/responseEntities";

import ActionButton from "../../../components/sub-components/action-button";
import FilterOption from "../../../components/sub-components/filter-option";
import SearchBox from "../../../components/sub-components/search-box";
import { Toast } from "primereact/toast";

const TopToolbar: React.FunctionComponent = () => {
  let defaultValue: any;
  const toastRef = React.useRef<Toast>(defaultValue);

  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);

  const settingsAuth: settingsI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.settings
  );
  const filtersS: any = useSelector(
    (globalState: RootStore) => globalState.roomsSearch.filtersSearch
  );
  const searchS: any = useSelector(
    (globalState: RootStore) => globalState.roomsSearch.searchSearch
  );

  const timeS: any = useSelector(
    (globalState: RootStore) => globalState.roomsSearch.timeSearch
  );
  const dispatch = useDispatch();

  const updateFilter = (name: string) => {
    let filtersAux: any = [];

    // eslint-disable-next-line array-callback-return
    filtersS.map((filter: filterI) => {
      if (filter.name === name) {
        filtersAux.push({ name: filter.name, value: true });
      } else {
        filtersAux.push({ name: filter.name, value: false });
      }
    });

    dispatch({ type: ROOMS_SEARCH_UPDATE_FILTER, payload: filtersAux });
  };

  const updateFilterTime = (name: string) => {
    let filtersAux: any = [];

    timeS.map((filter: timeSearchI) => {
      if (filter.name === name) {
        filtersAux.push({ name: filter.name, value: true });
      } else {
        filtersAux.push({ name: filter.name, value: false });
      }
    });

    dispatch({ type: ROOMS_SEARCH_UPDATE_TIME, payload: filtersAux });
  };

  const updateSearch = (name: string) => {
    dispatch({ type: ROOMS_SEARCH_SEARCH, payload: name });
  };

  const fetchRoomSearch = () => {
    setLoadingAction(true);
    //FETCH ROOM LIST HISTORY
    if (searchS === "" || !searchS) {
      setLoadingAction(false);
      toastRef.current.show({
        severity: "error",
        summary: "Busqueda - Buscar",
        detail: "Campo de búsqueda vacío",
        life: 3000,
        closable: false,
      });
    } else {
      dispatch(
        getListSearchFB(
          {
            date:
              timeS.find((filter: timeSearchI) => {
                return filter.value === true;
              })?.name || TIME_SEARCH.TODOS,
            type:
              filtersS.find((filter: filterI) => {
                return filter.value === true;
              })?.name || TT_FILTER_LIST.TODOS,
            search: searchS,
            local: settingsAuth?.local_cod,
          } as fetchRoomsSearchDTO,
          () => {
            setLoadingAction(false);
          },
          () => {
            setLoadingAction(false);
          }
        )
      );
    }
  };

  return (
    <React.Fragment>
      <div className="room-search-toolbar">
        <div className="toolbar-left">
          <FilterOption
            clickAction={(name: string) => {
              updateFilter(name);
            }}
            filterArray={filtersS}
          ></FilterOption>
          <SearchBox
            searchValue={searchS}
            searchAction={(value: string) => {
              updateSearch(value);
            }}
          ></SearchBox>
          <ActionButton
            loadingAction={loadingAction}
            isSearch={true}
            searchAction={() => {
              fetchRoomSearch();
            }}
          ></ActionButton>
        </div>
        <div className="toolbar-right">
          <FilterOption
            clickAction={(name: string) => {
              updateFilterTime(name);
            }}
            filterArray={timeS}
          ></FilterOption>
        </div>
      </div>
      <Toast ref={toastRef} />
    </React.Fragment>
  );
};

export default TopToolbar;
