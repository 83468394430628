import React from "react";
import { useDispatch } from "react-redux";

import {
  addBannedFB,
  checkDniBannedFB,
} from "../../../redux/banned/BannedAction";
import { addBannedDTO, GetByDniRequest } from "../../../utils/requestDto";
import { BANNED_CATEGORY, capitalizeWords } from "../../../utils/utils";
import { rentI } from "../../../utils/responseEntities";

import ModalDefault from "../../../components/sub-components/modal-default";

interface ModalAddBannedProps {
  roomHistory: rentI;
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalAddBanned: React.FunctionComponent<ModalAddBannedProps> = ({
  roomHistory,
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalAddBannedProps) => {
  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const addBanned = () => {
    setLoadingAction(true);
    //ADD BANNED
    dispatch(
      checkDniBannedFB(
        {
          dni: roomHistory.usuarios.dni,
        } as GetByDniRequest,
        (response: any) => {
          if (!response) {
            dispatch(
              addBannedFB(
                {
                  banned: {
                    nombre:
                      roomHistory.usuarios.nombres +
                      (roomHistory.usuarios.apellido_pat !== ""
                        ? " " + roomHistory.usuarios.apellido_pat
                        : "") +
                      (roomHistory.usuarios.apellido_mat !== ""
                        ? " " + roomHistory.usuarios.apellido_mat
                        : ""),
                    categoria: BANNED_CATEGORY.OTRO.toUpperCase(),
                    fecha_nacimiento: roomHistory.usuarios.fecha_nacimiento
                      ? roomHistory.usuarios.fecha_nacimiento
                      : "",
                    dni: roomHistory.usuarios.dni,
                  },
                } as addBannedDTO,
                () => {
                  setLoadingAction(false);
                  if (successFun)
                    successFun("Baneado agregado satisfactoriamente");
                  setModalShow(false);
                },
                () => {
                  setLoadingAction(false);
                  if (errorFun) errorFun("Ups! Algo salio mal");
                  setModalShow(false);
                }
              )
            );
          } else {
            console.log("dni ya registrado");
            setLoadingAction(false);
            if (errorFun) errorFun("DNI ya registrado");
          }
        }
      )
    );
  };

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-rent-banned"
      title={"Agregar Banned"}
      showModalHeader={true}
      showModalFooter={true}
      showAdd={true}
      showCancel={true}
      addAction={() => {
        addBanned();
      }}
    >
      <p>
        {`¿Está seguro de agregar a `}
        <strong>{"BANEADOS"}</strong>
        {` a `}
        <strong>
          {capitalizeWords(roomHistory.usuarios.nombres)}{" "}
          {capitalizeWords(roomHistory.usuarios.apellido_pat)}{" "}
          {capitalizeWords(roomHistory.usuarios.apellido_mat)}
        </strong>
        {`?`}
      </p>
    </ModalDefault>
  );
};

export default ModalAddBanned;
