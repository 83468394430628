import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";

import { addTurnHistoryFB } from "../../../redux/roomsHistory/RoomsHistoryAction";
import { rentI, settingsI } from "../../../utils/responseEntities";
import { addTurnHistoryDTO } from "../../../utils/requestDto";

import ModalDefault from "../../../components/sub-components/modal-default";
import NumberInput from "../../../components/sub-components/input-number";

interface ModalAddTurnProps {
  roomHistory: rentI;
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalAddTurn: React.FunctionComponent<ModalAddTurnProps> = ({
  roomHistory,
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalAddTurnProps) => {
  const settingsAuth: settingsI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.settings
  );

  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);

  const [inTime, setInTime] = React.useState<any>();

  const [turn, setTurn] = React.useState<number>(1);
  const [errorTurn, setErrorTurn] = React.useState(false);

  const [price, setPrice] = React.useState<number>(
    Number(roomHistory.detalle_habitacion.precio)
  );
  const [errorPrice, setErrorPrice] = React.useState(false);

  const dispatch = useDispatch();

  const validateTurnData = () => {
    if (!turn || turn === 0) {
      setErrorTurn(true);
      setErrorPrice(true);
      return false;
    } else {
      return true;
    }
  };

  const addTurn = () => {
    setLoadingAction(true);

    let totalTurns = turn + roomHistory.detalle_alquiler.cantidad_turnos;

    let totalRent =
      Number(price) + Number(roomHistory.detalle_alquiler.precio_total);

    let auxOutDate =
      inTime.toDate().getTime() +
      (settingsAuth?.tiempo_turno || 0) * 3600000 * totalTurns;

    let newOutDate = new Date(auxOutDate);

    // ADD TURN
    dispatch(
      addTurnHistoryFB(
        {
          rent: {
            ...roomHistory,
            detalle_alquiler: {
              ...roomHistory.detalle_alquiler,
              cantidad_turnos: Number(totalTurns),
              precio_total: totalRent.toString(),
              hora_salida: newOutDate,
            },
          },
        } as addTurnHistoryDTO,
        () => {
          setLoadingAction(false);
          if (successFun) successFun("Turno agregado satisfactoriamente");
          setModalShow(false);
        },
        () => {
          setLoadingAction(false);
          if (errorFun) errorFun("Ups! Algo salio mal");
          setModalShow(false);
        }
      )
    );
  };

  React.useEffect(() => {
    if (roomHistory) {
      setInTime(roomHistory.detalle_alquiler.hora_ingreso);
    }
  }, [roomHistory]);

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-rent-addturn"
      title={`Agregar turno`}
      showModalHeader={true}
      showModalFooter={true}
      showAdd={true}
      showCancel={true}
      addAction={() => {
        if (validateTurnData()) {
          addTurn();
        }
      }}
      size="sm"
    >
      <React.Fragment>
        <p className="add-turn-title">{`Hab #${roomHistory.detalle_habitacion.numero}`}</p>
        <NumberInput
          key={"add-turn-cantidad"}
          horizontal={true}
          placeholder={""}
          value={turn}
          onChange={(value: any) => {
            setTurn(value);
            setErrorTurn(false);

            setPrice(
              Number(value) * Number(roomHistory.detalle_habitacion.precio)
            );
            setErrorPrice(false);
          }}
          className={"add-turn-input"}
          viewTitle={true}
          viewErrorMessage={errorTurn}
          title={`Turno`}
          // errorMessage={"Incorrecto"}
          decimals={2}
        ></NumberInput>
        <NumberInput
          key={"add-turn-precio"}
          horizontal={true}
          placeholder={""}
          value={price}
          onChange={(value: any) => {
            setPrice(value);
            setErrorPrice(false);

            setTurn(
              Number(value) / Number(roomHistory.detalle_habitacion.precio)
            );
            setErrorTurn(false);
          }}
          className={"add-turn-input"}
          viewTitle={true}
          viewErrorMessage={errorPrice}
          title={"Precio"}
          // errorMessage={"Incorrecto"}
          isCurrency={true}
          decimals={2}
        ></NumberInput>
      </React.Fragment>
    </ModalDefault>
  );
};

export default ModalAddTurn;
