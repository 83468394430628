import React, { useState } from "react";
import { BiCommentDetail } from "react-icons/bi";
interface DetailTableProps{
  data: any;
  isArray: boolean;
}

export const DetailTable: React.FunctionComponent<DetailTableProps> = ({
  data,
  isArray
}:DetailTableProps) => {
  const [viewOptions, setViewOptions] = useState(false);
  return(
    <div className="cell-container">
      <div
          className="cell-detail"
          onClick={() => {
          setViewOptions(!viewOptions);
          }}
        >
          <BiCommentDetail></BiCommentDetail>
          {viewOptions && isArray && data.length > 0 &&(
          <div className="detail-square">
            {data?.map((option: any) => (
            <div className="option-element" key={`option-${option}`}>{option}</div>
          ))}
          </div>
          )}
          {viewOptions && !isArray && data && data !== "" &&(
          <div className="detail-square">
            <div className="option-element">{data}</div>
          </div>
          )}
          </div>
          {((viewOptions && isArray && data.length > 0) || (viewOptions && !isArray && data && data !== "")) && (
          <div
            className="hidden-wall"
            onClick={() => {
              setViewOptions(!viewOptions);
            }}
          ></div>
        )}
    </div>
  )
}

export default DetailTable;