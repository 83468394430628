import React from "react";
import { InputNumber } from "primereact/inputnumber";
import "../style.scss";

interface NumberInputProps {
  horizontal?: boolean;
  className?: string;
  placeholder: string;
  value: any;
  disabled?: boolean;
  onChange: any;
  refElement?: any;
  viewTitle?: boolean;
  viewErrorMessage?: boolean;
  title?: any;
  errorMessage?: any;
  isCurrency?: boolean;
  isPorcentage?: boolean;
  width?: string;
  height?: string;
  decimals?: number;
}

const NumberInput: React.FunctionComponent<NumberInputProps> = ({
  horizontal = true,
  className,
  placeholder,
  value,
  disabled = false,
  onChange,
  refElement,
  viewTitle = false,
  viewErrorMessage = false,
  title = "",
  errorMessage = "",
  isCurrency = false,
  isPorcentage = false,
  width,
  height,
  decimals = 0,
}: any) => {
  return (
    <div
      className={
        horizontal
          ? "input-number-horizontal " + className
          : "input-number-vertical " + className
      }
    >
      {viewTitle && <div className="input-number-title">{title}</div>}
      <InputNumber
        // className={`${viewErrorMessage ? "error-mode" : ""}`}
        inputClassName={`${
          viewErrorMessage ? "error-mode" : ""
        } ${width} ${height}`}
        value={value}
        onChange={(data: any) => {
          onChange(data.value);
        }}
        onValueChange={(data: any) => {
          onChange(data.value);
        }}
        min={0}
        maxFractionDigits={decimals}
        placeholder={placeholder}
        disabled={disabled}
        ref={refElement}
        useGrouping={false}
        mode={isCurrency ? "currency" : undefined}
        currency={isCurrency ? "PEN" : undefined}
        locale={isCurrency ? "en-US" : undefined}
        suffix={isPorcentage ? "%" : undefined}
      />
      {viewErrorMessage && errorMessage && (
        <div className="input-number-error">{errorMessage}</div>
      )}
    </div>
  );
};

export default NumberInput;
