// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyA9S2NVO2wftoasunnGW8SatcfriZynckY",
  authDomain: "casablanca-webapp.firebaseapp.com",
  databaseURL: "https://casablanca-webapp.firebaseio.com",
  projectId: "casablanca-webapp",
  storageBucket: "casablanca-webapp.appspot.com",
  messagingSenderId: "663278681463",
  appId: "1:663278681463:web:b14d8c589ab8156c7c3572",
  measurementId: "G-LWCFBQ8HEP"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyDukV7-sH2MoV4qbAi7yCV1uNypzPyFXwA",
//   authDomain: "dacabi-demo.firebaseapp.com",
//   projectId: "dacabi-demo",
//   storageBucket: "dacabi-demo.appspot.com",
//   messagingSenderId: "354288071309",
//   appId: "1:354288071309:web:426f6d53a0020fec54e8c4"
// };


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getFirestore()