import React from "react";
import { useLocation } from "react-router-dom";
import "./style.scss";

import LeftToolbar from "./components/LeftToolbar";
import { Toast } from "primereact/toast";
import List from "./components/List";

const RoomsRent: React.FunctionComponent = () => {
  let defaultValue: any;
  const toastRef = React.useRef<Toast>(defaultValue);

  const [active, setActive] = React.useState<boolean>(true);

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();

  React.useEffect(() => {
    if (query.get("action") === "true") {
      toastRef.current.show({
        severity: "success",
        summary: "Rentar Habitación",
        detail: "Rentado satisfactoriamente",
        life: 3000,
        closable: false,
      });
    }
  }, [query]);

  return (
    <div className="rooms-rent-container">
      <List active={active}></List>
      <LeftToolbar active={active} setActive={setActive}></LeftToolbar>
      <Toast ref={toastRef} />
    </div>
  );
};

export default RoomsRent;
