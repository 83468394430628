import React from "react";
import { RootStore } from "../../../redux/Store";
import { useSelector } from "react-redux";

import {
  productI,
  stockHistoryI,
  userI,
} from "../../../utils/responseEntities";

import {
  porcentageTemplate,
  productCategoryTemplate,
  stockTemplate,
  textCapitalizeTemplate,
  textMoneyTemplate,
} from "../../../components/sub-components/table-components";
import ActionOverlay from "../../../components/sub-components/actions-overlay";
import ModalAddStockProduct from "./ModalAddStockProduct";
import { Column, ColumnBodyOptions } from "primereact/column";
import ModalDeleteProduct from "./ModalDeleteProduct";
import ModalEditProduct from "./ModalEditProduct";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { USER_TYPE } from "../../../utils/utils";
import ModalReduceStockProduct from "./ModalReduceStockProduct";
import ModalHistoryStock from "./ModalHistoryStock";

const List: React.FunctionComponent = () => {
  let defaultValue: any;
  const toastRef = React.useRef<Toast>(defaultValue);

  const [modalShowEdit, setModalShowEdit] = React.useState(false);
  const [productEdit, setProductEdit] = React.useState<productI>(defaultValue);

  const [modalShowDelete, setModalShowDelete] = React.useState(false);
  const [productDelete, setProductDelete] =
    React.useState<productI>(defaultValue);

  const [modalShowStock, setModalShowStock] = React.useState(false);
  const [productStock, setProductStock] =
    React.useState<productI>(defaultValue);

  const [modalShowStockR, setModalShowStockR] = React.useState(false);
  const [productStockR, setProductStockR] =
    React.useState<productI>(defaultValue);

  const [modalShowHistoryStock, setModalShowHistoryStock] =
    React.useState(false);
  const [historyStock, setHistoryStock] =
    React.useState<productI>(defaultValue);

  const permissionsAuth: Array<string> = useSelector(
    (globalState: RootStore) => globalState.auth.permissions
  );
  const userAuth: userI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.user
  );
  const productP: Array<productI> = useSelector(
    (globalState: RootStore) => globalState.products.products
  );
  const productHistory: Array<stockHistoryI> = useSelector(
    (globalState: RootStore) => globalState.products.historyStock
  );
  const loadingP: boolean = useSelector(
    (globalState: RootStore) => globalState.products.loading
  );

  const actionsTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    return (
      <React.Fragment>
        <div className="table-action-buttons">
          <ActionOverlay
            showStock={
              permissionsAuth.includes("product.general.stock") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            stockAction={() => {
              let auxProduct: productI =
                productP.find(
                  (product: productI) => rowData[field || ""] === product.key
                ) || defaultValue;
              setProductStock({ ...auxProduct } as productI);
              setModalShowStock(true);
            }}
            showReduceStock={
              permissionsAuth.includes("product.general.stock") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            reduceAction={() => {
              let auxProduct: productI =
                productP.find(
                  (product: productI) => rowData[field || ""] === product.key
                ) || defaultValue;
              setProductStockR({ ...auxProduct } as productI);
              setModalShowStockR(true);
            }}
            showhistoryStock={
              permissionsAuth.includes("product.general.stock") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            historyAction={() => {
              let auxProduct: productI =
                productP.find(
                  (product: productI) => rowData[field || ""] === product.key
                ) || defaultValue;
              setHistoryStock({ ...auxProduct } as productI);
              setModalShowHistoryStock(true);
            }}
            showEdit={
              permissionsAuth.includes("product.general.addedit") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            editAction={() => {
              let auxProduct: productI =
                productP.find(
                  (product: productI) => rowData[field || ""] === product.key
                ) || defaultValue;
              setProductEdit({ ...auxProduct } as productI);
              setModalShowEdit(true);
            }}
            showDelete={
              permissionsAuth.includes("product.general.delete") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            deleteAction={() => {
              let auxProduct: productI =
                productP.find(
                  (product: productI) => rowData[field || ""] === product.key
                ) || defaultValue;
              setProductDelete({ ...auxProduct } as productI);
              setModalShowDelete(true);
            }}
          ></ActionOverlay>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="product-list">
        <DataTable
          //GENERAL
          dataKey="_id"
          loading={loadingP}
          className="p-datatable-main"
          value={productP}
          //SORTING
          sortField="nombre"
          sortMode="single"
          sortOrder={1}
          //SCROLLING
          scrollable
          scrollDirection="vertical"
          //PAGINATION
          alwaysShowPaginator
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
        >
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="nombre"
            header="Nombre"
            body={textCapitalizeTemplate}
            headerClassName="text-align-left nombre-field"
            className="text-align-left nombre-field"
            sortable
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="categoria"
            header="Categoría"
            body={productCategoryTemplate}
            headerClassName="text-align-left categoria-field"
            className="text-align-left categoria-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="precio_unitario"
            header="Precio"
            body={textMoneyTemplate}
            headerClassName="text-align-left unitario-field"
            className="text-align-left unitario-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="stock"
            header="Stock"
            body={stockTemplate}
            headerClassName="text-align-center stock-field"
            className="text-align-center stock-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="stock_minimo"
            header="Stock Mínimo"
            body={stockTemplate}
            headerClassName="text-align-center minimo-field"
            className="text-align-center minimo-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="porc_aviso"
            header="Porcentaje"
            body={porcentageTemplate}
            headerClassName="text-align-center porcentaje-field"
            className="text-align-center porcentaje-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="key"
            header="Acciones"
            body={actionsTemplate}
            headerClassName="text-align-center acciones-field"
            className="text-align-center acciones-field"
          ></Column>
        </DataTable>
      </div>
      {modalShowStock && (
        <ModalAddStockProduct
          product={productStock}
          modalShow={modalShowStock}
          setModalShow={setModalShowStock}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Products - Stock",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            console.log("message");
            toastRef.current.show({
              severity: "error",
              summary: "Products - Stock",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalAddStockProduct>
      )}
      {modalShowStockR && (
        <ModalReduceStockProduct
          product={productStockR}
          modalShow={modalShowStockR}
          setModalShow={setModalShowStockR}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Products - Stock",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            console.log("message");
            toastRef.current.show({
              severity: "error",
              summary: "Products - Stock",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalReduceStockProduct>
      )}
      {modalShowHistoryStock && (
        <ModalHistoryStock
          product={historyStock}
          modalShow={modalShowHistoryStock}
          setModalShow={setModalShowHistoryStock}
          successFun={() => {}}
        ></ModalHistoryStock>
      )}
      {modalShowEdit && (
        <ModalEditProduct
          product={productEdit}
          modalShow={modalShowEdit}
          setModalShow={setModalShowEdit}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Products - Editar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            console.log("message");
            toastRef.current.show({
              severity: "error",
              summary: "Products - Editar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalEditProduct>
      )}
      {modalShowDelete && (
        <ModalDeleteProduct
          product={productDelete}
          modalShow={modalShowDelete}
          setModalShow={setModalShowDelete}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Products - Eliminar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            console.log("message");
            toastRef.current.show({
              severity: "error",
              summary: "Products - Eliminar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalDeleteProduct>
      )}
      <Toast ref={toastRef} />
    </React.Fragment>
  );
};

export default List;
