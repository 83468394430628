import React from "react";
import { useDispatch } from "react-redux";

import { deleteUserFB } from "../../../redux/users/UsersAction";
import { deleteUsersDTO } from "../../../utils/requestDto";
import { userI } from "../../../utils/responseEntities";
import { capitalizeWords } from "../../../utils/utils";

import ModalDefault from "../../../components/sub-components/modal-default";

interface ModalDeleteUserProps {
  user: userI;
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalDeleteUser: React.FunctionComponent<ModalDeleteUserProps> = ({
  user,
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalDeleteUserProps) => {
  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const deleteUser = () => {
    setLoadingAction(true);
    //DELETE USER
    dispatch(
      deleteUserFB(
        {
          key: user.key,
        } as deleteUsersDTO,
        () => {
          setLoadingAction(false);
          if (successFun) successFun("Eliminado satisfactoriamente");
          setModalShow(false);
        },
        () => {
          setLoadingAction(false);
          if (errorFun) errorFun("Ups! Algo salio mal");
          setModalShow(false);
        }
      )
    );
  };

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-users-delete"
      title={"Eliminar Usuario"}
      showModalHeader={true}
      showModalFooter={true}
      showDelete={true}
      showCancel={true}
      deleteAction={() => {
        deleteUser();
      }}
    >
      <p>
        {`¿Está seguro de eliminar al usuario `}
        <strong>{capitalizeWords(user.nombre)}</strong>
        {`?`}
      </p>
    </ModalDefault>
  );
};

export default ModalDeleteUser;
