import React from "react";
import Form from "react-bootstrap/Form";
import { InputText } from "primereact/inputtext";
import "../style.scss";
interface InputFieldProps {
  horizontal?: boolean;
  className?: string;
  placeholder: string;
  value: any;
  disabled?: boolean;
  length?: any;
  as?: string;
  regExp?: any;
  onChange: any;
  focusAction?: any;
  viewTitle?: boolean;
  viewErrorMessage?: boolean;
  title?: any;
  errorMessage?: any;
  isCurrency?: boolean;
  width?: string;
  height?: string;
  isUppercase?: boolean;
}

const InputField: React.FunctionComponent<InputFieldProps> = ({
  horizontal = true,
  className,
  placeholder,
  value,
  disabled = false,
  length,
  regExp,
  onChange,
  focusAction,
  viewTitle = false,
  viewErrorMessage = false,
  title = "",
  errorMessage = "",
  isCurrency = false,
  width,
  height,
  isUppercase = false,
}: any) => {
  return (
    <div
      className={
        horizontal
          ? "input-box-horizontal " + className
          : "input-box-vertical " + className
      }
    >
      {viewTitle && <div className="input-box-title">{title}</div>}
      <InputText
        className={`${viewErrorMessage ? "error-mode" : ""} ${
          isCurrency ? "currency-mode" : ""
        } ${width} ${height} ${isUppercase ? "uppercase" : ""}`}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        maxLength={length}
        spellCheck={false}
        onChange={(data: any) => {
          if (
            regExp === undefined ||
            regExp.test(data.target.value) ||
            data.target.value === ""
          ) {
            onChange(data.target.value);
          }
        }}
        onKeyPress={(data: any) => {
          if (data.key === "Enter") {
            if (focusAction) {
              focusAction();
            }
          }
        }}
      />
      {isCurrency && <div className="currency">{`$`}</div>}
      {viewErrorMessage && (
        <div className="input-box-error">{errorMessage}</div>
      )}
    </div>
  );
};

export default InputField;
