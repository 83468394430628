import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";

import { productI, settingsI } from "../../../utils/responseEntities";
import { addProductFB } from "../../../redux/products/ProductsAction";
import { addProductsDTO } from "../../../utils/requestDto";
import { PRODUCT_CATEGORY } from "../../../utils/utils";

import ModalDefault from "../../../components/sub-components/modal-default";
import SelectOption from "../../../components/sub-components/select-option";
import NumberInput from "../../../components/sub-components/input-number";
import InputField from "../../../components/sub-components/input-field";

interface ModalAddProductProps {
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalAddProduct: React.FunctionComponent<ModalAddProductProps> = ({
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalAddProductProps) => {
  let defaultValue: any;
  const settingsAuth: settingsI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.settings
  );

  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);
  const [state, setState] = React.useState<productI>({
    nombre: "",
    categoria: "",
    precio_unitario: "",
    stock: defaultValue,
    stock_minimo: defaultValue,
    porc_aviso: defaultValue,
    fecha_creacion: new Date(),
    local_cod: settingsAuth?.local_cod || 0,
  });

  const [errorNombre, setErrorNombre] = React.useState(false);
  const [errorCategoria, setErrorCategoria] = React.useState(false);
  const [errorPrecio, setErrorPrecio] = React.useState(false);
  const [errorStock, setErrorStock] = React.useState(false);
  const [errorStockMinimo, setErrorStockMinimo] = React.useState(false);
  const [errorPorcAviso, setErrorPorcAviso] = React.useState(false);

  const dispatch = useDispatch();

  const updateProductData = (name: any, value: any) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateProductData = () => {
    if (
      state.nombre === "" ||
      state.categoria === "" ||
      state.precio_unitario === "" ||
      !state.precio_unitario ||
      !state.stock ||
      !state.stock_minimo ||
      !state.porc_aviso
    ) {
      setErrorNombre(state.nombre === "" ? true : false);
      setErrorCategoria(state.categoria === "" ? true : false);
      setErrorPrecio(
        state.precio_unitario === "" || !state.precio_unitario ? true : false
      );
      setErrorStock(!state.stock ? true : false);
      setErrorStockMinimo(!state.stock_minimo ? true : false);
      setErrorPorcAviso(!state.porc_aviso ? true : false);
      return false;
    } else {
      return true;
    }
  };

  const addProduct = () => {
    setLoadingAction(true);
    //ADD PRODUCT
    dispatch(
      addProductFB(
        {
          product: {
            ...state,
            precio_unitario: Number(state.precio_unitario).toFixed(2),
          },
        } as addProductsDTO,
        () => {
          setLoadingAction(false);
          if (successFun) successFun("Agregado satisfactoriamente");
          setModalShow(false);
        },
        () => {
          setLoadingAction(false);
          if (errorFun) errorFun("Ups! Algo salio mal");
          setModalShow(false);
        }
      )
    );
  };

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-products-add"
      title={"Agregar Producto"}
      showModalHeader={true}
      showModalFooter={true}
      showAdd={true}
      showCancel={true}
      addAction={() => {
        if (validateProductData()) {
          addProduct();
        }
      }}
    >
      <React.Fragment>
        <InputField
          key={"add-product-nombre"}
          horizontal={false}
          placeholder={""}
          value={state.nombre}
          onChange={(value: any) => {
            updateProductData("nombre", value.toLowerCase());
            setErrorNombre(false);
          }}
          className={"add-product-input"}
          viewTitle={true}
          viewErrorMessage={errorNombre}
          title={"Nombre"}
          errorMessage={"Incorrecto"}
          height={"!h-[40px]"}
          width={"!w-full"}
          isUppercase={true}
        ></InputField>
        <SelectOption
          key={"add-product-categoria"}
          horizontal={false}
          className={"add-product-select"}
          clickAction={(value: string) => {
            updateProductData("categoria", value);
            setErrorCategoria(false);
          }}
          optionArray={[
            PRODUCT_CATEGORY.BEBIDA,
            PRODUCT_CATEGORY.CARAMELO,
            PRODUCT_CATEGORY.CHOCOLATE,
            PRODUCT_CATEGORY.CIGARRO,
            PRODUCT_CATEGORY.CUIDADO,
            PRODUCT_CATEGORY.GALLETA,
            PRODUCT_CATEGORY.SNACK,
          ]}
          selected={state.categoria}
          viewTitle={true}
          viewErrorMessage={errorCategoria}
          title={"CategorÍa"}
          errorMessage={"Incorrecto"}
        ></SelectOption>
        <NumberInput
          key={"add-product-precio"}
          horizontal={false}
          placeholder={""}
          value={state.precio_unitario}
          onChange={(value: any) => {
            updateProductData("precio_unitario", value);
            setErrorPrecio(false);
          }}
          className={"add-product-input"}
          viewTitle={true}
          viewErrorMessage={errorPrecio}
          title={"Precio"}
          errorMessage={"Incorrecto"}
          isCurrency={true}
          decimals={2}
        ></NumberInput>
        <NumberInput
          key={"add-product-stock"}
          horizontal={false}
          placeholder={""}
          value={state.stock}
          onChange={(value: any) => {
            updateProductData("stock", value);
            setErrorStock(false);
          }}
          className={"add-product-input"}
          viewTitle={true}
          viewErrorMessage={errorStock}
          title={"Stock"}
          errorMessage={"Incorrecto"}
        ></NumberInput>
        <NumberInput
          key={"add-product-minimo"}
          horizontal={false}
          placeholder={""}
          value={state.stock_minimo}
          onChange={(value: any) => {
            updateProductData("stock_minimo", value);
            setErrorStockMinimo(false);
          }}
          className={"add-product-input"}
          viewTitle={true}
          viewErrorMessage={errorStockMinimo}
          title={"Stock Minimo"}
          errorMessage={"Incorrecto"}
        ></NumberInput>
        <NumberInput
          key={"add-product-aviso"}
          horizontal={false}
          placeholder={""}
          value={state.porc_aviso}
          onChange={(value: any) => {
            updateProductData("porc_aviso", value);
            setErrorPorcAviso(false);
          }}
          className={"add-product-input"}
          viewTitle={true}
          viewErrorMessage={errorPorcAviso}
          title={"Porcentaje"}
          errorMessage={"Incorrecto"}
          isPorcentage={true}
        ></NumberInput>
      </React.Fragment>
    </ModalDefault>
  );
};

export default ModalAddProduct;
