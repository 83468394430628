import React from "react";
import { useDispatch } from "react-redux";

import { addPenaltyHistoryFB } from "../../../redux/roomsHistory/RoomsHistoryAction";
import { addPenaltyHistoryDTO } from "../../../utils/requestDto";
import { rentI } from "../../../utils/responseEntities";

import ModalDefault from "../../../components/sub-components/modal-default";
import NumberInput from "../../../components/sub-components/input-number";

interface ModalAddPenaltyProps {
  roomHistory: rentI;
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalAddPenalty: React.FunctionComponent<ModalAddPenaltyProps> = ({
  roomHistory,
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalAddPenaltyProps) => {
  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);
  const [penalty, setPenalty] = React.useState<string>("");
  const [errorPenalty, setErrorPenalty] = React.useState(false);

  const dispatch = useDispatch();

  const validatePenaltyData = () => {
    if (!penalty || penalty === "") {
      setErrorPenalty(true);
      return false;
    } else {
      return true;
    }
  };

  const addPenalty = () => {
    setLoadingAction(true);
    //ADD PENALTY
    dispatch(
      addPenaltyHistoryFB(
        {
          rent: { ...roomHistory, penalidad_monto: penalty.toString() },
        } as addPenaltyHistoryDTO,
        () => {
          setLoadingAction(false);
          if (successFun) successFun("Penalidad agregada satisfactoriamente");
          setModalShow(false);
        },
        () => {
          setLoadingAction(false);
          if (errorFun) errorFun("Ups! Algo salio mal");
          setModalShow(false);
        }
      )
    );
  };

  React.useEffect(() => {
    if (roomHistory) {
      setPenalty(roomHistory?.penalidad_monto || "");
    }
  }, [roomHistory]);

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-rent-addpenalty"
      title={`Agregar penalidad`}
      showModalHeader={true}
      showModalFooter={true}
      showAdd={true}
      showCancel={true}
      addAction={() => {
        if (validatePenaltyData()) {
          addPenalty();
        }
      }}
      size="sm"
    >
      <React.Fragment>
        <NumberInput
          horizontal={false}
          placeholder={""}
          value={penalty}
          onChange={(value: any) => {
            setPenalty(value);
            setErrorPenalty(false);
          }}
          className={"add-penalty-input"}
          viewTitle={true}
          viewErrorMessage={errorPenalty}
          title={`Hab #${roomHistory.detalle_habitacion.numero}`}
          errorMessage={"Incorrecto"}
          isCurrency={true}
        ></NumberInput>
      </React.Fragment>
    </ModalDefault>
  );
};

export default ModalAddPenalty;
