import {
  PRODUCTS_LOADING,
  PRODUCTS_FAIL,
  PRODUCTS_LIST,
} from "./ProductsTypes";
import {
  CollectionReference,
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  QuerySnapshot,
  FirestoreError,
  QueryConstraint,
  QueryDocumentSnapshot,
  deleteDoc,
  doc,
  updateDoc,
  increment,
  orderBy,
} from "firebase/firestore";
import { productI, stockHistoryI } from "../../utils/responseEntities";
import { db } from "../../lib/init-firebase";
import {
  addProductsDTO,
  deleteProductsDTO,
  fetchProductsDTO,
  updateProductsDTO,
  addStockDTO,
  addStockHistoryDTO,
  reduceStockDTO,
  fetchStockHistoryDTO,
} from "../../utils/requestDto";
import { PRODUCT_CATEGORY, TT_FILTER_LIST } from "../../utils/utils";

const dbPathProducts = "/productos";
let productRef = collection(
  db,
  dbPathProducts
) as CollectionReference<productI>;

const dbPathStock = "/stock";
let stockRef = collection(
  db,
  dbPathStock
) as CollectionReference<stockHistoryI>;

export const getProductListFB = (
  data: fetchProductsDTO,
  funDone?: any,
  funError?: any
) => {
  console.log("getProductListFB DATA:", data);
  return (dispatch: any) => {
    dispatch({ type: PRODUCTS_LOADING });

    let queryConst: Array<QueryConstraint> = [];

    if (data.type === TT_FILTER_LIST.NOMBRE) {
      queryConst.push(where("nombre", ">=", data.search.toLowerCase()));
      queryConst.push(where("nombre", "<=", data.search.toLowerCase() + "~"));
    } else if (data.type === TT_FILTER_LIST.PRODUCT_CATEGORY) {
      if (data.categoria !== PRODUCT_CATEGORY.TODOS) {
        queryConst.push(where("categoria", "==", data.categoria));
      }
    }
    queryConst.push(where("local_cod", "==", data.local));

    const q = query(productRef, ...queryConst);

    onSnapshot(
      q,
      (snapshot: QuerySnapshot<productI>) => {
        let auxProducts: Array<productI> = [];

        snapshot.docs.forEach((change: QueryDocumentSnapshot<productI>) => {
          // console.log(change.data());
          auxProducts.push({ key: change.id, ...change.data() });
        });

        dispatch({ type: PRODUCTS_LIST, payload: auxProducts });
        dispatch({ type: PRODUCTS_FAIL });

        if (funDone) {
          funDone();
        }
      },
      (error: FirestoreError) => {
        console.log("error on getProductListFB:", error);
        dispatch({ type: PRODUCTS_FAIL });

        if (funError) {
          funError();
        }
      }
    );
  };
};

export const addProductFB = (
  data: addProductsDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("addProductFB DATA:", data);
    await addDoc(productRef, data.product)
      .catch((error: any) => {
        console.log("error on addProductFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("addProductFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const updateProductFB = (
  data: updateProductsDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("updateProductFB DATA:", data);
    await updateDoc(doc(db, dbPathProducts + "/" + data.product.key), {
      nombre: data.product.nombre,
      categoria: data.product.categoria,
      precio_unitario: data.product.precio_unitario,
      stock: data.product.stock,
      stock_minimo: data.product.stock_minimo,
      porc_aviso: data.product.porc_aviso,
      local_cod: data.product.local_cod,
    })
      .catch((error: any) => {
        console.log("error on updateProductFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("updateProductFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const deleteProductFB = (
  data: deleteProductsDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("deleteProductFB DATA:", data);
    await deleteDoc(doc(db, dbPathProducts, data.key))
      .catch((error: any) => {
        console.log("error on deleteProductFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("deleteProductFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const addStockFB = (
  data: addStockDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("addStockFB DATA:", data);
    await updateDoc(doc(db, dbPathProducts + "/" + data.key), {
      stock: increment(data.addStock),
    })
      .catch((error: any) => {
        console.log("error on addStockFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("addStockFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const reduceStockFB = (
  data: reduceStockDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("reduceStockFB DATA:", data);
    await updateDoc(doc(db, dbPathProducts + "/" + data.key), {
      stock: increment(data.reduceStock),
    })
      .catch((error: any) => {
        console.log("error on reduceStockFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("reduceStockFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const addStockHistory = (
  data: addStockHistoryDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("addStockHistory DATA:", data);
    await addDoc(stockRef, data.stockHistoryI)
      .catch((error: any) => {
        console.log("error on addStockHistory:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("addStockHistory onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const getStockHistoryFB = (
  data: fetchStockHistoryDTO,
  funDone?: any,
  funError?: any
) => {
  return (dispatch: any) => {
    let queryConst: Array<QueryConstraint> = [];

    queryConst.push(where("product_key", "==", data.product_key));
    queryConst.push(orderBy("fecha_creacion", "desc"));

    const q = query(stockRef, ...queryConst);

    onSnapshot(
      q,
      (snapshot: QuerySnapshot<stockHistoryI>) => {
        let auxStockHistory: Array<stockHistoryI> = [];

        snapshot.docs.forEach(
          (change: QueryDocumentSnapshot<stockHistoryI>) => {
            auxStockHistory.push({ key: change.id, ...change.data() });
          }
        );

        if (funDone) {
          funDone(auxStockHistory);
        }
      },
      (error: FirestoreError) => {
        console.log("error on getStockHistoryFB:", error);

        if (funError) {
          funError();
        }
      }
    );
  };
};
