export const ROOMS_RENT_LOADING = "ROOMS_RENT_LOADING";
export const ROOMS_RENT_FAIL = "ROOMS_RENT_FAIL";
export const ROOMS_RENT_UPDATE_TYPE_SELECTOR =
  "ROOMS_RENT_UPDATE_TYPE_SELECTOR";
export const ROOMS_RENT_UPDATE_FLOOR_SELECTOR =
  "ROOMS_RENT_UPDATE_FLOOR_SELECTOR";
export const ROOMS_RENT_UPDATE_STATE_FILTER = "ROOMS_RENT_UPDATE_STATE_FILTER";
export const ROOMS_RENT_UPDATE_CONTENT_FILTER =
  "ROOMS_RENT_UPDATE_CONTENT_FILTER";

export interface RoomsRentFail {
  type: typeof ROOMS_RENT_FAIL;
  payload: any;
}
export interface RoomsRentLoading {
  type: typeof ROOMS_RENT_LOADING;
  payload: any;
}
export interface RoomsRentUpdateTypeSelector {
  type: typeof ROOMS_RENT_UPDATE_TYPE_SELECTOR;
  payload: any;
}
export interface RoomsRentUpdateFloorSelector {
  type: typeof ROOMS_RENT_UPDATE_FLOOR_SELECTOR;
  payload: any;
}
export interface RoomsRentUpdateStateFilter {
  type: typeof ROOMS_RENT_UPDATE_STATE_FILTER;
  payload: any;
}
export interface RoomsRentUpdateContentFilter {
  type: typeof ROOMS_RENT_UPDATE_CONTENT_FILTER;
  payload: any;
}

export type RoomsRentDispatchTypes =
  | RoomsRentLoading
  | RoomsRentFail
  | RoomsRentUpdateTypeSelector
  | RoomsRentUpdateFloorSelector
  | RoomsRentUpdateStateFilter
  | RoomsRentUpdateContentFilter;
