import React from "react";
import { useDispatch } from "react-redux";
import LoginBG from "../../assets/loginBg.png";
import LogoLogin from "../../assets/logoLogin.png";
import ActionButton from "../../components/sub-components/action-button";
import InputField from "../../components/sub-components/input-field";
import PasswordInput from "../../components/sub-components/input-password";
import { doLogin } from "../../redux/auth/AuthAction";
import { CheckAuthentication } from "../../redux/CheckAuth";
import { validEmailRegex } from "../../utils/utils";
import "./style.scss";

const Login: React.FunctionComponent = () => {
  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);
  const [state, setState] = React.useState<any>({
    email: "",
    password: "",
  });

  const [errorEmail, setErrorEmail] = React.useState<string>("");
  const [errorPassword, setErrorPassword] = React.useState<string>("");

  const dispatch = useDispatch();

  const updateLoginData = (name: any, value: any) => {
    setState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateEmail = (text: string) => {
    let error = "";
    if (!validEmailRegex.test(text)) {
      error = "Ingresar un email válido";
    }
    setErrorEmail(error);
  };

  const validateSubmit = () => {
    if (validEmailRegex.test(state.email) && state.password === "") {
      return false;
    } else {
      return true;
    }
  };

  const login = () => {
    setLoadingAction(true);
    //LOGIN ACTION
    dispatch(
      doLogin(
        {
          email: state.email.trim(),
          password: state.password.trim(),
        },
        (response: boolean) => {
          setLoadingAction(false);
          if (response) {
            setErrorEmail("");
            setErrorPassword("");
            CheckAuthentication();
          } else {
            setErrorPassword("Correo o Password Incorrecto");
          }
        },
        () => {
          setLoadingAction(false);
          setErrorPassword("Ups! algo salio mal");
        }
      )
    );
  };

  return (
    <div className="login-container">
      <div
        className="login-image"
        style={{ backgroundImage: `url(${LoginBG}` }}
      >
        <div className="login-logo">
          <img src={LogoLogin} alt="" />
        </div>
        <div className="login-message">
          <p className="row-2">Tu emprendimiento</p>
          <p className="row-2">es nuestra meta</p>
        </div>
      </div>
      <div className="login-box">
        <div className="login-form">
          <div className="main-title">
            <p>Bienvenido a</p>
            <p className="profit-text">Dacabi System</p>
          </div>
          <InputField
            key={"login-email"}
            horizontal={false}
            placeholder={""}
            value={state.email}
            onChange={(value: any) => {
              updateLoginData("email", value.trim());
              validateEmail(value.trim());
            }}
            className={"w-[300px]"}
            viewTitle={true}
            viewErrorMessage={errorEmail !== ""}
            title={"Email"}
            errorMessage={errorEmail}
            height={"!h-[40px]"}
            width={"!w-full"}
          ></InputField>
          <PasswordInput
            key={"login-password"}
            horizontal={false}
            placeholder={""}
            value={state.password}
            onChange={(value: any) => {
              updateLoginData("password", value);
              setErrorPassword("");
            }}
            onKeyPress={() => {
              if (validateSubmit()) {
                login();
              }
            }}
            className={"w-[300px]"}
            inputClassName={"w-[300px]"}
            viewTitle={true}
            viewErrorMessage={errorPassword !== ""}
            title={"Password"}
            errorMessage={errorPassword}
          ></PasswordInput>
          <ActionButton
            loadingAction={loadingAction}
            viewLogin={true}
            loginAction={() => {
              if (validateSubmit()) {
                login();
              }
            }}
          ></ActionButton>
        </div>
        <p className="login-copy">Technologial Empowerment | Copyright 2022</p>
      </div>
    </div>
  );
};

export default Login;
