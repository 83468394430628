import {
  bannedI,
  preferentI,
  productI,
  roomI,
} from "../../utils/responseEntities";
import {
  GENERAL_PRODUCT,
  GENERAL_ROOM,
  GENERAL_PREFERENT,
  GENERAL_BANNED,
  GeneralDispatchTypes,
} from "./GeneralTypes";

interface GeneralState {
  productList: Array<productI>;
  roomList: Array<roomI>;
  preferentList: Array<preferentI>;
  bannedList: Array<bannedI>;
}

const defaultState: GeneralState = {
  productList: [],
  roomList: [],
  preferentList: [],
  bannedList: [],
};

const generalReducer = (
  state: GeneralState = defaultState,
  action: GeneralDispatchTypes
): GeneralState => {
  switch (action.type) {
    case GENERAL_PRODUCT:
      return {
        ...state,
        productList: action.payload,
      };
    case GENERAL_ROOM:
      return {
        ...state,
        roomList: action.payload,
      };
    case GENERAL_PREFERENT:
      return {
        ...state,
        preferentList: action.payload,
      };
    case GENERAL_BANNED:
      return {
        ...state,
        bannedList: action.payload,
      };
    default:
      return state;
  }
};

export default generalReducer;
