import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addStockFB,
  addStockHistory,
} from "../../../redux/products/ProductsAction";
import {
  productI,
  settingsI,
  stockHistoryI,
  userI,
} from "../../../utils/responseEntities";
import { addStockDTO, addStockHistoryDTO } from "../../../utils/requestDto";
import { capitalizeWords } from "../../../utils/utils";

import ModalDefault from "../../../components/sub-components/modal-default";
import NumberInput from "../../../components/sub-components/input-number";
import { RootStore } from "../../../redux/Store";

interface ModalAddStockProductProps {
  product: productI;
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalAddStockProduct: React.FunctionComponent<
  ModalAddStockProductProps
> = ({
  product,
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalAddStockProductProps) => {
  let defaultValue: any;
  const settingsAuth: settingsI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.settings
  );
  const userAuth: userI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.user
  );

  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);
  const [state, setState] = React.useState<addStockDTO>({
    key: "",
    addStock: defaultValue,
  });

  const [errorStock, setErrorStock] = React.useState(false);

  const dispatch = useDispatch();

  const updateProductData = (name: any, value: any) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateStockData = () => {
    if (!state.addStock) {
      setErrorStock(!state.addStock ? true : false);
      return false;
    } else {
      return true;
    }
  };

  const addStock = () => {
    setLoadingAction(true);
    //ADD STOCK
    dispatch(
      addStockHistory(
        {
          stockHistoryI: {
            product_key: product?.key || "",
            stock: state.addStock,
            total_previo: product.stock,
            total_actual: product.stock + state.addStock,
            usuario: userAuth?.nombre || "",
            fecha_creacion: new Date(),
            local_cod: settingsAuth?.local_cod || 0,
          } as stockHistoryI,
        } as addStockHistoryDTO,
        () => {
          dispatch(
            addStockFB(
              {
                key: state.key,
                addStock: state.addStock,
              } as addStockDTO,
              () => {
                setLoadingAction(false);
                if (successFun) successFun("Stock agregado satisfactoriamente");
                setModalShow(false);
              },
              () => {
                setLoadingAction(false);
                if (errorFun) errorFun("Ups! Algo salio mal");
                setModalShow(false);
              }
            )
          );
        },
        () => {
          setLoadingAction(false);
          if (errorFun) errorFun("Ups! Algo salio mal");
          setModalShow(false);
        }
      )
    );
  };

  React.useEffect(() => {
    if (product) {
      setState((prev) => ({
        ...prev,
        key: product.key || "",
      }));
    }
  }, [product]);

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-products-addstock"
      title={`Agregar stock`}
      showModalHeader={true}
      showModalFooter={true}
      showAdd={true}
      showCancel={true}
      addAction={() => {
        if (validateStockData()) {
          addStock();
        }
      }}
      size="sm"
    >
      <React.Fragment>
        <NumberInput
          key={"add-product-stock"}
          horizontal={false}
          placeholder={""}
          value={state.addStock}
          onChange={(value: any) => {
            updateProductData("addStock", value);
            setErrorStock(false);
          }}
          className={"add-stockproduct-input"}
          viewTitle={true}
          viewErrorMessage={errorStock}
          title={`${capitalizeWords(product.nombre)}`}
          errorMessage={"Incorrecto"}
        ></NumberInput>
      </React.Fragment>
    </ModalDefault>
  );
};

export default ModalAddStockProduct;
