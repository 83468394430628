//RENT
export const DASHBOARD_FILTERS_RENT = "DASHBOARD_FILTERS_RENT";
export const DASHBOARD_FIRST_SELECTOR_RENT = "DASHBOARD_FIRST_SELECTOR_RENT";
export const DASHBOARD_SECOND_SELECTOR_RENT = "DASHBOARD_SECOND_SELECTOR_RENT";
export const DASHBOARD_FIRST_LIST_RENT = "DASHBOARD_FIRST_LIST_RENT";
export const DASHBOARD_SECOND_LIST_RENT = "DASHBOARD_SECOND_LIST_RENT";
//SELL
export const DASHBOARD_FILTERS_SELL = "DASHBOARD_FILTERS_SELL";
export const DASHBOARD_FIRST_SELECTOR_SELL = "DASHBOARD_FIRST_SELECTOR_SELL";
export const DASHBOARD_SECOND_SELECTOR_SELL = "DASHBOARD_SECOND_SELECTOR_SELL";
export const DASHBOARD_FIRST_LIST_SELL = "DASHBOARD_FIRST_LIST_SELL";
export const DASHBOARD_SECOND_LIST_SELL = "DASHBOARD_SECOND_LIST_SELL";

//RENT
export interface DashboardFiltersRent {
  type: typeof DASHBOARD_FILTERS_RENT;
  payload: any;
}
export interface DashboardFirstSelectorRent {
  type: typeof DASHBOARD_FIRST_SELECTOR_RENT;
  payload: any;
}
export interface DashboardSecondSelectorRent {
  type: typeof DASHBOARD_SECOND_SELECTOR_RENT;
  payload: any;
}
export interface DashboardFirstListRent {
  type: typeof DASHBOARD_FIRST_LIST_RENT;
  payload: any;
}
export interface DashboardSecondListRent {
  type: typeof DASHBOARD_SECOND_LIST_RENT;
  payload: any;
}

//SELL
export interface DashboardFiltersSell {
  type: typeof DASHBOARD_FILTERS_SELL;
  payload: any;
}
export interface DashboardFirstSelectorSell {
  type: typeof DASHBOARD_FIRST_SELECTOR_SELL;
  payload: any;
}
export interface DashboardSecondSelectorSell {
  type: typeof DASHBOARD_SECOND_SELECTOR_SELL;
  payload: any;
}
export interface DashboardFirstListSell {
  type: typeof DASHBOARD_FIRST_LIST_SELL;
  payload: any;
}
export interface DashboardSecondListSell {
  type: typeof DASHBOARD_SECOND_LIST_SELL;
  payload: any;
}

export type DashboardDispatchTypes =
//RENT
  | DashboardFiltersRent
  | DashboardFirstSelectorRent
  | DashboardSecondSelectorRent
  | DashboardFirstListRent
  | DashboardSecondListRent
//SELL  
  | DashboardFiltersSell
  | DashboardFirstSelectorSell
  | DashboardSecondSelectorSell
  | DashboardFirstListSell
  | DashboardSecondListSell;