import React from "react";
import { BsThreeDots } from "react-icons/bs";
import { BiDoorOpen } from "react-icons/bi";
import { OverlayPanel } from "primereact/overlaypanel";
import {
  FiAlertTriangle,
  FiEdit2,
  FiEye,
  FiLock,
  FiMinus,
  FiPlus,
  FiTrash,
  FiUserCheck,
  FiUserX,
} from "react-icons/fi";

interface ActionButtonProps {
  //======ADD STOCK
  showStock?: boolean;
  stockAction?(): void;
  //======REDUCE STOCK
  showReduceStock?: boolean;
  reduceAction?(): void;
  //======HISTORY STOCK ADD || REDUCE
  showhistoryStock?: boolean;
  historyAction?(): void;
  //======SEE DETAIL
  showDetail?: boolean;
  detailAction?(): void;
  //======ENABLE OR DISABLE
  showEnable?: boolean;
  isEnable?: boolean;
  enableAction?(): void;
  //======ADD TURN
  showTurn?: boolean;
  turnAction?(): void;
  //======ADD PUNISHMENT
  showPunish?: boolean;
  punishAction?(): void;
  //======ADD PREFERENT
  showPreferent?: boolean;
  preferentAction?(): void;
  //======ADD BANNED
  showBanned?: boolean;
  bannedAction?(): void;
  //======FREE ROOM
  showFreeRoom?: boolean;
  freeRoomAction?(): void;
  //======EDIT
  showEdit?: boolean;
  editAction?(): void;
  //======DELETE
  showDelete?: boolean;
  deleteAction?(): void;
}

const ActionOverlay: React.FunctionComponent<ActionButtonProps> = ({
  //======ADD STOCK
  showStock = false,
  stockAction,
  //======REDUCE STOCK
  showReduceStock = false,
  reduceAction,
  //======HISTORY STOCK ADD || REDUCE
  showhistoryStock = false,
  historyAction,
  //======SEE DETAIL
  showDetail = false,
  detailAction,
  //======ENABLE OR DISABLE
  showEnable = false,
  isEnable = false,
  enableAction,
  //======ADD TURN
  showTurn = false,
  turnAction,
  //======ADD PUNISHMENT
  showPunish = false,
  punishAction,
  //======ADD PREFERENT
  showPreferent = false,
  preferentAction,
  //======ADD BANNED
  showBanned = false,
  bannedAction,
  //======FREE ROOM
  showFreeRoom = false,
  freeRoomAction,
  //======EDIT
  showEdit = false,
  editAction,
  //======DELETE
  showDelete = false,
  deleteAction,
}: any) => {
  const op = React.useRef<OverlayPanel>(null);

  return (
    <React.Fragment>
      <div
        key={Math.random().toString(36).substring(7)}
        className="action-detail"
        onClick={(e: any) => {
          op.current?.toggle(e);
        }}
        aria-haspopup
        aria-controls="overlay_panel"
      >
        <BsThreeDots></BsThreeDots>
      </div>
      <OverlayPanel ref={op} id="overlay_panel" className="action-overlaypanel">
        {showStock && (
          <div
            className="action-row"
            onClick={() => {
              if (stockAction) {
                stockAction();
              }
            }}
          >
            <FiPlus></FiPlus>
            <p>{"Agregar Stock"}</p>
          </div>
        )}
        {showReduceStock && (
          <div
            className="action-row"
            onClick={() => {
              if (reduceAction) {
                reduceAction();
              }
            }}
          >
            <FiMinus></FiMinus>
            <p>{"Reducir Stock"}</p>
          </div>
        )}
        {showDetail && (
          <div
            className="action-row"
            onClick={() => {
              if (detailAction) {
                detailAction();
              }
            }}
          >
            <FiEye></FiEye>
            <p>{"Ver Detalle"}</p>
          </div>
        )}
        {showhistoryStock && (
          <div
            className="action-row"
            onClick={() => {
              if (historyAction) {
                historyAction();
              }
            }}
          >
            <FiEye></FiEye>
            <p>{"Historial Stock"}</p>
          </div>
        )}
        {showEnable && (
          <div
            className="action-row"
            onClick={() => {
              if (enableAction) {
                enableAction();
              }
            }}
          >
            <FiLock></FiLock>
            <p>{isEnable ? "Habilitar" : "Deshabilitar"}</p>
          </div>
        )}
        {showTurn && (
          <div
            className="action-row"
            onClick={() => {
              if (turnAction) {
                turnAction();
              }
            }}
          >
            <FiPlus></FiPlus>
            <p>{"Agregar Turno"}</p>
          </div>
        )}
        {showPunish && (
          <div
            className="action-row"
            onClick={() => {
              if (punishAction) {
                punishAction();
              }
            }}
          >
            <FiAlertTriangle></FiAlertTriangle>
            <p>{"Agregar Penalidad"}</p>
          </div>
        )}
        {showPreferent && (
          <div
            className="action-row"
            onClick={() => {
              if (preferentAction) {
                preferentAction();
              }
            }}
          >
            <FiUserCheck></FiUserCheck>
            <p>{"Guardar Preferente"}</p>
          </div>
        )}
        {showBanned && (
          <div
            className="action-row"
            onClick={() => {
              if (bannedAction) {
                bannedAction();
              }
            }}
          >
            <FiUserX></FiUserX>
            <p>{"Guardar Banned"}</p>
          </div>
        )}
        {showFreeRoom && (
          <div
            className="action-row"
            onClick={() => {
              if (freeRoomAction) {
                freeRoomAction();
              }
            }}
          >
            <BiDoorOpen></BiDoorOpen>
            <p>{"Liberar Habitación"}</p>
          </div>
        )}
        {showEdit && (
          <div
            className="action-row"
            onClick={() => {
              if (editAction) {
                editAction();
              }
            }}
          >
            <FiEdit2></FiEdit2>
            <p>{"Editar"}</p>
          </div>
        )}
        {showDelete && (
          <div
            className="action-row"
            onClick={() => {
              if (deleteAction) {
                deleteAction();
              }
            }}
          >
            <FiTrash className="stroke-[#ff4a57]"></FiTrash>
            <p className="text-[#ff4a57]">{"Eliminar"}</p>
          </div>
        )}
      </OverlayPanel>
    </React.Fragment>
  );
};

export default ActionOverlay;
