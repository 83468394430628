import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";

import {
  getUserLevel,
  onlyLetters,
  SEX_LIST,
  USER_TYPE,
  validEmailRegex,
} from "../../../utils/utils";
import { addUserFB, checkEmailInUseFB } from "../../../redux/users/UsersAction";
import { addUsersDTO, CheckInUseRequest } from "../../../utils/requestDto";
import { settingsI, userI } from "../../../utils/responseEntities";

import ModalDefault from "../../../components/sub-components/modal-default";
import SelectOption from "../../../components/sub-components/select-option";
import InputField from "../../../components/sub-components/input-field";

interface ModalAddUserProps {
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalAddUser: React.FunctionComponent<ModalAddUserProps> = ({
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalAddUserProps) => {
  let defaultValue: any;

  const settingsAuth: settingsI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.settings
  );

  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);
  const [state, setState] = React.useState<userI>({
    nombre: "",
    password: "",
    correo: "",
    rol: "",
    sexo: "",
    nivel: defaultValue,
    fecha_creacion: new Date(),
    local_cod: settingsAuth?.local_cod || 0,
  });

  const [errorNombre, setErrorNombre] = React.useState(false);
  const [errorPassword, setErrorPassword] = React.useState(false);
  const [errorCorreo, setErrorCorreo] = React.useState(false);
  const [errorRol, setErrorRol] = React.useState(false);
  const [errorSexo, setErrorSexo] = React.useState(false);

  const dispatch = useDispatch();

  const updateUserData = (name: any, value: any) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateUserData = () => {
    if (
      state.nombre === "" ||
      state.password === "" ||
      !validEmailRegex.test(state.correo) ||
      state.rol === "" ||
      state.sexo === "" ||
      !state.local_cod
    ) {
      setErrorNombre(state.nombre === "" ? true : false);
      setErrorPassword(state.password === "" ? true : false);
      setErrorCorreo(!validEmailRegex.test(state.correo) ? true : false);
      setErrorRol(state.rol === "" ? true : false);
      setErrorSexo(state.sexo === "" ? true : false);
      return false;
    } else {
      return true;
    }
  };

  const addUser = () => {
    setLoadingAction(true);
    //ADD USER
    dispatch(
      checkEmailInUseFB(
        {
          email: state.correo,
        } as CheckInUseRequest,
        (response: any) => {
          if (!response) {
            dispatch(
              addUserFB(
                {
                  user: state,
                } as addUsersDTO,
                () => {
                  setLoadingAction(false);
                  if (successFun) successFun("Agregado satisfactoriamente");
                  setModalShow(false);
                },
                () => {
                  setLoadingAction(false);
                  if (errorFun) errorFun("Ups! Algo salio mal");
                  setModalShow(false);
                }
              )
            );
          } else {
            console.log("correo ya existe");
            setLoadingAction(false);
            if (errorFun) errorFun("Correo ya utilizado");
          }
        }
      )
    );
  };

  return (
    <React.Fragment>
      <ModalDefault
        loadingAction={loadingAction}
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        customClass="modal-users-add"
        title={"Agregar Usuario"}
        showModalHeader={true}
        showModalFooter={true}
        showAdd={true}
        showCancel={true}
        addAction={() => {
          if (validateUserData()) {
            addUser();
          }
        }}
      >
        <InputField
          key={"add-user-nombre"}
          horizontal={false}
          placeholder={""}
          value={state.nombre}
          onChange={(value: string) => {
            updateUserData("nombre", value.toLowerCase());
            setErrorNombre(false);
          }}
          className={"add-user-input"}
          regExp={onlyLetters}
          viewTitle={true}
          viewErrorMessage={errorNombre}
          title={"Nombre"}
          errorMessage={"Incorrecto"}
          height={"!h-[40px]"}
          width={"!w-full"}
          isUppercase={true}
        ></InputField>
        <InputField
          key={"add-user-password"}
          horizontal={false}
          placeholder={""}
          value={state.password}
          onChange={(value: any) => {
            updateUserData("password", value);
            setErrorPassword(false);
          }}
          className={"add-user-input"}
          viewTitle={true}
          viewErrorMessage={errorPassword}
          title={"Password"}
          errorMessage={"Incorrecto"}
          height={"!h-[40px]"}
          width={"!w-full"}
        ></InputField>
        <InputField
          key={"add-user-correo"}
          horizontal={false}
          placeholder={""}
          value={state.correo}
          onChange={(value: any) => {
            updateUserData("correo", value.toLowerCase());
            setErrorCorreo(false);
          }}
          className={"add-user-input"}
          viewTitle={true}
          viewErrorMessage={errorCorreo}
          title={"Correo"}
          errorMessage={"Incorrecto"}
          height={"!h-[40px]"}
          width={"!w-full"}
          isUppercase={true}
        ></InputField>
        <SelectOption
          key={"add-user-rol"}
          horizontal={false}
          className={"add-user-select"}
          clickAction={(value: string) => {
            updateUserData("rol", value);
            updateUserData("nivel", getUserLevel(value));
            setErrorRol(false);
          }}
          optionArray={[
            USER_TYPE.ADMINISTRADOR,
            USER_TYPE.RECEPCIONISTA,
            USER_TYPE.LIMPIEZA,
          ]}
          selected={state.rol}
          viewTitle={true}
          viewErrorMessage={errorRol}
          title={"Rol"}
          errorMessage={"Incorrecto"}
        ></SelectOption>
        <SelectOption
          key={"add-user-sexo"}
          horizontal={false}
          className={"add-user-select"}
          clickAction={(value: string) => {
            updateUserData("sexo", value);
            setErrorSexo(false);
          }}
          optionArray={[SEX_LIST.MASCULINO, SEX_LIST.FEMENINO]}
          selected={state.sexo}
          viewTitle={true}
          viewErrorMessage={errorSexo}
          title={"Sexo"}
          errorMessage={"Incorrecto"}
        ></SelectOption>
      </ModalDefault>
    </React.Fragment>
  );
};

export default ModalAddUser;
