import React from "react";
import { useDispatch } from "react-redux";

import { deleteSellHistoryFB } from "../../../redux/productsHistory/ProductsHistoryAction";
import {
  addStockDTO,
  deleteProductHistoryDTO,
} from "../../../utils/requestDto";
import { sellI } from "../../../utils/responseEntities";
import { capitalizeWords } from "../../../utils/utils";

import ModalDefault from "../../../components/sub-components/modal-default";
import { addStockFB } from "../../../redux/products/ProductsAction";

interface ModalDeleteProductHistoryProps {
  productsHistory: sellI;
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalDeleteProductHistory: React.FunctionComponent<
  ModalDeleteProductHistoryProps
> = ({
  productsHistory,
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalDeleteProductHistoryProps) => {
  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const deleteProductHistory = () => {
    setLoadingAction(true);
    //DELETE SELL
    dispatch(
      deleteSellHistoryFB(
        {
          key: productsHistory.key,
        } as deleteProductHistoryDTO,
        () => {
          Object.entries(productsHistory.detalle).forEach((element) => {
            dispatch(
              addStockFB({
                key: element[1]["key"],
                addStock: element[1]["cantidad"],
              } as addStockDTO)
            );
          });
          setLoadingAction(false);
          if (successFun) successFun("Eliminado satisfactoriamente");
          setModalShow(false);
        },
        () => {
          setLoadingAction(false);
          if (errorFun) errorFun("Ups! Algo salio mal");
          setModalShow(false);
        }
      )
    );
  };

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-products-delete"
      title={"Eliminar Producto"}
      showModalHeader={true}
      showModalFooter={true}
      showDelete={true}
      showCancel={true}
      deleteAction={() => {
        deleteProductHistory();
      }}
    >
      <p>
        {`¿Desea eliminar la venta que realizó `}
        <strong>{capitalizeWords(productsHistory.recepcionista)}</strong>
        {` por un monto de `}
        <strong>S/.{capitalizeWords(productsHistory.monto)}</strong>
        {`?`}
      </p>
    </ModalDefault>
  );
};

export default ModalDeleteProductHistory;
