import { filterI, PRODUCT_CATEGORY, TT_FILTER_LIST } from "../../utils/utils";

import {
  PRODUCTS_SELL_LOADING,
  PRODUCTS_SELL_FAIL,
  PRODUCTS_SELL_UPDATE_FILTER,
  PRODUCTS_SELL_UPDATE_SELECTOR,
  PRODUCTS_SELL_SEARCH,
  ProductsSellDispatchTypes,
} from "./ProductsSellTypes";

interface ProductsSellState {
  loading: boolean;
  filters: Array<filterI>;
  selector: PRODUCT_CATEGORY;
  search: string;
  selectOption: Array<PRODUCT_CATEGORY>;
}

const defaultState: ProductsSellState = {
  loading: false,
  filters: [
    { name: TT_FILTER_LIST.NOMBRE, value: true },
    { name: TT_FILTER_LIST.PRODUCT_CATEGORY, value: false },
  ],
  selector: PRODUCT_CATEGORY.TODOS,
  search: "",
  selectOption: [
    PRODUCT_CATEGORY.TODOS,
    PRODUCT_CATEGORY.BEBIDA,
    PRODUCT_CATEGORY.CARAMELO,
    PRODUCT_CATEGORY.CHOCOLATE,
    PRODUCT_CATEGORY.CIGARRO,
    PRODUCT_CATEGORY.CUIDADO,
    PRODUCT_CATEGORY.GALLETA,
    PRODUCT_CATEGORY.SNACK,
  ],
};

const productsSellReducer = (
  state: ProductsSellState = defaultState,
  action: ProductsSellDispatchTypes
): ProductsSellState => {
  switch (action.type) {
    case PRODUCTS_SELL_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PRODUCTS_SELL_FAIL:
      return {
        ...state,
        loading: false,
      };
    case PRODUCTS_SELL_UPDATE_FILTER:
      return {
        ...state,
        filters: action.payload,
      };
    case PRODUCTS_SELL_UPDATE_SELECTOR:
      return {
        ...state,
        selector: action.payload,
      };
    case PRODUCTS_SELL_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    default:
      return state;
  }
};

export default productsSellReducer;
