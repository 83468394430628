import React from "react";
import { RiCloseLine } from "react-icons/ri";
import Modal from "react-bootstrap/Modal";
import { Button } from "primereact/button";

interface ModalDefaultProps {
  loadingAction?: boolean;
  show: boolean;
  onHide: any;
  size?: "sm" | "md" | "lg" | "xl" | "xxl";
  customClass?: any;
  title?: any;
  children: any;
  showModalHeader?: boolean;
  showModalFooter?: boolean;
  showEnable?: boolean;
  showAdd?: boolean;
  showEdit?: boolean;
  showDelete?: boolean;
  showReduce?: boolean;
  showClose?: boolean;
  showCancel?: boolean;
  showClean?: boolean;
  enableAction?(): void;
  addAction?(): void;
  editAction?(): void;
  deleteAction?(): void;
  reduceAction?(): void;
  closeAction?(): void;
  cancelAction?(): void;
  cleanAction?(): void;
  isEnable?: boolean;
}

const ModalDefault: React.FunctionComponent<ModalDefaultProps> = ({
  loadingAction = false,
  show,
  onHide,
  size = "md",
  customClass,
  title,
  children,
  showModalHeader = false,
  showModalFooter = false,
  showEnable = false,
  showAdd = false,
  showEdit = false,
  showDelete = false,
  showReduce = false,
  showCancel = false,
  showClose = false,
  showClean = false,
  enableAction,
  addAction,
  editAction,
  deleteAction,
  reduceAction,
  cancelAction,
  closeAction,
  cleanAction,
  isEnable = false,
}: any) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={true}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="static-modal"
      size={size || ""}
      className={customClass || ""}
    >
      {showModalHeader && (
        <Modal.Header>
          <Modal.Title className="title">{title || ""}</Modal.Title>
          <div className="close-button" onClick={onHide}>
            <RiCloseLine />
          </div>
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
      {showModalFooter && (
        <Modal.Footer>
          {showEnable && (
            <Button
              className="button button-modal-enable"
              label={isEnable ? "Habilitar" : "Deshabilitar"}
              loading={loadingAction}
              disabled={loadingAction}
              onClick={() => {
                if (enableAction) enableAction();
              }}
            />
          )}
          {showAdd && (
            <Button
              className="button button-modal-add"
              label={"Agregar"}
              loading={loadingAction}
              disabled={loadingAction}
              onClick={() => {
                if (addAction) addAction();
              }}
            />
          )}
          {showEdit && (
            <Button
              className="button button-modal-edit"
              label={"Editar"}
              loading={loadingAction}
              disabled={loadingAction}
              onClick={() => {
                if (editAction) editAction();
              }}
            />
          )}
          {showDelete && (
            <Button
              className="button button-modal-delete"
              label={"Eliminar"}
              loading={loadingAction}
              disabled={loadingAction}
              onClick={() => {
                if (deleteAction) deleteAction();
              }}
            />
          )}
          {showClean && (
            <Button
              className="button button-modal-delete"
              label={"Limpiar"}
              loading={loadingAction}
              disabled={loadingAction}
              onClick={() => {
                if (cleanAction) cleanAction();
              }}
            />
          )}
          {showReduce && (
            <Button
              className="button button-modal-reduce"
              label={"Reducir"}
              loading={loadingAction}
              disabled={loadingAction}
              onClick={() => {
                if (reduceAction) reduceAction();
              }}
            />
          )}
          {showClose && (
            <Button
              className="button button-modal-close"
              label={"Cerrar"}
              onClick={() => {
                onHide();
                if (closeAction) closeAction();
              }}
            />
          )}
          {showCancel && (
            <Button
              className="button button-modal-cancel"
              label={"Cancelar"}
              onClick={() => {
                onHide();
                if (cancelAction) cancelAction();
              }}
            />
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ModalDefault;
