import { settingsI, userI } from "../../utils/responseEntities";

export const SET_WINDOW_SIZE = "SET_WINDOW_SIZE";

export const SET_AUTH = "SET_AUTH";
export const SET_AUTH_LOADING = "SET_AUTH_LOADING";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";

export const SET_USER = "SET_USER";
export const SET_SETTINGS = "SET_SETTINGS";
export const SET_PERMISSIONS = "SET_PERMISSIONS";

export interface SetWindowSize {
  type: typeof SET_WINDOW_SIZE;
  payload: "MOBILE" | "WEB";
}
export interface SetAuth {
  type: typeof SET_AUTH;
  access_token: string;
}
export interface SetAuthLoading {
  type: typeof SET_AUTH_LOADING;
  authLoading: boolean;
}
export interface SetUnAuthenticated {
  type: typeof SET_UNAUTHENTICATED;
}
export interface SetAuthenticated {
  type: typeof SET_AUTHENTICATED;
  superAdmin: boolean;
  authenticated: boolean;
}
export interface SetUser {
  type: typeof SET_USER;
  payload: userI;
}
export interface SetSettings {
  type: typeof SET_SETTINGS;
  payload: settingsI;
}
export interface SetPermissions {
  type: typeof SET_PERMISSIONS;
  payload: Array<string>;
}

export type AuthDispatchTypes =
  | SetWindowSize
  | SetAuth
  | SetAuthLoading
  | SetUnAuthenticated
  | SetAuthenticated
  | SetUser
  | SetSettings
  | SetPermissions;
