import { customDateI } from "../../utils/utils";

export const ROOMS_HISTORY_LOADING_LAST = "ROOMS_HISTORY_LOADING_LAST";
export const ROOMS_HISTORY_FAIL_LAST = "ROOMS_HISTORY_FAIL_LAST";
export const ROOMS_HISTORY_LOADING_ACTUAL = "ROOMS_HISTORY_LOADING_ACTUAL";
export const ROOMS_HISTORY_FAIL_ACTUAL = "ROOMS_HISTORY_FAIL_ACTUAL";
export const ROOMS_HISTORY_LOADING_CUSTOM = "ROOMS_HISTORY_LOADING_CUSTOM";
export const ROOMS_HISTORY_FAIL_CUSTOM = "ROOMS_HISTORY_FAIL_CUSTOM";

export const ROOMS_HISTORY_LIST_LAST = "ROOMS_HISTORY_LIST_LAST";
export const ROOMS_HISTORY_LIST_ACTUAL = "ROOMS_HISTORY_LIST_ACTUAL";
export const ROOMS_HISTORY_LIST_CUSTOM = "ROOMS_HISTORY_LIST_CUSTOM";

export const ROOMS_HISTORY_TURN = "ROOMS_HISTORY_TURN";
export const ROOMS_HISTORY_RANGE = "ROOMS_HISTORY_RANGE";
export const ROOMS_HISTORY_FINISHED = "ROOMS_HISTORY_FINISHED";
export const ROOMS_HISTORY_HOLD = "ROOMS_HISTORY_HOLD";

export interface RoomsHistoryLoadingLast {
  type: typeof ROOMS_HISTORY_LOADING_LAST;
  payload: any;
}
export interface RoomsHistoryFailLast {
  type: typeof ROOMS_HISTORY_FAIL_LAST;
  payload: any;
}
export interface RoomsHistoryLoadingActual {
  type: typeof ROOMS_HISTORY_LOADING_ACTUAL;
  payload: any;
}
export interface RoomsHistoryFailActual {
  type: typeof ROOMS_HISTORY_FAIL_ACTUAL;
  payload: any;
}
export interface RoomsHistoryLoadingCustom {
  type: typeof ROOMS_HISTORY_LOADING_CUSTOM;
  payload: any;
}
export interface RoomsHistoryFailCustom {
  type: typeof ROOMS_HISTORY_FAIL_CUSTOM;
  payload: any;
}
export interface RoomsHistoryListLast {
  type: typeof ROOMS_HISTORY_LIST_LAST;
  payload: any;
}
export interface RoomsHistoryListActual {
  type: typeof ROOMS_HISTORY_LIST_ACTUAL;
  payload: any;
}
export interface RoomsHistoryListCustom {
  type: typeof ROOMS_HISTORY_LIST_CUSTOM;
  payload: any;
}
export interface RoomsHistoryTurn {
  type: typeof ROOMS_HISTORY_TURN;
  payload: any;
}
export interface RoomsHistoryRange {
  type: typeof ROOMS_HISTORY_RANGE;
  payload: customDateI;
}
export interface RoomsHistoryFinished {
  type: typeof ROOMS_HISTORY_FINISHED;
  payload: boolean;
}
export interface RoomsHistoryHold {
  type: typeof ROOMS_HISTORY_HOLD;
  payload: boolean;
}

export type RoomsHistoryDispatchTypes =
  | RoomsHistoryLoadingLast
  | RoomsHistoryFailLast
  | RoomsHistoryLoadingActual
  | RoomsHistoryFailActual
  | RoomsHistoryLoadingCustom
  | RoomsHistoryFailCustom
  | RoomsHistoryTurn
  | RoomsHistoryRange
  | RoomsHistoryFinished
  | RoomsHistoryHold
  | RoomsHistoryListLast
  | RoomsHistoryListActual
  | RoomsHistoryListCustom;
