import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { useDispatch } from "react-redux";
import ModalDefault from "../../../components/sub-components/modal-default";
import {
  dateCreateTemplate,
  dateRentInTemplate,
  stockTemplate,
  textCapitalizeTemplate,
} from "../../../components/sub-components/table-components";
import { getStockHistoryFB } from "../../../redux/products/ProductsAction";
import { fetchStockHistoryDTO } from "../../../utils/requestDto";
import { productI, stockHistoryI } from "../../../utils/responseEntities";

interface ModalHistoryStockProps {
  product: productI;
  modalShow: any;
  setModalShow: any;
  successFun: any;
}

export const ModalHistoryStock: React.FunctionComponent<
  ModalHistoryStockProps
> = ({
  product,
  modalShow,
  setModalShow,
  successFun,
}: ModalHistoryStockProps) => {
  let defaultValue: any;

  const [stockHistoryList, setStockHistoryList] = React.useState<
    Array<stockHistoryI>
  >([]);

  const dispatch = useDispatch();

  const historyStock = (rowData: any, { field }: ColumnBodyOptions) => {
    let color = "";
    rowData.total_previo > rowData.total_actual
      ? (color = "!bg-[#ff4a57]")
      : (color = "!bg-[#00a35a]");
    return (
      <React.Fragment>
        <div className="cell-container">
          <div className={`${color} cell-stock !text-[#fff]`}>
            {rowData[field || ""]}
          </div>
        </div>
      </React.Fragment>
    );
  };
  const getStockHistory = () => {
    dispatch(
      getStockHistoryFB(
        {
          product_key: product?.key || "",
        } as fetchStockHistoryDTO,
        (value: Array<stockHistoryI>) => {
          console.log(value);
          setStockHistoryList(value);
        },
        () => {}
      )
    );
  };

  React.useEffect(() => {
    getStockHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  return (
    <ModalDefault
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      showModalHeader={true}
      title={`Historial de ${product.nombre}`}
      showModalFooter={true}
      showClose={true}
      customClass="modal-products-detail"
      size="lg"
    >
      <DataTable
        //GENERAL
        dataKey="_id"
        className="p-datatable-main !h-[275px]"
        value={stockHistoryList}
        //SCROLLING
        scrollable
        scrollDirection="vertical"
      >
        <Column
          column={defaultValue}
          rowIndex={defaultValue}
          field="usuario"
          header="Usuario"
          body={textCapitalizeTemplate}
          headerClassName="text-align-left usuario-field"
          className="text-align-left usuario-field"
        ></Column>
        <Column
          column={defaultValue}
          rowIndex={defaultValue}
          field="stock"
          header="Stock"
          body={historyStock}
          headerClassName="text-align-center stock-field"
          className="text-align-center stock-field"
        ></Column>
        <Column
          column={defaultValue}
          rowIndex={defaultValue}
          field="total_previo"
          header="Stock Anterior"
          body={stockTemplate}
          headerClassName="text-align-center previo-field"
          className="text-align-center previo-field"
        ></Column>
        <Column
          column={defaultValue}
          rowIndex={defaultValue}
          field="total_actual"
          header="Stock Final"
          body={stockTemplate}
          headerClassName="text-align-center actual-field"
          className="text-align-center actual-field"
        ></Column>
        <Column
          column={defaultValue}
          rowIndex={defaultValue}
          field="fecha_creacion"
          header="Creación"
          body={dateRentInTemplate}
          headerClassName="text-align-center creacion-field"
          className="text-align-center creacion-field"
        ></Column>
      </DataTable>
    </ModalDefault>
  );
};

export default ModalHistoryStock;
