import React from "react";

interface SidebarSubelementProps {
  clickAction: any;
  selectedList: any;
  option: any;
}

const SidebarSubelement: React.FunctionComponent<SidebarSubelementProps> = ({
  clickAction,
  selectedList,
  option,
}: any) => {
  return (
    <React.Fragment>
      {option.map((op: any) => {
        return (
          <div
            key={op.label}
            className={
              selectedList.indexOf(op.label) !== -1
                ? "sub-selector " + op.class + " active"
                : "sub-selector " + op.class
            }
            onClick={() => {
              clickAction(op.label);
            }}
          >
            <div
              className={
                selectedList.indexOf(op.label) !== -1
                  ? "sub-selector-check active"
                  : "sub-selector-check"
              }
            ></div>
            <div className="sub-selector-label">{op.label}</div>
          </div>
        );
      })}
    </React.Fragment>
  );
};
export default SidebarSubelement;
