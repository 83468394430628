import {
  collection,
  CollectionReference,
  doc,
  getDocs,
  getDoc,
  limit,
  orderBy,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../lib/init-firebase";
import {
  fetchLastRentByKeyDTO,
  fetchLastRentDTO,
  updateRentDTO,
  updateRoomsDTO,
} from "../../utils/requestDto";
import { rentI } from "../../utils/responseEntities";

const dbPathRooms = "/habitaciones";

const dbPathRent = "/alquiler";
let rentRef = collection(db, dbPathRent) as CollectionReference<rentI>;

export const releaseRoomFB = (data: updateRoomsDTO, done?: any) => {
  return async () => {
    console.log("dataRent ReleaseRoom", data);
    await updateDoc(doc(db, dbPathRooms + "/" + data.room.key), {
      estatus: data.room.estatus,
      hora_liberado: data.room.hora_liberado,
    })
      .catch((error: any) => {
        console.log("error update room", error);
      })
      .then(() => {
        if (done) {
          done();
        }
      });
  };
};

export const releaseRentFB = (data: updateRentDTO, done?: any) => {
  return async () => {
    console.log("dataRent ReleaseRent", data);
    await updateDoc(doc(db, dbPathRent + "/" + data.rent.key), {
      detalle_alquiler: data.rent.detalle_alquiler,
    })
      .catch((error: any) => {
        console.log("error update rent", error);
      })
      .then(() => {
        if (done) {
          done();
        }
      });
  };
};

export const getLastRentByRoomFB = (data: fetchLastRentDTO, done?: any) => {
  console.log("dataRent LastRentByRoom", data);
  return async (dispatch: any) => {
    let queryConst: Array<QueryConstraint> = [];

    queryConst.push(where("detalle_habitacion.numero", "==", data.number));
    queryConst.push(where("local_cod", "==", data.local));
    queryConst.push(orderBy("detalle_alquiler.hora_ingreso", "desc"));
    queryConst.push(limit(1));

    const q = query(rentRef, ...queryConst);

    const querySnapshot = await getDocs(q);

    let auxRent: rentI = {} as rentI;
    querySnapshot.forEach((doc: QueryDocumentSnapshot<rentI>) => {
      auxRent = { key: doc.id, ...doc.data() };
    });

    if (done) {
      done(auxRent);
    }
  };
};

export const getLastRentByKeyFB = (data: fetchLastRentByKeyDTO, done?: any) => {
  console.log("dataRent LastRentByKey", data);
  return async (dispatch: any) => {
    const querySnapshot = await getDoc(doc(db, dbPathRent + "/" + data.key));

    if (querySnapshot.exists()) {
      if (done) {
        done({ key: querySnapshot.id, ...querySnapshot.data() });
      }
    }
  };
};
