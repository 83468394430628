import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";

import {
  PREFERENT_UPDATE_FILTER,
  PREFERENT_SEARCH,
} from "../../../redux/preferent/PreferentTypes";
import { getPreferentListFB } from "../../../redux/preferent/PreferentAction";
import { fetchPreferentsDTO } from "../../../utils/requestDto";
import { filterI, TT_FILTER_LIST, USER_TYPE } from "../../../utils/utils";
import { settingsI, userI } from "../../../utils/responseEntities";

import ActionButton from "../../../components/sub-components/action-button";
import FilterOption from "../../../components/sub-components/filter-option";
import SearchBox from "../../../components/sub-components/search-box";
import ModalCleanPreferent from "./ModalCleanPreferent";
import ModalAddPreferent from "./ModalAddPreferent";
import ModalTopPreferent from "./ModalTopPreferent";
import { Toast } from "primereact/toast";

const TopToolbar: React.FunctionComponent = () => {
  let defaultValue: any;
  const toastRef = React.useRef<Toast>(defaultValue);

  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);
  const [modalShowAdd, setModalShowAdd] = React.useState(false);
  const [modalShowClean, setModalShowClean] = React.useState(false);
  const [modalShowTop, setModalShowTop] = React.useState(false);

  const settingsAuth: settingsI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.settings
  );
  const permissionsAuth: Array<string> = useSelector(
    (globalState: RootStore) => globalState.auth.permissions
  );
  const userAuth: userI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.user
  );
  const filtersP: Array<filterI> = useSelector(
    (globalState: RootStore) => globalState.preferent.filters
  );
  const searchP: any = useSelector(
    (globalState: RootStore) => globalState.preferent.search
  );

  const dispatch = useDispatch();

  const updateFilter = (name: string) => {
    let filtersAux: any = [];

    // eslint-disable-next-line array-callback-return
    filtersP.map((filter: filterI) => {
      if (filter.name === name) {
        filtersAux.push({ name: filter.name, value: true });
      } else {
        filtersAux.push({ name: filter.name, value: false });
      }
    });

    dispatch({ type: PREFERENT_UPDATE_FILTER, payload: filtersAux });
  };

  const updateSearch = (name: string) => {
    dispatch({ type: PREFERENT_SEARCH, payload: name });
  };

  const fetchPreferent = () => {
    setLoadingAction(true);
    //FETCH PREFERENT LIST
    dispatch(
      getPreferentListFB(
        {
          type:
            filtersP.find((filter: filterI) => {
              return filter.value === true;
            })?.name || TT_FILTER_LIST.TODOS,
          search: searchP,
        } as fetchPreferentsDTO,
        () => {
          setLoadingAction(false);
        },
        () => {
          setLoadingAction(false);
        }
      )
    );
  };

  return (
    <React.Fragment>
      <div className="client-preferent-toolbar">
        <div className="toolbar-left">
          <FilterOption
            clickAction={(name: string) => {
              updateFilter(name);
            }}
            filterArray={filtersP}
          ></FilterOption>
          <SearchBox
            searchValue={searchP}
            searchAction={(value: string) => {
              updateSearch(value);
            }}
          ></SearchBox>
          <ActionButton
            loadingAction={loadingAction}
            isSearch={true}
            searchAction={() => {
              fetchPreferent();
            }}
          ></ActionButton>
        </div>
        <div className="toolbar-right">
          <ActionButton
            viewAdd={
              permissionsAuth.includes("client.preferent.addedit") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            addAction={() => {
              setModalShowAdd(true);
            }}
            viewClean={
              permissionsAuth.includes("client.preferent.clean") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            cleanAction={() => {
              setModalShowClean(true);
            }}
            viewTop={
              permissionsAuth.includes("client.preferent.ranking") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            qtyTop={settingsAuth?.preferent_cantidad}
            topAction={() => {
              setModalShowTop(true);
            }}
          ></ActionButton>
        </div>
      </div>
      {modalShowAdd && (
        <ModalAddPreferent
          modalShow={modalShowAdd}
          setModalShow={setModalShowAdd}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Preferentes - Agregar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            console.log("message");
            toastRef.current.show({
              severity: "error",
              summary: "Preferentes - Agregar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalAddPreferent>
      )}
      {modalShowClean && (
        <ModalCleanPreferent
          modalShow={modalShowClean}
          setModalShow={setModalShowClean}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Preferentes - Limpiar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={() => {}}
        ></ModalCleanPreferent>
      )}
      {modalShowTop && (
        <ModalTopPreferent
          modalShow={modalShowTop}
          setModalShow={setModalShowTop}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Preferentes - Top",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={() => {}}
        ></ModalTopPreferent>
      )}
      <Toast ref={toastRef} />
    </React.Fragment>
  );
};

export default TopToolbar;
