import React from "react";
import { useDispatch } from "react-redux";

import { deleteProductFB } from "../../../redux/products/ProductsAction";
import { deleteProductsDTO } from "../../../utils/requestDto";
import { productI } from "../../../utils/responseEntities";
import { capitalizeWords } from "../../../utils/utils";

import ModalDefault from "../../../components/sub-components/modal-default";

interface ModalDeleteProductProps {
  product: productI;
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalDeleteProduct: React.FunctionComponent<
  ModalDeleteProductProps
> = ({
  product,
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalDeleteProductProps) => {
  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const deleteProduct = () => {
    setLoadingAction(true);
    //DELETE PRODUCT
    dispatch(
      deleteProductFB(
        {
          key: product.key,
        } as deleteProductsDTO,
        () => {
          setLoadingAction(false);
          if (successFun) successFun("Eliminado satisfactoriamente");
          setModalShow(false);
        },
        () => {
          setLoadingAction(false);
          if (errorFun) errorFun("Ups! Algo salio mal");
          setModalShow(false);
        }
      )
    );
  };

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-products-delete"
      title={"Eliminar Producto"}
      showModalHeader={true}
      showModalFooter={true}
      showDelete={true}
      showCancel={true}
      deleteAction={() => {
        deleteProduct();
      }}
    >
      <p>
        {`¿Está seguro de eliminar `}
        <strong>{capitalizeWords(product.nombre)}</strong>
        {` de la lista?`}
      </p>
    </ModalDefault>
  );
};

export default ModalDeleteProduct;
