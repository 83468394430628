import React from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";

import {
  banReasonTemplate,
  dateCreateTemplate,
  textCapitalizeTemplate,
  textTemplate,
} from "../../../components/sub-components/table-components";
import { bannedI, userI } from "../../../utils/responseEntities";

import ActionOverlay from "../../../components/sub-components/actions-overlay";
import { Column, ColumnBodyOptions } from "primereact/column";
import ModalDeleteBanned from "./ModalDeleteBanned";
import { DataTable } from "primereact/datatable";
import ModalEditBanned from "./ModalEditBanned";
import { Toast } from "primereact/toast";
import { USER_TYPE } from "../../../utils/utils";

const List: React.FunctionComponent = () => {
  let defaultValue: any;
  const toastRef = React.useRef<Toast>(defaultValue);

  const [modalShowEdit, setModalShowEdit] = React.useState(false);
  const [bannedEdit, setBannedEdit] = React.useState<bannedI>(defaultValue);

  const [modalShowDelete, setModalShowDelete] = React.useState(false);
  const [bannedDelete, setBannedDelete] = React.useState<bannedI>(defaultValue);

  const permissionsAuth: Array<string> = useSelector(
    (globalState: RootStore) => globalState.auth.permissions
  );
  const userAuth: userI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.user
  );
  const bannedsB: Array<bannedI> = useSelector(
    (globalState: RootStore) => globalState.banned.banneds
  );
  const loadingB: boolean = useSelector(
    (globalState: RootStore) => globalState.banned.loading
  );

  const actionsTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    return (
      <React.Fragment>
        <div className="table-action-buttons">
          <ActionOverlay
            showEdit={
              permissionsAuth.includes("client.banned.addedit") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            editAction={() => {
              let auxClient: bannedI =
                bannedsB.find(
                  (client: bannedI) => rowData[field || ""] === client.key
                ) || defaultValue;
              setBannedEdit({ ...auxClient } as bannedI);
              setModalShowEdit(true);
            }}
            showDelete={
              permissionsAuth.includes("client.banned.delete") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            deleteAction={() => {
              let auxClientBanned: bannedI =
                bannedsB.find(
                  (client: bannedI) => rowData[field || ""] === client.key
                ) || defaultValue;
              setBannedDelete({ ...auxClientBanned } as bannedI);
              setModalShowDelete(true);
            }}
          ></ActionOverlay>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="client-banned-list">
        <DataTable
          //GENERAL
          dataKey="_id"
          loading={loadingB}
          className="p-datatable-main"
          value={bannedsB}
          //SORTING
          sortField="nombre"
          sortMode="single"
          sortOrder={1}
          //SCROLLING
          scrollable
          scrollDirection="vertical"
          //PAGINATION
          alwaysShowPaginator
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
        >
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="nombre"
            header="Nombre"
            body={textCapitalizeTemplate}
            headerClassName="text-align-left nombre-field"
            className="text-align-left nombre-field"
            sortable
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="dni"
            header="DNI"
            body={textTemplate}
            headerClassName="text-align-left dni-field"
            className="text-align-center dni-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="categoria"
            header="Categoria"
            body={banReasonTemplate}
            headerClassName="text-align-center categoria-field"
            className="text-align-center categoria-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="fecha_nacimiento"
            header="Nacimiento"
            body={dateCreateTemplate}
            headerClassName="text-align-center nacimiento-field"
            className="text-align-center nacimiento-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="key"
            header="Acciones"
            body={actionsTemplate}
            headerClassName="text-align-center acciones-field"
            className="text-align-center acciones-field"
          ></Column>
        </DataTable>
      </div>
      {modalShowEdit && (
        <ModalEditBanned
          banned={bannedEdit}
          modalShow={modalShowEdit}
          setModalShow={setModalShowEdit}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Baneados - Editar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            console.log("message");
            toastRef.current.show({
              severity: "error",
              summary: "Baneados - Editar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalEditBanned>
      )}
      {modalShowDelete && (
        <ModalDeleteBanned
          banned={bannedDelete}
          modalShow={modalShowDelete}
          setModalShow={setModalShowDelete}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Baneados - Eliminar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            console.log("message");
            toastRef.current.show({
              severity: "error",
              summary: "Baneados - Eliminar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalDeleteBanned>
      )}
      <Toast ref={toastRef} />
    </React.Fragment>
  );
};

export default List;
