import React from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { FaUserTie } from "react-icons/fa";
import { BiBuildings, BiDish, BiLogOut, BiUser } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import UserDefault from "../../assets/userDefault.png";
import { CheckAuthentication, Logout } from "../../redux/CheckAuth";
import { RiCustomerService2Line } from "react-icons/ri";
import { useSelector } from "react-redux";
import { RootStore } from "../../redux/Store";
import { userI } from "../../utils/responseEntities";
import { capitalizeWords } from "../../utils/utils";

const UserOverlay: React.FunctionComponent = () => {
  const op = React.useRef<OverlayPanel>(null);

  const userAuth: userI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.user
  );

  return (
    <React.Fragment>
      <div
        className="profile"
        onClick={(e: any) => {
          op.current?.toggle(e);
        }}
        aria-haspopup
        aria-controls="overlay_panel"
      >
        <FaUserTie></FaUserTie>
      </div>
      <OverlayPanel ref={op} id="overlay_panel" className="user-overlaypanel">
        <div className="user-title">
          <img src={UserDefault} alt="" className="user-img" />
          <div className="user-info">
            <p className="top">{capitalizeWords(userAuth?.nombre || "")}</p>
            <p className="bottom">{capitalizeWords(userAuth?.rol || "")}</p>
          </div>
        </div>
        <div className="user-options">
          <div className="option-row">
            <BiUser></BiUser>
            <p>{"Perfil (v.3)"}</p>
          </div>
          <div className="option-row">
            <RiCustomerService2Line></RiCustomerService2Line>
            <p>{"Reserva Habitación (v.3)"}</p>
          </div>
          <div className="option-row">
            <BiDish></BiDish>
            <p>{"Atención a Cuarto (v.3)"}</p>
          </div>
          <div className="option-row">
            <BiBuildings></BiBuildings>
            <p>{"Sedes (v.3)"}</p>
          </div>
        </div>
        <div
          className="user-logout"
          onClick={() => {
            Logout();
            CheckAuthentication();
          }}
        >
          <BiLogOut></BiLogOut>
          <p>{"LOGOUT"}</p>
        </div>
      </OverlayPanel>
    </React.Fragment>
  );
};

export default UserOverlay;
