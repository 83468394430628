import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AiOutlineDashboard } from "react-icons/ai";
import {
  BiBarcode,
  BiWrench,
  BiNotepad,
  BiHistory,
  BiDollarCircle,
  BiChevronDown,
  BiSearch,
  BiUserCheck,
  BiUserX,
  BiErrorCircle,
} from "react-icons/bi";
import { CgHome, CgUser } from "react-icons/cg";
import { RiHandCoinLine } from "react-icons/ri";
import Logo from "../assets/logo.png";
import { RootStore } from "../redux/Store";
import { useSelector } from "react-redux";
import { settingsI, userI } from "../utils/responseEntities";
import { USER_TYPE } from "../utils/utils";

interface MenuProps {
  history: any;
  showMenu: boolean;
  setMenu: any;
}

const Menu: React.FunctionComponent<MenuProps> = ({
  history,
  showMenu,
  setMenu,
}: any) => {
  const settingsAuth: settingsI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.settings
  );
  const permissionsAuth: Array<string> = useSelector(
    (globalState: RootStore) => globalState.auth.permissions
  );
  const userAuth: userI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.user
  );

  const location = useLocation();

  const setSubMenu = (subMenu: string) => {
    setState((prevState) => ({
      ...prevState,
      itemSelected: subMenu,
    }));
  };

  const getSubMenu = () => {
    if (location.pathname.includes("dashboard")) {
      return "dashboard";
    }
    if (location.pathname.includes("products")) {
      return "products";
    }
    if (location.pathname.includes("rooms")) {
      return "rooms";
    }
    if (location.pathname.includes("clients")) {
      return "clients";
    }
    if (location.pathname.includes("users")) {
      return "users";
    }
    if (location.pathname.includes("settings")) {
      return "settings";
    }
    return "";
  };

  const [state, setState] = useState({
    itemSelected: getSubMenu(),
  });

  useEffect(() => {
    setSubMenu(getSubMenu());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className={showMenu ? "menu-left-side" : "menu-left-side hidden"}>
      <div className="menu-top-side">
        <img src={Logo} alt="" className="top-img" />
        <div className="top-title">{settingsAuth?.local_name || ""}</div>
      </div>

      <div className="menu-bottom-side">
        {/*MENU LIST DASHBOARD*/}
        {/* {(permissionsAuth.includes("dashboard") ||
          userAuth?.rol === USER_TYPE.DESARROLLADOR) && (
          <NavLink to="/dashboard" className={"nav-item-profile"}>
            <div className="content-item-profile">
              <AiOutlineDashboard></AiOutlineDashboard>
              <p className="title-menu">{"Dashboard"}</p>
            </div>
            <div className="border-active"></div>
          </NavLink>
        )} */}

        {/*MENU LIST PRODUCTS*/}
        {(permissionsAuth.includes("product.general") ||
          permissionsAuth.includes("product.history") ||
          permissionsAuth.includes("product.sell") ||
          userAuth?.rol === USER_TYPE.DESARROLLADOR) && (
          <React.Fragment>
            <div
              className={
                "nav-item-profile menu-item" +
                (state.itemSelected === "products" ? " active" : "")
              }
              onClick={() => {
                setSubMenu(state.itemSelected === "products" ? "" : "products");
              }}
            >
              <div className="content-item-profile">
                <BiBarcode></BiBarcode>
                <p className="title-menu">{"Productos"}</p>
              </div>
              <BiChevronDown className="menu-item-icon"></BiChevronDown>
            </div>
            <div
              className={
                "sub-menu-list" +
                (state.itemSelected === "products" ? "" : " hidden")
              }
            >
              {(permissionsAuth.includes("product.general") ||
                userAuth?.rol === USER_TYPE.DESARROLLADOR) && (
                <NavLink
                  to="/products"
                  className={"nav-item-profile sub-menu-item"}
                >
                  <div className="content-item-profile">
                    <BiNotepad></BiNotepad>
                    <p className="title-menu">{"General"}</p>
                  </div>
                  <div className="border-active"></div>
                </NavLink>
              )}
              {(permissionsAuth.includes("product.history") ||
                userAuth?.rol === USER_TYPE.DESARROLLADOR) && (
                <NavLink
                  to="/products-history"
                  className={"nav-item-profile sub-menu-item"}
                >
                  <div className="content-item-profile">
                    <BiHistory></BiHistory>
                    <p className="title-menu">{"Historial"}</p>
                  </div>
                  <div className="border-active"></div>
                </NavLink>
              )}
              {(permissionsAuth.includes("product.sell") ||
                userAuth?.rol === USER_TYPE.DESARROLLADOR) && (
                <NavLink
                  to="/products-sell"
                  className={"nav-item-profile sub-menu-item"}
                >
                  <div className="content-item-profile">
                    <BiDollarCircle></BiDollarCircle>
                    <p className="title-menu">{"Venta"}</p>
                  </div>
                  <div className="border-active"></div>
                </NavLink>
              )}
            </div>
          </React.Fragment>
        )}

        {/*MENU LIST ROOMS*/}
        {(permissionsAuth.includes("room.general") ||
          permissionsAuth.includes("room.history") ||
          permissionsAuth.includes("room.rent") ||
          permissionsAuth.includes("room.search") ||
          userAuth?.rol === USER_TYPE.DESARROLLADOR) && (
          <React.Fragment>
            <div
              className={
                "nav-item-profile menu-item" +
                (state.itemSelected === "rooms" ? " active" : "")
              }
              onClick={() => {
                setSubMenu(state.itemSelected === "rooms" ? "" : "rooms");
              }}
            >
              <div className="content-item-profile">
                <CgHome></CgHome>
                <p className="title-menu">{"Habitaciones"}</p>
              </div>
              <BiChevronDown className="menu-item-icon"></BiChevronDown>
            </div>
            <div
              className={
                "sub-menu-list" +
                (state.itemSelected === "rooms" ? "" : " hidden")
              }
            >
              {(permissionsAuth.includes("room.general") ||
                userAuth?.rol === USER_TYPE.DESARROLLADOR) && (
                <NavLink
                  to="/rooms"
                  className={"nav-item-profile sub-menu-item"}
                >
                  <div className="content-item-profile">
                    <BiNotepad></BiNotepad>
                    <p className="title-menu">{"General"}</p>
                  </div>
                  <div className="border-active"></div>
                </NavLink>
              )}
              {(permissionsAuth.includes("room.history") ||
                userAuth?.rol === USER_TYPE.DESARROLLADOR) && (
                <NavLink
                  to="/rooms-history"
                  className={"nav-item-profile sub-menu-item"}
                >
                  <div className="content-item-profile">
                    <BiHistory></BiHistory>
                    <p className="title-menu">{"Historial"}</p>
                  </div>
                  <div className="border-active"></div>
                </NavLink>
              )}
              {(permissionsAuth.includes("room.rent") ||
                userAuth?.rol === USER_TYPE.DESARROLLADOR) && (
                <NavLink
                  to="/rooms-rent"
                  className={
                    "nav-item-profile sub-menu-item" +
                    (location.pathname.includes("rooms-form") ? " active" : "")
                  }
                >
                  <div className="content-item-profile">
                    <BiDollarCircle></BiDollarCircle>
                    <p className="title-menu">{"Renta"}</p>
                  </div>
                  <div className="border-active"></div>
                </NavLink>
              )}
              {(permissionsAuth.includes("room.search") ||
                userAuth?.rol === USER_TYPE.DESARROLLADOR) && (
                <NavLink
                  to="/rooms-search"
                  className={"nav-item-profile sub-menu-item"}
                >
                  <div className="content-item-profile">
                    <BiSearch></BiSearch>
                    <p className="title-menu">{"Búsqueda"}</p>
                  </div>
                  <div className="border-active"></div>
                </NavLink>
              )}
            </div>
          </React.Fragment>
        )}

        {/*MENU LIST CLIENTS*/}
        {(permissionsAuth.includes("client.preferent") ||
          permissionsAuth.includes("client.banned") ||
          userAuth?.rol === USER_TYPE.DESARROLLADOR) && (
          <React.Fragment>
            <div
              className={
                "nav-item-profile menu-item" +
                (state.itemSelected === "clients" ? " active" : "")
              }
              onClick={() => {
                setSubMenu(state.itemSelected === "clients" ? "" : "clients");
              }}
            >
              <div className="content-item-profile">
                <CgUser></CgUser>
                <p className="title-menu">{"Clientes"}</p>
              </div>
              <BiChevronDown className="menu-item-icon"></BiChevronDown>
            </div>
            <div
              className={
                "sub-menu-list" +
                (state.itemSelected === "clients" ? "" : " hidden")
              }
            >
              {(permissionsAuth.includes("client.preferent") ||
                userAuth?.rol === USER_TYPE.DESARROLLADOR) && (
                <NavLink
                  to="/clients-preferent"
                  className={"nav-item-profile sub-menu-item"}
                >
                  <div className="content-item-profile">
                    <BiUserCheck></BiUserCheck>
                    <p className="title-menu">{"Preferentes"}</p>
                  </div>
                  <div className="border-active"></div>
                </NavLink>
              )}
              {(permissionsAuth.includes("client.banned") ||
                userAuth?.rol === USER_TYPE.DESARROLLADOR) && (
                <NavLink
                  to="/clients-banned"
                  className={"nav-item-profile sub-menu-item"}
                >
                  <div className="content-item-profile">
                    <BiUserX></BiUserX>
                    <p className="title-menu">{"Baneados"}</p>
                  </div>
                  <div className="border-active"></div>
                </NavLink>
              )}
            </div>
          </React.Fragment>
        )}

        {/*MENU LIST USERS*/}
        {(permissionsAuth.includes("user") ||
          userAuth?.rol === USER_TYPE.DESARROLLADOR) && (
          <NavLink to="/users" className={"nav-item-profile"}>
            <div className="content-item-profile">
              <RiHandCoinLine></RiHandCoinLine>
              <p className="title-menu">{"Usuarios"}</p>
            </div>
            <div className="border-active"></div>
          </NavLink>
        )}

        {/*MENU LIST SETTINGS*/}
        {(userAuth?.rol === USER_TYPE.DESARROLLADOR ||
          userAuth?.rol === USER_TYPE.ADMINISTRADOR) && (
          <React.Fragment>
            <div
              className={
                "nav-item-profile menu-item" +
                (state.itemSelected === "settings" ? " active" : "")
              }
              onClick={() => {
                setSubMenu(state.itemSelected === "settings" ? "" : "settings");
              }}
            >
              <div className="content-item-profile">
                <BiWrench></BiWrench>
                <p className="title-menu">{"Ajustes"}</p>
              </div>
              <BiChevronDown className="menu-item-icon"></BiChevronDown>
            </div>
            <div
              className={
                "sub-menu-list" +
                (state.itemSelected === "settings" ? "" : " hidden")
              }
            >
              <NavLink
                to="/settings-general"
                className={"nav-item-profile sub-menu-item"}
              >
                <div className="content-item-profile">
                  <BiNotepad></BiNotepad>
                  <p className="title-menu">{"General"}</p>
                </div>
                <div className="border-active"></div>
              </NavLink>
              <NavLink
                to="/settings-permission"
                className={"nav-item-profile sub-menu-item"}
              >
                <div className="content-item-profile">
                  <BiErrorCircle></BiErrorCircle>
                  <p className="title-menu">{"Permisos"}</p>
                </div>
                <div className="border-active"></div>
              </NavLink>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default Menu;
