import React from "react";
import { useDispatch } from "react-redux";

import {
  addPreferentFB,
  checkDniPreferentFB,
} from "../../../redux/preferent/PreferentAction";
import { addPreferentsDTO, GetByDniRequest } from "../../../utils/requestDto";
import { onlyDigits, onlyLetters } from "../../../utils/utils";
import { preferentI } from "../../../utils/responseEntities";

import ModalDefault from "../../../components/sub-components/modal-default";
import InputField from "../../../components/sub-components/input-field";
import InputDate from "../../../components/sub-components/input-date";

interface ModalAddPreferentProps {
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalAddPreferent: React.FunctionComponent<
  ModalAddPreferentProps
> = ({
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalAddPreferentProps) => {
  let defaultValue: any;

  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);
  const [state, setState] = React.useState<preferentI>({
    nombres: "",
    apellido_pat: "",
    apellido_mat: "",
    dni: "",
    fecha_nacimiento: defaultValue,
  });

  const [errorNombres, setErrorNombres] = React.useState(false);
  const [errorApellido_pat, setErrorApellido_pat] = React.useState(false);
  const [errorApellido_mat, setErrorApellido_mat] = React.useState(false);
  const [errorDni, setErrorDni] = React.useState(false);

  const dispatch = useDispatch();

  const updatePreferentData = (name: any, value: any) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validatePreferentData = () => {
    if (
      state.nombres === "" ||
      state.apellido_mat === "" ||
      state.apellido_pat === "" ||
      state.dni === ""
    ) {
      setErrorNombres(state.nombres === "" ? true : false);
      setErrorApellido_pat(state.apellido_pat === "" ? true : false);
      setErrorApellido_mat(state.apellido_mat === "" ? true : false);
      setErrorDni(state.dni === "" ? true : false);
      return false;
    } else {
      return true;
    }
  };

  const addPreferent = () => {
    setLoadingAction(true);
    //ADD PREFERENT
    dispatch(
      checkDniPreferentFB(
        {
          dni: state.dni,
        } as GetByDniRequest,
        (response: any) => {
          if (!response) {
            dispatch(
              addPreferentFB(
                {
                  preferent: {
                    nombres: state.nombres,
                    apellido_pat: state.apellido_pat,
                    apellido_mat: state.apellido_mat,
                    dni: state.dni,
                    fecha_nacimiento: state.fecha_nacimiento
                      ? state.fecha_nacimiento
                      : "",
                  },
                } as addPreferentsDTO,
                () => {
                  setLoadingAction(false);
                  if (successFun) successFun("Agregado satisfactoriamente");
                  setModalShow(false);
                },
                () => {
                  setLoadingAction(false);
                  if (errorFun) errorFun("Ups! Algo salio mal");
                  setModalShow(false);
                }
              )
            );
          } else {
            console.log("dni ya registrado");
            setLoadingAction(false);
            if (errorFun) errorFun("DNI ya registrado");
          }
        }
      )
    );
  };

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-preferents-add"
      title={"Agregar Preferente"}
      showModalHeader={true}
      showModalFooter={true}
      showAdd={true}
      showCancel={true}
      addAction={() => {
        if (validatePreferentData()) {
          addPreferent();
        }
      }}
    >
      <React.Fragment>
        <InputField
          key={"add-preferent-nombres"}
          horizontal={false}
          placeholder={""}
          value={state.nombres}
          onChange={(value: any) => {
            updatePreferentData("nombres", value.toLowerCase());
            setErrorNombres(false);
          }}
          className={"add-preferent-input"}
          regExp={onlyLetters}
          viewTitle={true}
          viewErrorMessage={errorNombres}
          title={"Nombre"}
          errorMessage={"Incorrecto"}
          height={"!h-[40px]"}
          width={"!w-full"}
          isUppercase={true}
        ></InputField>
        <InputField
          key={"add-preferent-apellido-pat"}
          horizontal={false}
          placeholder={""}
          value={state.apellido_pat}
          onChange={(value: any) => {
            updatePreferentData("apellido_pat", value.toLowerCase());
            setErrorApellido_pat(false);
          }}
          className={"add-preferent-input"}
          regExp={onlyLetters}
          viewTitle={true}
          viewErrorMessage={errorApellido_pat}
          title={"Apellido Pat."}
          errorMessage={"Incorrecto"}
          height={"!h-[40px]"}
          width={"!w-full"}
          isUppercase={true}
        ></InputField>
        <InputField
          key={"add-preferent-apellido-mat"}
          horizontal={false}
          placeholder={""}
          value={state.apellido_mat}
          onChange={(value: any) => {
            updatePreferentData("apellido_mat", value.toLowerCase());
            setErrorApellido_pat(false);
          }}
          className={"add-preferent-input"}
          regExp={onlyLetters}
          viewTitle={true}
          viewErrorMessage={errorApellido_mat}
          title={"Apellido Mat."}
          errorMessage={"Incorrecto"}
          height={"!h-[40px]"}
          width={"!w-full"}
          isUppercase={true}
        ></InputField>
        <InputField
          key={"add-preferent-dni"}
          horizontal={false}
          placeholder={""}
          value={state.dni}
          onChange={(value: any) => {
            updatePreferentData("dni", value);
            setErrorDni(false);
          }}
          className={"add-preferent-input"}
          regExp={onlyDigits}
          viewTitle={true}
          viewErrorMessage={errorDni}
          title={"DNI"}
          errorMessage={"Incorrecto"}
          height={"!h-[40px]"}
          width={"!w-full"}
        ></InputField>
        <InputDate
          key={"add-preferent-fecha-nacimiento"}
          horizontal={false}
          placeholder={"DD/MM/YYYY"}
          value={state.fecha_nacimiento || undefined}
          onChange={(value: any) => {
            updatePreferentData("fecha_nacimiento", value);
          }}
          className={"add-preferent-input"}
          viewTitle={true}
          viewErrorMessage={false}
          title={"Fech. Nac."}
          errorMessage={"Incorrecto"}
        ></InputDate>
      </React.Fragment>
    </ModalDefault>
  );
};

export default ModalAddPreferent;
