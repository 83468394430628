import React from "react";
import { RootStore } from "../../../redux/Store";
import { useSelector } from "react-redux";
import moment from "moment";

import { rentI } from "../../../utils/responseEntities";
import { Column, ColumnBodyOptions } from "primereact/column";
import { capitalizeWords } from "../../../utils/utils";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";

const List: React.FunctionComponent = () => {
  let defaultValue: any;
  const toastRef = React.useRef<Toast>(defaultValue);

  const [searchData, setSearchData] = React.useState<Array<any>>(defaultValue);

  const rentS: Array<rentI> = useSelector(
    (globalState: RootStore) => globalState.roomsSearch.rentSearch
  );
  const loadingS: boolean = useSelector(
    (globalState: RootStore) => globalState.roomsSearch.loadingSearch
  );

  React.useEffect(() => {
    let auxSearchArray: any = [];

    // eslint-disable-next-line array-callback-return
    rentS?.map((rent: rentI) => {
      auxSearchArray.push({
        ...rent,
        hora_ingreso: rent.detalle_alquiler.hora_ingreso,
        hora_salida: rent.detalle_alquiler.hora_salida,
        hora_salida_real: rent.detalle_alquiler.hora_salida_real,
      });
    });

    setSearchData(auxSearchArray);
  }, [rentS]);

  const userTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    return (
      <div>
        {capitalizeWords(
          `${rowData[field || ""].nombres} ${
            rowData[field || ""].apellido_pat
          } ${rowData[field || ""].apellido_mat}`
        )}
      </div>
    );
  };

  const habitacionTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    return <div>{rowData[field || ""].numero}</div>;
  };

  const turnsTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    return <div>{`${rowData[field || ""].cantidad_turnos}`}</div>;
  };

  const totalPriceTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    return <div>{`S/.${rowData[field || ""].precio_total}`}</div>;
  };

  const timeInTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    return (
      <div className="cell-container">
        {rowData[field || ""] && (
          <div className="cell-date-rent">
            <div className="in-date">
              {moment(rowData[field || ""]?.toDate() || "").format(
                "DD/MM/YYYY"
              )}
            </div>
            <div className="in-time">
              {moment(rowData[field || ""]?.toDate() || "").format("hh:mm a")}
            </div>
          </div>
        )}
      </div>
    );
  };

  const timeOutRealTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    return (
      <div className="cell-container">
        {rowData[field || ""] && (
          <div className="cell-date-rent">
            <div className="out-real-date">
              {moment(rowData[field || ""]?.toDate() || "").format(
                "DD/MM/YYYY"
              )}
            </div>
            <div className="out-real-time">
              {moment(rowData[field || ""]?.toDate() || "").format("hh:mm a")}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="room-search-list">
        <DataTable
          //GENERAL
          dataKey="_id"
          loading={loadingS}
          className="p-datatable-main"
          value={searchData}
          //SORTING
          sortField="hora_ingreso"
          sortMode="single"
          sortOrder={-1}
          //SCROLLING
          scrollable
          scrollDirection="vertical"
          //PAGINATION
          alwaysShowPaginator
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
        >
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="usuarios"
            header="Nombre"
            body={userTemplate}
            headerClassName="text-align-left nombre-field"
            className="text-align-left nombre-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="detalle_habitacion"
            header="#Hab"
            body={habitacionTemplate}
            headerClassName="text-align-center habitacion-field"
            className="text-align-center habitacion-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="detalle_alquiler"
            header="Turnos"
            body={turnsTemplate}
            headerClassName="text-align-center turnos-field"
            className="text-align-center turnos-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="detalle_alquiler"
            header="Total"
            body={totalPriceTemplate}
            headerClassName="text-align-center total-field"
            className="text-align-center total-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="hora_ingreso"
            header="Ingreso"
            body={timeInTemplate}
            headerClassName="text-align-center ingreso-field"
            className="text-align-center ingreso-field"
            sortable
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="hora_salida_real"
            header="Salida Real"
            body={timeOutRealTemplate}
            headerClassName="text-align-center salida-real-field"
            className="text-align-center salida-real-field"
          ></Column>
        </DataTable>
      </div>
      <Toast ref={toastRef} />
    </React.Fragment>
  );
};

export default List;
