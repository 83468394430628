import { rentI } from "../../utils/responseEntities";
import { TT_TURN_SELECTOR } from "../../utils/utils";
import {
  ROOMS_HISTORY_LOADING_LAST,
  ROOMS_HISTORY_FAIL_LAST,
  ROOMS_HISTORY_LOADING_ACTUAL,
  ROOMS_HISTORY_FAIL_ACTUAL,
  ROOMS_HISTORY_LOADING_CUSTOM,
  ROOMS_HISTORY_FAIL_CUSTOM,
  ROOMS_HISTORY_TURN,
  ROOMS_HISTORY_RANGE,
  ROOMS_HISTORY_FINISHED,
  ROOMS_HISTORY_HOLD,
  ROOMS_HISTORY_LIST_LAST,
  ROOMS_HISTORY_LIST_ACTUAL,
  ROOMS_HISTORY_LIST_CUSTOM,
  RoomsHistoryDispatchTypes,
} from "./RoomsHistoryTypes";

let defaultValue: any;

interface RoomsHistoryState {
  loadingHistoryLast: boolean;
  loadingHistoryActual: boolean;
  loadingHistoryCustom: boolean;
  turnHistory: string;
  customDateHistory: Date;
  showFinishedHistory: boolean;
  showHoldHistory: boolean;
  rentHistoryLast: Array<rentI>;
  rentHistoryActual: Array<rentI>;
  rentHistoryCustom: Array<rentI>;
}

const defaultState: RoomsHistoryState = {
  loadingHistoryLast: false,
  loadingHistoryActual: false,
  loadingHistoryCustom: false,
  turnHistory: TT_TURN_SELECTOR.ACTUAL,
  customDateHistory: defaultValue,
  showFinishedHistory: false,
  showHoldHistory: false,
  rentHistoryLast: [],
  rentHistoryActual: [],
  rentHistoryCustom: [],
};

const roomsHistoryReducer = (
  state: RoomsHistoryState = defaultState,
  action: RoomsHistoryDispatchTypes
): RoomsHistoryState => {
  switch (action.type) {
    case ROOMS_HISTORY_LOADING_LAST:
      return {
        ...state,
        loadingHistoryLast: true,
      };
    case ROOMS_HISTORY_FAIL_LAST:
      return {
        ...state,
        loadingHistoryLast: false,
      };
    case ROOMS_HISTORY_LOADING_ACTUAL:
      return {
        ...state,
        loadingHistoryActual: true,
      };
    case ROOMS_HISTORY_FAIL_ACTUAL:
      return {
        ...state,
        loadingHistoryActual: false,
      };
    case ROOMS_HISTORY_LOADING_CUSTOM:
      return {
        ...state,
        loadingHistoryCustom: true,
      };
    case ROOMS_HISTORY_FAIL_CUSTOM:
      return {
        ...state,
        loadingHistoryCustom: false,
      };
    case ROOMS_HISTORY_TURN:
      return {
        ...state,
        turnHistory: action.payload,
      };
    case ROOMS_HISTORY_RANGE:
      return {
        ...state,
        customDateHistory: action.payload.date,
      };
    case ROOMS_HISTORY_FINISHED:
      return {
        ...state,
        showFinishedHistory: action.payload,
      };
    case ROOMS_HISTORY_HOLD:
      return {
        ...state,
        showHoldHistory: action.payload,
      };
    case ROOMS_HISTORY_LIST_LAST:
      return {
        ...state,
        rentHistoryLast: action.payload,
      };
    case ROOMS_HISTORY_LIST_ACTUAL:
      return {
        ...state,
        rentHistoryActual: action.payload,
      };
    case ROOMS_HISTORY_LIST_CUSTOM:
      return {
        ...state,
        rentHistoryCustom: action.payload,
      };
    default:
      return state;
  }
};

export default roomsHistoryReducer;
