import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../redux/Store";
import "./style.scss";

import { updateSettingsFB } from "../../redux/settings/SettingsAction";
import { updateSettingsDTO } from "../../utils/requestDto";
import { settingsI } from "../../utils/responseEntities";
import { defaultPermission } from "../../utils/utils";

import ActionButton from "../../components/sub-components/action-button";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { Toast } from "primereact/toast";

const SettingsGeneral: React.FunctionComponent = () => {
  let defaultValue: any;
  const toastRef = React.useRef<Toast>(defaultValue);

  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);
  const [state, setState] = React.useState<settingsI>({
    key: "",
    tiempo_cambiodia: defaultValue,
    tiempo_penalizacion: defaultValue,
    tiempo_standby: defaultValue,
    tiempo_turno: defaultValue,
    show_preferents: defaultValue,
    preferent_cantidad: defaultValue,
    preferent_periodo: defaultValue,
    show_discount: defaultValue,
    discount: defaultValue,
    roles: defaultValue,
    local_cod: defaultValue,
    local_name: defaultValue,
  });

  const settingsAuth: settingsI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.settings
  );

  const dispatch = useDispatch();

  const updateSettingData = (name: any, value: any) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const editSettings = () => {
    setLoadingAction(true);
    //UPDATE SETTINGS
    dispatch(
      updateSettingsFB(
        {
          setting: state,
        } as updateSettingsDTO,
        () => {
          setLoadingAction(false);
          toastRef.current.show({
            severity: "success",
            summary: "Ajustes General",
            detail: "Actualizado satisfactoriamente",
            life: 3000,
            closable: false,
          });
        },
        () => {
          setLoadingAction(false);
          toastRef.current.show({
            severity: "error",
            summary: "Ajustes General",
            detail: "Ups! Algo salio mal",
            life: 3000,
            closable: false,
          });
        }
      )
    );
  };

  React.useEffect(() => {
    if (settingsAuth) {
      setState((prev) => ({
        ...prev,
        key: settingsAuth.key,
        tiempo_cambiodia: settingsAuth.tiempo_cambiodia || 0,
        tiempo_penalizacion: settingsAuth.tiempo_penalizacion || 0,
        tiempo_standby: settingsAuth.tiempo_standby || 0,
        tiempo_turno: settingsAuth.tiempo_turno || 0,
        show_preferents: settingsAuth.show_preferents || false,
        preferent_cantidad: settingsAuth.preferent_cantidad || 0,
        preferent_periodo: settingsAuth.preferent_periodo || 0,
        show_discount: settingsAuth.show_discount || false,
        discount: settingsAuth.discount || "0",
        roles: settingsAuth.roles || defaultPermission,
        local_cod: settingsAuth.local_cod,
        local_name: settingsAuth.local_name || "",
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsAuth]);

  return (
    <div className="settings-general-container">
      <div className="settings-body">
        <div className="setting-element">
          <p className="title">{`Hora Cambio Día`}</p>
          <div className="p-inputgroup max-w-[200px]">
            <InputNumber
              className="setting-input"
              id="dayChange"
              value={state.tiempo_cambiodia}
              onChange={(e: any) => {
                updateSettingData("tiempo_cambiodia", e.value || 0);
              }}
              onValueChange={(e: any) => {
                updateSettingData("tiempo_cambiodia", e.value || 0);
              }}
              min={0}
              useGrouping={false}
            />
            <span className="p-inputgroup-addon">hra</span>
          </div>
          <p className="footer">{`* De no existir el valor por defecto es 8:00.`}</p>
        </div>
        <div className="setting-element">
          <p className="title">{`Tolerancia Penalidad`}</p>
          <div className="p-inputgroup max-w-[200px]">
            <InputNumber
              className="setting-input"
              id="penaltyTolerance"
              value={state.tiempo_penalizacion / 60000}
              onChange={(e: any) => {
                updateSettingData("tiempo_penalizacion", e.value * 60000);
              }}
              onValueChange={(e: any) => {
                updateSettingData("tiempo_penalizacion", e.value * 60000);
              }}
              min={0}
              useGrouping={false}
            />
            <span className="p-inputgroup-addon">min</span>
          </div>
          <p className="footer">{`* De no existir el valor por defecto es 60 min.`}</p>
        </div>
        <div className="setting-element">
          <p className="title">{`Tiempo Stand By`}</p>
          <div className="p-inputgroup max-w-[200px]">
            <InputNumber
              className="setting-input"
              id="standBy"
              value={state.tiempo_standby / 60000}
              onChange={(e: any) => {
                updateSettingData("tiempo_standby", e.value * 60000);
              }}
              onValueChange={(e: any) => {
                updateSettingData("tiempo_standby", e.value * 60000);
              }}
              min={0}
              useGrouping={false}
            />
            <span className="p-inputgroup-addon">min</span>
          </div>
          <p className="footer">{`* De no existir el valor por defecto es 10 min.`}</p>
        </div>
        <div className="setting-element">
          <p className="title">{`Duración Turno Habitación`}</p>
          <div className="p-inputgroup max-w-[200px]">
            <InputNumber
              className="setting-input"
              id="timeDuration"
              value={state.tiempo_turno}
              onChange={(e: any) => {
                updateSettingData("tiempo_turno", e.value);
              }}
              onValueChange={(e: any) => {
                updateSettingData("tiempo_turno", e.value);
              }}
              min={0}
              useGrouping={false}
            />
            <span className="p-inputgroup-addon">hra</span>
          </div>
          <p className="footer">{`* De no existir el valor por defecto es 6 horas.`}</p>
        </div>
        <div className="setting-element">
          <p className="title">{`Mostrar Preferentes`}</p>
          <InputSwitch
            id="showPreferent"
            checked={state.show_preferents}
            onChange={(e: any) => {
              updateSettingData("show_preferents", e.value);
            }}
          />
        </div>
        <div className="setting-element">
          <p className="title">{`Cantidad de Preferentes`}</p>
          <div className="p-inputgroup max-w-[200px]">
            <InputNumber
              className="setting-input"
              id="preferentQuantity"
              value={state.preferent_cantidad}
              onChange={(e: any) => {
                updateSettingData("preferent_cantidad", e.value);
              }}
              onValueChange={(e: any) => {
                updateSettingData("preferent_cantidad", e.value);
              }}
              min={0}
              useGrouping={false}
            />
            <span className="p-inputgroup-addon">qty</span>
          </div>

          <p className="footer">{`* De no existir el valor por defecto es 10 pref.`}</p>
        </div>
        <div className="setting-element">
          <p className="title">{`Periódo para Preferente`}</p>
          <div className="p-inputgroup max-w-[200px]">
            <InputNumber
              className="setting-input"
              id="preferentPeriod"
              value={state.preferent_periodo}
              onChange={(e: any) => {
                updateSettingData("preferent_periodo", e.value);
              }}
              onValueChange={(e: any) => {
                updateSettingData("preferent_periodo", e.value);
              }}
              min={0}
              useGrouping={false}
            />
            <span className="p-inputgroup-addon">mes</span>
          </div>

          <p className="footer">{`* De no existir el valor por defecto es 6 meses`}</p>
        </div>
        <div className="setting-element">
          <p className="title">{`Mostrar Descuento`}</p>
          <InputSwitch
            id="applyDiscount"
            checked={state.show_discount}
            onChange={(e: any) => {
              updateSettingData("show_discount", e.value);
            }}
          />
        </div>
        <div className="setting-element">
          <p className="title">{`Descuento Preferente`}</p>
          <div className="p-inputgroup max-w-[200px]">
            <InputNumber
              className="setting-input"
              id="preferentDiscount"
              value={Number(state.discount)}
              onChange={(e: any) => {
                updateSettingData("discount", e.value.toString());
              }}
              onValueChange={(e: any) => {
                updateSettingData("discount", e.value.toString());
              }}
              min={0}
              useGrouping={false}
              mode="currency"
              currency="PEN"
              locale="en-US"
            />
          </div>
          <p className="footer">{`* De no existir el valor por defecto es s/. 0.`}</p>
        </div>
      </div>
      <div className="settings-footer">
        <ActionButton
          loadingAction={loadingAction}
          viewSave={true}
          saveAction={() => {
            editSettings();
          }}
        ></ActionButton>
      </div>
      <Toast ref={toastRef} />
    </div>
  );
};

export default SettingsGeneral;
