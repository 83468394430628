import {
  ROOMS_SEARCH_LOADING,
  ROOMS_SEARCH_FAIL,
  ROOMS_SEARCH_LIST,
} from "./RoomsSearchTypes";
import {
  query,
  where,
  orderBy,
  onSnapshot,
  QuerySnapshot,
  FirestoreError,
  QueryConstraint,
  QueryDocumentSnapshot,
  collection,
  CollectionReference,
} from "firebase/firestore";
import { rentI } from "../../utils/responseEntities";
import { db } from "../../lib/init-firebase";
import { fetchRoomsSearchDTO } from "../../utils/requestDto";
import { TIME_SEARCH, TT_FILTER_LIST } from "../../utils/utils";

const dbPathRent = "/alquiler";
let roomRef = collection(db, dbPathRent) as CollectionReference<rentI>;

export const getListSearchFB = (
  data: fetchRoomsSearchDTO,
  funDone?: any,
  funError?: any
) => {
  console.log("getListSearchFB DATA:", data);
  return (dispatch: any) => {
    if (data?.search) {
      dispatch({ type: ROOMS_SEARCH_LOADING });

      let queryConst: Array<QueryConstraint> = [];
      let today = new Date();

      if (data.date === TIME_SEARCH.WEEK) {
        let startdate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 7,
          0,
          0,
          0,
          0
        );
        queryConst.push(
          where("detalle_alquiler.hora_ingreso", ">=", startdate)
        );
      } else {
        if (data.date === TIME_SEARCH.MONTH) {
          let startdate = new Date(
            today.getFullYear(),
            today.getMonth() - 1,
            today.getDate(),
            0,
            0,
            0,
            0
          );
          queryConst.push(
            where("detalle_alquiler.hora_ingreso", ">=", startdate)
          );
        } else {
          let startdate = new Date(
            today.getFullYear() - 1,
            today.getMonth(),
            today.getDate(),
            0,
            0,
            0,
            0
          );
          queryConst.push(
            where("detalle_alquiler.hora_ingreso", ">=", startdate)
          );
        }
      }

      if (data.type === TT_FILTER_LIST.DNI) {
        queryConst.push(where("usuarios.dni", "==", data.search.toLowerCase()));
      } else if (data.type === TT_FILTER_LIST.HABITACION) {
        queryConst.push(
          where("detalle_habitacion.numero", "==", Number(data.search))
        );
      }
      queryConst.push(where("local_cod", "==", data.local));

      const q = query(roomRef, ...queryConst);

      onSnapshot(
        q,
        (snapshot: QuerySnapshot<rentI>) => {
          let auxProductsHistory: Array<rentI> = [];

          snapshot.docs.forEach((change: QueryDocumentSnapshot<rentI>) => {
            // console.log(change.data());
            auxProductsHistory.push({ key: change.id, ...change.data() });
          });
          dispatch({
            type: ROOMS_SEARCH_LIST,
            payload: auxProductsHistory,
          });
          dispatch({ type: ROOMS_SEARCH_FAIL });

          if (funDone) {
            funDone();
          }
        },
        (error: FirestoreError) => {
          console.log("error on getListSearchFB:", error);
          dispatch({ type: ROOMS_SEARCH_FAIL });

          if (funError) {
            funError();
          }
        }
      );
    }
  };
};
