import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../lib/init-firebase";
import { updateSettingsDTO } from "../../utils/requestDto";

const dbPath = "/configuracion";

export const updateSettingsFB = (
  data: updateSettingsDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("updateSettingsFB DATA:", data);
    await updateDoc(doc(db, dbPath + "/" + data.setting.key), {
      tiempo_cambiodia: data.setting.tiempo_cambiodia,
      tiempo_penalizacion: data.setting.tiempo_penalizacion,
      tiempo_standby: data.setting.tiempo_standby,
      tiempo_turno: data.setting.tiempo_turno,
      show_preferents: data.setting.show_preferents,
      preferent_cantidad: data.setting.preferent_cantidad,
      preferent_periodo: data.setting.preferent_periodo,
      show_discount: data.setting.show_discount,
      discount: data.setting.discount,
      roles: data.setting.roles,
      local_cod: data.setting.local_cod,
      local_name: data.setting.local_name,
    })
      .catch((error: any) => {
        console.log("error on updateSettingsFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(() => {
        if (funDone) {
          funDone();
        }
      });
  };
};
