import React from "react";
import { RootStore } from "../../../redux/Store";
import { useSelector } from "react-redux";
import moment from "moment";

import {
  FLOOR_LIST,
  getFloorByFilter,
  getRoomDescriptionLabel,
  ROOM_CONTENT,
  ROOM_STATUS,
  ROOM_TYPE,
} from "../../../utils/utils";
import { roomI } from "../../../utils/responseEntities";

import RoomTile from "./RoomTile";

interface ListProps {
  active: boolean;
}

const List: React.FunctionComponent<ListProps> = ({ active }: ListProps) => {
  const [timer, setTimer] = React.useState<Date>(new Date());
  const [roomList, setRoomList] = React.useState<Array<roomI>>([]);

  const roomsG: Array<roomI> = useSelector(
    (globalState: RootStore) => globalState.general.roomList
  );
  const selectorRoomR: ROOM_TYPE = useSelector(
    (globalState: RootStore) => globalState.roomsRent.selectorRoom
  );
  const selectorFloorR: FLOOR_LIST = useSelector(
    (globalState: RootStore) => globalState.roomsRent.selectorFloor
  );
  const filterStateR: Array<any> = useSelector(
    (globalState: RootStore) => globalState.roomsRent.filterState
  );
  const filterContentR: Array<ROOM_CONTENT> = useSelector(
    (globalState: RootStore) => globalState.roomsRent.filterContent
  );

  React.useEffect(() => {
    setTimeout(() => {
      setTimer(moment(timer).add(1, "seconds").toDate());
    }, 1000);
  }, [timer]);

  React.useEffect(() => {
    let auxRooms: Array<roomI> = roomsG.filter((room: roomI) => {
      return room.estatus !== ROOM_STATUS.MANTENIMIENTO;
    });

    if (selectorRoomR !== ROOM_TYPE.TODOS) {
      auxRooms = auxRooms.filter((room: roomI) => {
        return room.tipo === selectorRoomR;
      });
    }

    if (selectorFloorR !== FLOOR_LIST.TODOS) {
      auxRooms = auxRooms.filter((room: roomI) => {
        return room.piso === getFloorByFilter(selectorFloorR);
      });
    }

    if (filterStateR.length > 0) {
      auxRooms = auxRooms.filter((room: roomI) => {
        return filterStateR.includes(room.estatus);
      });
    }

    if (filterContentR.length > 0) {
      let auxContent: Array<any> = [];

      // eslint-disable-next-line array-callback-return
      filterContentR.map((content: ROOM_CONTENT) => {
        auxContent.push(getRoomDescriptionLabel(content));
      });

      auxRooms = auxRooms.filter((room: roomI) => {
        return auxContent?.every((desc: string) =>
          room.descripcion?.includes(desc)
        );
      });
    }

    setRoomList(auxRooms);
  }, [roomsG, selectorRoomR, selectorFloorR, filterStateR, filterContentR]);

  return (
    <div className={active ? "room-rent-list" : "room-rent-list active"}>
      {roomList
        .sort((a: roomI, b: roomI) => {
          return a.numero - b.numero;
        })
        .map((room: roomI) => {
          return <RoomTile key={room.key} room={room} timer={timer}></RoomTile>;
        })}
    </div>
  );
};

export default List;
