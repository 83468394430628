import React from "react";
import { ProgressBar } from "primereact/progressbar";
import Logo from "../../assets/logo.png";
import LoginWhiteBackground from "../../assets/login_white_background.png";

interface LoaderProps {
  hasDashboard: boolean;
}

const Loader: React.FunctionComponent<LoaderProps> = ({
  hasDashboard,
}: LoaderProps) => {
  let defaultValue: any;

  const [value, setValue] = React.useState(0);
  const interval = React.useRef(defaultValue);

  React.useEffect(() => {
    let val = value;

    interval.current = setInterval(
      () => {
        val += Math.floor(Math.random() * 20) + 1;

        if (val >= 100) {
          val = 100;
          clearInterval(interval.current);
        }

        setValue(val);
      },
      hasDashboard ? 300 : 50
    );

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="loader"
      style={{
        backgroundImage: `url(${LoginWhiteBackground}`,
      }}
    >
      <img src={Logo} alt="" className="loader-image" />
      <div className="progress-container">
        <ProgressBar
          className="loader-progress"
          showValue={false}
          value={value}
        ></ProgressBar>
      </div>
    </div>
  );
};

export default Loader;
