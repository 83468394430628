import React from "react";
import { useDispatch } from "react-redux";

import {
  getRoomContentLabel,
  ROOM_CONTENT_VALUE,
  ROOM_STATUS,
  ROOM_TYPE,
} from "../../../utils/utils";
import { updateRoomFB } from "../../../redux/rooms/RoomsAction";
import { updateRoomsDTO } from "../../../utils/requestDto";
import { roomI } from "../../../utils/responseEntities";

import ModalDefault from "../../../components/sub-components/modal-default";
import SelectOption from "../../../components/sub-components/select-option";
import NumberInput from "../../../components/sub-components/input-number";

interface ModalEditRoomProps {
  room: roomI;
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalEditRoom: React.FunctionComponent<ModalEditRoomProps> = ({
  room,
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalEditRoomProps) => {
  let defaultValue: any;

  const descArray: Array<string> = [
    ROOM_CONTENT_VALUE.JACUZZI,
    ROOM_CONTENT_VALUE.SMART,
    ROOM_CONTENT_VALUE.LED,
    ROOM_CONTENT_VALUE.SIMPLE,
    ROOM_CONTENT_VALUE.ESPEJO,
    ROOM_CONTENT_VALUE.SILLON,
  ];

  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);
  const [state, setState] = React.useState<roomI>({
    key: "",
    numero: defaultValue,
    piso: defaultValue,
    precio: "",
    tipo: "",
    estatus: "",
    descripcion: [],
    hora_liberado: defaultValue,
    local_cod: defaultValue,
  });

  const [errorNumero, setErrorNumero] = React.useState(false);
  const [errorPiso, setErrorPiso] = React.useState(false);
  const [errorTipo, setErrorTipo] = React.useState(false);
  const [errorPrecio, setErrorPrecio] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);

  const dispatch = useDispatch();

  const updateRoomData = (name: any, value: any) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateDescriptionArray = (desc: string) => {
    let index = state.descripcion?.indexOf(desc, 0);
    let auxArray: Array<string> = [...(state.descripcion || [])];

    if (index !== -1) {
      auxArray.splice(index || 0, 1);
    } else {
      auxArray.push(desc);
    }
    updateRoomData("descripcion", auxArray);
  };

  const validateRoomData = () => {
    if (
      !state.numero ||
      !state.piso ||
      state.precio === "" ||
      state.tipo === "" ||
      state.estatus === ""
    ) {
      setErrorNumero(!state.numero ? true : false);
      setErrorPiso(!state.piso ? true : false);
      setErrorPrecio(state.precio === "" ? true : false);
      setErrorTipo(state.tipo === "" ? true : false);
      setErrorStatus(state.estatus === "" ? true : false);
      return false;
    } else {
      return true;
    }
  };

  const editRoom = () => {
    setLoadingAction(true);
    //EDIT ROOM
    dispatch(
      updateRoomFB(
        {
          room: { ...state, precio: Number(state.precio).toFixed(2) },
        } as updateRoomsDTO,
        () => {
          setLoadingAction(false);
          if (successFun) successFun("Modificado satisfactoriamente");
          setModalShow(false);
        },
        () => {
          setLoadingAction(false);
          if (errorFun) errorFun("Ups! Algo salio mal");
          setModalShow(false);
        }
      )
    );
  };

  React.useEffect(() => {
    if (room) {
      setState((prev) => ({
        ...prev,
        key: room.key,
        numero: room.numero,
        piso: room.piso,
        precio: room.precio,
        tipo: room.tipo,
        estatus: room.estatus,
        descripcion: room.descripcion,
        hora_liberado: room.hora_liberado,
        local_cod: room.local_cod,
      }));
    }
  }, [room]);

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-rooms-edit"
      title={"Editar Habitación"}
      showModalHeader={true}
      showModalFooter={true}
      showEdit={true}
      showCancel={true}
      editAction={() => {
        if (validateRoomData()) {
          editRoom();
        }
      }}
    >
      <React.Fragment>
        <NumberInput
          key={"edit-room-numero"}
          horizontal={false}
          placeholder={""}
          value={state.numero}
          onChange={(value: any) => {
            updateRoomData("numero", value);
            setErrorNumero(false);
          }}
          className={"edit-room-input"}
          viewTitle={true}
          viewErrorMessage={errorNumero}
          title={"Número"}
          errorMessage={"Incorrecto"}
        ></NumberInput>
        <SelectOption
          key={"edit-room-tipo"}
          horizontal={false}
          className={"edit-room-select"}
          clickAction={(value: string) => {
            updateRoomData("tipo", value);
            setErrorTipo(false);
          }}
          optionArray={[
            ROOM_TYPE.SIMPLE,
            ROOM_TYPE.SIMPLE_TV,
            ROOM_TYPE.SMART,
            ROOM_TYPE.SMART_SILLON,
            ROOM_TYPE.LED,
            ROOM_TYPE.LED_SILLON,
            ROOM_TYPE.JACUZZI,
            ROOM_TYPE.DOBLE,
          ]}
          selected={state.tipo}
          viewTitle={true}
          viewErrorMessage={errorTipo}
          title={"Tipo"}
          errorMessage={"Incorrecto"}
        ></SelectOption>
        <NumberInput
          key={"edit-room-piso"}
          horizontal={false}
          placeholder={""}
          value={state.piso}
          onChange={(value: any) => {
            updateRoomData("piso", value);
            setErrorPiso(false);
          }}
          className={"edit-room-input"}
          viewTitle={true}
          viewErrorMessage={errorPiso}
          title={"Piso"}
          errorMessage={"Incorrecto"}
        ></NumberInput>
        <SelectOption
          key={"edit-room-status"}
          horizontal={false}
          className={"edit-room-select"}
          clickAction={(value: string) => {
            updateRoomData("status", value);
            setErrorStatus(false);
          }}
          optionArray={[ROOM_STATUS.LIBRE, ROOM_STATUS.MANTENIMIENTO]}
          selected={state.estatus}
          viewTitle={true}
          viewErrorMessage={errorStatus}
          title={"Estado"}
          errorMessage={"Incorrecto"}
        ></SelectOption>
        <NumberInput
          key={"edit-room-precio"}
          horizontal={false}
          placeholder={""}
          value={state.precio}
          onChange={(value: any) => {
            updateRoomData("precio", value);
            setErrorPrecio(false);
          }}
          className={"edit-room-input"}
          viewTitle={true}
          viewErrorMessage={errorPrecio}
          title={"Precio"}
          errorMessage={"Incorrecto"}
          isCurrency={true}
        ></NumberInput>
        <div className="multipanel-title">{`Descripción`}</div>
        <div className="multipanel-option first">
          {
            // eslint-disable-next-line array-callback-return
            descArray.map((description: string, i: number) => {
              if (i < 3)
                return (
                  <div
                    key={description}
                    className={`single-option ${
                      state.descripcion?.includes(description) ? "active" : ""
                    }`}
                    onClick={() => {
                      updateDescriptionArray(description);
                    }}
                  >
                    <div
                      className={`sub-selector-check ${
                        state.descripcion?.includes(description) ? "active" : ""
                      }`}
                    ></div>
                    <div className="sub-selector-label">{`${getRoomContentLabel(
                      description
                    )}`}</div>
                  </div>
                );
            })
          }
        </div>
        <div className="multipanel-option">
          {
            // eslint-disable-next-line array-callback-return
            descArray.map((description: string, i: number) => {
              if (i > 2)
                return (
                  <div
                    key={description}
                    className={`single-option ${
                      state.descripcion?.includes(description) ? "active" : ""
                    }`}
                    onClick={() => {
                      updateDescriptionArray(description);
                    }}
                  >
                    <div
                      className={`sub-selector-check ${
                        state.descripcion?.includes(description) ? "active" : ""
                      }`}
                    ></div>
                    <div className="sub-selector-label">{`${getRoomContentLabel(
                      description
                    )}`}</div>
                  </div>
                );
            })
          }
        </div>
      </React.Fragment>
    </ModalDefault>
  );
};

export default ModalEditRoom;
