import React from "react";
import { useDispatch } from "react-redux";

import { cleanPreferentFB } from "../../../redux/preferent/PreferentAction";

import ModalDefault from "../../../components/sub-components/modal-default";

interface ModalCleanPreferentProps {
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalCleanPreferent: React.FunctionComponent<
  ModalCleanPreferentProps
> = ({
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalCleanPreferentProps) => {
  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const cleanPreferent = () => {
    setLoadingAction(true);
    //CLEAN PREFERENT
    dispatch(
      cleanPreferentFB(() => {
        setLoadingAction(false);
        if (successFun) successFun("Limpieza satisfactoria");
        setModalShow(false);
      })
    );
  };

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-preferent-clean"
      title={"Limpiar Registros"}
      showModalHeader={true}
      showModalFooter={true}
      showClean={true}
      showCancel={true}
      cleanAction={() => {
        cleanPreferent();
      }}
    >
      <p>
        {`¿Está seguro de `}
        <strong>{"limpiar"}</strong>
        {` la lista de clientes?`}
      </p>
    </ModalDefault>
  );
};

export default ModalCleanPreferent;
