import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../redux/Store";
import "./style.scss";

import {
  defaultPermission,
  generalPermission,
  generalPermissionI,
  permissionI,
} from "../../utils/utils";
import { updateSettingsFB } from "../../redux/settings/SettingsAction";
import { updateSettingsDTO } from "../../utils/requestDto";
import { rolI, settingsI } from "../../utils/responseEntities";

import ActionButton from "../../components/sub-components/action-button";
import { InputSwitch } from "primereact/inputswitch";
import { Toast } from "primereact/toast";

const SettingsRol: React.FunctionComponent = () => {
  let defaultValue: any;
  const toastRef = React.useRef<Toast>(defaultValue);

  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);
  const [toEdit, setToEdit] = React.useState<rolI>(defaultValue);
  const [state, setState] = React.useState<settingsI>({
    key: "",
    tiempo_cambiodia: defaultValue,
    tiempo_penalizacion: defaultValue,
    tiempo_standby: defaultValue,
    tiempo_turno: defaultValue,
    show_preferents: defaultValue,
    preferent_cantidad: defaultValue,
    preferent_periodo: defaultValue,
    show_discount: defaultValue,
    discount: defaultValue,
    roles: defaultValue,
    local_cod: defaultValue,
    local_name: defaultValue,
  });

  const settingsAuth: settingsI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.settings
  );

  const dispatch = useDispatch();

  const getRol = (rol: rolI) => {
    return (
      <div
        key={"rol-" + rol.rol}
        className={`rol-item ${toEdit.rol === rol.rol ? "active" : ""}`}
        onClick={() => {
          setToEdit(rol);
        }}
      >
        <p>{rol.rol}</p>
      </div>
    );
  };

  const getPermission = (general: generalPermissionI, index: number) => {
    return (
      <div className="permission-item" key={"permission-item-" + index}>
        <div className="main-title">
          <p>{general.title.toUpperCase()}</p>
          <InputSwitch
            id={"_" + general.title}
            checked={toEdit?.permission?.includes(general.general)}
            onChange={(e: any) => {
              let index = toEdit.permission?.indexOf(general.general, 0);
              let auxPermissions: Array<string> = [
                ...(toEdit?.permission || []),
              ];

              if (e.value && index === -1) {
                auxPermissions.push(general.general);
              } else if (!e.value && index !== -1) {
                auxPermissions.splice(index || 0, 1);

                auxPermissions = auxPermissions.filter(
                  (p: string) => !p.includes(general.general)
                );
              }

              setToEdit((prevState) => ({
                ...prevState,
                permission: auxPermissions,
              }));
            }}
          />
        </div>
        {general.permission.length > 0 && (
          <div className="description">
            <div className="lower">
              <p>{`Asignado`}</p>
            </div>
            <div className="bigger">
              <p>{`Permiso`}</p>
            </div>
          </div>
        )}
        {
          // eslint-disable-next-line array-callback-return
          general.permission.length > 0 &&
            general.permission.map((p: permissionI) => {
              return (
                <div className="permission" key={"permission-" + p.value}>
                  <div className="lower">
                    <InputSwitch
                      id={p.value}
                      checked={toEdit?.permission?.includes(p.value)}
                      onChange={(e: any) => {
                        let index = toEdit.permission?.indexOf(p.value, 0);
                        let auxPermissions: Array<string> = [
                          ...(toEdit?.permission || []),
                        ];
                        let auxSimilar = auxPermissions.filter((p: string) =>
                          p.includes(general.general)
                        );

                        if (index !== -1) {
                          auxPermissions.splice(index || 0, 1);
                        } else {
                          auxPermissions.push(p.value);
                          if (auxSimilar.length === 0) {
                            auxPermissions.push(general.general);
                          }
                        }

                        setToEdit((prevState) => ({
                          ...prevState,
                          permission: auxPermissions,
                        }));
                      }}
                    />
                  </div>
                  <div className="bigger">
                    <p>{p.name}</p>
                  </div>
                </div>
              );
            })
        }
      </div>
    );
  };

  const editSettings = () => {
    let auxRol: Array<rolI> = [];

    // eslint-disable-next-line array-callback-return
    settingsAuth?.roles.map((rol: rolI) => {
      if (rol.rol === toEdit.rol) {
        auxRol.push(toEdit);
      } else {
        auxRol.push(rol);
      }
    });

    setLoadingAction(true);
    //UPDATE SETTINGS
    dispatch(
      updateSettingsFB(
        {
          setting: { ...state, roles: auxRol },
        } as updateSettingsDTO,
        () => {
          setLoadingAction(false);
          toastRef.current.show({
            severity: "success",
            summary: "Ajustes General",
            detail: "Actualizado satisfactoriamente",
            life: 3000,
            closable: false,
          });
        },
        () => {
          setLoadingAction(false);
          toastRef.current.show({
            severity: "error",
            summary: "Ajustes General",
            detail: "Ups! Algo salio mal",
            life: 3000,
            closable: false,
          });
        }
      )
    );
  };

  React.useEffect(() => {
    if (settingsAuth) {
      setState((prev) => ({
        ...prev,
        key: settingsAuth.key,
        tiempo_cambiodia: settingsAuth.tiempo_cambiodia || 0,
        tiempo_penalizacion: settingsAuth.tiempo_penalizacion || 0,
        tiempo_standby: settingsAuth.tiempo_standby || 0,
        tiempo_turno: settingsAuth.tiempo_turno || 0,
        show_preferents: settingsAuth.show_preferents || false,
        preferent_cantidad: settingsAuth.preferent_cantidad || 0,
        preferent_periodo: settingsAuth.preferent_periodo || 0,
        show_discount: settingsAuth.show_discount || false,
        discount: settingsAuth.discount || "0",
        roles: settingsAuth.roles || defaultPermission,
        local_cod: settingsAuth.local_cod,
        local_name: settingsAuth.local_name || "",
      }));
      setToEdit(
        toEdit
          ? settingsAuth.roles.find((rol: rolI) => toEdit.rol === rol.rol) ||
              settingsAuth.roles[0]
          : settingsAuth.roles[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsAuth]);

  return (
    <div className="settings-rol-container">
      <div className="settings-rol">
        <div className="rol-list">
          {state.roles?.map((rol: rolI, i: number) => getRol(rol))}
        </div>
        <ActionButton
          loadingAction={loadingAction}
          viewSave={true}
          saveAction={() => {
            editSettings();
          }}
        ></ActionButton>
      </div>
      <div className="settings-permission">
        {generalPermission.map((general: generalPermissionI, i: number) =>
          getPermission(general, i)
        )}
      </div>
      <Toast ref={toastRef} />
    </div>
  );
};
export default SettingsRol;
