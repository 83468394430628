import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";
import moment from "moment";

import { ROOMS_HISTORY_RANGE } from "../../../redux/roomsHistory/RoomsHistoryTypes";

import { Calendar } from "primereact/calendar";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { rentI } from "../../../utils/responseEntities";
// import { downloadPDF, generatePDFbody } from "../../../utils/utils";
// import { downloadPDF, generatePDFbody } from "../../../utils/utils";

interface TimeTileProps {
  tileTitle: any;
  tileAmount: any;
  tileQty: any;
  isActive: any;
  isCustom: any;
  updateTurn: any;
}

const TimeTile: React.FunctionComponent<TimeTileProps> = ({
  tileTitle,
  tileAmount,
  tileQty,
  isActive,
  isCustom,
  updateTurn,
}: any) => {
  const dateH: Date = useSelector(
    (globalState: RootStore) => globalState.roomsHistory.customDateHistory
  );
  const rentH: Array<rentI> = useSelector(
    (globalState: RootStore) => globalState.roomsHistory.rentHistoryCustom
  );

  const dispatch = useDispatch();

  const updateDate = (value: any) => {
    dispatch({ type: ROOMS_HISTORY_RANGE, payload: { date: value } });
  };

  return (
    <div
      className={"room-history-time-tile" + (isActive ? " active" : "")}
      onClick={() => {
        updateTurn(tileTitle);
      }}
    >
      <div className="tile-first">
        {!isCustom && <div className="first-title">{tileTitle}</div>}
        {isCustom && (
          <React.Fragment>
            {dateH && dateH !== undefined ? (
              <div className="first-title active">
                {moment(dateH).format("DD/MM/YYYY")}
              </div>
            ) : (
              <div className="first-title">{tileTitle}</div>
            )}
            <div className="custom-tile-action">
              {dateH && dateH !== undefined && rentH.length > 0 && (
                <div
                  className="pdf-button"
                  onClick={() => {
                    console.log("PDFFFFFFF");
                    // let bodyResults = generatePDFbody(rentH);
                    // downloadPDF(bodyResults["body"]);
                  }}
                >
                  <BsFileEarmarkPdf></BsFileEarmarkPdf>
                </div>
              )}
              <Calendar
                id="icon"
                value={dateH}
                panelClassName={"!right-[45px] !top-[120px] !left-auto"}
                onChange={(e: any) => updateDate(e.value)}
                showIcon
              />
            </div>
          </React.Fragment>
        )}
      </div>
      <div className="tile-amount">{"s/. " + tileAmount}</div>
      <div className="tile-last">
        <div className="last-legend">{"#Rentas:"}</div>
        <div className="last-qty">{tileQty}</div>
      </div>
    </div>
  );
};

export default TimeTile;
