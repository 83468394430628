import React from "react";
import { useLocation } from "react-router";
import { CgPlayList } from "react-icons/cg";
import ProductsOverlay from "./sub-components/products-overlay";
import RoomsOverlay from "./sub-components/rooms-overlay";
import UserOverlay from "./sub-components/user-overlay";

interface TopProps {
  history: any;
  showMenu: boolean;
  setMenu: any;
}

const Top: React.FunctionComponent<TopProps> = ({
  history,
  showMenu,
  setMenu,
}: any) => {
  let defaultValue: any;

  const [title, setTitle] = React.useState(defaultValue);
  const location = useLocation();

  const changeTitle = () => {
    if (location.pathname === "/dashboard") {
      setTitle("Dashboard");
    } else if (location.pathname.includes("products")) {
      setTitle("Productos");
    } else if (location.pathname.includes("rooms")) {
      setTitle("Habitaciones");
    } else if (location.pathname.includes("clients")) {
      setTitle("Clientes");
    } else if (location.pathname.includes("users")) {
      setTitle("Usuarios");
    } else if (location.pathname.includes("settings")) {
      setTitle("Ajustes");
    }
  };

  React.useEffect(() => {
    changeTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className="top-header">
      <div className="top-left-side">
        <CgPlayList></CgPlayList>
        <div className="title">{title}</div>
      </div>
      <div className="top-rigth-side">
        <ProductsOverlay></ProductsOverlay>
        <RoomsOverlay></RoomsOverlay>
        <UserOverlay></UserOverlay>
      </div>
    </div>
  );
};

export default Top;
