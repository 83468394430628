import { detailSI, sellI } from "../../utils/responseEntities";
import { customDateI } from "../../utils/utils";

export const PRODUCTS_HISTORY_LOADING_LAST = "PRODUCTS_HISTORY_LOADING_LAST";
export const PRODUCTS_HISTORY_FAIL_LAST = "PRODUCTS_HISTORY_FAIL_LAST";
export const PRODUCTS_HISTORY_LOADING_ACTUAL =
  "PRODUCTS_HISTORY_LOADING_ACTUAL";
export const PRODUCTS_HISTORY_FAIL_ACTUAL = "PRODUCTS_HISTORY_FAIL_ACTUAL";
export const PRODUCTS_HISTORY_LOADING_CUSTOM =
  "PRODUCTS_HISTORY_LOADING_CUSTOM";
export const PRODUCTS_HISTORY_FAIL_CUSTOM = "PRODUCTS_HISTORY_FAIL_CUSTOM";

export const PRODUCTS_HISTORY_LIST_LAST = "PRODUCTS_HISTORY_LIST_LAST";
export const PRODUCTS_HISTORY_LIST_ACTUAL = "PRODUCTS_HISTORY_LIST_ACTUAL";
export const PRODUCTS_HISTORY_LIST_CUSTOM = "PRODUCTS_HISTORY_LIST_CUSTOM";
export const PRODUCTS_HISTORY_DETAIL_LIST = "PRODUCTS_HISTORY_DETAIL_LIST";

export const PRODUCTS_HISTORY_TURN = "PRODUCTS_HISTORY_TURN";
export const PRODUCTS_HISTORY_RANGE = "PRODUCTS_HISTORY_RANGE";

export interface ProductsHistoryLoadingLast {
  type: typeof PRODUCTS_HISTORY_LOADING_LAST;
  payload: any;
}
export interface ProductsHistoryFailLast {
  type: typeof PRODUCTS_HISTORY_FAIL_LAST;
  payload: any;
}
export interface ProductsHistoryLoadingActual {
  type: typeof PRODUCTS_HISTORY_LOADING_ACTUAL;
  payload: any;
}
export interface ProductsHistoryFailActual {
  type: typeof PRODUCTS_HISTORY_FAIL_ACTUAL;
  payload: any;
}
export interface ProductsHistoryLoadingCustom {
  type: typeof PRODUCTS_HISTORY_LOADING_CUSTOM;
  payload: any;
}
export interface ProductsHistoryFailCustom {
  type: typeof PRODUCTS_HISTORY_FAIL_CUSTOM;
  payload: any;
}
export interface ProductsHistoryListLast {
  type: typeof PRODUCTS_HISTORY_LIST_LAST;
  payload: any;
}
export interface ProductsHistoryListActual {
  type: typeof PRODUCTS_HISTORY_LIST_ACTUAL;
  payload: any;
}
export interface ProductsHistoryListCustom {
  type: typeof PRODUCTS_HISTORY_LIST_CUSTOM;
  payload: any;
}
export interface ProductsHistoryTurn {
  type: typeof PRODUCTS_HISTORY_TURN;
  payload: any;
}
export interface ProductsHistoryRange {
  type: typeof PRODUCTS_HISTORY_RANGE;
  payload: customDateI;
}
export interface ProductsHistoryDetails {
  type: typeof PRODUCTS_HISTORY_DETAIL_LIST;
  payload: any;
}

export type ProductsHistoryDispatchTypes =
  | ProductsHistoryLoadingLast
  | ProductsHistoryFailLast
  | ProductsHistoryLoadingActual
  | ProductsHistoryFailActual
  | ProductsHistoryLoadingCustom
  | ProductsHistoryFailCustom
  | ProductsHistoryListLast
  | ProductsHistoryListActual
  | ProductsHistoryListCustom
  | ProductsHistoryTurn
  | ProductsHistoryRange
  | ProductsHistoryDetails;
