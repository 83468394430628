import React from "react";
import { useDispatch } from "react-redux";

import { BANNED_CATEGORY, onlyDigits, onlyLetters } from "../../../utils/utils";
import { updateBannedFB } from "../../../redux/banned/BannedAction";
import { updateBannedDTO } from "../../../utils/requestDto";
import { bannedI } from "../../../utils/responseEntities";

import ModalDefault from "../../../components/sub-components/modal-default";
import SelectOption from "../../../components/sub-components/select-option";
import InputField from "../../../components/sub-components/input-field";
import InputDate from "../../../components/sub-components/input-date";

interface ModalEditBannedProps {
  banned: bannedI;
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalEditBanned: React.FunctionComponent<ModalEditBannedProps> = ({
  banned,
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalEditBannedProps) => {
  let defaultValue: any;

  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);
  const [state, setState] = React.useState<bannedI>({
    key: "",
    nombre: "",
    dni: "",
    categoria: "",
    fecha_nacimiento: defaultValue,
  });

  const [errorNombre, setErrorNombre] = React.useState(false);
  const [errorDni, setErrorDni] = React.useState(false);
  const [errorCategoria, setErrorCategoria] = React.useState(false);

  const dispatch = useDispatch();

  const updateBannedData = (name: any, value: any) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateBannedData = () => {
    if (state.nombre === "" || state.dni === "" || state.categoria === "") {
      setErrorNombre(state.nombre === "" ? true : false);
      setErrorDni(state.dni === "" ? true : false);
      setErrorCategoria(state.categoria === "" ? true : false);
      return false;
    } else {
      return true;
    }
  };

  const editBanned = () => {
    setLoadingAction(true);
    //EDIT BANNED
    dispatch(
      updateBannedFB(
        {
          banned: {
            key: state.key,
            nombre: state.nombre,
            dni: state.dni,
            categoria: state.categoria.toUpperCase(),
            fecha_nacimiento: state.fecha_nacimiento
              ? state.fecha_nacimiento
              : "",
          },
        } as updateBannedDTO,
        () => {
          setLoadingAction(false);
          if (successFun) successFun("Modificado satisfactoriamente");
          setModalShow(false);
        },
        () => {
          setLoadingAction(false);
          if (errorFun) errorFun("Ups! Algo salio mal");
          setModalShow(false);
        }
      )
    );
  };

  React.useEffect(() => {
    if (banned) {
      setState((prev) => ({
        ...prev,
        key: banned.key,
        nombre: banned.nombre.toLowerCase(),
        dni: banned.dni,
        categoria: banned.categoria,
        fecha_nacimiento: banned.fecha_nacimiento,
      }));
    }
  }, [banned]);

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-banned-edit"
      title={"Editar Banned"}
      showModalHeader={true}
      showModalFooter={true}
      showEdit={true}
      showCancel={true}
      editAction={() => {
        if (validateBannedData()) {
          editBanned();
        }
      }}
    >
      <React.Fragment>
        <InputField
          key={"edit-banned-nombre"}
          horizontal={false}
          placeholder={""}
          value={state.nombre}
          onChange={(value: any) => {
            updateBannedData("nombre", value.toLowerCase());
            setErrorNombre(false);
          }}
          className={"edit-banned-input"}
          regExp={onlyLetters}
          viewTitle={true}
          viewErrorMessage={errorNombre}
          title={"Nombre"}
          errorMessage={"Incorrecto"}
          height={"!h-[40px]"}
          width={"!w-full"}
          isUppercase={true}
        ></InputField>
        <SelectOption
          key={"edit-banned-categoria"}
          horizontal={false}
          className={"edit-banned-select"}
          clickAction={(value: string) => {
            updateBannedData("categoria", value);
            setErrorCategoria(false);
          }}
          optionArray={[
            BANNED_CATEGORY.LADRON,
            BANNED_CATEGORY.FUMON,
            BANNED_CATEGORY.OTRO,
          ]}
          selected={state.categoria}
          viewTitle={true}
          viewErrorMessage={errorCategoria}
          title={"Categoría"}
          errorMessage={"Incorrecto"}
        ></SelectOption>
        <InputField
          key={"edit-banned-dni"}
          disabled
          horizontal={false}
          placeholder={""}
          value={state.dni}
          onChange={(value: any) => {
            updateBannedData("dni", value);
            setErrorDni(false);
          }}
          className={"edit-banned-input"}
          regExp={onlyDigits}
          viewTitle={true}
          viewErrorMessage={errorDni}
          title={"DNI"}
          errorMessage={"Incorrecto"}
          height={"!h-[40px]"}
          width={"!w-full"}
        ></InputField>
        <InputDate
          key={"edit-banned-fecha-nacimiento"}
          horizontal={false}
          placeholder={"DD/MM/YYYY"}
          value={state.fecha_nacimiento || undefined}
          onChange={(value: any) => {
            updateBannedData("fecha_nacimiento", value);
          }}
          className={"edit-banned-input"}
          viewTitle={true}
          viewErrorMessage={false}
          title={"Fech. Nac."}
          errorMessage={"Incorrecto"}
        ></InputDate>
      </React.Fragment>
    </ModalDefault>
  );
};

export default ModalEditBanned;
