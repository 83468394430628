import React from "react";
import { useDispatch } from "react-redux";

import { deletePreferentFB } from "../../../redux/preferent/PreferentAction";
import { deletePreferentsDTO } from "../../../utils/requestDto";
import { preferentI } from "../../../utils/responseEntities";
import { capitalizeWords } from "../../../utils/utils";

import ModalDefault from "../../../components/sub-components/modal-default";

interface ModalDeletePreferentProps {
  preferent: preferentI;
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalDeletePreferent: React.FunctionComponent<
  ModalDeletePreferentProps
> = ({
  preferent,
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalDeletePreferentProps) => {
  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const deletePreferent = () => {
    setLoadingAction(true);
    //DELETE PREFERENT
    dispatch(
      deletePreferentFB(
        {
          key: preferent.key,
        } as deletePreferentsDTO,
        () => {
          setLoadingAction(false);
          if (successFun) successFun("Eliminado satisfactoriamente");
          setModalShow(false);
        },
        () => {
          setLoadingAction(false);
          if (errorFun) errorFun("Ups! Algo salio mal");
          setModalShow(false);
        }
      )
    );
  };

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-preferents-delete"
      title={"Eliminar Preferent"}
      showModalHeader={true}
      showModalFooter={true}
      showDelete={true}
      showCancel={true}
      deleteAction={() => {
        deletePreferent();
      }}
    >
      <p>
        {`¿Está seguro de eliminar al cliente `}
        <strong>
          {capitalizeWords(preferent.nombres)}{" "}
          {capitalizeWords(preferent.apellido_pat)}{" "}
          {capitalizeWords(preferent.apellido_mat)}
        </strong>
        {`?`}
      </p>
    </ModalDefault>
  );
};

export default ModalDeletePreferent;
