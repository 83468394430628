import React from "react";

interface FilterOptionProps {
  clickAction: any;
  radioTitle: any;
  isActive: any;
}

const RadioOption: React.FunctionComponent<FilterOptionProps> = ({
  clickAction,
  radioTitle,
  isActive,
}: any) => {
  return (
    <div
      className={isActive ? "radio-option active" : "radio-option"}
      onClick={() => {
        clickAction(!isActive);
      }}
    >
      <div className="radio-circle"></div>
      <div className="radio-title">{radioTitle}</div>
    </div>
  );
};

export default RadioOption;
