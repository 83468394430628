export const PRODUCTS_SELL_LOADING = "PRODUCTS_SELL_LOADING";
export const PRODUCTS_SELL_FAIL = "PRODUCTS_SELL_FAIL";
export const PRODUCTS_SELL_UPDATE_FILTER = "PRODUCTS_SELL_UPDATE_FILTER";
export const PRODUCTS_SELL_UPDATE_SELECTOR = "PRODUCTS_SELL_UPDATE_SELECTOR";
export const PRODUCTS_SELL_SEARCH = "PRODUCTS_SELL_SEARCH";

export interface ProductsSellLoading {
  type: typeof PRODUCTS_SELL_LOADING;
  payload: any;
}
export interface ProductsSellFail {
  type: typeof PRODUCTS_SELL_FAIL;
  payload: any;
}
export interface ProductsSellUpdateFilter {
  type: typeof PRODUCTS_SELL_UPDATE_FILTER;
  payload: any;
}
export interface ProductsSellUpdateSelector {
  type: typeof PRODUCTS_SELL_UPDATE_SELECTOR;
  payload: any;
}
export interface ProductsSellSearch {
  type: typeof PRODUCTS_SELL_SEARCH;
  payload: any;
}

export type ProductsSellDispatchTypes =
  | ProductsSellLoading
  | ProductsSellFail
  | ProductsSellUpdateFilter
  | ProductsSellUpdateSelector
  | ProductsSellSearch;
