import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "./auth/AuthReducer";
import generalReducer from "./general/GeneralReducer";
import dashboardReducer from "./dashboard/DashboardReducer";
import productsReducer from "./products/ProductsReducer";
import productsHistoryReducer from "./productsHistory/ProductsHistoryReducer";
import productsSellReducer from "./productsSell/ProductsSellReducer";
import roomsReducer from "./rooms/RoomsReducer";
import roomsHistoryReducer from "./roomsHistory/RoomsHistoryReducer";
import roomsRentReducer from "./roomsRent/RoomsRentReducer";
import roomsSearchReducer from "./roomsSearch/RoomsSearchReducer";
import preferentReducer from "./preferent/PreferentReducer";
import bannedReducer from "./banned/BannedReducer";
import usersReducer from "./users/UsersReducer";

const appReducer = combineReducers({
  //=====AUTH
  auth: authReducer,
  //=====GENERAL
  general: generalReducer,
  //=====DASHBOARD
  dashboard: dashboardReducer,
  //=====PRODUCTS
  products: productsReducer,
  productsHistory: productsHistoryReducer,
  productsSell: productsSellReducer,
  //=====ROOMS
  rooms: roomsReducer,
  roomsHistory: roomsHistoryReducer,
  roomsRent: roomsRentReducer,
  roomsSearch: roomsSearchReducer,
  //=====CLIENTS
  preferent: preferentReducer,
  banned: bannedReducer,
  //=====USERS
  users: usersReducer,
});

const RootReducer = (state: any, action: any) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "USER_LOGGED_OUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default RootReducer;
