import React from "react";
import { useDispatch } from "react-redux";

import { deleteRoomFB } from "../../../redux/rooms/RoomsAction";
import { deleteRoomsDTO } from "../../../utils/requestDto";
import { roomI } from "../../../utils/responseEntities";

import ModalDefault from "../../../components/sub-components/modal-default";

interface ModalDeleteRoomProps {
  room: roomI;
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalDeleteRoom: React.FunctionComponent<ModalDeleteRoomProps> = ({
  room,
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalDeleteRoomProps) => {
  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const deleteRoom = () => {
    setLoadingAction(true);
    //DELETE ROOM
    dispatch(
      deleteRoomFB(
        {
          key: room.key,
        } as deleteRoomsDTO,
        () => {
          setLoadingAction(false);
          if (successFun) successFun("Eliminado satisfactoriamente");
          setModalShow(false);
        },
        () => {
          setLoadingAction(false);
          if (errorFun) errorFun("Ups! Algo salio mal");
          setModalShow(false);
        }
      )
    );
  };

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-rooms-delete"
      title={"Eliminar Habitación"}
      showModalHeader={true}
      showModalFooter={true}
      showDelete={true}
      showCancel={true}
      deleteAction={() => {
        deleteRoom();
      }}
    >
      <p>
        {`¿Está seguro de eliminar la habitación `}
        <strong>#{room.numero}</strong>
        {`?`}
      </p>
    </ModalDefault>
  );
};

export default ModalDeleteRoom;
