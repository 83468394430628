import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";

import {
  getRoomContentLabel,
  ROOM_CONTENT_VALUE,
  ROOM_STATUS,
  ROOM_TYPE,
} from "../../../utils/utils";
import { roomI, settingsI } from "../../../utils/responseEntities";
import { addRoomFB } from "../../../redux/rooms/RoomsAction";
import { addRoomsDTO } from "../../../utils/requestDto";

import ModalDefault from "../../../components/sub-components/modal-default";
import SelectOption from "../../../components/sub-components/select-option";
import NumberInput from "../../../components/sub-components/input-number";

interface ModalAddRoomProps {
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalAddRoom: React.FunctionComponent<ModalAddRoomProps> = ({
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalAddRoomProps) => {
  let defaultValue: any;
  const settingsAuth: settingsI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.settings
  );

  const descArray: Array<string> = [
    ROOM_CONTENT_VALUE.JACUZZI,
    ROOM_CONTENT_VALUE.SMART,
    ROOM_CONTENT_VALUE.LED,
    ROOM_CONTENT_VALUE.SIMPLE,
    ROOM_CONTENT_VALUE.ESPEJO,
    ROOM_CONTENT_VALUE.SILLON,
  ];

  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);
  const [state, setState] = React.useState<roomI>({
    numero: defaultValue,
    piso: defaultValue,
    precio: "",
    tipo: "",
    estatus: "",
    descripcion: [],
    hora_liberado: new Date(),
    local_cod: settingsAuth?.local_cod || 0,
  });

  const [errorNumero, setErrorNumero] = React.useState(false);
  const [errorTipo, setErrorTipo] = React.useState(false);
  const [errorPiso, setErrorPiso] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [errorPrecio, setErrorPrecio] = React.useState(false);

  const dispatch = useDispatch();

  const updateRoomData = (name: any, value: any) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateDescriptionArray = (desc: string) => {
    let index = state.descripcion?.indexOf(desc, 0);
    let auxArray: Array<string> = [...(state.descripcion || [])];

    if (index !== -1) {
      auxArray.splice(index || 0, 1);
    } else {
      auxArray.push(desc);
    }
    updateRoomData("descripcion", auxArray);
  };

  const validateRoomData = () => {
    if (
      !state.numero ||
      !state.piso ||
      state.precio === "" ||
      state.tipo === "" ||
      state.estatus === ""
    ) {
      setErrorNumero(!state.numero ? true : false);
      setErrorPiso(!state.piso ? true : false);
      setErrorPrecio(state.precio === "" ? true : false);
      setErrorTipo(state.tipo === "" ? true : false);
      setErrorStatus(state.estatus === "" ? true : false);
      return false;
    } else {
      return true;
    }
  };

  const addRoom = () => {
    setLoadingAction(true);
    //ADD ROOM
    dispatch(
      addRoomFB(
        {
          room: {
            ...state,
            precio: Number(state.precio).toFixed(2),
          },
        } as addRoomsDTO,
        () => {
          setLoadingAction(false);
          if (successFun) successFun("Agregado satisfactoriamente");
          setModalShow(false);
        },
        () => {
          setLoadingAction(false);
          if (errorFun) errorFun("Ups! Algo salio mal");
          setModalShow(false);
        }
      )
    );
  };

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-rooms-add"
      title={"Agregar Habitación"}
      showModalHeader={true}
      showModalFooter={true}
      showAdd={true}
      showCancel={true}
      addAction={() => {
        if (validateRoomData()) {
          addRoom();
        }
      }}
    >
      <React.Fragment>
        <NumberInput
          key={"add-room-numero"}
          horizontal={false}
          placeholder={""}
          value={state.numero}
          onChange={(value: any) => {
            updateRoomData("numero", value);
            setErrorNumero(false);
          }}
          className={"add-room-input"}
          viewTitle={true}
          viewErrorMessage={errorNumero}
          title={"Número"}
          errorMessage={"Incorrecto"}
        ></NumberInput>
        <SelectOption
          key={"add-room-tipo"}
          horizontal={false}
          className={"add-room-select"}
          clickAction={(value: string) => {
            updateRoomData("tipo", value);
            setErrorTipo(false);
          }}
          optionArray={[
            ROOM_TYPE.SIMPLE,
            ROOM_TYPE.SIMPLE_TV,
            ROOM_TYPE.SMART,
            ROOM_TYPE.SMART_SILLON,
            ROOM_TYPE.LED,
            ROOM_TYPE.LED_SILLON,
            ROOM_TYPE.JACUZZI,
            ROOM_TYPE.DOBLE,
          ]}
          selected={state.tipo}
          viewTitle={true}
          viewErrorMessage={errorTipo}
          title={"Tipo"}
          errorMessage={"Incorrecto"}
        ></SelectOption>
        <NumberInput
          key={"add-room-piso"}
          horizontal={false}
          placeholder={""}
          value={state.piso}
          onChange={(value: any) => {
            updateRoomData("piso", value);
            setErrorPiso(false);
          }}
          className={"add-room-input"}
          viewTitle={true}
          viewErrorMessage={errorPiso}
          title={"Piso"}
          errorMessage={"Incorrecto"}
        ></NumberInput>
        <SelectOption
          key={"add-room-estatus"}
          horizontal={false}
          className={"add-room-select"}
          clickAction={(value: string) => {
            updateRoomData("estatus", value);
            setErrorStatus(false);
          }}
          optionArray={[ROOM_STATUS.LIBRE, ROOM_STATUS.MANTENIMIENTO]}
          selected={state.estatus}
          viewTitle={true}
          viewErrorMessage={errorStatus}
          title={"Estado"}
          errorMessage={"Incorrecto"}
        ></SelectOption>
        <NumberInput
          key={"add-room-precio"}
          horizontal={false}
          placeholder={""}
          value={state.precio}
          onChange={(value: any) => {
            updateRoomData("precio", value);
            setErrorPrecio(false);
          }}
          className={"add-room-input"}
          viewTitle={true}
          viewErrorMessage={errorPrecio}
          title={"Precio"}
          errorMessage={"Incorrecto"}
          isCurrency={true}
        ></NumberInput>
        <div className="multipanel-title">{`Descripción`}</div>
        <div className="multipanel-option first">
          {
            // eslint-disable-next-line array-callback-return
            descArray.map((description: string, i: number) => {
              if (i < 3)
                return (
                  <div
                    key={description}
                    className={`single-option ${
                      state.descripcion?.includes(description) ? "active" : ""
                    }`}
                    onClick={() => {
                      updateDescriptionArray(description);
                    }}
                  >
                    <div
                      className={`sub-selector-check ${
                        state.descripcion?.includes(description) ? "active" : ""
                      }`}
                    ></div>
                    <div className="sub-selector-label">{`${getRoomContentLabel(
                      description
                    )}`}</div>
                  </div>
                );
            })
          }
        </div>
        <div className="multipanel-option">
          {
            // eslint-disable-next-line array-callback-return
            descArray.map((description: string, i: number) => {
              if (i > 2)
                return (
                  <div
                    key={description}
                    className={`single-option ${
                      state.descripcion?.includes(description) ? "active" : ""
                    }`}
                    onClick={() => {
                      updateDescriptionArray(description);
                    }}
                  >
                    <div
                      className={`sub-selector-check ${
                        state.descripcion?.includes(description) ? "active" : ""
                      }`}
                    ></div>
                    <div className="sub-selector-label">{`${getRoomContentLabel(
                      description
                    )}`}</div>
                  </div>
                );
            })
          }
        </div>
      </React.Fragment>
    </ModalDefault>
  );
};

export default ModalAddRoom;
