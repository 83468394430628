import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";

import {
  PRODUCTS_SELL_SEARCH,
  PRODUCTS_SELL_UPDATE_SELECTOR,
} from "../../../redux/productsSell/ProductsSellTypes";

import SelectOption from "../../../components/sub-components/select-option";
import SearchBox from "../../../components/sub-components/search-box";

const TopToolbar: React.FunctionComponent = () => {
  const selectOptionS: any = useSelector(
    (globalState: RootStore) => globalState.productsSell.selectOption
  );
  const selectorS: any = useSelector(
    (globalState: RootStore) => globalState.productsSell.selector
  );
  const searchS: any = useSelector(
    (globalState: RootStore) => globalState.productsSell.search
  );

  const dispatch = useDispatch();

  const updateSelector = (name: string) => {
    dispatch({ type: PRODUCTS_SELL_UPDATE_SELECTOR, payload: name });
  };

  const updateSearch = (name: string) => {
    dispatch({ type: PRODUCTS_SELL_SEARCH, payload: name });
  };

  return (
    <React.Fragment>
      <div className="products-sell-toolbar">
        <div className="toolbar-left">
          <SearchBox
            searchValue={searchS}
            searchAction={(value: string) => {
              updateSearch(value);
            }}
          ></SearchBox>
          <SelectOption
            clickAction={(name: string) => {
              updateSelector(name);
            }}
            optionArray={selectOptionS}
            selected={selectorS}
          ></SelectOption>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TopToolbar;
