export const ROOMS_LOADING = "ROOMS_LOADING";
export const ROOMS_FAIL = "ROOMS_FAIL";
export const ROOMS_UPDATE_FILTER = "ROOMS_UPDATE_FILTER";
export const ROOMS_UPDATE_SELECTOR = "ROOMS_UPDATE_SELECTOR";
export const ROOMS_SEARCH = "ROOMS_SEARCH";
export const ROOMS_LIST = "ROOMS_LIST";

export interface RoomsLoading {
  type: typeof ROOMS_LOADING;
  payload: any;
}
export interface RoomsFail {
  type: typeof ROOMS_FAIL;
  payload: any;
}
export interface RoomsUpdateFilter {
  type: typeof ROOMS_UPDATE_FILTER;
  payload: any;
}
export interface RoomsUpdateSelector {
  type: typeof ROOMS_UPDATE_SELECTOR;
  payload: any;
}
export interface RoomsSearch {
  type: typeof ROOMS_SEARCH;
  payload: any;
}
export interface RoomsList {
  type: typeof ROOMS_LIST;
  payload: any;
}

export type RoomsDispatchTypes =
  | RoomsLoading
  | RoomsFail
  | RoomsUpdateFilter
  | RoomsUpdateSelector
  | RoomsSearch
  | RoomsList;
