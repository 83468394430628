import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../redux/Store";
import { useLocation } from "react-router";
import moment from "moment";
import "./style.scss";

import {
  bannedI,
  frequencyI,
  preferentI,
  rentI,
  roomI,
  settingsI,
  userI,
} from "../../utils/responseEntities";
import { onlyDigits, onlyLetters, ROOM_STATUS } from "../../utils/utils";
import { PRIVATE_ROOMS_RENT } from "../../utils/paths";
import history from "../../utils/history";

import ActionButton from "../../components/sub-components/action-button";
import NumberInput from "../../components/sub-components/input-number";
import InputField from "../../components/sub-components/input-field";
import InputDate from "../../components/sub-components/input-date";
import {
  addFrecuencyFB,
  checkClientExist,
  occupyRoomFB,
  registerRentFB,
} from "../../redux/roomsForm/RoomsFormAction";
import {
  addFrequencyDTO,
  addRentDTO,
  CheckClientExist,
  updateRoomsDTO,
} from "../../utils/requestDto";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";

const RoomsForm: React.FunctionComponent = () => {
  let defaultValue: any;
  const toastRef = React.useRef<Toast>(defaultValue);

  const roomsG: Array<roomI> = useSelector(
    (globalState: RootStore) => globalState.general.roomList
  );
  const userAuth: userI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.user
  );
  const settingsAuth: settingsI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.settings
  );
  const preferentGeneral: Array<preferentI> = useSelector(
    (globalState: RootStore) => globalState.general.preferentList
  );
  const bannedGeneral: Array<bannedI> = useSelector(
    (globalState: RootStore) => globalState.general.bannedList
  );

  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);
  const [loadingSearchAction, setLoadingSearchAction] =
    React.useState<boolean>(false);
  const [actualDate] = React.useState(new Date());
  const [state, setState] = React.useState<rentI>({
    detalle_alquiler: {
      cantidad_turnos: defaultValue,
      hora_ingreso: defaultValue,
      hora_salida: defaultValue,
      precio_total: "",
    },
    detalle_habitacion: {
      key: "",
      numero: defaultValue,
      piso: defaultValue,
      precio: "",
      tipo: "",
    },
    usuarios: {
      dni: "",
      nombres: "",
      apellido_pat: "",
      apellido_mat: "",
    },
    recepcionista: userAuth?.nombre || "",
    descuento_monto: settingsAuth?.show_discount
      ? settingsAuth?.discount || ""
      : "",
    local_cod: settingsAuth?.local_cod || 0,
  });

  const [errorDni, setErrorDni] = React.useState(false);
  const [errorNombres, setErrorNombres] = React.useState(false);
  const [errorApellidoPat, setErrorApellidoPat] = React.useState(false);
  const [errorApellidoMat, setErrorApellidoMat] = React.useState(false);
  const [errorTurnos, setErrorTurnos] = React.useState(false);
  const [errorTotal, setErrorTotal] = React.useState(false);

  const location = useLocation();
  const dispatch = useDispatch();

  const getTotalPrice = (turns: number, price: string) => {
    return (turns * Number(price)).toFixed(2);
  };

  const getOutDate = (turns: number) => {
    return new Date(
      actualDate.getTime() + turns * 3600000 * (settingsAuth?.tiempo_turno || 1)
    );
  };

  const validateRentData = () => {
    if (
      state.usuarios.dni === "" ||
      state.usuarios.nombres === "" ||
      state.usuarios.apellido_mat === "" ||
      state.usuarios.apellido_pat === "" ||
      !state.detalle_alquiler.cantidad_turnos ||
      state.detalle_alquiler.precio_total === ""
    ) {
      setErrorDni(state.usuarios.dni === "" ? true : false);
      setErrorNombres(state.usuarios.nombres === "" ? true : false);
      setErrorApellidoPat(state.usuarios.apellido_pat === "" ? true : false);
      setErrorApellidoMat(state.usuarios.apellido_mat === "" ? true : false);
      setErrorTurnos(!state.detalle_alquiler.cantidad_turnos ? true : false);
      setErrorTotal(state.detalle_alquiler.precio_total ? true : false);
      return false;
    } else {
      return true;
    }
  };

  const rentRoom = () => {
    setLoadingAction(true);
    let auxRoom: roomI | undefined;

    // eslint-disable-next-line array-callback-return
    auxRoom = roomsG.find((room: roomI) => {
      return room.key === location.pathname.replace("/rooms-form/", "");
    });

    //ADD RENT
    if (auxRoom && auxRoom.local_cod === settingsAuth?.local_cod) {
      dispatch(
        registerRentFB(
          {
            rent: { ...state },
          } as addRentDTO,
          (response: any) => {
            dispatch(
              occupyRoomFB(
                {
                  room: {
                    key: auxRoom?.key,
                    estatus: ROOM_STATUS.OCUPADO,
                    rent_key: response || "",
                  },
                } as updateRoomsDTO,
                () => {
                  dispatch(
                    addFrecuencyFB(
                      {
                        frequency: {
                          dni: state.usuarios.dni,
                          nombres: state.usuarios.nombres,
                          apellido_pat: state.usuarios.apellido_pat,
                          apellido_mat: state.usuarios.apellido_mat,
                          cantidad: 1,
                        },
                      } as addFrequencyDTO,
                      () => {
                        setLoadingAction(false);
                        history.push(PRIVATE_ROOMS_RENT + "?action=true");
                      },
                      () => {
                        setLoadingAction(false);
                        toastRef.current.show({
                          severity: "error",
                          summary: "Rentar Habitación",
                          detail: "Ups! Algo salio mal",
                          life: 3000,
                          closable: false,
                        });
                      }
                    )
                  );
                },
                () => {
                  setLoadingAction(false);
                  toastRef.current.show({
                    severity: "error",
                    summary: "Rentar Habitación",
                    detail: "Ups! Algo salio mal",
                    life: 3000,
                    closable: false,
                  });
                }
              )
            );
          },
          () => {
            setLoadingAction(false);
            toastRef.current.show({
              severity: "error",
              summary: "Rentar Habitación",
              detail: "Ups! Algo salio mal",
              life: 3000,
              closable: false,
            });
          }
        )
      );
    } else {
      setLoadingAction(false);
      toastRef.current.show({
        severity: "error",
        summary: "Rentar Habitación",
        detail: "Ups! Algo salio mal",
        life: 3000,
        closable: false,
      });
    }
  };

  React.useEffect(() => {
    let auxRoom: roomI | undefined;

    // eslint-disable-next-line array-callback-return
    auxRoom = roomsG.find((room: roomI) => {
      return room.key === location.pathname.replace("/rooms-form/", "");
    });

    setState((prevState) => ({
      ...prevState,
      detalle_habitacion: {
        key: auxRoom?.key || "",
        numero: auxRoom?.numero || 0,
        piso: auxRoom?.piso || 0,
        precio: auxRoom?.precio || "",
        tipo: auxRoom?.tipo || "",
      },
      detalle_alquiler: {
        hora_ingreso: actualDate,
        hora_salida: getOutDate(1),
        cantidad_turnos: 1,
        precio_total: getTotalPrice(1, auxRoom?.precio || "0"),
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserBox = () => {
    return (
      <div className="information-box">
        <div className="title-section">
          <p className="main-title">{"Datos Cliente"}</p>
        </div>
        <div className="inputs-section">
          <div className="flex flex-row justify-start">
            <InputField
              key={"register-user-dni"}
              horizontal={false}
              placeholder={""}
              value={state.usuarios.dni}
              onChange={(value: any) => {
                setState((prevState) => ({
                  ...prevState,
                  usuarios: {
                    ...prevState.usuarios,
                    dni: value,
                  },
                }));
                setErrorDni(false);
              }}
              className={"register-rent-input"}
              regExp={onlyDigits}
              viewTitle={true}
              viewErrorMessage={errorDni}
              title={"DNI"}
              errorMessage={"Incorrecto"}
              height={"!h-[40px]"}
              width={"!w-[200px]"}
            ></InputField>
            <Button
              className="button button-search-dni"
              icon="pi pi-search"
              loading={loadingSearchAction}
              disabled={
                loadingSearchAction ||
                loadingAction ||
                state.usuarios.dni.length < 8
              }
              onClick={() => {
                setLoadingSearchAction(true);
                dispatch(
                  checkClientExist(
                    {
                      dni: state.usuarios.dni,
                    } as CheckClientExist,
                    (user: frequencyI) => {
                      setLoadingSearchAction(false);
                      if (user.dni) {
                        if (user.fecha_nacimiento) {
                          setState((prevState) => ({
                            ...prevState,
                            usuarios: {
                              ...prevState.usuarios,
                              nombres: user.nombres,
                              apellido_pat: user.apellido_pat,
                              apellido_mat: user.apellido_mat,
                              fecha_nacimiento: user.fecha_nacimiento,
                            },
                          }));
                        } else {
                          setState((prevState) => ({
                            ...prevState,
                            usuarios: {
                              ...prevState.usuarios,
                              nombres: user.nombres,
                              apellido_pat: user.apellido_pat,
                              apellido_mat: user.apellido_mat,
                            },
                          }));
                        }

                        let prefIndex = preferentGeneral.findIndex(
                          (p: preferentI) => {
                            return p.dni === user.dni;
                          }
                        );

                        let banIndex = bannedGeneral.findIndex((b: bannedI) => {
                          return b.dni === user.dni;
                        });

                        if (banIndex !== -1) {
                          toastRef.current.show({
                            severity: "warn",
                            summary: "Buscar Cliente",
                            detail: "Cliente BANEADO encontrado",
                            life: 3000,
                            closable: false,
                          });
                        } else if (prefIndex !== -1) {
                          toastRef.current.show({
                            severity: "success",
                            summary: "Buscar Cliente",
                            detail: "Cliente PREFERENTE encontrado",
                            life: 3000,
                            closable: false,
                          });
                        } else {
                          toastRef.current.show({
                            severity: "success",
                            summary: "Buscar Cliente",
                            detail: "Cliente encontrado",
                            life: 3000,
                            closable: false,
                          });
                        }
                      } else {
                        setState((prevState) => ({
                          ...prevState,
                          usuarios: {
                            nombres: "",
                            apellido_pat: "",
                            apellido_mat: "",
                            dni: state.usuarios.dni,
                          },
                        }));
                        toastRef.current.show({
                          severity: "error",
                          summary: "Buscar Cliente",
                          detail: "No se encontro cliente",
                          life: 3000,
                          closable: false,
                        });
                      }
                    }
                  )
                );
              }}
            />
          </div>
          <InputField
            key={"register-user-nombres"}
            horizontal={false}
            placeholder={""}
            value={state.usuarios.nombres}
            onChange={(value: any) => {
              setState((prevState) => ({
                ...prevState,
                usuarios: {
                  ...prevState.usuarios,
                  nombres: value.toLowerCase(),
                },
              }));
              setErrorNombres(false);
            }}
            className={"register-rent-input"}
            regExp={onlyLetters}
            viewTitle={true}
            viewErrorMessage={errorNombres}
            title={"Nombres"}
            errorMessage={"Incorrecto"}
            height={"!h-[40px]"}
            width={"!w-[200px]"}
            isUppercase={true}
          ></InputField>
          <InputField
            key={"register-user-appat"}
            horizontal={false}
            placeholder={""}
            value={state.usuarios.apellido_pat}
            onChange={(value: any) => {
              setState((prevState) => ({
                ...prevState,
                usuarios: {
                  ...prevState.usuarios,
                  apellido_pat: value.toLowerCase(),
                },
              }));
              setErrorApellidoPat(false);
            }}
            className={"register-rent-input"}
            regExp={onlyLetters}
            viewTitle={true}
            viewErrorMessage={errorApellidoPat}
            title={"Apellido Pat."}
            errorMessage={"Incorrecto"}
            height={"!h-[40px]"}
            width={"!w-[200px]"}
            isUppercase={true}
          ></InputField>
          <InputField
            key={"register-user-apmat"}
            horizontal={false}
            placeholder={""}
            value={state.usuarios.apellido_mat}
            onChange={(value: any) => {
              setState((prevState) => ({
                ...prevState,
                usuarios: {
                  ...prevState.usuarios,
                  apellido_mat: value.toLowerCase(),
                },
              }));
              setErrorApellidoMat(false);
            }}
            className={"register-rent-input"}
            regExp={onlyLetters}
            viewTitle={true}
            viewErrorMessage={errorApellidoMat}
            title={"Apellido Mat."}
            errorMessage={"Incorrecto"}
            height={"!h-[40px]"}
            width={"!w-[200px]"}
            isUppercase={true}
          ></InputField>
          <InputDate
            key={"register-user-fecha-nacimiento"}
            horizontal={false}
            placeholder={"DD/MM/YYYY"}
            value={state.usuarios.fecha_nacimiento || undefined}
            onChange={(value: any) => {
              setState((prevState) => ({
                ...prevState,
                usuarios: {
                  ...prevState.usuarios,
                  fecha_nacimiento: value,
                },
              }));
            }}
            className={"register-rent-input"}
            viewTitle={true}
            viewErrorMessage={false}
            title={"Fech. Nac."}
            errorMessage={"Incorrecto"}
            height={"!h-[40px]"}
            width={"!w-[200px]"}
          ></InputDate>
        </div>
      </div>
    );
  };

  const getRoomBox = () => {
    return (
      <div className="information-box">
        <div className="title-section">
          <p className="main-title">{"Habitación"}</p>
        </div>
        <div className="inputs-section">
          <NumberInput
            key={"register-room-numero"}
            disabled={true}
            horizontal={false}
            placeholder={""}
            value={state.detalle_habitacion.numero}
            onChange={() => {}}
            className={"register-rent-input"}
            viewTitle={true}
            viewErrorMessage={false}
            title={"Número"}
            errorMessage={"Incorrecto"}
            height={"!h-[40px]"}
            width={"!w-[200px] !text-[black]"}
          ></NumberInput>
          <InputField
            key={"register-room-tipo"}
            disabled={true}
            horizontal={false}
            placeholder={""}
            value={state.detalle_habitacion.tipo}
            onChange={() => {}}
            className={"register-rent-input"}
            viewTitle={true}
            viewErrorMessage={false}
            title={"Tipo"}
            errorMessage={"Incorrecto"}
            height={"!h-[40px]"}
            width={"!w-[200px] !text-[black]"}
          ></InputField>
          <NumberInput
            key={"register-room-piso"}
            disabled={true}
            horizontal={false}
            placeholder={""}
            value={state.detalle_habitacion.piso}
            onChange={() => {}}
            className={"register-rent-input"}
            viewTitle={true}
            viewErrorMessage={false}
            title={"Piso"}
            errorMessage={"Incorrecto"}
            height={"!h-[40px]"}
            width={"!w-[200px] !text-[black]"}
          ></NumberInput>
          <NumberInput
            key={"register-room-precio"}
            disabled={true}
            horizontal={false}
            placeholder={""}
            value={state.detalle_habitacion.precio}
            onChange={() => {}}
            className={"register-rent-input"}
            viewTitle={true}
            viewErrorMessage={false}
            title={"Precio"}
            errorMessage={"Incorrecto"}
            isCurrency={true}
            height={"!h-[40px]"}
            width={"!w-[200px] !text-[black]"}
          ></NumberInput>
        </div>
      </div>
    );
  };

  const getRentBox = () => {
    return (
      <div className="information-box">
        <div className="title-section">
          <p className="main-title">{"Detalle Alquiler"}</p>
        </div>
        <div className="inputs-section">
          <InputField
            key={"register-rent-entrada"}
            disabled={true}
            horizontal={false}
            placeholder={""}
            value={moment(state.detalle_alquiler.hora_ingreso).format(
              "DD/MM/YYYY     HH:mm:ss a"
            )}
            onChange={() => {}}
            className={"register-rent-input"}
            viewTitle={true}
            viewErrorMessage={false}
            title={"Ingreso"}
            errorMessage={"Incorrecto"}
            height={"!h-[40px]"}
            width={"!w-[200px] !text-[black]"}
          ></InputField>
          <InputField
            key={"register-rent-salida"}
            disabled={true}
            horizontal={false}
            placeholder={""}
            value={moment(state.detalle_alquiler.hora_salida).format(
              "DD/MM/YYYY     HH:mm:ss a"
            )}
            onChange={() => {}}
            className={"register-rent-input"}
            viewTitle={true}
            viewErrorMessage={false}
            title={"Salida"}
            errorMessage={"Incorrecto"}
            height={"!h-[40px]"}
            width={"!w-[200px] !text-[black]"}
          ></InputField>
          <NumberInput
            key={"register-rent-turnos"}
            horizontal={false}
            placeholder={""}
            value={state.detalle_alquiler.cantidad_turnos}
            onChange={(value: any) => {
              setState((prevState) => ({
                ...prevState,
                detalle_alquiler: {
                  ...prevState.detalle_alquiler,
                  hora_salida: getOutDate(value),
                  cantidad_turnos: value,
                  precio_total: getTotalPrice(
                    value,
                    state.detalle_habitacion.precio
                  ),
                },
              }));
              setErrorTurnos(false);
            }}
            className={"register-rent-input"}
            viewTitle={true}
            viewErrorMessage={errorTurnos}
            title={"Turnos"}
            errorMessage={"Incorrecto"}
            height={"!h-[40px]"}
            width={"!w-[200px]"}
            decimals={2}
          ></NumberInput>
          {settingsAuth?.show_discount && (
            <NumberInput
              key={"register-rent-descuento"}
              disabled={true}
              horizontal={false}
              placeholder={""}
              value={state.descuento_monto}
              onChange={() => {}}
              className={"register-rent-input"}
              viewTitle={true}
              viewErrorMessage={false}
              title={"Descuento"}
              errorMessage={"Incorrecto"}
              isCurrency={true}
              height={"!h-[40px]"}
              width={"!w-[200px] !text-[#ff4a57]"}
              decimals={2}
            ></NumberInput>
          )}
          <NumberInput
            key={"register-rent-total"}
            horizontal={false}
            placeholder={""}
            value={
              settingsAuth?.show_discount
                ? (
                    Number(state.detalle_alquiler.precio_total) -
                    Number(state.descuento_monto || "")
                  ).toFixed(2)
                : state.detalle_alquiler.precio_total
            }
            onChange={(value: any) => {
              let auxTurn = value
                ? value / Number(state.detalle_habitacion.precio)
                : 0;

              setState((prevState) => ({
                ...prevState,
                detalle_alquiler: {
                  ...prevState.detalle_alquiler,
                  hora_salida: getOutDate(auxTurn),
                  cantidad_turnos: auxTurn,
                  precio_total: value ? value : "",
                },
              }));
            }}
            className={"register-rent-input"}
            viewTitle={true}
            viewErrorMessage={errorTotal}
            title={"Precio Total"}
            errorMessage={"Incorrecto"}
            isCurrency={true}
            height={"!h-[40px]"}
            width={"!w-[200px] !text-[#00a35a]"}
            decimals={2}
          ></NumberInput>
          <InputField
            key={"register-rent-descripcion"}
            horizontal={false}
            placeholder={""}
            value={state.detalle_alquiler.descripcion}
            onChange={(value: any) => {
              setState((prevState) => ({
                ...prevState,
                detalle_alquiler: {
                  ...prevState.detalle_alquiler,
                  descripcion: value,
                },
              }));
            }}
            className={"register-rent-input"}
            viewTitle={true}
            viewErrorMessage={false}
            title={"Descripción"}
            errorMessage={"Incorrecto"}
            height={"!h-[40px]"}
            width={"!w-[200px]"}
          ></InputField>
        </div>
      </div>
    );
  };

  return (
    <div className="rooms-form-container">
      {getUserBox()}
      {getRoomBox()}
      {getRentBox()}
      <div className="footer-form">
        <ActionButton
          loadingAction={loadingAction}
          viewCancel={true}
          viewRent={true}
          cancelAction={() => {
            history.push(PRIVATE_ROOMS_RENT);
          }}
          rentAction={() => {
            if (validateRentData()) {
              rentRoom();
            }
          }}
        ></ActionButton>
      </div>
      <Toast ref={toastRef} />
    </div>
  );
};

export default RoomsForm;
