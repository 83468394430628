import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  FirestoreError,
  onSnapshot,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  where,
} from "firebase/firestore";
import { db } from "../../lib/init-firebase";
import {
  deleteProductHistoryDTO,
  detailProductHistoryDTO,
  fetchProductHistoryDTO,
} from "../../utils/requestDto";
import { sellI } from "../../utils/responseEntities";
import {
  PRODUCTS_HISTORY_FAIL_ACTUAL,
  PRODUCTS_HISTORY_FAIL_CUSTOM,
  PRODUCTS_HISTORY_FAIL_LAST,
  PRODUCTS_HISTORY_LIST_ACTUAL,
  PRODUCTS_HISTORY_LIST_CUSTOM,
  PRODUCTS_HISTORY_LIST_LAST,
  PRODUCTS_HISTORY_LOADING_ACTUAL,
  PRODUCTS_HISTORY_LOADING_CUSTOM,
  PRODUCTS_HISTORY_LOADING_LAST,
} from "./ProductsHistoryTypes";

const dbPathSell = "/ventas";
let sellRef = collection(db, dbPathSell) as CollectionReference<sellI>;

export const getProductsLastHistoryFB = (data: fetchProductHistoryDTO) => {
  console.log("getProductsLastHistoryFB DATA:", data);
  return (dispatch: any) => {
    dispatch({ type: PRODUCTS_HISTORY_LOADING_LAST });

    let queryConst: Array<QueryConstraint> = [];
    let today = new Date();

    if (today.getHours() >= (data.change_hour || 0) + 12) {
      let startdate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        (data.change_hour || 0) + 12,
        0,
        0,
        0
      );
      let enddate = startdate.getTime() - 43200000;
      let new_enddate = new Date(enddate);

      queryConst.push(where("fecha_venta", "<", startdate));
      queryConst.push(where("fecha_venta", ">=", new_enddate));
    } else if (
      today.getHours() >= (data.change_hour || 0) &&
      today.getHours() < (data.change_hour || 0) + 12
    ) {
      let startdate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        data.change_hour || 0,
        0,
        0,
        0
      );
      let enddate = startdate.getTime() - 43200000;
      let new_enddate = new Date(enddate);

      queryConst.push(where("fecha_venta", "<", startdate));
      queryConst.push(where("fecha_venta", ">=", new_enddate));
    } else if (today.getHours() < (data.change_hour || 0)) {
      let startdate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        data.change_hour || 0,
        0,
        0,
        0
      );
      let aux_startdate = startdate.getTime() - 43200000;
      let aux_enddate = startdate.getTime() - 86400000;
      let new_startdate = new Date(aux_startdate);
      let new_enddate = new Date(aux_enddate);

      queryConst.push(where("fecha_venta", "<", new_startdate));
      queryConst.push(where("fecha_venta", ">=", new_enddate));
    }
    queryConst.push(where("local_cod", "==", data.local));

    const q = query(sellRef, ...queryConst);

    onSnapshot(
      q,
      (snapshot: QuerySnapshot<sellI>) => {
        let auxProductsHistory: Array<sellI> = [];

        snapshot.docs.forEach((change: QueryDocumentSnapshot<sellI>) => {
          // console.log(change.data());
          auxProductsHistory.push({ key: change.id, ...change.data() });
        });
        dispatch({
          type: PRODUCTS_HISTORY_LIST_LAST,
          payload: auxProductsHistory,
        });
        dispatch({ type: PRODUCTS_HISTORY_FAIL_LAST });
      },
      (error: FirestoreError) => {
        console.log("error on getProductsLastHistoryFB:", error);
        dispatch({ type: PRODUCTS_HISTORY_FAIL_LAST });
      }
    );
  };
};

export const getProductsActualHistoryFB = (data: fetchProductHistoryDTO) => {
  console.log("getProductsActualHistoryFB DATA:", data);
  return (dispatch: any) => {
    dispatch({ type: PRODUCTS_HISTORY_LOADING_ACTUAL });

    let queryConst: Array<QueryConstraint> = [];
    let today = new Date();

    if (today.getHours() >= (data.change_hour || 0) + 12) {
      let startdate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        (data.change_hour || 0) + 12,
        0,
        0,
        0
      );

      queryConst.push(where("fecha_venta", ">=", startdate));
    } else if (
      today.getHours() >= (data.change_hour || 0) &&
      today.getHours() < (data.change_hour || 0) + 12
    ) {
      let startdate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        (data.change_hour || 0) + 12,
        0,
        0,
        0
      );
      let enddate = startdate.getTime() - 43200000;
      let new_enddate = new Date(enddate);

      queryConst.push(where("fecha_venta", "<", startdate));
      queryConst.push(where("fecha_venta", ">=", new_enddate));
    } else if (today.getHours() < (data.change_hour || 0)) {
      let startdate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        data.change_hour || 0,
        0,
        0,
        0
      );
      let enddate = startdate.getTime() - 43200000;
      let new_enddate = new Date(enddate);

      queryConst.push(where("fecha_venta", "<", startdate));
      queryConst.push(where("fecha_venta", ">=", new_enddate));
    }
    queryConst.push(where("local_cod", "==", data.local));

    const q = query(sellRef, ...queryConst);

    onSnapshot(
      q,
      (snapshot: QuerySnapshot<sellI>) => {
        let auxProductsHistory: Array<sellI> = [];

        snapshot.docs.forEach((change: QueryDocumentSnapshot<sellI>) => {
          // console.log(change.data());
          auxProductsHistory.push({ key: change.id, ...change.data() });
        });
        dispatch({
          type: PRODUCTS_HISTORY_LIST_ACTUAL,
          payload: auxProductsHistory,
        });
        dispatch({ type: PRODUCTS_HISTORY_FAIL_ACTUAL });
      },
      (error: FirestoreError) => {
        console.log("error on getProductsActualHistoryFB:", error);
        dispatch({ type: PRODUCTS_HISTORY_FAIL_ACTUAL });
      }
    );
  };
};

export const getProductsCustomHistoryFB = (data: fetchProductHistoryDTO) => {
  console.log("getProductsCustomHistoryFB DATA:", data);
  return (dispatch: any) => {
    if (data?.date) {
      dispatch({ type: PRODUCTS_HISTORY_LOADING_CUSTOM });

      let queryConst: Array<QueryConstraint> = [];

      let startDay = new Date(
        data?.date.getFullYear(),
        data?.date.getMonth(),
        data?.date.getDate(),
        0,
        0,
        0,
        0
      );

      let endDay = new Date(
        data?.date.getFullYear(),
        data?.date.getMonth(),
        data?.date.getDate() + 1,
        0,
        0,
        0,
        0
      );
      queryConst.push(where("fecha_venta", ">=", startDay));
      queryConst.push(where("fecha_venta", "<", endDay));
      queryConst.push(where("local_cod", "==", data.local));

      const q = query(sellRef, ...queryConst);

      onSnapshot(
        q,
        (snapshot: QuerySnapshot<sellI>) => {
          let auxProductsHistory: Array<sellI> = [];

          snapshot.docs.forEach((change: QueryDocumentSnapshot<sellI>) => {
            // console.log(change.data());
            auxProductsHistory.push({ key: change.id, ...change.data() });
          });
          dispatch({
            type: PRODUCTS_HISTORY_LIST_CUSTOM,
            payload: auxProductsHistory,
          });
          dispatch({ type: PRODUCTS_HISTORY_FAIL_CUSTOM });
        },
        (error: FirestoreError) => {
          console.log("error on getProductsCustomHistoryFB:", error);
          dispatch({ type: PRODUCTS_HISTORY_FAIL_CUSTOM });
        }
      );
    }
  };
};

export const deleteSellHistoryFB = (
  data: deleteProductHistoryDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("deleteSellHistoryFB DATA:", data);
    await deleteDoc(doc(db, dbPathSell, data.key))
      .catch((error: any) => {
        console.log("error on deleteSellHistoryFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("deleteSellHistoryFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const detailSellHistoryFB = (
  data: detailProductHistoryDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("detailSellHistoryFB DATA:", data);
    await deleteDoc(doc(db, dbPathSell, data.key))
      .catch((error: any) => {
        console.log("error on detailSellHistoryFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("detailSellHistoryFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};