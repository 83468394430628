import React from "react";
import { useDispatch } from "react-redux";

import { updatePreferentFB } from "../../../redux/preferent/PreferentAction";
import { updatePreferentsDTO } from "../../../utils/requestDto";
import { onlyDigits, onlyLetters } from "../../../utils/utils";
import { preferentI } from "../../../utils/responseEntities";

import ModalDefault from "../../../components/sub-components/modal-default";
import InputField from "../../../components/sub-components/input-field";
import InputDate from "../../../components/sub-components/input-date";

interface ModalEditPreferentProps {
  preferent: preferentI;
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalEditPreferent: React.FunctionComponent<
  ModalEditPreferentProps
> = ({
  preferent,
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalEditPreferentProps) => {
  let defaultValue: any;

  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);
  const [state, setState] = React.useState<preferentI>({
    key: "",
    nombres: "",
    apellido_pat: "",
    apellido_mat: "",
    dni: "",
    fecha_nacimiento: defaultValue,
  });

  const [errorNombres, setErrorNombres] = React.useState(false);
  const [errorApellido_pat, setErrorApellido_pat] = React.useState(false);
  const [errorApellido_mat, setErrorApellido_mat] = React.useState(false);
  const [errorDni, setErrorDni] = React.useState(false);

  const dispatch = useDispatch();

  const updatePreferentData = (name: any, value: any) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validatePreferentData = () => {
    if (
      state.nombres === "" ||
      state.apellido_mat === "" ||
      state.apellido_pat === "" ||
      state.dni === ""
    ) {
      setErrorNombres(state.nombres === "" ? true : false);
      setErrorApellido_pat(state.apellido_pat === "" ? true : false);
      setErrorApellido_mat(state.apellido_mat === "" ? true : false);
      setErrorDni(state.dni === "" ? true : false);
      return false;
    } else {
      return true;
    }
  };

  const editPreferent = () => {
    setLoadingAction(true);
    //EDIT PREFERENT
    dispatch(
      updatePreferentFB(
        {
          preferent: {
            key: state.key,
            nombres: state.nombres,
            apellido_pat: state.apellido_pat,
            apellido_mat: state.apellido_mat,
            dni: state.dni,
            fecha_nacimiento: state.fecha_nacimiento
              ? state.fecha_nacimiento
              : "",
          },
        } as updatePreferentsDTO,
        () => {
          setLoadingAction(false);
          if (successFun) successFun("Modificado satisfactoriamente");
          setModalShow(false);
        },
        () => {
          setLoadingAction(false);
          if (errorFun) errorFun("Ups! Algo salio mal");
          setModalShow(false);
        }
      )
    );
  };

  React.useEffect(() => {
    if (preferent) {
      setState((prev) => ({
        ...prev,
        key: preferent.key,
        nombres: preferent.nombres.toLowerCase(),
        apellido_pat: preferent.apellido_pat.toLowerCase(),
        apellido_mat: preferent.apellido_mat.toLowerCase(),
        dni: preferent.dni,
        fecha_nacimiento: preferent.fecha_nacimiento,
      }));
    }
  }, [preferent]);

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-preferents-edit"
      title={"Editar Preferente"}
      showModalHeader={true}
      showModalFooter={true}
      showEdit={true}
      showCancel={true}
      editAction={() => {
        if (validatePreferentData()) {
          editPreferent();
        }
      }}
    >
      <React.Fragment>
        <InputField
          key={"edit-preferent-nombres"}
          horizontal={false}
          placeholder={""}
          value={state.nombres}
          onChange={(value: any) => {
            updatePreferentData("nombres", value.toLowerCase());
            setErrorNombres(false);
          }}
          className={"edit-preferent-input"}
          regExp={onlyLetters}
          viewTitle={true}
          viewErrorMessage={errorNombres}
          title={"Nombre"}
          errorMessage={"Incorrecto"}
          height={"!h-[40px]"}
          width={"!w-full"}
          isUppercase={true}
        ></InputField>
        <InputField
          key={"edit-preferent-apellido-pat"}
          horizontal={false}
          placeholder={""}
          value={state.apellido_pat}
          onChange={(value: any) => {
            updatePreferentData("apellido_pat", value.toLowerCase());
            setErrorApellido_pat(false);
          }}
          className={"edit-preferent-input"}
          regExp={onlyLetters}
          viewTitle={true}
          viewErrorMessage={errorApellido_pat}
          title={"Apellido Paterno"}
          errorMessage={"Incorrecto"}
          height={"!h-[40px]"}
          width={"!w-full"}
          isUppercase={true}
        ></InputField>
        <InputField
          key={"edit-preferent-apellido-mat"}
          horizontal={false}
          placeholder={""}
          value={state.apellido_mat}
          onChange={(value: any) => {
            updatePreferentData("apellido_mat", value.toLowerCase());
            setErrorApellido_pat(false);
          }}
          className={"edit-preferent-input"}
          regExp={onlyLetters}
          viewTitle={true}
          viewErrorMessage={errorApellido_mat}
          title={"Apellido Materno"}
          errorMessage={"Incorrecto"}
          height={"!h-[40px]"}
          width={"!w-full"}
          isUppercase={true}
        ></InputField>
        <InputField
          key={"edit-preferent-dni"}
          disabled
          horizontal={false}
          placeholder={""}
          value={state.dni}
          onChange={(value: any) => {
            updatePreferentData("dni", value);
            setErrorDni(false);
          }}
          className={"edit-preferent-input"}
          regExp={onlyDigits}
          viewTitle={true}
          viewErrorMessage={errorDni}
          title={"DNI"}
          errorMessage={"Incorrecto"}
          height={"!h-[40px]"}
          width={"!w-full"}
        ></InputField>
        <InputDate
          key={"edit-preferent-fecha-nacimiento"}
          horizontal={false}
          placeholder={"DD/MM/YYYY"}
          value={state.fecha_nacimiento || undefined}
          onChange={(value: any) => {
            updatePreferentData("fecha_nacimiento", value);
          }}
          className={"edit-preferent-input"}
          viewTitle={true}
          viewErrorMessage={false}
          title={"Fech. Nac."}
          errorMessage={"Incorrecto"}
        ></InputDate>
      </React.Fragment>
    </ModalDefault>
  );
};

export default ModalEditPreferent;
