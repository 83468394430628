import React from "react";
import { useDispatch } from "react-redux";

import {
  addPreferentFB,
  checkDniPreferentFB,
} from "../../../redux/preferent/PreferentAction";
import { addPreferentsDTO, GetByDniRequest } from "../../../utils/requestDto";
import { rentI } from "../../../utils/responseEntities";
import { capitalizeWords } from "../../../utils/utils";

import ModalDefault from "../../../components/sub-components/modal-default";

interface ModalAddPreferentProps {
  roomHistory: rentI;
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalAddPreferent: React.FunctionComponent<
  ModalAddPreferentProps
> = ({
  roomHistory,
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalAddPreferentProps) => {
  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const addPreferent = () => {
    setLoadingAction(true);
    //ADD PREFERENT
    dispatch(
      checkDniPreferentFB(
        {
          dni: roomHistory.usuarios.dni,
        } as GetByDniRequest,
        (response: any) => {
          if (!response) {
            dispatch(
              addPreferentFB(
                {
                  preferent: {
                    nombres: roomHistory.usuarios.nombres.toLowerCase(),
                    apellido_pat:
                      roomHistory.usuarios.apellido_pat.toLowerCase(),
                    apellido_mat:
                      roomHistory.usuarios.apellido_mat.toLowerCase(),
                    fecha_nacimiento: roomHistory.usuarios.fecha_nacimiento,
                    dni: roomHistory.usuarios.dni,
                  },
                } as addPreferentsDTO,
                () => {
                  setLoadingAction(false);
                  if (successFun)
                    successFun("Preferente agregado satisfactoriamente");
                  setModalShow(false);
                },
                () => {
                  setLoadingAction(false);
                  if (errorFun) errorFun("Ups! Algo salio mal");
                  setModalShow(false);
                }
              )
            );
          } else {
            console.log("dni ya registrado");
            setLoadingAction(false);
            if (errorFun) errorFun("DNI ya registrado");
          }
        }
      )
    );
  };

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-rent-preferent"
      title={"Agregar Preferente"}
      showModalHeader={true}
      showModalFooter={true}
      showAdd={true}
      showCancel={true}
      addAction={() => {
        addPreferent();
      }}
    >
      <p>
        {`¿Está seguro de agregar a `}
        <strong>{"PREFERENTES"}</strong>
        {` a `}
        <strong>
          {capitalizeWords(roomHistory.usuarios.nombres)}{" "}
          {capitalizeWords(roomHistory.usuarios.apellido_pat)}{" "}
          {capitalizeWords(roomHistory.usuarios.apellido_mat)}
        </strong>
        {`?`}
      </p>
    </ModalDefault>
  );
};

export default ModalAddPreferent;
