import React from "react";
import List from "./components/List";
import TopToolbar from "./components/TopToolbar";
import "./style.scss";

const ClientsPreferent: React.FunctionComponent = () => {
  return (
    <div className="clients-preferent-container">
      <TopToolbar></TopToolbar>
      <List></List>
    </div>
  );
};

export default ClientsPreferent;
