import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";

import { PRODUCTS_HISTORY_TURN } from "../../../redux/productsHistory/ProductsHistoryTypes";
import { TT_TURN_SELECTOR } from "../../../utils/utils";
import { sellI } from "../../../utils/responseEntities";

import TimeTile from "./TimeTile";

const TopToolbar: React.FunctionComponent = () => {
  const productLastH: Array<sellI> = useSelector(
    (globalState: RootStore) => globalState.productsHistory.productsHistoryLast
  );
  const productActualH: Array<sellI> = useSelector(
    (globalState: RootStore) =>
      globalState.productsHistory.productsHistoryActual
  );
  const productCustomH: Array<sellI> = useSelector(
    (globalState: RootStore) =>
      globalState.productsHistory.productsHistoryCustom
  );
  const turnH: any = useSelector(
    (globalState: RootStore) => globalState.productsHistory.turnHistory
  );

  const dispatch = useDispatch();

  const updateTurn = (name: string) => {
    dispatch({ type: PRODUCTS_HISTORY_TURN, payload: name });
  };

  const getTotalSales = (type: TT_TURN_SELECTOR) => {
    let auxSum = 0;

    switch (type) {
      case TT_TURN_SELECTOR.ANTERIOR:
        // eslint-disable-next-line array-callback-return
        productLastH.map((sell: sellI) => {
          auxSum = auxSum + Number(sell.monto);
        });

        return auxSum.toFixed(2);
      case TT_TURN_SELECTOR.ACTUAL:
        // eslint-disable-next-line array-callback-return
        productActualH.map((sell: sellI) => {
          auxSum = auxSum + Number(sell.monto);
        });

        return auxSum.toFixed(2);
      case TT_TURN_SELECTOR.CUSTOM:
        // eslint-disable-next-line array-callback-return
        productCustomH.map((sell: sellI) => {
          auxSum = auxSum + Number(sell.monto);
        });

        return auxSum.toFixed(2);
    }
  };

  return (
    <div className="product-history-toolbar">
      <div className="history-tiles-list">
        <TimeTile
          key="product-history-last"
          tileTitle={TT_TURN_SELECTOR.ANTERIOR}
          tileAmount={getTotalSales(TT_TURN_SELECTOR.ANTERIOR)}
          tileQty={productLastH?.length || 0}
          isActive={turnH === TT_TURN_SELECTOR.ANTERIOR}
          isCustom={false}
          updateTurn={updateTurn}
        ></TimeTile>
        <TimeTile
          key="product-history-actual"
          tileTitle={TT_TURN_SELECTOR.ACTUAL}
          tileAmount={getTotalSales(TT_TURN_SELECTOR.ACTUAL)}
          tileQty={productActualH?.length || 0}
          isActive={turnH === TT_TURN_SELECTOR.ACTUAL}
          isCustom={false}
          updateTurn={updateTurn}
        ></TimeTile>
        <TimeTile
          key="product-history-custom"
          tileTitle={TT_TURN_SELECTOR.CUSTOM}
          tileAmount={getTotalSales(TT_TURN_SELECTOR.CUSTOM)}
          tileQty={productCustomH?.length || 0}
          isActive={turnH === TT_TURN_SELECTOR.CUSTOM}
          isCustom={true}
          updateTurn={updateTurn}
        ></TimeTile>
      </div>
    </div>
  );
};

export default TopToolbar;
