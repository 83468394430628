import React from "react";
import { useLocation } from "react-router";
import "./style.scss";

import TopToolbar from "./components/TopToolbar";
import { Toast } from "primereact/toast";
import List from "./components/List";

const ProductsHistory: React.FunctionComponent = () => {
  let defaultValue: any;
  const toastRef = React.useRef<Toast>(defaultValue);

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();

  React.useEffect(() => {
    if (query.get("action") === "true") {
      toastRef.current.show({
        severity: "success",
        summary: "Venta Productos",
        detail: "Vendido satisfactoriamente",
        life: 3000,
        closable: false,
      });
    }
  }, [query]);

  return (
    <div className="products-history-container">
      <TopToolbar></TopToolbar>
      <List></List>
      <Toast ref={toastRef} />
    </div>
  );
};

export default ProductsHistory;
