import React from "react";
import { useDispatch } from "react-redux";

import { roomI } from "../../../utils/responseEntities";
import { ROOM_STATUS } from "../../../utils/utils";

import ModalDefault from "../../../components/sub-components/modal-default";
import { enableRoomsDTO } from "../../../utils/requestDto";
import { updateRoomFB } from "../../../redux/rooms/RoomsAction";

interface ModalEnableRoomProps {
  room: roomI;
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalEnableRoom: React.FunctionComponent<ModalEnableRoomProps> = ({
  room,
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalEnableRoomProps) => {
  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const enableRoom = () => {
    //ENABLE ROOM
    setLoadingAction(true);
    //DELETE USER
    dispatch(
      updateRoomFB(
        {
          room: {
            ...room,
            estatus:
              room.estatus === ROOM_STATUS.MANTENIMIENTO
                ? ROOM_STATUS.LIBRE
                : ROOM_STATUS.MANTENIMIENTO,
          },
        } as enableRoomsDTO,
        () => {
          setLoadingAction(false);
          if (successFun)
            successFun(
              room.estatus === ROOM_STATUS.MANTENIMIENTO
                ? "Habilitado satisfactoriamente"
                : "Deshabilitado satisfactoriamente"
            );
          setModalShow(false);
        },
        () => {
          setLoadingAction(false);
          if (errorFun) errorFun("Ups! Algo salio mal");
          setModalShow(false);
        }
      )
    );
  };

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-rooms-enable"
      title={`${
        room.estatus === ROOM_STATUS.MANTENIMIENTO
          ? "Habilitar"
          : "Deshabilitar"
      } Habitación`}
      showModalHeader={true}
      showModalFooter={true}
      showEnable={true}
      showCancel={true}
      enableAction={() => {
        if (
          room.estatus === ROOM_STATUS.MANTENIMIENTO ||
          room.estatus === ROOM_STATUS.LIBRE
        ) {
          enableRoom();
        }
      }}
      isEnable={room.estatus === ROOM_STATUS.MANTENIMIENTO}
    >
      <p>
        {`¿Está seguro de `}
        <strong>
          {room.estatus === ROOM_STATUS.MANTENIMIENTO
            ? "habilitar"
            : "deshabilitar"}
        </strong>
        {` la habitación `}
        <strong>{`#${room.numero}`}</strong>
        {`?`}
      </p>
    </ModalDefault>
  );
};

export default ModalEnableRoom;
