import React from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { FiFlag } from "react-icons/fi";
import { ProgressBar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootStore } from "../../redux/Store";
import { rentI, roomI } from "../../utils/responseEntities";
import { ROOM_STATUS } from "../../utils/utils";
import moment from "moment";

const RoomsOverlay: React.FunctionComponent = () => {
  let defaultValue: any;

  const roomsRO: Array<roomI> = useSelector(
    (globalState: RootStore) => globalState.general.roomList
  );
  const rentLastH: Array<rentI> = useSelector(
    (globalState: RootStore) => globalState.roomsHistory.rentHistoryLast
  );
  const rentActualH: Array<rentI> = useSelector(
    (globalState: RootStore) => globalState.roomsHistory.rentHistoryActual
  );
  const rentCustomH: Array<rentI> = useSelector(
    (globalState: RootStore) => globalState.roomsHistory.rentHistoryCustom
  );

  const [free, setFree] = React.useState<number>(0);
  const [rented, setRented] = React.useState<number>(0);
  const [maintenance, setMaintenance] = React.useState<number>(0);

  const op = React.useRef<OverlayPanel>(null);

  const getPorcentage = (qty: number) => {
    return parseFloat(Number((qty * 100) / roomsRO.length).toFixed(2));
  };

  const getOutOfDateState = () => {
    let auxLast: rentI =
      rentLastH.find((data: any) => {
        return (
          (!data.detalle_alquiler.hora_salida_real ||
            data.detalle_alquiler.hora_salida_real === undefined ||
            data.detalle_alquiler.hora_salida_real === null) &&
          moment(data?.detalle_alquiler.hora_salida?.toDate() || "") < moment()
        );
      }) || defaultValue;

    let auxActual: rentI =
      rentActualH.find((data: any) => {
        return (
          (!data.detalle_alquiler.hora_salida_real ||
            data.detalle_alquiler.hora_salida_real === undefined ||
            data.detalle_alquiler.hora_salida_real === null) &&
          moment(data?.detalle_alquiler.hora_salida?.toDate() || "") < moment()
        );
      }) || defaultValue;

    let auxCustom: rentI =
      rentCustomH.find((data: any) => {
        return (
          (!data.detalle_alquiler.hora_salida_real ||
            data.detalle_alquiler.hora_salida_real === undefined ||
            data.detalle_alquiler.hora_salida_real === null) &&
          moment(data?.detalle_alquiler.hora_salida?.toDate() || "") < moment()
        );
      }) || defaultValue;

    return auxLast || auxActual || auxCustom;
  };

  React.useEffect(() => {
    let auxFree: number = 0;
    let auxRented: number = 0;
    let auxMaintenance: number = 0;

    auxFree = roomsRO.filter((room: roomI) => {
      return room.estatus === ROOM_STATUS.LIBRE;
    }).length;

    auxRented = roomsRO.filter((room: roomI) => {
      return room.estatus === ROOM_STATUS.OCUPADO;
    }).length;

    auxMaintenance = roomsRO.filter((room: roomI) => {
      return room.estatus === ROOM_STATUS.MANTENIMIENTO;
    }).length;

    setFree(auxFree);
    setRented(auxRented);
    setMaintenance(auxMaintenance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomsRO]);

  return (
    <React.Fragment>
      <div
        className="room-resume"
        onClick={(e: any) => {
          op.current?.toggle(e);
        }}
        aria-haspopup
        aria-controls="overlay_panel"
      >
        <FiFlag
          className={`${getOutOfDateState() ? "fill-[#ff3b3bb3]" : ""}`}
        ></FiFlag>
      </div>
      <OverlayPanel ref={op} id="overlay_panel" className="rooms-overlaypanel">
        <div className="room-row">
          <div className="room-title">
            <p>{`Libres [${free}]`}</p>
            <p className="text-[13px]">{`${getPorcentage(free)}%`}</p>
          </div>
          <ProgressBar
            className="cell-progress"
            variant={"success"}
            now={getPorcentage(free)}
          ></ProgressBar>
        </div>
        <div className="room-row">
          <div className="room-title">
            <p>{`Ocupados [${rented}]`}</p>
            <p className="text-[13px]">{`${getPorcentage(rented)}%`}</p>
          </div>
          <ProgressBar
            className="cell-progress"
            variant={"danger"}
            now={getPorcentage(rented)}
          ></ProgressBar>
        </div>
        <div className="room-row">
          <div className="room-title">
            <p>{`En Mantenimiento [${maintenance}]`}</p>
            <p className="text-[13px]">{`${getPorcentage(maintenance)}%`}</p>
          </div>
          <ProgressBar
            className="cell-progress"
            variant={"warning"}
            now={getPorcentage(maintenance)}
          ></ProgressBar>
        </div>
      </OverlayPanel>
    </React.Fragment>
  );
};

export default RoomsOverlay;
