// With AUTHENTICATION
export const PUBLIC_LOGIN = "/login";

export const PRIVATE_DASHBOARD = "/dashboard";

export const PRIVATE_PRODUCTS = "/products";
export const PRIVATE_PRODUCTS_HISTORY = "/products-history";
export const PRIVATE_PRODUCTS_SELL = "/products-sell";

export const PRIVATE_ROOMS = "/rooms";
export const PRIVATE_ROOMS_HISTORY = "/rooms-history";
export const PRIVATE_ROOMS_RENT = "/rooms-rent";
export const PRIVATE_ROOMS_SEARCH = "/rooms-search";
export const PRIVATE_ROOMS_FORM = "/rooms-form/:id";

export const PRIVATE_CLIENTS_PREFERENT = "/clients-preferent";
export const PRIVATE_CLIENTS_BANNED = "/clients-banned";

export const PRIVATE_USERS = "/users";

export const PRIVATE_SETTINGS_GENERAL = "/settings-general";
export const PRIVATE_SETTINGS_PERMISSION = "/settings-permission";
export const PRIVATE_SETTINGS_LOCAL = "/settings-campus";
