import React from "react";
import { Password } from "primereact/password";
import "../style.scss";

interface PasswordInputProps {
  horizontal?: boolean;
  className?: string;
  inputClassName?: string;
  placeholder: string;
  value: any;
  disabled?: boolean;
  onChange: any;
  refElement?: any;
  viewTitle?: boolean;
  viewErrorMessage?: boolean;
  title?: any;
  errorMessage?: any;
  onKeyPress?: any;
}

const PasswordInput: React.FunctionComponent<PasswordInputProps> = ({
  horizontal = true,
  className,
  inputClassName,
  placeholder,
  value,
  disabled = false,
  onChange,
  refElement,
  viewTitle = false,
  viewErrorMessage = false,
  title = "",
  errorMessage = "",
  onKeyPress,
}: any) => {
  return (
    <div
      className={
        horizontal
          ? "input-number-horizontal " + className
          : "input-number-vertical " + className
      }
    >
      {viewTitle && <div className="input-number-title">{title}</div>}
      <Password
        // className={`${viewErrorMessage ? "error-mode" : ""}`}
        inputClassName={`${inputClassName} ${
          viewErrorMessage ? "error-mode" : ""
        }`}
        value={value}
        onChange={(data: any) => {
          // console.log(data);
          onChange(data.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter" && onKeyPress) {
            onKeyPress();
          }
        }}
        toggleMask
        placeholder={placeholder}
        disabled={disabled}
        ref={refElement}
        feedback={false}
        spellCheck={false}
      />
      {viewErrorMessage && (
        <div className="input-number-error">{errorMessage}</div>
      )}
    </div>
  );
};

export default PasswordInput;
