import React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

import { deleteRentHistoryFB } from "../../../redux/roomsHistory/RoomsHistoryAction";
import { deleteRoomHistoryDTO } from "../../../utils/requestDto";
import { rentI } from "../../../utils/responseEntities";

import ModalDefault from "../../../components/sub-components/modal-default";

interface ModalDeleteHistoryProps {
  roomHistory: rentI;
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalDeleteHistory: React.FunctionComponent<
  ModalDeleteHistoryProps
> = ({
  roomHistory,
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalDeleteHistoryProps) => {
  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);
  const [inTime] = React.useState<any>(
    roomHistory.detalle_alquiler.hora_ingreso
  );

  const dispatch = useDispatch();

  const deleteRoom = () => {
    setLoadingAction(true);
    //DELETE RENT
    dispatch(
      deleteRentHistoryFB(
        {
          key: roomHistory.key,
        } as deleteRoomHistoryDTO,
        () => {
          setLoadingAction(false);
          if (successFun) successFun("Eliminado satisfactoriamente");
          setModalShow(false);
        },
        () => {
          setLoadingAction(false);
          if (errorFun) errorFun("Ups! Algo salio mal");
          setModalShow(false);
        }
      )
    );
  };

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-rooms-delete"
      title={"Eliminar"}
      showModalHeader={true}
      showModalFooter={true}
      showDelete={true}
      showCancel={true}
      deleteAction={() => {
        deleteRoom();
      }}
    >
      <p>
        {`¿Desea eliminar el alquiler de la habitacion `}
        <strong>{`#${roomHistory.detalle_habitacion.numero}`}</strong>
        {` del dia `}
        <strong>{`${moment(inTime.toDate()).format("DD/MM/YYYY")}`}</strong>
        {`?`}
      </p>
    </ModalDefault>
  );
};

export default ModalDeleteHistory;
