import { ROOMS_LOADING, ROOMS_FAIL, ROOMS_LIST } from "./RoomsTypes";
import {
  CollectionReference,
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  QuerySnapshot,
  FirestoreError,
  QueryConstraint,
  QueryDocumentSnapshot,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { roomI } from "../../utils/responseEntities";
import { db } from "../../lib/init-firebase";
import {
  fetchRoomsDTO,
  addRoomsDTO,
  updateRoomsDTO,
  deleteRoomsDTO,
  fetchRoomByNumberDTO,
} from "../../utils/requestDto";
import { ROOM_STATUS, TT_FILTER_LIST } from "../../utils/utils";

const dbPathRooms = "/habitaciones";
let roomRef = collection(db, dbPathRooms) as CollectionReference<roomI>;

export const getRoomListFB = (
  data: fetchRoomsDTO,
  funDone?: any,
  funError?: any
) => {
  console.log("getRoomListFB DATA:", data);
  return (dispatch: any) => {
    dispatch({ type: ROOMS_LOADING });

    let queryConst: Array<QueryConstraint> = [];

    if (data.type === TT_FILTER_LIST.PISO) {
      if (data.search !== "0" && data.search !== "") {
        queryConst.push(where("piso", "==", Number(data.search)));
      }
    } else if (data.type === TT_FILTER_LIST.ESTADO) {
      if (data.status !== ROOM_STATUS.TODOS) {
        queryConst.push(where("estatus", "==", data.status));
      }
    }
    queryConst.push(where("local_cod", "==", data.local));

    const q = query(roomRef, ...queryConst);

    onSnapshot(
      q,
      (snapshot: QuerySnapshot<roomI>) => {
        let auxRooms: Array<roomI> = [];

        snapshot.docs.forEach((change: QueryDocumentSnapshot<roomI>) => {
          // console.log(change.data());
          auxRooms.push({ key: change.id, ...change.data() });
        });

        dispatch({ type: ROOMS_LIST, payload: auxRooms });
        dispatch({ type: ROOMS_FAIL });

        if (funDone) {
          funDone();
        }
      },
      (error: FirestoreError) => {
        console.log("error on getRoomListFB:", error);
        dispatch({ type: ROOMS_FAIL });

        if (funError) {
          funError();
        }
      }
    );
  };
};

export const addRoomFB = (data: addRoomsDTO, funDone?: any, funError?: any) => {
  return async () => {
    console.log("addRoomFB DATA:", data);
    await addDoc(roomRef, data.room)
      .catch((error: any) => {
        console.log("error on addRoomFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("addRoomFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const updateRoomFB = (
  data: updateRoomsDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("updateRoomFB DATA:", data);
    await updateDoc(doc(db, dbPathRooms + "/" + data.room.key), {
      numero: data.room.numero,
      piso: data.room.piso,
      precio: data.room.precio,
      tipo: data.room.tipo,
      estatus: data.room.estatus,
      descripcion: data.room.descripcion,
      hora_liberado: data.room.hora_liberado,
      local_cod: data.room.local_cod,
    })
      .catch((error: any) => {
        console.log("error on updateRoomFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("updateRoomFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const deleteRoomFB = (
  data: deleteRoomsDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("deleteRoomFB DATA:", data);
    await deleteDoc(doc(db, dbPathRooms, data.key))
      .catch((error: any) => {
        console.log("error on deleteRoomFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("deleteRoomFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};
