import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";
import moment from "moment";

import { Calendar } from "primereact/calendar";
import { PRODUCTS_HISTORY_RANGE } from "../../../redux/productsHistory/ProductsHistoryTypes";

interface TimeTileProps {
  tileTitle: any;
  tileAmount: any;
  tileQty: any;
  isActive: any;
  isCustom: any;
  updateTurn: any;
}

const TimeTile: React.FunctionComponent<TimeTileProps> = ({
  tileTitle,
  tileAmount,
  tileQty,
  isActive,
  isCustom,
  updateTurn,
}: any) => {
  const dateH: Date = useSelector(
    (globalState: RootStore) => globalState.productsHistory.customDateHistory
  );

  const dispatch = useDispatch();

  const updateDate = (value: any) => {
    dispatch({ type: PRODUCTS_HISTORY_RANGE, payload: { date: value } });
  };

  return (
    <div
      className={"product-history-time-tile" + (isActive ? " active" : "")}
      onClick={() => {
        updateTurn(tileTitle);
      }}
    >
      <div className="tile-first">
        {!isCustom && <div className="first-title">{tileTitle}</div>}
        {isCustom && (
          <React.Fragment>
            {dateH && dateH !== undefined ? (
              <div className="first-title active">
                {moment(dateH).format("DD/MM/YYYY")}
              </div>
            ) : (
              <div className="first-title">{tileTitle}</div>
            )}
            <Calendar
              id="icon"
              value={dateH}
              panelClassName={"!right-[45px] !top-[120px] !left-auto"}
              onChange={(e: any) => updateDate(e.value)}
              showIcon
            />
          </React.Fragment>
        )}
      </div>
      <div className="tile-amount">{"s/. " + tileAmount}</div>
      <div className="tile-last">
        <div className="last-legend">{"#Ventas:"}</div>
        <div className="last-qty">{tileQty}</div>
      </div>
    </div>
  );
};

export default TimeTile;
