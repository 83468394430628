export const ROOMS_SEARCH_LOADING = "ROOMS_SEARCH_LOADING";
export const ROOMS_SEARCH_FAIL = "ROOMS_SEARCH_FAIL";
export const ROOMS_SEARCH_UPDATE_FILTER = "ROOMS_SEARCH_UPDATE_FILTER";
export const ROOMS_SEARCH_UPDATE_TIME = "ROOMS_SEARCH_UPDATE_TIME";
export const ROOMS_SEARCH_SEARCH = "ROOMS_SEARCH_SEARCH";
export const ROOMS_SEARCH_LIST = "ROOMS_SEARCH_LIST";

export interface RoomsSearchLoading {
  type: typeof ROOMS_SEARCH_LOADING;
  payload: any;
}
export interface RoomsSearchFail {
  type: typeof ROOMS_SEARCH_FAIL;
  payload: any;
}
export interface RoomsSearchUpdateFilter {
  type: typeof ROOMS_SEARCH_UPDATE_FILTER;
  payload: any;
}
export interface RoomsSearchUpdateTime {
  type: typeof ROOMS_SEARCH_UPDATE_TIME;
  payload: any;
}
export interface RoomsSearchSearch {
  type: typeof ROOMS_SEARCH_SEARCH;
  payload: any;
}
export interface RoomsSearchList {
  type: typeof ROOMS_SEARCH_LIST;
  payload: any;
}

export type RoomsDispatchTypes =
  | RoomsSearchLoading
  | RoomsSearchFail
  | RoomsSearchUpdateFilter
  | RoomsSearchUpdateTime
  | RoomsSearchSearch
  | RoomsSearchList;