import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionButton from "../../../components/sub-components/action-button";
import { RootStore } from "../../../redux/Store";
import {
  detailSI,
  productI,
  settingsI,
  userI,
} from "../../../utils/responseEntities";
import { capitalizeWords, productSellI } from "../../../utils/utils";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import history from "../../../utils/history";
import { PRIVATE_PRODUCTS_HISTORY } from "../../../utils/paths";
import { addSellDTO, reduceStockDTO } from "../../../utils/requestDto";
import {
  reduceStockFB,
  registerSellFB,
} from "../../../redux/productsSell/ProductsSellAction";

interface RightToolbarProps {
  sellArray: Array<productSellI>;
  setSellArray: any;
}

const RightToolbar: React.FunctionComponent<RightToolbarProps> = ({
  sellArray,
  setSellArray,
}: RightToolbarProps) => {
  let defaultValue: any;
  const toastRef = React.useRef<Toast>(defaultValue);

  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const userAuth: userI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.user
  );
  const settingsAuth: settingsI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.settings
  );
  const productG: Array<productI> = useSelector(
    (globalState: RootStore) => globalState.general.productList
  );

  const getAddStatus = (key: string, cant: number) => {
    let auxProduct = productG.find((prod: productI) => {
      return prod.key === key;
    });

    if ((auxProduct?.stock || 0) <= cant) {
      return true;
    } else {
      return false;
    }
  };

  const addQty = (product: productSellI) => {
    let auxPos = sellArray.findIndex((prod: productSellI) => {
      return prod.key === product.key;
    });

    let auxSellArray = [...sellArray];

    if (auxPos !== -1) {
      auxSellArray[auxPos].cantidad++;
    }

    setSellArray(auxSellArray);
  };

  const reduceQty = (product: productSellI) => {
    let auxPos = sellArray.findIndex((prod: productSellI) => {
      return prod.key === product.key;
    });

    let auxSellArray = [...sellArray];

    if (auxPos !== -1) {
      if (auxSellArray[auxPos].cantidad === 1) {
        auxSellArray.splice(auxPos, 1);
      } else {
        auxSellArray[auxPos].cantidad--;
      }
    }

    setSellArray(auxSellArray);
  };

  const deleteProduct = (product: productSellI) => {
    let auxPos = sellArray.findIndex((prod: productSellI) => {
      return prod.key === product.key;
    });

    let auxSellArray = [...sellArray];
    auxSellArray.splice(auxPos, 1);

    setSellArray(auxSellArray);
  };

  const getSubTotal = (cantidad: number, precio: string) => {
    return (cantidad * Number(precio)).toFixed(2);
  };

  const getTotal = () => {
    let auxTotal = 0;

    sellArray?.map(
      (sell: {
        key: string;
        nombre: string;
        precio_unitario: string;
        categoria: string;
        cantidad: number;
        // eslint-disable-next-line array-callback-return
      }) => {
        auxTotal = auxTotal + sell.cantidad * Number(sell.precio_unitario);
      }
    );

    return auxTotal.toFixed(2);
  };

  const getDetalle = () => {
    let auxDetail: {
      [id: string]: detailSI;
    } = {};

    // eslint-disable-next-line array-callback-return
    sellArray.map((sell: productSellI) => {
      auxDetail[sell.nombre.toUpperCase()] = {
        key: sell.key,
        categoria: sell.categoria,
        precio_unitario: sell.precio_unitario,
        subtotal: (sell.cantidad * Number(sell.precio_unitario)).toFixed(2),
        cantidad: sell.cantidad,
      } as detailSI;
    });

    return auxDetail;
  };

  const sellProducts = () => {
    let correctSell: boolean = true;

    // eslint-disable-next-line array-callback-return
    sellArray.map((sell: productSellI) => {
      let auxProduct = productG.find((prod: productI) => {
        return prod.key === sell.key;
      });

      if (sell.cantidad > (auxProduct?.stock || 0)) {
        correctSell = false;
      }
    });

    if (correctSell) {
      setLoadingAction(true);
      //DELETE BANNED
      dispatch(
        registerSellFB(
          {
            sell: {
              detalle: getDetalle(),
              recepcionista: userAuth?.nombre || "",
              monto: getTotal(),
              fecha_venta: new Date(),
              local_cod: settingsAuth?.local_cod || 0,
            },
          } as addSellDTO,
          () => {
            // eslint-disable-next-line array-callback-return
            sellArray.map((sell: productSellI) => {
              dispatch(
                reduceStockFB({
                  key: sell.key,
                  reduceStock: -sell.cantidad,
                } as reduceStockDTO)
              );
            });
            setLoadingAction(false);
            history.push(PRIVATE_PRODUCTS_HISTORY + "?action=true");
          },
          () => {
            setLoadingAction(false);
            toastRef.current.show({
              severity: "error",
              summary: "Productos - Venta",
              detail: "Ups! Algo salio mal",
              life: 3000,
              closable: false,
            });
          }
        )
      );
    } else {
      toastRef.current.show({
        severity: "error",
        summary: "Productos - Venta",
        detail: "Uno o mas productos no tiene stock suficiente",
        life: 3000,
        closable: false,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="products-right">
        <div className="header-right">
          <div className="title">
            <p>{"Lista de compras"}</p>
          </div>
          <div className="details">
            <p>{`Fecha de venta: ${moment().format("DD/MM/YYYY")}`}</p>
            <p>{`Recepcionista: ${capitalizeWords(userAuth?.nombre || "")}`}</p>
          </div>
        </div>
        <div className="body-right">
          {sellArray?.map(
            (sell: {
              key: string;
              nombre: string;
              precio_unitario: string;
              categoria: string;
              cantidad: number;
            }) => {
              return (
                <div key={sell.key} className="sell-elements">
                  <div className="side-right-color"></div>
                  <div className="name-price">
                    <p className="name !text-[15px] !font-bold">{`${capitalizeWords(
                      sell.nombre
                    )}`}</p>
                    <span className="bg-[#c8e6c9] text-[#256029] text-[16px] font-bold rounded-md px-1">{`S/.${getSubTotal(
                      sell.cantidad,
                      sell.precio_unitario
                    )}`}</span>
                  </div>
                  <div className="body-sell-elements">
                    <div className="quantity">
                      <p className="quantity-text !text-[13px]">{`x`}</p>
                      <p className="quantity-number !text-[15px] !font-bold">{`${sell.cantidad}`}</p>
                    </div>
                    <div className="quantity-action">
                      <Button
                        icon="pi pi-plus"
                        className="p-button-rounded p-button-info !w-[30px] !h-[30px] !mr-2"
                        aria-label="Agregar"
                        disabled={getAddStatus(sell.key, sell.cantidad)}
                        onClick={() => {
                          addQty(sell);
                        }}
                      />
                      <Button
                        icon="pi pi-minus"
                        className="p-button-rounded p-button-danger !w-[30px] !h-[30px] !mr-2"
                        aria-label="Disminuir"
                        onClick={() => {
                          reduceQty(sell);
                        }}
                      />
                      <Button
                        icon="pi pi-times"
                        className="p-button-rounded p-button-secondary !w-[30px] !h-[30px]"
                        aria-label="Eliminar"
                        onClick={() => {
                          deleteProduct(sell);
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
        <div className="footer-right">
          <div className="sale">
            <p className="total">{"Total:"}</p>
            <p className="price">{`S/.${getTotal()}`}</p>
          </div>
          <div className="sell-cancel">
            <div className="sell">
              <ActionButton
                loadingAction={loadingAction}
                viewSell={true}
                sellAction={() => {
                  sellProducts();
                }}
              ></ActionButton>
            </div>
            <div className="cancel">
              <ActionButton
                viewClean={true}
                cleanAction={() => {
                  setSellArray([]);
                }}
              ></ActionButton>
            </div>
          </div>
        </div>
      </div>
      <Toast ref={toastRef} />
    </React.Fragment>
  );
};
export default RightToolbar;
