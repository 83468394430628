import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";
import moment from "moment";

import {
  bannedI,
  preferentI,
  rentI,
  roomI,
  settingsI,
  userI,
} from "../../../utils/responseEntities";
import { getRoomsCustomHistoryFB } from "../../../redux/roomsHistory/RoomsHistoryAction";
import {
  capitalizeWords,
  ROOM_STATUS,
  TT_TURN_SELECTOR,
  USER_TYPE,
} from "../../../utils/utils";
import {
  fetchRoomHistoryDTO,
  updateRentDTO,
  updateRoomsDTO,
} from "../../../utils/requestDto";

import { BiTrophy } from "react-icons/bi";
import { textTemplate } from "../../../components/sub-components/table-components";
import ActionOverlay from "../../../components/sub-components/actions-overlay";
import DetailTable from "../../../components/sub-components/detail-table";
import { ModalDeleteHistory } from "./ModalDeleteHistory";
import { Column, ColumnBodyOptions } from "primereact/column";
import ModalDetailHistory from "./ModalDetailHistory";
import ModalAddPreferent from "./ModalAddPreferent";
import { DataTable } from "primereact/datatable";
import ModalAddPenalty from "./ModalAddPenalty";
import ModalAddBanned from "./ModalAddBanned";
import ModalAddTurn from "./ModalAddTurn";
import { Toast } from "primereact/toast";
import {
  releaseRentFB,
  releaseRoomFB,
} from "../../../redux/roomsRent/RoomsRentAction";

const List: React.FunctionComponent = () => {
  let defaultValue: any;
  const toastRef = React.useRef<Toast>(defaultValue);

  const [timer, setTimer] = React.useState<Date>(new Date());

  const [historyData, setHistoryData] =
    React.useState<Array<any>>(defaultValue);

  const [modalShowDeleteHistory, setModalShowDeleteHistory] =
    React.useState(false);
  const [DeleteHistory, setDeleteHistory] = React.useState<rentI>(defaultValue);

  const [modalShowDetailHistory, setmodalShowDetailHistory] =
    React.useState(false);
  const [DetailHistory, setDetailHistory] = React.useState<rentI>(defaultValue);

  const [modalShowAddTurn, setmodalShowAddTurn] = React.useState(false);
  const [AddTurn, setAddTurn] = React.useState<rentI>(defaultValue);

  const [modalShowAddPenalty, setmodalShowAddPunish] = React.useState(false);
  const [AddPenalty, setAddPunish] = React.useState<rentI>(defaultValue);

  const [modalShowAddPreferent, setmodalShowAddPreferent] =
    React.useState(false);
  const [AddPreferent, setAddPreferent] = React.useState<rentI>(defaultValue);

  const [modalShowAddBanned, setmodalShowAddBanned] = React.useState(false);
  const [AddBanned, setAddBanned] = React.useState<rentI>(defaultValue);

  const settingsAuth: settingsI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.settings
  );
  const permissionsAuth: Array<string> = useSelector(
    (globalState: RootStore) => globalState.auth.permissions
  );
  const userAuth: userI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.user
  );
  const preferentGeneral: Array<preferentI> = useSelector(
    (globalState: RootStore) => globalState.general.preferentList
  );
  const bannedGeneral: Array<bannedI> = useSelector(
    (globalState: RootStore) => globalState.general.bannedList
  );
  const roomsGeneral: Array<roomI> = useSelector(
    (globalState: RootStore) => globalState.general.roomList
  );
  const loadingLastH: boolean = useSelector(
    (globalState: RootStore) => globalState.roomsHistory.loadingHistoryLast
  );
  const loadingActualH: boolean = useSelector(
    (globalState: RootStore) => globalState.roomsHistory.loadingHistoryActual
  );
  const loadingCustomH: boolean = useSelector(
    (globalState: RootStore) => globalState.roomsHistory.loadingHistoryCustom
  );
  const rentLastH: Array<rentI> = useSelector(
    (globalState: RootStore) => globalState.roomsHistory.rentHistoryLast
  );
  const rentActualH: Array<rentI> = useSelector(
    (globalState: RootStore) => globalState.roomsHistory.rentHistoryActual
  );
  const rentCustomH: Array<rentI> = useSelector(
    (globalState: RootStore) => globalState.roomsHistory.rentHistoryCustom
  );
  const turnH: string = useSelector(
    (globalState: RootStore) => globalState.roomsHistory.turnHistory
  );
  const dateH: Date = useSelector(
    (globalState: RootStore) => globalState.roomsHistory.customDateHistory
  );
  const finishedH: boolean = useSelector(
    (globalState: RootStore) => globalState.roomsHistory.showFinishedHistory
  );
  const holdH: boolean = useSelector(
    (globalState: RootStore) => globalState.roomsHistory.showHoldHistory
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (dateH && dateH !== undefined) {
      dispatch(
        getRoomsCustomHistoryFB({
          date: dateH,
          local: settingsAuth?.local_cod,
        } as fetchRoomHistoryDTO)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateH]);

  React.useEffect(() => {
    setTimeout(() => {
      setTimer(moment(timer).add(1, "seconds").toDate());
    }, 1000);
  }, [timer]);

  React.useEffect(() => {
    let auxRentArray: any = [];
    switch (turnH) {
      case TT_TURN_SELECTOR.ANTERIOR:
        // eslint-disable-next-line array-callback-return
        rentLastH?.map((rent: rentI) => {
          let date_out: any = rent.detalle_alquiler.hora_salida;

          if (!finishedH && !holdH) {
            auxRentArray.push({
              ...rent,
              hora_ingreso: rent.detalle_alquiler.hora_ingreso,
              hora_salida: rent.detalle_alquiler.hora_salida,
              hora_salida_real: rent.detalle_alquiler.hora_salida_real,
            });
          } else {
            if (finishedH) {
              if (
                (!rent.detalle_alquiler.hora_salida_real ||
                  rent.detalle_alquiler.hora_salida_real === undefined ||
                  rent.detalle_alquiler.hora_salida_real === null) &&
                moment(date_out?.toDate() || "") <= moment()
              ) {
                auxRentArray.push({
                  ...rent,
                  hora_ingreso: rent.detalle_alquiler.hora_ingreso,
                  hora_salida: rent.detalle_alquiler.hora_salida,
                  hora_salida_real: rent.detalle_alquiler.hora_salida_real,
                });
              }
            }

            if (holdH) {
              if (
                (!rent.detalle_alquiler.hora_salida_real ||
                  rent.detalle_alquiler.hora_salida_real === undefined ||
                  rent.detalle_alquiler.hora_salida_real === null) &&
                moment(date_out?.toDate() || "") > moment()
              ) {
                auxRentArray.push({
                  ...rent,
                  hora_ingreso: rent.detalle_alquiler.hora_ingreso,
                  hora_salida: rent.detalle_alquiler.hora_salida,
                  hora_salida_real: rent.detalle_alquiler.hora_salida_real,
                });
              }
            }
          }
        });

        setHistoryData(auxRentArray);
        break;
      case TT_TURN_SELECTOR.ACTUAL:
        // eslint-disable-next-line array-callback-return
        rentActualH?.map((rent: rentI) => {
          let date_out: any = rent.detalle_alquiler.hora_salida;

          if (!finishedH && !holdH) {
            auxRentArray.push({
              ...rent,
              hora_ingreso: rent.detalle_alquiler.hora_ingreso,
              hora_salida: rent.detalle_alquiler.hora_salida,
              hora_salida_real: rent.detalle_alquiler.hora_salida_real,
            });
          } else {
            if (finishedH) {
              if (
                (!rent.detalle_alquiler.hora_salida_real ||
                  rent.detalle_alquiler.hora_salida_real === undefined ||
                  rent.detalle_alquiler.hora_salida_real === null) &&
                moment(date_out?.toDate() || "") <= moment()
              ) {
                auxRentArray.push({
                  ...rent,
                  hora_ingreso: rent.detalle_alquiler.hora_ingreso,
                  hora_salida: rent.detalle_alquiler.hora_salida,
                  hora_salida_real: rent.detalle_alquiler.hora_salida_real,
                });
              }
            }

            if (holdH) {
              if (
                (!rent.detalle_alquiler.hora_salida_real ||
                  rent.detalle_alquiler.hora_salida_real === undefined ||
                  rent.detalle_alquiler.hora_salida_real === null) &&
                moment(date_out?.toDate() || "") > moment()
              ) {
                auxRentArray.push({
                  ...rent,
                  hora_ingreso: rent.detalle_alquiler.hora_ingreso,
                  hora_salida: rent.detalle_alquiler.hora_salida,
                  hora_salida_real: rent.detalle_alquiler.hora_salida_real,
                });
              }
            }
          }
        });

        setHistoryData(auxRentArray);
        break;
      case TT_TURN_SELECTOR.CUSTOM:
        // eslint-disable-next-line array-callback-return
        rentCustomH?.map((rent: rentI) => {
          let date_out: any = rent.detalle_alquiler.hora_salida;

          if (!finishedH && !holdH) {
            auxRentArray.push({
              ...rent,
              hora_ingreso: rent.detalle_alquiler.hora_ingreso,
              hora_salida: rent.detalle_alquiler.hora_salida,
              hora_salida_real: rent.detalle_alquiler.hora_salida_real,
            });
          } else {
            if (finishedH) {
              if (
                (!rent.detalle_alquiler.hora_salida_real ||
                  rent.detalle_alquiler.hora_salida_real === undefined ||
                  rent.detalle_alquiler.hora_salida_real === null) &&
                moment(date_out?.toDate() || "") <= moment()
              ) {
                auxRentArray.push({
                  ...rent,
                  hora_ingreso: rent.detalle_alquiler.hora_ingreso,
                  hora_salida: rent.detalle_alquiler.hora_salida,
                  hora_salida_real: rent.detalle_alquiler.hora_salida_real,
                });
              }
            }

            if (holdH) {
              if (
                (!rent.detalle_alquiler.hora_salida_real ||
                  rent.detalle_alquiler.hora_salida_real === undefined ||
                  rent.detalle_alquiler.hora_salida_real === null) &&
                moment(date_out?.toDate() || "") > moment()
              ) {
                auxRentArray.push({
                  ...rent,
                  hora_ingreso: rent.detalle_alquiler.hora_ingreso,
                  hora_salida: rent.detalle_alquiler.hora_salida,
                  hora_salida_real: rent.detalle_alquiler.hora_salida_real,
                });
              }
            }
          }
        });

        setHistoryData(auxRentArray);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [turnH, finishedH, holdH, rentLastH, rentActualH, rentCustomH]);

  const rowClass = (data: any) => {
    return {
      "row-finalized":
        (!data.detalle_alquiler.hora_salida_real ||
          data.detalle_alquiler.hora_salida_real === undefined ||
          data.detalle_alquiler.hora_salida_real === null) &&
        moment(data?.detalle_alquiler.hora_salida?.toDate() || "") < moment(),
    };
  };

  const actionsTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    return (
      <React.Fragment>
        <div className="table-action-buttons">
          <ActionOverlay
            showDetail={
              permissionsAuth.includes("room.history.detail") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            detailAction={() => {
              let auxRentDetailHistory: rentI =
                historyData.find(
                  (rentDetailHistory: rentI) =>
                    rowData[field || ""] === rentDetailHistory.key
                ) || defaultValue;
              setDetailHistory({ ...auxRentDetailHistory } as rentI);
              setmodalShowDetailHistory(true);
            }}
            showTurn={
              !rowData.detalle_alquiler.hora_salida_real &&
              ((timer.getDate() -
                rowData.detalle_alquiler.hora_ingreso.toDate().getDate() ===
                0 &&
                rowData.detalle_alquiler.hora_ingreso.toDate().getHours() <
                  (settingsAuth?.tiempo_cambiodia || 0) &&
                timer.getHours() < (settingsAuth?.tiempo_cambiodia || 0)) ||
                (timer.getDate() -
                  rowData.detalle_alquiler.hora_ingreso.toDate().getDate() ===
                  0 &&
                  rowData.detalle_alquiler.hora_ingreso.toDate().getHours() >=
                    (settingsAuth?.tiempo_cambiodia || 0) &&
                  timer.getHours() >= (settingsAuth?.tiempo_cambiodia || 0)) ||
                (timer.getDate() -
                  rowData.detalle_alquiler.hora_ingreso.toDate().getDate() ===
                  1 &&
                  rowData.detalle_alquiler.hora_ingreso.toDate().getHours() <
                    (settingsAuth?.tiempo_cambiodia || 0) &&
                  timer.getHours() < (settingsAuth?.tiempo_cambiodia || 0))) &&
              (permissionsAuth.includes("room.history.addturn") ||
                userAuth?.rol === USER_TYPE.DESARROLLADOR)
            }
            turnAction={() => {
              let auxRentDetailHistory: rentI =
                historyData.find(
                  (rentDetailHistory: rentI) =>
                    rowData[field || ""] === rentDetailHistory.key
                ) || defaultValue;
              setAddTurn({ ...auxRentDetailHistory } as rentI);
              setmodalShowAddTurn(true);
            }}
            showPunish={
              permissionsAuth.includes("room.history.addpunish") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            punishAction={() => {
              let auxRentDetailHistory: rentI =
                historyData.find(
                  (rentDetailHistory: rentI) =>
                    rowData[field || ""] === rentDetailHistory.key
                ) || defaultValue;
              setAddPunish({ ...auxRentDetailHistory } as rentI);
              setmodalShowAddPunish(true);
            }}
            showPreferent={
              preferentGeneral.findIndex((p: preferentI) => {
                return p.dni === rowData.usuarios.dni;
              }) === -1 &&
              (permissionsAuth.includes("room.history.addpreferent") ||
                userAuth?.rol === USER_TYPE.DESARROLLADOR)
            }
            preferentAction={() => {
              let auxRentDetailHistory: rentI =
                historyData.find(
                  (rentDetailHistory: rentI) =>
                    rowData[field || ""] === rentDetailHistory.key
                ) || defaultValue;
              setAddPreferent({ ...auxRentDetailHistory } as rentI);
              setmodalShowAddPreferent(true);
            }}
            showBanned={
              bannedGeneral.findIndex((b: bannedI) => {
                return b.dni === rowData.usuarios.dni;
              }) === -1 &&
              (permissionsAuth.includes("room.history.addbanned") ||
                userAuth?.rol === USER_TYPE.DESARROLLADOR)
            }
            bannedAction={() => {
              let auxRentDetailHistory: rentI =
                historyData.find(
                  (rentDetailHistory: rentI) =>
                    rowData[field || ""] === rentDetailHistory.key
                ) || defaultValue;
              setAddBanned({ ...auxRentDetailHistory } as rentI);
              setmodalShowAddBanned(true);
            }}
            showFreeRoom={
              !rowData.detalle_alquiler.hora_salida_real &&
              (permissionsAuth.includes("room.history.freeroom") ||
                userAuth?.rol === USER_TYPE.DESARROLLADOR)
            }
            freeRoomAction={() => {
              let auxFreeRent: rentI =
                historyData.find(
                  (rentDetailHistory: rentI) =>
                    rowData[field || ""] === rentDetailHistory.key
                ) || defaultValue;

              let auxFreeRoom: roomI =
                roomsGeneral.find(
                  (roomDetail: roomI) =>
                    auxFreeRent?.detalle_habitacion?.key === roomDetail?.key
                ) || defaultValue;

              if (
                auxFreeRoom?.key !== "" &&
                auxFreeRoom.local_cod === auxFreeRent.local_cod
              ) {
                dispatch(
                  releaseRoomFB(
                    {
                      room: {
                        ...auxFreeRoom,
                        estatus: ROOM_STATUS.LIBRE,
                        hora_liberado: new Date(),
                        key:
                          auxFreeRent?.detalle_habitacion?.key &&
                          auxFreeRent?.detalle_habitacion?.key !== ""
                            ? auxFreeRent?.detalle_habitacion?.key
                            : auxFreeRoom.key,
                      },
                    } as updateRoomsDTO,
                    () => {
                      dispatch(
                        releaseRentFB(
                          {
                            rent: {
                              ...auxFreeRent,
                              detalle_alquiler: {
                                ...auxFreeRent.detalle_alquiler,
                                hora_salida_real: new Date(),
                              },
                            },
                          } as updateRentDTO,
                          () => {
                            toastRef.current.show({
                              severity: "success",
                              summary: "Liberar Habitación",
                              detail: "Liberado satisfactoriamente",
                              life: 3000,
                              closable: false,
                            });
                          }
                        )
                      );
                    }
                  )
                );
              } else {
                toastRef.current.show({
                  severity: "error",
                  summary: "Liberar Habitación",
                  detail: "Ups! Algo salio mal",
                  life: 3000,
                  closable: false,
                });
              }
            }}
            showDelete={
              permissionsAuth.includes("room.history.delete") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            deleteAction={() => {
              let auxRentHistory: rentI =
                historyData.find(
                  (rentHistory: rentI) =>
                    rowData[field || ""] === rentHistory.key
                ) || defaultValue;
              setDeleteHistory({ ...auxRentHistory } as rentI);
              setModalShowDeleteHistory(true);
            }}
          ></ActionOverlay>
        </div>
      </React.Fragment>
    );
  };

  const userTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    let isPreferent = preferentGeneral.find(
      (preferent: preferentI) => preferent.dni === rowData.usuarios.dni
    );

    return (
      <div className="cell-container ">
        {settingsAuth?.show_preferents && isPreferent && (
          <BiTrophy className="w-[25px] h-[25px] mr-[5px] fill-[#b39e38]"></BiTrophy>
        )}
        <p className="">
          {capitalizeWords(
            `${rowData[field || ""].nombres} ${
              rowData[field || ""].apellido_pat
            } ${rowData[field || ""].apellido_mat}`
          )}
        </p>
      </div>
    );
  };

  const habitacionTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    return <div className="cell-container">{rowData[field || ""].numero}</div>;
  };

  const detailRoomTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    return (
      <div className="cell-container">
        <DetailTable
          key={`detail-${rowData["key"]}`}
          data={rowData[field || ""].descripcion}
          isArray={false}
        ></DetailTable>
      </div>
    );
  };

  const turnsTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    return (
      <div className="cell-container">{`${rowData[
        field || ""
      ].cantidad_turnos.toFixed(2)}`}</div>
    );
  };

  const totalPriceTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    return (
      <div className="cell-container">{`S/.${Number(
        rowData[field || ""].precio_total
      ).toFixed(2)}`}</div>
    );
  };

  const timeInTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    return (
      <div className="cell-container">
        {rowData[field || ""] && (
          <div className="cell-date-rent">
            <div className="in-date">
              {moment(rowData[field || ""]?.toDate() || "").format(
                "DD/MM/YYYY"
              )}
            </div>
            <div className="in-time">
              {moment(rowData[field || ""]?.toDate() || "").format("hh:mm a")}
            </div>
          </div>
        )}
      </div>
    );
  };

  const timeOutTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    return (
      <div className="cell-container">
        {rowData[field || ""] && (
          <div className="cell-date-rent">
            <div className="out-date">
              {moment(rowData[field || ""]?.toDate() || "").format(
                "DD/MM/YYYY"
              )}
            </div>
            <div className="out-time">
              {moment(rowData[field || ""]?.toDate() || "").format("hh:mm a")}
            </div>
          </div>
        )}
      </div>
    );
  };

  const timeOutRealTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    return (
      <div className="cell-container">
        {rowData[field || ""] && (
          <div className="cell-date-rent">
            <div className="out-real-date">
              {moment(rowData[field || ""]?.toDate() || "").format(
                "DD/MM/YYYY"
              )}
            </div>
            <div className="out-real-time">
              {moment(rowData[field || ""]?.toDate() || "").format("hh:mm a")}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="room-history-list">
        <DataTable
          //GENERAL
          dataKey="_id"
          loading={
            turnH === TT_TURN_SELECTOR.ANTERIOR
              ? loadingLastH
              : turnH === TT_TURN_SELECTOR.ACTUAL
              ? loadingActualH
              : loadingCustomH
          }
          className="p-datatable-main"
          rowClassName={rowClass}
          value={historyData}
          //SORTING
          sortField="hora_ingreso"
          sortMode="single"
          sortOrder={-1}
          //SCROLLING
          scrollable
          scrollDirection="vertical"
          //PAGINATION
          alwaysShowPaginator
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
        >
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="usuarios"
            header="Nombre"
            body={userTemplate}
            headerClassName="text-align-left nombre-field"
            className="text-align-left nombre-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="detalle_habitacion"
            header="#Hab"
            body={habitacionTemplate}
            headerClassName="text-align-center habitacion-field"
            className="text-align-center habitacion-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="detalle_alquiler"
            header="Nota"
            body={detailRoomTemplate}
            headerClassName="text-align-center descripcion-field"
            className="text-align-center descripcion-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="detalle_alquiler"
            header="Turnos"
            body={turnsTemplate}
            headerClassName="text-align-center turnos-field"
            className="text-align-center turnos-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="detalle_alquiler"
            header="Total"
            body={totalPriceTemplate}
            headerClassName="text-align-center total-field"
            className="text-align-center total-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="hora_ingreso"
            header="Ingreso"
            body={timeInTemplate}
            headerClassName="text-align-center ingreso-field"
            className="text-align-center ingreso-field"
            sortable
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="hora_salida"
            header="Salida"
            body={timeOutTemplate}
            headerClassName="text-align-center salida-field"
            className="text-align-center salida-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="hora_salida_real"
            header="Salida Real"
            body={timeOutRealTemplate}
            headerClassName="text-align-center salida-real-field"
            className="text-align-center salida-real-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="penalidad"
            header="Penalidad"
            body={textTemplate}
            headerClassName="text-align-center penalidad-field"
            className="text-align-center penalidad-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="key"
            header="Acciones"
            body={actionsTemplate}
            headerClassName="text-align-center acciones-field"
            className="text-align-center acciones-field"
          ></Column>
        </DataTable>
      </div>
      {modalShowDeleteHistory && (
        <ModalDeleteHistory
          roomHistory={DeleteHistory}
          modalShow={modalShowDeleteHistory}
          setModalShow={setModalShowDeleteHistory}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Renta - Eliminar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            console.log("message");
            toastRef.current.show({
              severity: "error",
              summary: "Renta - Eliminar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalDeleteHistory>
      )}
      {modalShowDetailHistory && (
        <ModalDetailHistory
          roomHistory={DetailHistory}
          modalShow={modalShowDetailHistory}
          setModalShow={setmodalShowDetailHistory}
          successFun={() => {}}
        ></ModalDetailHistory>
      )}
      {modalShowAddTurn && (
        <ModalAddTurn
          roomHistory={AddTurn}
          modalShow={modalShowAddTurn}
          setModalShow={setmodalShowAddTurn}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Renta - Agregar  Turno",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            console.log("message");
            toastRef.current.show({
              severity: "error",
              summary: "Renta - Agregar  Turno",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalAddTurn>
      )}
      {modalShowAddPenalty && (
        <ModalAddPenalty
          roomHistory={AddPenalty}
          modalShow={modalShowAddPenalty}
          setModalShow={setmodalShowAddPunish}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Renta - Penalizar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            console.log("message");
            toastRef.current.show({
              severity: "error",
              summary: "Renta - Penalizar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalAddPenalty>
      )}
      {modalShowAddPreferent && (
        <ModalAddPreferent
          roomHistory={AddPreferent}
          modalShow={modalShowAddPreferent}
          setModalShow={setmodalShowAddPreferent}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Renta - Agregar Preferente",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            console.log("message");
            toastRef.current.show({
              severity: "error",
              summary: "Renta - Agregar Preferente",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalAddPreferent>
      )}
      {modalShowAddBanned && (
        <ModalAddBanned
          roomHistory={AddBanned}
          modalShow={modalShowAddBanned}
          setModalShow={setmodalShowAddBanned}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Renta - Agregar Baneado",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            console.log("message");
            toastRef.current.show({
              severity: "error",
              summary: "Renta - Agregar Baneado",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalAddBanned>
      )}
      <Toast ref={toastRef} />
    </React.Fragment>
  );
};

export default List;
