import { USERS_LOADING, USERS_FAIL, USERS_LIST } from "./UsersTypes";
import {
  CollectionReference,
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  QuerySnapshot,
  FirestoreError,
  QueryConstraint,
  QueryDocumentSnapshot,
  deleteDoc,
  doc,
  updateDoc,
  getDocs,
} from "firebase/firestore";
import { userI } from "../../utils/responseEntities";
import { db } from "../../lib/init-firebase";
import {
  addUsersDTO,
  CheckInUseRequest,
  deleteUsersDTO,
  fetchUsersDTO,
  updateUsersDTO,
} from "../../utils/requestDto";
import { TT_FILTER_LIST, USER_TYPE } from "../../utils/utils";

const dbPathUsers = "/usuarios";

let userRef = collection(db, dbPathUsers) as CollectionReference<userI>;

export const getUserListFB = (
  data: fetchUsersDTO,
  funDone?: any,
  funError?: any
) => {
  console.log("getUserListFB DATA:", data);
  return (dispatch: any) => {
    dispatch({ type: USERS_LOADING });

    let queryConst: Array<QueryConstraint> = [];

    if (data.type === TT_FILTER_LIST.NOMBRE) {
      queryConst.push(where("nombre", ">=", data.search.toLowerCase()));
      queryConst.push(where("nombre", "<=", data.search.toLowerCase() + "~"));
    } else if (data.type === TT_FILTER_LIST.ROL) {
      if (data.rol !== USER_TYPE.TODOS) {
        queryConst.push(where("rol", "==", data.rol));
      }
    }
    queryConst.push(where("local_cod", "==", data.local));

    const q = query(userRef, ...queryConst);

    onSnapshot(
      q,
      (snapshot: QuerySnapshot<userI>) => {
        let auxUsers: Array<userI> = [];

        snapshot.docs.forEach((change: QueryDocumentSnapshot<userI>) => {
          // console.log(change.data());
          auxUsers.push({ key: change.id, ...change.data() });
        });

        dispatch({ type: USERS_LIST, payload: auxUsers });
        dispatch({ type: USERS_FAIL });

        if (funDone) {
          funDone();
        }
      },
      (error: FirestoreError) => {
        console.log("error on getUserListFB:", error);
        dispatch({ type: USERS_FAIL });

        if (funError) {
          funError();
        }
      }
    );
  };
};

export const addUserFB = (data: addUsersDTO, funDone?: any, funError?: any) => {
  return async () => {
    console.log("addUserFB DATA:", data);
    await addDoc(userRef, data.user)
      .catch((error: any) => {
        console.log("error on addUserFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("addUserFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const updateUserFB = (
  data: updateUsersDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("updateUserFB DATA:", data);
    await updateDoc(doc(db, dbPathUsers + "/" + data.user.key), {
      nombre: data.user.nombre,
      password: data.user.password,
      correo: data.user.correo,
      rol: data.user.rol,
      sexo: data.user.sexo,
      nivel: data.user.nivel,
      fecha_creacion: data.user.fecha_creacion,
      fecha_modificacion: data.user.fecha_modificacion,
      local_cod: data.user.local_cod,
    })
      .catch((error: any) => {
        console.log("error on updateUserFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("updateUserFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const deleteUserFB = (
  data: deleteUsersDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("deleteUserFB DATA:", data);
    await deleteDoc(doc(db, dbPathUsers, data.key))
      .catch((error: any) => {
        console.log("error on deleteUserFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("deleteUserFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const checkEmailInUseFB = (data: CheckInUseRequest, funDone?: any) => {
  return async (dispatch: any) => {
    console.log("checkEmailInUseFB DATA:", data);
    let queryConst: Array<QueryConstraint> = [];

    queryConst.push(where("correo", "==", data.email));

    const q = query(userRef, ...queryConst);

    const querySnapshot = await getDocs(q);

    let auxRent: Array<userI> = [];
    querySnapshot.forEach((doc: QueryDocumentSnapshot<userI>) => {
      auxRent.push({ key: doc.id, ...doc.data() });
    });

    if (funDone) {
      funDone(auxRent.length > 0);
    }
  };
};
