import { roomI } from "../../utils/responseEntities";
import { filterI, ROOM_STATUS, TT_FILTER_LIST } from "../../utils/utils";

import {
  ROOMS_LOADING,
  ROOMS_FAIL,
  ROOMS_UPDATE_FILTER,
  ROOMS_UPDATE_SELECTOR,
  ROOMS_SEARCH,
  ROOMS_LIST,
  RoomsDispatchTypes,
} from "./RoomsTypes";

interface RoomsState {
  loading: boolean;
  filters: Array<filterI>;
  selector: ROOM_STATUS;
  search: string;
  selectOption: Array<ROOM_STATUS>;
  rooms: Array<roomI>;
}

const defaultState: RoomsState = {
  loading: false,
  filters: [
    { name: TT_FILTER_LIST.PISO, value: true },
    { name: TT_FILTER_LIST.ESTADO, value: false },
  ],
  selector: ROOM_STATUS.TODOS,
  search: "",
  selectOption: [
    ROOM_STATUS.TODOS,
    ROOM_STATUS.LIBRE,
    ROOM_STATUS.OCUPADO,
    ROOM_STATUS.MANTENIMIENTO,
  ],
  rooms: [],
};

const roomsReducer = (
  state: RoomsState = defaultState,
  action: RoomsDispatchTypes
): RoomsState => {
  switch (action.type) {
    //=====ROOMS GENERAL VIEW
    case ROOMS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ROOMS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case ROOMS_UPDATE_FILTER:
      return {
        ...state,
        filters: action.payload,
      };
    case ROOMS_UPDATE_SELECTOR:
      return {
        ...state,
        selector: action.payload,
      };
    case ROOMS_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case ROOMS_LIST:
      return {
        ...state,
        rooms: action.payload,
      };
    default:
      return state;
  }
};

export default roomsReducer;
