import { productI, stockHistoryI } from "../../utils/responseEntities";
import { filterI, PRODUCT_CATEGORY, TT_FILTER_LIST } from "../../utils/utils";

import {
  PRODUCTS_LOADING,
  PRODUCTS_FAIL,
  PRODUCTS_UPDATE_FILTER,
  PRODUCTS_UPDATE_SELECTOR,
  PRODUCTS_SEARCH,
  PRODUCTS_LIST,
  ProductsDispatchTypes,
} from "./ProductsTypes";

interface ProductsState {
  loading: boolean;
  filters: Array<filterI>;
  selector: PRODUCT_CATEGORY;
  search: string;
  selectOption: Array<PRODUCT_CATEGORY>;
  products: Array<productI>;
  historyStock: Array<stockHistoryI>;
}

const defaultState: ProductsState = {
  loading: false,
  filters: [
    { name: TT_FILTER_LIST.NOMBRE, value: true },
    { name: TT_FILTER_LIST.PRODUCT_CATEGORY, value: false },
  ],
  selector: PRODUCT_CATEGORY.TODOS,
  search: "",
  selectOption: [
    PRODUCT_CATEGORY.TODOS,
    PRODUCT_CATEGORY.BEBIDA,
    PRODUCT_CATEGORY.CARAMELO,
    PRODUCT_CATEGORY.CHOCOLATE,
    PRODUCT_CATEGORY.CIGARRO,
    PRODUCT_CATEGORY.CUIDADO,
    PRODUCT_CATEGORY.GALLETA,
    PRODUCT_CATEGORY.SNACK,
  ],
  products: [],
  historyStock: [],
};

const productsReducer = (
  state: ProductsState = defaultState,
  action: ProductsDispatchTypes
): ProductsState => {
  switch (action.type) {
    case PRODUCTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PRODUCTS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case PRODUCTS_UPDATE_FILTER:
      return {
        ...state,
        filters: action.payload,
      };
    case PRODUCTS_UPDATE_SELECTOR:
      return {
        ...state,
        selector: action.payload,
      };
    case PRODUCTS_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case PRODUCTS_LIST:
      return {
        ...state,
        products: action.payload,
      };
    default:
      return state;
  }
};

export default productsReducer;
