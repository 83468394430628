import {
  setDoc,
  addDoc,
  collection,
  CollectionReference,
  doc,
  getDocs,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  updateDoc,
  where,
  DocumentReference,
  increment,
} from "firebase/firestore";
import { db } from "../../lib/init-firebase";
import {
  addFrequencyDTO,
  addRentDTO,
  CheckClientExist,
  updateRoomsDTO,
} from "../../utils/requestDto";
import { frequencyI, rentI } from "../../utils/responseEntities";

const dbPathRooms = "/habitaciones";

const dbPathRent = "/alquiler";
let rentRef = collection(db, dbPathRent) as CollectionReference<rentI>;

const dbPathFrequency = "/frecuencia";
let frequencyRef = collection(
  db,
  dbPathFrequency
) as CollectionReference<frequencyI>;

const dbReferenceFrequency = doc(
  frequencyRef,
  dbPathFrequency
) as DocumentReference;

export const occupyRoomFB = (
  data: updateRoomsDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("occupyRoomFB DATA:", data);
    await updateDoc(doc(db, dbPathRooms + "/" + data.room.key), {
      estatus: data.room.estatus,
      rent_key: data.room.rent_key,
    })
      .catch((error: any) => {
        console.log("error on occupyRoomFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("occupyRoomFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const registerRentFB = (
  data: addRentDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("registerRentFB DATA:", data);
    await addDoc(rentRef, data.rent)
      .catch((error: any) => {
        console.log("error on registerRentFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        (response: any) => {
          if (funDone) {
            funDone(response.id);
          }
        },
        (reason: any) => {
          console.log("registerRentFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const checkClientExist = (data: CheckClientExist, funDone?: any) => {
  return async (dispatch: any) => {
    console.log("checkEmailInUseFB DATA:", data);
    let queryConst: Array<QueryConstraint> = [];

    queryConst.push(where("dni", "==", data.dni));

    const q = query(frequencyRef, ...queryConst);

    const querySnapshot = await getDocs(q);

    let auxClient: frequencyI = {} as frequencyI;
    querySnapshot.forEach((doc: QueryDocumentSnapshot<frequencyI>) => {
      auxClient = { key: doc.id, ...doc.data() };
    });

    if (funDone) {
      funDone(auxClient);
    }
  };
};

export const addFrecuencyFB = (
  data: addFrequencyDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("addFrecuencyFB DATA:", data);
    let queryConst: Array<QueryConstraint> = [];

    queryConst.push(where("dni", "==", data.frequency.dni));

    const q = query(frequencyRef, ...queryConst);

    const querySnapshot = await getDocs(q);

    console.log("size", querySnapshot.size);

    if (querySnapshot.size > 0) {
      querySnapshot.forEach(async (docA: QueryDocumentSnapshot<frequencyI>) => {
        await updateDoc(doc(db, dbPathFrequency + "/" + docA.id), {
          cantidad: increment(1),
        })
          .catch((error: any) => {
            console.log("error on addFrecuencyFB:", error);
            if (funError) {
              funError();
            }
          })
          .then(
            () => {
              if (funDone) {
                funDone();
              }
            },
            (reason: any) => {
              console.log("addFrecuencyFB onrejected", reason);
              if (funError) {
                funError();
              }
            }
          );
      });
    } else {
      await addDoc(frequencyRef, data.frequency)
        .catch((error: any) => {
          console.log("error on addFrecuencyFB:", error);
          if (funError) {
            funError();
          }
        })
        .then(
          () => {
            if (funDone) {
              funDone();
            }
          },
          (reason: any) => {
            console.log("addFrecuencyFB onrejected", reason);
            if (funError) {
              funError();
            }
          }
        );
    }
  };
};
