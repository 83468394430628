import React from "react";
import moment from "moment";

import {
  capitalizeWords,
  getCategoryClass,
  getStatusClass,
  getUserLocal_byNumber,
  getUserTypeClass,
} from "../../utils/utils";

import { ProgressBar } from "react-bootstrap";
import { ColumnBodyOptions } from "primereact/column";

export const textTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
  return (
    <React.Fragment>
      <div className="cell-container">
        {rowData[field || ""] ? rowData[field || ""] : "-"}
      </div>
    </React.Fragment>
  );
};

export const textCapitalizeTemplate = (
  rowData: any,
  { field }: ColumnBodyOptions
) => {
  return (
    <React.Fragment>
      <div className="cell-container">
        {rowData[field || ""] ? capitalizeWords(rowData[field || ""]) : "-"}
      </div>
    </React.Fragment>
  );
};

export const textUppercaseTemplate = (
  rowData: any,
  { field }: ColumnBodyOptions
) => {
  return (
    <React.Fragment>
      <div className="cell-container">
        {rowData[field || ""] ? rowData[field || ""].toUpperCase() : "-"}
      </div>
    </React.Fragment>
  );
};

export const textLowercaseTemplate = (
  rowData: any,
  { field }: ColumnBodyOptions
) => {
  return (
    <React.Fragment>
      <div className="cell-container">
        {rowData[field || ""] ? rowData[field || ""].toLowerCase() : "-"}
      </div>
    </React.Fragment>
  );
};

export const textMoneyTemplate = (
  rowData: any,
  { field }: ColumnBodyOptions
) => {
  return (
    <React.Fragment>
      <div className="cell-container">
        {rowData[field || ""]
          ? "S/. " + Number(rowData[field || ""]).toFixed(2)
          : "-"}
      </div>
    </React.Fragment>
  );
};

export const localTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
  return (
    <React.Fragment>
      <div className="cell-container">
        {capitalizeWords(getUserLocal_byNumber(rowData[field || ""]))}
      </div>
    </React.Fragment>
  );
};

export const productCategoryTemplate = (
  rowData: any,
  { field }: ColumnBodyOptions
) => {
  return (
    <React.Fragment>
      <div className="cell-container">
        <div className="cell-category">
          <div
            className={"category-bar " + getCategoryClass(rowData[field || ""])}
          ></div>
          <div className="category-name">{rowData[field || ""]}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export const stockTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
  return (
    <React.Fragment>
      <div className="cell-container">
        <div className="cell-stock">{rowData[field || ""]}</div>
      </div>
    </React.Fragment>
  );
};

export const porcentageTemplate = (
  rowData: any,
  { field }: ColumnBodyOptions
) => {
  let auxPorcentage = (rowData["stock"] * 100) / rowData["stock_minimo"];

  return (
    <React.Fragment>
      <div className="cell-container">
        <ProgressBar
          className="cell-progress"
          variant={
            auxPorcentage <= rowData["porc_aviso"] ? "danger" : "success"
          }
          now={auxPorcentage}
        ></ProgressBar>
      </div>
    </React.Fragment>
  );
};

export const statusTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
  return (
    <React.Fragment>
      <div className="cell-container">
        <div className="cell-status">
          <div
            className={"status-bar " + getStatusClass(rowData[field || ""])}
          ></div>
          <div className="status-name">{rowData[field || ""]}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export const userTypeTemplate = (
  rowData: any,
  { field }: ColumnBodyOptions
) => {
  return (
    <React.Fragment>
      <div className="cell-container">
        <div
          className={"cell-user-type " + getUserTypeClass(rowData[field || ""])}
        >
          {rowData[field || ""][0]}
        </div>
      </div>
    </React.Fragment>
  );
};

export const dateCreateTemplate = (
  rowData: any,
  { field }: ColumnBodyOptions
) => {
  return (
    <React.Fragment>
      <div className="cell-container">
        {rowData[field || ""] ? (
          <div className="cell-date-create">
            {moment(rowData[field || ""]?.toDate() || "").format("DD/MM/YYYY")}
          </div>
        ) : (
          "-"
        )}
      </div>
    </React.Fragment>
  );
};

export const dateModifyTemplate = (
  rowData: any,
  { field }: ColumnBodyOptions
) => {
  return (
    <React.Fragment>
      <div className="cell-container">
        {rowData[field || ""] ? (
          <div className="cell-date-modify">
            {moment(rowData[field || ""]?.toDate() || "").format("DD/MM/YYYY")}
          </div>
        ) : (
          "-"
        )}
      </div>
    </React.Fragment>
  );
};

export const banReasonTemplate = (
  rowData: any,
  { field }: ColumnBodyOptions
) => {
  return (
    <React.Fragment>
      <div className="cell-container">
        <div className="cell-ban-reason">{rowData[field || ""]}</div>
      </div>
    </React.Fragment>
  );
};

export const dateRentInTemplate = (
  rowData: any,
  { field }: ColumnBodyOptions
) => {
  return (
    <React.Fragment>
      <div className="cell-container">
        {rowData[field || ""] !== "" && rowData[field || ""] && (
          <div className="cell-date-rent">
            <p className="in-date">
              {moment(rowData[field || ""]?.toDate() || "").format(
                "DD/MM/YYYY"
              )}
            </p>
            <p className="in-time">
              {moment(rowData[field || ""]?.toDate() || "").format("hh:mm a")}
            </p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export const dateRentOutTemplate = (
  rowData: any,
  { field }: ColumnBodyOptions
) => {
  return (
    <React.Fragment>
      <div className="cell-container">
        {rowData[field || ""] !== "" && rowData[field || ""] && (
          <div className="cell-date-rent">
            <div className="out-date">
              {moment(rowData[field || ""]).format("DD/MM/YYYY")}
            </div>
            <div className="out-time">
              {moment(rowData[field || ""]).format("hh:mm a")}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export const dateRentOutRealTemplate = (
  rowData: any,
  { field }: ColumnBodyOptions
) => {
  return (
    <React.Fragment>
      <div className="cell-container">
        {rowData[field || ""] !== "" && rowData[field || ""] && (
          <div className="cell-date-rent">
            <div className="out-real-date">
              {moment(rowData[field || ""]).format("DD/MM/YYYY")}
            </div>

            <div className="out-real-time">
              {moment(rowData[field || ""]).format("hh:mm a")}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
