import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";

import { TT_TURN_SELECTOR } from "../../../utils/utils";
import {
  ROOMS_HISTORY_FINISHED,
  ROOMS_HISTORY_HOLD,
  ROOMS_HISTORY_TURN,
} from "../../../redux/roomsHistory/RoomsHistoryTypes";
import { rentI } from "../../../utils/responseEntities";

import RadioOption from "../../../components/sub-components/radio-option";
import TimeTile from "./TimeTile";

const TopToolbar: React.FunctionComponent = () => {
  const rentLastH: Array<rentI> = useSelector(
    (globalState: RootStore) => globalState.roomsHistory.rentHistoryLast
  );
  const rentActualH: Array<rentI> = useSelector(
    (globalState: RootStore) => globalState.roomsHistory.rentHistoryActual
  );
  const rentCustomH: Array<rentI> = useSelector(
    (globalState: RootStore) => globalState.roomsHistory.rentHistoryCustom
  );
  const finishedH: any = useSelector(
    (globalState: RootStore) => globalState.roomsHistory.showFinishedHistory
  );
  const holdH: any = useSelector(
    (globalState: RootStore) => globalState.roomsHistory.showHoldHistory
  );
  const turnH: any = useSelector(
    (globalState: RootStore) => globalState.roomsHistory.turnHistory
  );

  const dispatch = useDispatch();

  const updateTurn = (name: string) => {
    dispatch({ type: ROOMS_HISTORY_TURN, payload: name });
  };

  const updateFinishedFilter = (state: string) => {
    dispatch({ type: ROOMS_HISTORY_FINISHED, payload: state });
  };

  const updateHoldFilter = (state: string) => {
    dispatch({ type: ROOMS_HISTORY_HOLD, payload: state });
  };

  const getTotalRent = (type: TT_TURN_SELECTOR) => {
    let auxSum = 0;

    switch (type) {
      case TT_TURN_SELECTOR.ANTERIOR:
        // eslint-disable-next-line array-callback-return
        rentLastH.map((rent: rentI) => {
          auxSum = auxSum + Number(rent.detalle_alquiler.precio_total);
          auxSum =
            auxSum - (rent?.descuento_monto ? Number(rent.descuento_monto) : 0);
          auxSum =
            auxSum + (rent?.penalidad_monto ? Number(rent.penalidad_monto) : 0);
        });

        return auxSum.toFixed(2);
      case TT_TURN_SELECTOR.ACTUAL:
        // eslint-disable-next-line array-callback-return
        rentActualH.map((rent: rentI) => {
          auxSum = auxSum + Number(rent.detalle_alquiler.precio_total);
          auxSum =
            auxSum - (rent?.descuento_monto ? Number(rent.descuento_monto) : 0);
          auxSum =
            auxSum + (rent?.penalidad_monto ? Number(rent.penalidad_monto) : 0);
        });

        return auxSum.toFixed(2);
      case TT_TURN_SELECTOR.CUSTOM:
        // eslint-disable-next-line array-callback-return
        rentCustomH.map((rent: rentI) => {
          auxSum = auxSum + Number(rent.detalle_alquiler.precio_total);
          auxSum =
            auxSum - (rent?.descuento_monto ? Number(rent.descuento_monto) : 0);
          auxSum =
            auxSum + (rent?.penalidad_monto ? Number(rent.penalidad_monto) : 0);
        });

        return auxSum.toFixed(2);
    }
  };

  return (
    <div className="room-history-toolbar">
      <div className="history-filters-list">
        <RadioOption
          clickAction={updateFinishedFilter}
          radioTitle={"Finalizados"}
          isActive={finishedH}
        ></RadioOption>
        <RadioOption
          clickAction={updateHoldFilter}
          radioTitle={"En Espera"}
          isActive={holdH}
        ></RadioOption>
      </div>
      <div className="history-tiles-list">
        <TimeTile
          key="room-history-actual"
          tileTitle={TT_TURN_SELECTOR.ANTERIOR}
          tileAmount={getTotalRent(TT_TURN_SELECTOR.ANTERIOR)}
          tileQty={rentLastH?.length || 0}
          isActive={turnH === TT_TURN_SELECTOR.ANTERIOR}
          isCustom={false}
          updateTurn={updateTurn}
        ></TimeTile>
        <TimeTile
          key="room-history-last"
          tileTitle={TT_TURN_SELECTOR.ACTUAL}
          tileAmount={getTotalRent(TT_TURN_SELECTOR.ACTUAL)}
          tileQty={rentActualH?.length || 0}
          isActive={turnH === TT_TURN_SELECTOR.ACTUAL}
          isCustom={false}
          updateTurn={updateTurn}
        ></TimeTile>
        <TimeTile
          key="room-history-custom"
          tileTitle={TT_TURN_SELECTOR.CUSTOM}
          tileAmount={getTotalRent(TT_TURN_SELECTOR.CUSTOM)}
          tileQty={rentCustomH?.length || 0}
          isActive={turnH === TT_TURN_SELECTOR.CUSTOM}
          isCustom={true}
          updateTurn={updateTurn}
        ></TimeTile>
      </div>
    </div>
  );
};

export default TopToolbar;
