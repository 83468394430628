import {
  dashboardFilterI,
  DASHBOARD_FILTER,
} from "../../utils/utils";

import{
  //RENT
  DASHBOARD_FILTERS_RENT,
  DASHBOARD_FIRST_SELECTOR_RENT,
  DASHBOARD_SECOND_SELECTOR_RENT,
  DASHBOARD_FIRST_LIST_RENT,
  DASHBOARD_SECOND_LIST_RENT,
  //SELL
  DASHBOARD_FILTERS_SELL,
  DASHBOARD_FIRST_SELECTOR_SELL,
  DASHBOARD_SECOND_SELECTOR_SELL,
  DASHBOARD_FIRST_LIST_SELL,
  DASHBOARD_SECOND_LIST_SELL,
  DashboardDispatchTypes,
} from "./DashboardTypes";

let time = new Date();
let defaultValue : any;

interface DashboardState {
  //RENT
  dashboardFiltersRent: Array<dashboardFilterI>;
  firstSelectorRent: string;
  secondSelectorRent: string;
  firstRent: Array<number>;
  secondRent: Array<number>;
  //SELL
  dashboardFiltersSell: Array<dashboardFilterI>;
  firstSelectorSell: string;
  secondSelectorSell: string;
  firstSell: Array<number>;
  secondSell: Array<number>;
}

const defaultState: DashboardState = {
  //RENT
  dashboardFiltersRent: [
    { name: DASHBOARD_FILTER.INDIVIDUAL, value:true },
    { name: DASHBOARD_FILTER.MULTIPLE, value:false },
  ],
  firstSelectorRent:  time.getFullYear().toString(),
  secondSelectorRent:  (time.getFullYear()-1).toString(),
  firstRent: [],
  secondRent: [],
  //SELL
  dashboardFiltersSell: [
    { name: DASHBOARD_FILTER.INDIVIDUAL, value:true },
    { name: DASHBOARD_FILTER.MULTIPLE, value:false },
  ],
  firstSelectorSell:  time.getFullYear().toString(),
  secondSelectorSell:  (time.getFullYear()-1).toString(),
  firstSell: [],
  secondSell: [],
}

const dashboardReducer = (
  state: DashboardState = defaultState,
  action: DashboardDispatchTypes
): DashboardState => {
  switch (action.type) {
    //RENT
    case DASHBOARD_FILTERS_RENT:
      return {
        ...state,
        dashboardFiltersRent: action.payload,
      }
    case DASHBOARD_FIRST_SELECTOR_RENT:
      return {
        ...state,
        firstSelectorRent: action.payload,
      }
    case DASHBOARD_SECOND_SELECTOR_RENT:
      return {
        ...state,
        secondSelectorRent: action.payload,
      }
    case DASHBOARD_FIRST_LIST_RENT:
      let auxFirstListR: Array<number> = [] 

      if(state.firstSelectorRent === "2020"){
        for (let index = 0; index < action.payload.length; index++) {
          if(index <= 6){
            auxFirstListR.push(defaultValue)
          }else {
            auxFirstListR.push(action.payload[index])
          }
        }
      }else if(state.firstSelectorRent === time.getFullYear().toString()){
        for (let index = 0; index < action.payload.length; index++) {
          if(index > time.getMonth()){
            auxFirstListR.push(defaultValue)
          }else {
            auxFirstListR.push(action.payload[index])
          }
        }
      }else {
        auxFirstListR = [...action.payload]
      }

      return {
        ...state,
        firstRent: auxFirstListR,
      }  
    case DASHBOARD_SECOND_LIST_RENT:
      let auxSecondListR: Array<number> = [] 

      if(state.secondSelectorRent === "2020"){
        for (let index = 0; index < action.payload.length; index++) {
          if(index <= 6){
            auxSecondListR.push(defaultValue)
          }else {
            auxSecondListR.push(action.payload[index])
          }
        }
      }else if(state.secondSelectorRent === time.getFullYear().toString()){
        for (let index = 0; index < action.payload.length; index++) {
          if(index > time.getMonth()){
            auxSecondListR.push(defaultValue)
          }else {
            auxSecondListR.push(action.payload[index])
          }
        }
      }else {
        auxSecondListR = [...action.payload]
      }
      return {
        ...state,
        secondRent: auxSecondListR,
      }
    //SELL
    case DASHBOARD_FILTERS_SELL:
      return {
        ...state,
        dashboardFiltersSell: action.payload,
      }
    case DASHBOARD_FIRST_SELECTOR_SELL:
      return {
        ...state,
        firstSelectorSell: action.payload,
      }  
    case DASHBOARD_SECOND_SELECTOR_SELL:
      return {
        ...state,
        secondSelectorSell: action.payload,
      } 
    case DASHBOARD_FIRST_LIST_SELL:
      let auxFirstListS: Array<number> = [] 

      if(state.firstSelectorSell === "2020"){
        for (let index = 0; index < action.payload.length; index++) {
          if(index <= 6){
            auxFirstListS.push(defaultValue)
          }else {
            auxFirstListS.push(action.payload[index])
          }
        }
      }else if(state.firstSelectorSell === time.getFullYear().toString()){
        for (let index = 0; index < action.payload.length; index++) {
          if(index > time.getMonth()){
            auxFirstListS.push(defaultValue)
          }else {
            auxFirstListS.push(action.payload[index])
          }
        }
      }else {
        auxFirstListS = [...action.payload]
      }

      return {
        ...state,
        firstSell: auxFirstListS,
      }  
    case DASHBOARD_SECOND_LIST_SELL:
      let auxSecondListS: Array<number> = [] 

      if(state.secondSelectorSell === "2020"){
        for (let index = 0; index < action.payload.length; index++) {
          if(index <= 6){
            auxSecondListS.push(defaultValue)
          }else {
            auxSecondListS.push(action.payload[index])
          }
        }
      }else if(state.secondSelectorSell === time.getFullYear().toString()){
        for (let index = 0; index < action.payload.length; index++) {
          if(index > time.getMonth()){
            auxSecondListS.push(defaultValue)
          }else {
            auxSecondListS.push(action.payload[index])
          }
        }
      }else {
        auxSecondListS = [...action.payload]
      }

      return {
        ...state,
        secondSell: auxSecondListS,
      } 
    default:
      return state;  
  }
}

export default dashboardReducer;