import {
  ROOMS_HISTORY_LOADING_LAST,
  ROOMS_HISTORY_FAIL_LAST,
  ROOMS_HISTORY_LOADING_ACTUAL,
  ROOMS_HISTORY_FAIL_ACTUAL,
  ROOMS_HISTORY_LOADING_CUSTOM,
  ROOMS_HISTORY_FAIL_CUSTOM,
  ROOMS_HISTORY_LIST_LAST,
  ROOMS_HISTORY_LIST_ACTUAL,
  ROOMS_HISTORY_LIST_CUSTOM,
} from "./RoomsHistoryTypes";
import {
  query,
  where,
  onSnapshot,
  QuerySnapshot,
  FirestoreError,
  QueryConstraint,
  QueryDocumentSnapshot,
  deleteDoc,
  doc,
  collection,
  CollectionReference,
  updateDoc,
} from "firebase/firestore";
import { rentI } from "../../utils/responseEntities";
import { db } from "../../lib/init-firebase";
import {
  addPenaltyHistoryDTO,
  addTurnHistoryDTO,
  deleteRoomHistoryDTO,
  fetchRoomHistoryDTO,
} from "../../utils/requestDto";

const dbPathRent = "/alquiler";
let rentRef = collection(db, dbPathRent) as CollectionReference<rentI>;

export const getRoomsLastHistoryFB = (data: fetchRoomHistoryDTO) => {
  console.log("getRoomsLastHistoryFB DATA:", data);
  return (dispatch: any) => {
    dispatch({ type: ROOMS_HISTORY_LOADING_LAST });

    let queryConst: Array<QueryConstraint> = [];
    let today = new Date();

    if (today.getHours() >= (data.change_hour || 0) + 12) {
      let startdate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        (data.change_hour || 0) + 12,
        0,
        0,
        0
      );
      let enddate = startdate.getTime() - 43200000;
      let new_enddate = new Date(enddate);

      queryConst.push(where("detalle_alquiler.hora_ingreso", "<", startdate));
      queryConst.push(
        where("detalle_alquiler.hora_ingreso", ">=", new_enddate)
      );
    } else if (
      today.getHours() >= (data.change_hour || 0) &&
      today.getHours() < (data.change_hour || 0) + 12
    ) {
      let startdate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        data.change_hour || 0,
        0,
        0,
        0
      );
      let enddate = startdate.getTime() - 43200000;
      let new_enddate = new Date(enddate);

      queryConst.push(where("detalle_alquiler.hora_ingreso", "<", startdate));
      queryConst.push(
        where("detalle_alquiler.hora_ingreso", ">=", new_enddate)
      );
    } else if (today.getHours() < (data.change_hour || 0)) {
      let startdate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        data.change_hour || 0,
        0,
        0,
        0
      );
      let aux_startdate = startdate.getTime() - 43200000;
      let aux_enddate = startdate.getTime() - 86400000;
      let new_startdate = new Date(aux_startdate);
      let new_enddate = new Date(aux_enddate);

      queryConst.push(
        where("detalle_alquiler.hora_ingreso", "<", new_startdate)
      );
      queryConst.push(
        where("detalle_alquiler.hora_ingreso", ">=", new_enddate)
      );
    }
    queryConst.push(where("local_cod", "==", data.local));

    const q = query(rentRef, ...queryConst);

    onSnapshot(
      q,
      (snapshot: QuerySnapshot<rentI>) => {
        let auxProductsHistory: Array<rentI> = [];

        snapshot.docs.forEach((change: QueryDocumentSnapshot<rentI>) => {
          // console.log(change.data());
          auxProductsHistory.push({ key: change.id, ...change.data() });
        });
        dispatch({
          type: ROOMS_HISTORY_LIST_LAST,
          payload: auxProductsHistory,
        });
        dispatch({ type: ROOMS_HISTORY_FAIL_LAST });
      },
      (error: FirestoreError) => {
        console.log("error on getRoomsLastHistoryFB:", error);
        dispatch({ type: ROOMS_HISTORY_FAIL_LAST });
      }
    );
  };
};

export const getRoomsActualHistoryFB = (data: fetchRoomHistoryDTO) => {
  console.log("getRoomsActualHistoryFB DATA:", data);
  return (dispatch: any) => {
    dispatch({ type: ROOMS_HISTORY_LOADING_ACTUAL });

    let queryConst: Array<QueryConstraint> = [];
    let today = new Date();

    if (today.getHours() >= (data.change_hour || 0) + 12) {
      let startdate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        (data.change_hour || 0) + 12,
        0,
        0,
        0
      );

      queryConst.push(where("detalle_alquiler.hora_ingreso", ">=", startdate));
    } else if (
      today.getHours() >= (data.change_hour || 0) &&
      today.getHours() < (data.change_hour || 0) + 12
    ) {
      let startdate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        (data.change_hour || 0) + 12,
        0,
        0,
        0
      );
      let enddate = startdate.getTime() - 43200000;
      let new_enddate = new Date(enddate);

      queryConst.push(where("detalle_alquiler.hora_ingreso", "<", startdate));
      queryConst.push(
        where("detalle_alquiler.hora_ingreso", ">=", new_enddate)
      );
    } else if (today.getHours() < (data.change_hour || 0)) {
      let startdate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        data.change_hour || 0,
        0,
        0,
        0
      );
      let enddate = startdate.getTime() - 43200000;
      let new_enddate = new Date(enddate);

      queryConst.push(where("detalle_alquiler.hora_ingreso", "<", startdate));
      queryConst.push(
        where("detalle_alquiler.hora_ingreso", ">=", new_enddate)
      );
    }
    queryConst.push(where("local_cod", "==", data.local));

    const q = query(rentRef, ...queryConst);

    onSnapshot(
      q,
      (snapshot: QuerySnapshot<rentI>) => {
        let auxProductsHistory: Array<rentI> = [];

        snapshot.docs.forEach((change: QueryDocumentSnapshot<rentI>) => {
          // console.log(change.data());
          auxProductsHistory.push({ key: change.id, ...change.data() });
        });
        dispatch({
          type: ROOMS_HISTORY_LIST_ACTUAL,
          payload: auxProductsHistory,
        });
        dispatch({ type: ROOMS_HISTORY_FAIL_ACTUAL });
      },
      (error: FirestoreError) => {
        console.log("error on getRoomsActualHistoryFB:", error);
        dispatch({ type: ROOMS_HISTORY_FAIL_ACTUAL });
      }
    );
  };
};

export const getRoomsCustomHistoryFB = (data: fetchRoomHistoryDTO) => {
  console.log("getRoomsCustomHistoryFB DATA:", data);
  return (dispatch: any) => {
    if (data?.date) {
      dispatch({ type: ROOMS_HISTORY_LOADING_CUSTOM });

      let queryConst: Array<QueryConstraint> = [];

      let startDay = new Date(
        data?.date.getFullYear(),
        data?.date.getMonth(),
        data?.date.getDate(),
        0,
        0,
        0,
        0
      );

      let endDay = new Date(
        data?.date.getFullYear(),
        data?.date.getMonth(),
        data?.date.getDate() + 1,
        0,
        0,
        0,
        0
      );
      queryConst.push(where("detalle_alquiler.hora_ingreso", ">=", startDay));
      queryConst.push(where("detalle_alquiler.hora_ingreso", "<", endDay));
      queryConst.push(where("local_cod", "==", data.local));

      const q = query(rentRef, ...queryConst);

      onSnapshot(
        q,
        (snapshot: QuerySnapshot<rentI>) => {
          let auxProductsHistory: Array<rentI> = [];

          snapshot.docs.forEach((change: QueryDocumentSnapshot<rentI>) => {
            // console.log(change.data());
            auxProductsHistory.push({ key: change.id, ...change.data() });
          });
          dispatch({
            type: ROOMS_HISTORY_LIST_CUSTOM,
            payload: auxProductsHistory,
          });
          dispatch({ type: ROOMS_HISTORY_FAIL_CUSTOM });
        },
        (error: FirestoreError) => {
          console.log("error on getRoomsCustomHistoryFB:", error);
          dispatch({ type: ROOMS_HISTORY_FAIL_CUSTOM });
        }
      );
    }
  };
};

export const addPenaltyHistoryFB = (
  data: addPenaltyHistoryDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("addPenaltyHistoryFB DATA:", data);
    await updateDoc(doc(db, dbPathRent + "/" + data.rent.key), {
      penalidad_monto: data.rent.penalidad_monto,
    })
      .catch((error: any) => {
        console.log("error on addPenaltyHistoryFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("addPenaltyHistoryFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const addTurnHistoryFB = (
  data: addTurnHistoryDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("addTurnHistoryFB DATA:", data);
    await updateDoc(doc(db, dbPathRent + "/" + data.rent.key), {
      detalle_alquiler: data.rent.detalle_alquiler,
    })
      .catch((error: any) => {
        console.log("error on addTurnHistoryFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("addTurnHistoryFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const deleteRentHistoryFB = (
  data: deleteRoomHistoryDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("deleteRentHistoryFB DATA:", data);
    await deleteDoc(doc(db, dbPathRent, data.key))
      .catch((error: any) => {
        console.log("error on deleteRentHistoryFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("deleteRentHistoryFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};
