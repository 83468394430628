import { userI } from "../../utils/responseEntities";
import { filterI, TT_FILTER_LIST, USER_TYPE } from "../../utils/utils";
import {
  USERS_LOADING,
  USERS_FAIL,
  USERS_UPDATE_FILTER,
  USERS_UPDATE_SELECTOR,
  USERS_SEARCH,
  USERS_LIST,
  UsersDispatchTypes,
} from "./UsersTypes";

interface UsersState {
  loading: boolean;
  filters: Array<filterI>;
  selector: USER_TYPE;
  search: string;
  selectOption: Array<USER_TYPE>;
  users: Array<userI>;
}

const defaultState: UsersState = {
  loading: false,
  filters: [
    { name: TT_FILTER_LIST.NOMBRE, value: true },
    { name: TT_FILTER_LIST.ROL, value: false },
  ],
  selector: USER_TYPE.TODOS,
  search: "",
  selectOption: [
    USER_TYPE.TODOS,
    USER_TYPE.ADMINISTRADOR,
    USER_TYPE.RECEPCIONISTA,
    USER_TYPE.LIMPIEZA,
  ],
  users: [],
};

const usersReducer = (
  state: UsersState = defaultState,
  action: UsersDispatchTypes
): UsersState => {
  switch (action.type) {
    case USERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USERS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case USERS_UPDATE_FILTER:
      return {
        ...state,
        filters: action.payload,
      };
    case USERS_UPDATE_SELECTOR:
      return {
        ...state,
        selector: action.payload,
      };
    case USERS_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case USERS_LIST:
      return {
        ...state,
        users: action.payload,
      };
    default:
      return state;
  }
};

export default usersReducer;
