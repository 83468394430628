import React from "react";

import {
  stockTemplate,
  textCapitalizeTemplate,
  textMoneyTemplate,
} from "../../../components/sub-components/table-components";
import ModalDefault from "../../../components/sub-components/modal-default";
import { sellI } from "../../../utils/responseEntities";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

interface ModalDetailProductHistoryProps {
  productsHistoryDetails: sellI;
  modalShow: any;
  setModalShow: any;
  successFun: any;
}

export const ModalDetailProductHistory: React.FunctionComponent<
  ModalDetailProductHistoryProps
> = ({
  productsHistoryDetails,
  modalShow,
  setModalShow,
  successFun,
}: ModalDetailProductHistoryProps) => {
  let defaultValue: any;

  const [tableDetail, setTableDetail] = React.useState<any>([]);

  const getTotal = () => {
    let auxTotal = 0;

    tableDetail?.map(
      // eslint-disable-next-line array-callback-return
      (sell: any) => {
        auxTotal = auxTotal + Number(sell.subtotal);
      }
    );

    return auxTotal.toFixed(2);
  };

  React.useEffect(() => {
    let auxTableList: any = [];

    Object.entries(productsHistoryDetails.detalle).forEach((element) => {
      auxTableList.push({
        key: element[1]["key"],
        nombre: element[0],
        cantidad: element[1]["cantidad"],
        precio_unitario: element[1]["precio_unitario"],
        subtotal: element[1]["subtotal"],
      });
    });

    setTableDetail(auxTableList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalDefault
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      showModalHeader={true}
      title={"Detalle de venta"}
      showModalFooter={true}
      showClose={true}
      customClass="modal-products-detail"
      size="md"
    >
      <DataTable
        //GENERAL
        dataKey="_id"
        className="p-datatable-main !h-[250px]"
        value={tableDetail}
        //SCROLLING
        scrollable
        scrollDirection="vertical"
      >
        <Column
          column={defaultValue}
          rowIndex={defaultValue}
          field="nombre"
          header="Nombre"
          body={textCapitalizeTemplate}
          headerClassName="text-align-left nombre-field"
          className="text-align-left nombre-field"
        ></Column>
        <Column
          column={defaultValue}
          rowIndex={defaultValue}
          field="cantidad"
          header="Cantidad"
          body={stockTemplate}
          headerClassName="text-align-center cantidad-field"
          className="text-align-center cantidad-field"
        ></Column>
        <Column
          column={defaultValue}
          rowIndex={defaultValue}
          field="precio_unitario"
          header="Precio U."
          body={textMoneyTemplate}
          headerClassName="text-align-center unitario-field"
          className="text-align-center unitario-field"
        ></Column>
        <Column
          column={defaultValue}
          rowIndex={defaultValue}
          field="subtotal"
          header="Subtotal"
          body={textMoneyTemplate}
          headerClassName="text-align-center subtotal-field"
          className="subtotal-data text-subtotal text-align-center subtotal-field"
        ></Column>
      </DataTable>
      <div className="flex flex-row justify-end items-center w-full pt-3">
        <p className="font-bold text-[16px] pr-[10px]">{`Total:`}</p>
        <span className="bg-[#c8e6c9] text-[#256029] text-[16px] font-bold rounded-md px-1">{`S/.${getTotal()}`}</span>
      </div>
    </ModalDefault>
  );
};

export default ModalDetailProductHistory;
