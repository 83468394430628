import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";

import {
  PRODUCTS_UPDATE_FILTER,
  PRODUCTS_SEARCH,
  PRODUCTS_UPDATE_SELECTOR,
} from "../../../redux/products/ProductsTypes";
import { getProductListFB } from "../../../redux/products/ProductsAction";
import { filterI, TT_FILTER_LIST, USER_TYPE } from "../../../utils/utils";
import { settingsI, userI } from "../../../utils/responseEntities";
import { fetchProductsDTO } from "../../../utils/requestDto";

import ActionButton from "../../../components/sub-components/action-button";
import FilterOption from "../../../components/sub-components/filter-option";
import SelectOption from "../../../components/sub-components/select-option";
import SearchBox from "../../../components/sub-components/search-box";
import ModalAddProduct from "./ModalAddProduct";
import { Toast } from "primereact/toast";

const TopToolbar: React.FunctionComponent = () => {
  let defaultValue: any;
  const toastRef = React.useRef<Toast>(defaultValue);

  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);
  const [modalShowAdd, setModalShowAdd] = React.useState(false);

  const settingsAuth: settingsI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.settings
  );
  const permissionsAuth: Array<string> = useSelector(
    (globalState: RootStore) => globalState.auth.permissions
  );
  const userAuth: userI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.user
  );
  const filtersP: any = useSelector(
    (globalState: RootStore) => globalState.products.filters
  );
  const selectOptionP: any = useSelector(
    (globalState: RootStore) => globalState.products.selectOption
  );
  const selectorP: any = useSelector(
    (globalState: RootStore) => globalState.products.selector
  );
  const searchP: any = useSelector(
    (globalState: RootStore) => globalState.products.search
  );

  const dispatch = useDispatch();

  const updateSelector = (name: string) => {
    dispatch({ type: PRODUCTS_UPDATE_SELECTOR, payload: name });
  };

  const updateFilter = (name: string) => {
    let filtersAux: any = [];

    // eslint-disable-next-line array-callback-return
    filtersP.map((filter: filterI) => {
      if (filter.name === name) {
        filtersAux.push({ name: filter.name, value: true });
      } else {
        filtersAux.push({ name: filter.name, value: false });
      }
    });

    dispatch({ type: PRODUCTS_UPDATE_FILTER, payload: filtersAux });
  };

  const updateSearch = (name: string) => {
    dispatch({ type: PRODUCTS_SEARCH, payload: name });
  };

  const fetchProduct = () => {
    setLoadingAction(true);
    //FETCH PRODUCT LIST
    dispatch(
      getProductListFB(
        {
          type:
            filtersP.find((filter: filterI) => {
              return filter.value === true;
            })?.name || TT_FILTER_LIST.TODOS,
          search: searchP,
          categoria: selectorP,
          local: settingsAuth?.local_cod,
        } as fetchProductsDTO,
        () => {
          setLoadingAction(false);
        },
        () => {
          setLoadingAction(false);
        }
      )
    );
  };

  return (
    <React.Fragment>
      <div className="product-toolbar">
        <div className="toolbar-left">
          <FilterOption
            clickAction={(name: string) => {
              updateFilter(name);
            }}
            filterArray={filtersP}
          ></FilterOption>
          {filtersP[0].value === true && (
            <SearchBox
              searchValue={searchP}
              searchAction={(value: string) => {
                updateSearch(value);
              }}
            ></SearchBox>
          )}
          {filtersP[1].value === true && (
            <SelectOption
              clickAction={(name: string) => {
                updateSelector(name);
              }}
              optionArray={selectOptionP}
              selected={selectorP}
            ></SelectOption>
          )}
          <ActionButton
            loadingAction={loadingAction}
            isSearch={true}
            searchAction={() => {
              fetchProduct();
            }}
          ></ActionButton>
        </div>
        <div className="toolbar-right">
          <ActionButton
            viewAdd={
              permissionsAuth.includes("product.general.addedit") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            addAction={() => {
              setModalShowAdd(true);
            }}
          ></ActionButton>
        </div>
      </div>
      {modalShowAdd && (
        <ModalAddProduct
          modalShow={modalShowAdd}
          setModalShow={setModalShowAdd}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Productos - Agregar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            toastRef.current.show({
              severity: "error",
              summary: "Productos - Agregar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalAddProduct>
      )}
      <Toast ref={toastRef} />
    </React.Fragment>
  );
};

export default TopToolbar;
