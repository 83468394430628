import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";

import { getProductsCustomHistoryFB } from "../../../redux/productsHistory/ProductsHistoryAction";
import { sellI, settingsI, userI } from "../../../utils/responseEntities";
import { fetchProductHistoryDTO } from "../../../utils/requestDto";
import { TT_TURN_SELECTOR, USER_TYPE } from "../../../utils/utils";

import {
  dateRentInTemplate,
  textCapitalizeTemplate,
  textMoneyTemplate,
} from "../../../components/sub-components/table-components";
import ActionOverlay from "../../../components/sub-components/actions-overlay";
import ModalDeleteProductHistory from "./ModalDeleteProductHistory";
import ModalDetailProductHistory from "./ModalDetailProductHistory";
import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";

const List: React.FunctionComponent = () => {
  let defaultValue: any;
  const toastRef = React.useRef<Toast>(defaultValue);

  const [historyData, setHistoryData] =
    React.useState<Array<sellI>>(defaultValue);

  const [modalShowDeleteHistory, setModalShowDeleteHistory] =
    React.useState(false);
  const [productDeleteHistory, setProductDeleteHistory] =
    React.useState<sellI>(defaultValue);

  const [modalShowDetailHistory, setModalShowDetailHistory] =
    React.useState(false);
  const [productDetailHistory, setProductDetailHistory] =
    React.useState<sellI>(defaultValue);

  const settingsAuth: settingsI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.settings
  );
  const permissionsAuth: Array<string> = useSelector(
    (globalState: RootStore) => globalState.auth.permissions
  );
  const userAuth: userI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.user
  );
  const loadingLastH: boolean = useSelector(
    (globalState: RootStore) => globalState.productsHistory.loadingHistoryLast
  );
  const loadingActualH: boolean = useSelector(
    (globalState: RootStore) => globalState.productsHistory.loadingHistoryCustom
  );
  const loadingCustomH: boolean = useSelector(
    (globalState: RootStore) => globalState.productsHistory.loadingHistoryCustom
  );
  const productLastH: Array<sellI> = useSelector(
    (globalState: RootStore) => globalState.productsHistory.productsHistoryLast
  );
  const productActualH: Array<sellI> = useSelector(
    (globalState: RootStore) =>
      globalState.productsHistory.productsHistoryActual
  );
  const productCustomH: Array<sellI> = useSelector(
    (globalState: RootStore) =>
      globalState.productsHistory.productsHistoryCustom
  );
  const turnH: TT_TURN_SELECTOR = useSelector(
    (globalState: RootStore) => globalState.productsHistory.turnHistory
  );
  const dateH: Date = useSelector(
    (globalState: RootStore) => globalState.productsHistory.customDateHistory
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (dateH && dateH !== undefined) {
      dispatch(
        getProductsCustomHistoryFB({
          date: dateH,
          local: settingsAuth?.local_cod,
        } as fetchProductHistoryDTO)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateH]);

  React.useEffect(() => {
    switch (turnH) {
      case TT_TURN_SELECTOR.ANTERIOR:
        setHistoryData([...productLastH]);
        break;
      case TT_TURN_SELECTOR.ACTUAL:
        setHistoryData([...productActualH]);
        break;
      case TT_TURN_SELECTOR.CUSTOM:
        setHistoryData([...productCustomH]);
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [turnH, productLastH, productActualH, productCustomH]);

  const actionsTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    return (
      <React.Fragment>
        <div className="table-action-buttons">
          <ActionOverlay
            showDetail={
              permissionsAuth.includes("product.history.detail") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            detailAction={() => {
              let auxProductDetailHistory: sellI =
                historyData.find(
                  (productHistory: sellI) =>
                    rowData[field || ""] === productHistory.key
                ) || defaultValue;
              setProductDetailHistory({
                ...auxProductDetailHistory,
              } as sellI);
              setModalShowDetailHistory(true);
            }}
            showDelete={
              permissionsAuth.includes("product.history.delete") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            deleteAction={() => {
              let auxProductHistory: sellI =
                historyData.find(
                  (productHistory: sellI) =>
                    rowData[field || ""] === productHistory.key
                ) || defaultValue;
              setProductDeleteHistory({ ...auxProductHistory } as sellI);
              setModalShowDeleteHistory(true);
            }}
          ></ActionOverlay>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="product-history-list">
        <DataTable
          //GENERAL
          dataKey="_id"
          loading={
            turnH === TT_TURN_SELECTOR.ANTERIOR
              ? loadingLastH
              : turnH === TT_TURN_SELECTOR.ACTUAL
              ? loadingActualH
              : loadingCustomH
          }
          className="p-datatable-main"
          value={historyData}
          //SORTING
          sortField="fecha_venta"
          sortMode="single"
          sortOrder={-1}
          //SCROLLING
          scrollable
          scrollDirection="vertical"
          //PAGINATION
          alwaysShowPaginator
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
        >
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="recepcionista"
            header="Recepcionista"
            body={textCapitalizeTemplate}
            headerClassName="text-align-left recepcionista-field"
            className="text-align-left recepcionista-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="monto"
            header="Monto"
            body={textMoneyTemplate}
            headerClassName="text-align-left monto-field"
            className="text-align-left monto-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="fecha_venta"
            header="Fecha de venta"
            body={dateRentInTemplate}
            headerClassName="text-align-center venta-field"
            className="text-align-center venta-field"
            sortable
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="key"
            header="Acciones"
            body={actionsTemplate}
            headerClassName="text-align-center acciones-field"
            className="text-align-center acciones-field"
          ></Column>
        </DataTable>
      </div>
      {modalShowDeleteHistory && (
        <ModalDeleteProductHistory
          productsHistory={productDeleteHistory}
          modalShow={modalShowDeleteHistory}
          setModalShow={setModalShowDeleteHistory}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Venta - Eliminar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            console.log("message");
            toastRef.current.show({
              severity: "error",
              summary: "Venta - Eliminar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalDeleteProductHistory>
      )}
      {modalShowDetailHistory && (
        <ModalDetailProductHistory
          productsHistoryDetails={productDetailHistory}
          modalShow={modalShowDetailHistory}
          setModalShow={setModalShowDetailHistory}
          successFun={() => {}}
        ></ModalDetailProductHistory>
      )}
      <Toast ref={toastRef} />
    </React.Fragment>
  );
};

export default List;
