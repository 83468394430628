import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";

import {
  USERS_UPDATE_FILTER,
  USERS_SEARCH,
  USERS_UPDATE_SELECTOR,
} from "../../../redux/users/UsersTypes";
import { filterI, TT_FILTER_LIST, USER_TYPE } from "../../../utils/utils";
import { getUserListFB } from "../../../redux/users/UsersAction";
import { settingsI, userI } from "../../../utils/responseEntities";
import { fetchUsersDTO } from "../../../utils/requestDto";

import ActionButton from "../../../components/sub-components/action-button";
import FilterOption from "../../../components/sub-components/filter-option";
import SelectOption from "../../../components/sub-components/select-option";
import SearchBox from "../../../components/sub-components/search-box";
import ModalAddUser from "./ModalAddUser";
import { Toast } from "primereact/toast";

const TopToolbar: React.FunctionComponent = () => {
  let defaultValue: any;
  const toastRef = React.useRef<Toast>(defaultValue);

  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);
  const [modalShowAdd, setModalShowAdd] = React.useState<boolean>(false);

  const settingsAuth: settingsI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.settings
  );
  const permissionsAuth: Array<string> = useSelector(
    (globalState: RootStore) => globalState.auth.permissions
  );
  const userAuth: userI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.user
  );
  const filtersU: Array<filterI> = useSelector(
    (globalState: RootStore) => globalState.users.filters
  );
  const selectOptionU: Array<USER_TYPE> = useSelector(
    (globalState: RootStore) => globalState.users.selectOption
  );
  const selectorU: USER_TYPE = useSelector(
    (globalState: RootStore) => globalState.users.selector
  );
  const searchU: string = useSelector(
    (globalState: RootStore) => globalState.users.search
  );

  const dispatch = useDispatch();

  const updateSelector = (name: string) => {
    dispatch({ type: USERS_UPDATE_SELECTOR, payload: name });
  };

  const updateFilter = (name: string) => {
    let filtersAux: any = [];

    // eslint-disable-next-line array-callback-return
    filtersU.map((filter: filterI) => {
      if (filter.name === name) {
        filtersAux.push({ name: filter.name, value: true });
      } else {
        filtersAux.push({ name: filter.name, value: false });
      }
    });

    dispatch({ type: USERS_UPDATE_FILTER, payload: filtersAux });
  };

  const updateSearch = (name: string) => {
    dispatch({ type: USERS_SEARCH, payload: name });
  };

  const fetchUser = () => {
    setLoadingAction(true);
    //FETCH USER LIST
    dispatch(
      getUserListFB(
        {
          type:
            filtersU.find((filter: filterI) => {
              return filter.value === true;
            })?.name || TT_FILTER_LIST.TODOS,
          search: searchU,
          rol: selectorU,
          local: settingsAuth?.local_cod,
        } as fetchUsersDTO,
        () => {
          setLoadingAction(false);
        },
        () => {
          setLoadingAction(false);
        }
      )
    );
  };

  return (
    <React.Fragment>
      <div className="user-toolbar">
        <div className="toolbar-left">
          <FilterOption
            clickAction={(name: string) => {
              updateFilter(name);
            }}
            filterArray={filtersU}
          ></FilterOption>
          {filtersU[0].value === true && (
            <SearchBox
              searchValue={searchU}
              searchAction={(value: string) => {
                updateSearch(value);
              }}
            ></SearchBox>
          )}
          {filtersU[1].value === true && (
            <SelectOption
              clickAction={(name: string) => {
                updateSelector(name);
              }}
              optionArray={selectOptionU}
              selected={selectorU}
            ></SelectOption>
          )}
          <ActionButton
            loadingAction={loadingAction}
            isSearch={true}
            searchAction={() => {
              fetchUser();
            }}
          ></ActionButton>
        </div>
        <div className="toolbar-right">
          <ActionButton
            viewAdd={
              permissionsAuth.includes("user.delete") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            addAction={() => {
              setModalShowAdd(true);
            }}
          ></ActionButton>
        </div>
      </div>
      {modalShowAdd && (
        <ModalAddUser
          modalShow={modalShowAdd}
          setModalShow={setModalShowAdd}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Usuarios - Agregar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            console.log("message");
            toastRef.current.show({
              severity: "error",
              summary: "Usuarios - Agregar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalAddUser>
      )}
      <Toast ref={toastRef} />
    </React.Fragment>
  );
};

export default TopToolbar;
