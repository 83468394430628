import React from "react";
import List from "./components/List";
import TopToolbar from "./components/TopToolbar";
import "./style.scss";

const RoomsSearch: React.FunctionComponent = () => {
  return (
    <div className="rooms-search-container">
      <TopToolbar></TopToolbar>
      <List></List>
    </div>
  );
};

export default RoomsSearch;
