export const PREFERENT_LOADING = "PREFERENT_LOADING";
export const PREFERENT_FAIL = "PREFERENT_FAIL";
export const PREFERENT_UPDATE_FILTER = "PREFERENT_UPDATE_FILTER";
export const PREFERENT_SEARCH = "PREFERENT_SEARCH";
export const PREFERENT_LIST = "PREFERENT_LIST";

export interface PreferentLoading {
  type: typeof PREFERENT_LOADING;
}
export interface PreferentFail {
  type: typeof PREFERENT_FAIL;
  payload: any;
}
export interface PreferentUpdateFilter {
  type: typeof PREFERENT_UPDATE_FILTER;
  payload: any;
}
export interface PreferentSearch {
  type: typeof PREFERENT_SEARCH;
  payload: any;
}
export interface PreferentList {
  type: typeof PREFERENT_LIST;
  payload: any;
}

export type PreferentDispatchTypes =
  | PreferentLoading
  | PreferentFail
  | PreferentUpdateFilter
  | PreferentSearch
  | PreferentList;
