import React from "react";
import { useDispatch } from "react-redux";

import {
  addBannedFB,
  checkDniBannedFB,
} from "../../../redux/banned/BannedAction";
import { BANNED_CATEGORY, onlyDigits, onlyLetters } from "../../../utils/utils";
import { addBannedDTO, GetByDniRequest } from "../../../utils/requestDto";
import { bannedI } from "../../../utils/responseEntities";

import ModalDefault from "../../../components/sub-components/modal-default";
import SelectOption from "../../../components/sub-components/select-option";
import InputField from "../../../components/sub-components/input-field";
import InputDate from "../../../components/sub-components/input-date";

interface ModalAddBannedProps {
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalAddBanned: React.FunctionComponent<ModalAddBannedProps> = ({
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalAddBannedProps) => {
  let defaultValue: any;

  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);
  const [state, setState] = React.useState<bannedI>({
    nombre: "",
    dni: "",
    categoria: "",
    fecha_nacimiento: defaultValue,
  });

  const [errorNombre, setErrorNombre] = React.useState(false);
  const [errorDni, setErrorDni] = React.useState(false);
  const [errorCategoria, setErrorCategoria] = React.useState(false);

  const dispatch = useDispatch();

  const updateBannedData = (name: any, value: any) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateBannedData = () => {
    if (state.nombre === "" || state.dni === "" || state.categoria === "") {
      setErrorNombre(state.nombre === "" ? true : false);
      setErrorDni(state.dni === "" ? true : false);
      setErrorCategoria(state.categoria === "" ? true : false);
      return false;
    } else {
      return true;
    }
  };

  const addBanned = () => {
    setLoadingAction(true);
    //ADD BANNED
    dispatch(
      checkDniBannedFB(
        {
          dni: state.dni,
        } as GetByDniRequest,
        (response: any) => {
          if (!response) {
            dispatch(
              addBannedFB(
                {
                  banned: {
                    nombre: state.nombre,
                    dni: state.dni,
                    categoria: state.categoria.toUpperCase(),
                    fecha_nacimiento: state.fecha_nacimiento
                      ? state.fecha_nacimiento
                      : "",
                  },
                } as addBannedDTO,
                () => {
                  setLoadingAction(false);
                  if (successFun) successFun("Agregado satisfactoriamente");
                  setModalShow(false);
                },
                () => {
                  setLoadingAction(false);
                  if (errorFun) errorFun("Ups! Algo salio mal");
                  setModalShow(false);
                }
              )
            );
          } else {
            console.log("dni ya registrado");
            setLoadingAction(false);
            if (errorFun) errorFun("DNI ya registrado");
          }
        }
      )
    );
  };

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-banned-add"
      title={"Agregar Banned"}
      showModalHeader={true}
      showModalFooter={true}
      showAdd={true}
      showCancel={true}
      addAction={() => {
        if (validateBannedData()) {
          addBanned();
        }
      }}
    >
      <React.Fragment>
        <InputField
          key={"add-banned-nombres"}
          horizontal={false}
          placeholder={""}
          value={state.nombre}
          onChange={(value: any) => {
            updateBannedData("nombre", value.toLowerCase());
            setErrorNombre(false);
          }}
          className={"add-banned-input"}
          regExp={onlyLetters}
          viewTitle={true}
          viewErrorMessage={errorNombre}
          title={"Nombre"}
          errorMessage={"Incorrecto"}
          height={"!h-[40px]"}
          width={"!w-full"}
          isUppercase={true}
        ></InputField>
        <SelectOption
          key={"add-banned-categoria"}
          horizontal={false}
          className={"add-banned-select"}
          clickAction={(value: string) => {
            updateBannedData("categoria", value);
            setErrorCategoria(false);
          }}
          optionArray={[
            BANNED_CATEGORY.LADRON,
            BANNED_CATEGORY.FUMON,
            BANNED_CATEGORY.OTRO,
          ]}
          selected={state.categoria}
          viewTitle={true}
          viewErrorMessage={errorCategoria}
          title={"Categoría"}
          errorMessage={"Incorrecto"}
        ></SelectOption>
        <InputField
          key={"add-banned-dni"}
          horizontal={false}
          placeholder={""}
          value={state.dni}
          onChange={(value: any) => {
            updateBannedData("dni", value);
            setErrorDni(false);
          }}
          className={"add-banned-input"}
          regExp={onlyDigits}
          viewTitle={true}
          viewErrorMessage={errorDni}
          title={"DNI"}
          errorMessage={"Incorrecto"}
          height={"!h-[40px]"}
          width={"!w-full"}
        ></InputField>
        <InputDate
          key={"add-banned-fecha-nacimiento"}
          horizontal={false}
          placeholder={"DD/MM/YYYY"}
          value={state.fecha_nacimiento || undefined}
          onChange={(value: any) => {
            updateBannedData("fecha_nacimiento", value);
          }}
          className={"add-banned-input"}
          viewTitle={true}
          viewErrorMessage={false}
          title={"Fech. Nac."}
          errorMessage={"Incorrecto"}
        ></InputDate>
      </React.Fragment>
    </ModalDefault>
  );
};

export default ModalAddBanned;
