import React from "react";
import RightToolbar from "./components/RightToolbar";
import List from "./components/List";
import "./style.scss";

import { productSellI } from "../../utils/utils";

const ProductsSell: React.FunctionComponent = () => {
  const [sellArray, setSellArray] = React.useState<Array<productSellI>>([]);

  return (
    <div className="products-sell-container">
      <List sellArray={sellArray} setSellArray={setSellArray}></List>
      <RightToolbar
        sellArray={sellArray}
        setSellArray={setSellArray}
      ></RightToolbar>
    </div>
  );
};
export default ProductsSell;
