import React, { Fragment } from "react";
import { Button } from "primereact/button";

interface ActionButtonProps {
  loadingAction?: boolean;
  isSearch?: boolean;
  searchAction?(): void;
  viewAdd?: boolean;
  addAction?(): void;
  viewRent?: boolean;
  rentAction?(): void;
  viewReport?: boolean;
  reportAction?(): void;
  viewSell?: boolean;
  sellAction?(): void;
  viewSave?: boolean;
  saveAction?(): void;
  viewCancel?: boolean;
  cancelAction?(): void;
  viewReduce?: boolean;
  reduceAction?(): void;
  viewClose?: boolean;
  closeAction?(): void;
  viewExit?: boolean;
  exitAction?(): void;
  viewClean?: boolean;
  cleanAction?(): void;
  viewTop?: boolean;
  qtyTop?: number;
  topAction?(): void;
  viewLogin?: boolean;
  loginAction?(): void;
}

const ActionButton: React.FunctionComponent<ActionButtonProps> = ({
  loadingAction = false,
  isSearch = false,
  searchAction,
  viewAdd = false,
  addAction,
  viewRent = false,
  rentAction,
  viewReport = false,
  reportAction,
  viewSell = false,
  sellAction,
  viewSave = false,
  saveAction,
  viewCancel = false,
  cancelAction,
  viewReduce = false,
  reduceAction,
  viewClose = false,
  closeAction,
  viewExit = false,
  exitAction,
  viewClean = false,
  cleanAction,
  viewTop = false,
  qtyTop,
  topAction,
  viewLogin,
  loginAction,
}: any) => {
  return (
    <Fragment>
      {!isSearch ? (
        <div className="action-button-subcomponent">
          {viewAdd && (
            <Button
              className="button button-add"
              label={"Agregar"}
              loading={loadingAction}
              disabled={loadingAction}
              onClick={() => {
                if (addAction) addAction();
              }}
            />
          )}
          {viewRent && (
            <Button
              className="button button-add"
              label={"Alquilar"}
              loading={loadingAction}
              disabled={loadingAction}
              onClick={() => {
                if (rentAction) rentAction();
              }}
            />
          )}
          {viewReport && (
            <Button
              className="button button-pdf"
              label={"PDF"}
              loading={loadingAction}
              disabled={loadingAction}
              onClick={() => {
                if (reportAction) reportAction();
              }}
            />
          )}
          {viewSell && (
            <Button
              className="button button-sell"
              label={"Vender"}
              loading={loadingAction}
              disabled={loadingAction}
              onClick={() => {
                if (sellAction) sellAction();
              }}
            />
          )}
          {viewSave && (
            <Button
              className="button button-sell"
              label={"Guardar"}
              loading={loadingAction}
              disabled={loadingAction}
              onClick={() => {
                if (saveAction) saveAction();
              }}
            />
          )}
          {viewCancel && (
            <Button
              className="button button-cancel"
              label={"Cancelar"}
              // loading={loadingAction}
              disabled={loadingAction}
              onClick={() => {
                if (cancelAction) cancelAction();
              }}
            />
          )}
          {viewReduce && (
            <Button
              className="button button-modal-reduce"
              label={"Reducir"}
              onClick={() => {
                if (reduceAction) reduceAction();
              }}
            />
          )}
          {viewClose && (
            <Button
              className="button button-modal-close"
              label={"Cerrar"}
              onClick={() => {
                if (closeAction) closeAction();
              }}
            />
          )}
          {viewExit && (
            <Button
              className="button button-cancel"
              label={"Cancelar"}
              // loading={loadingAction}
              disabled={loadingAction}
              onClick={() => {
                if (exitAction) exitAction();
              }}
            />
          )}
          {viewClean && (
            <Button
              className="button button-exit"
              label={"Limpiar"}
              loading={loadingAction}
              disabled={loadingAction}
              onClick={() => {
                if (cleanAction) cleanAction();
              }}
            />
          )}
          {viewTop && (
            <Button
              className="button button-top"
              label={`Top ${qtyTop}`}
              loading={loadingAction}
              disabled={loadingAction}
              onClick={() => {
                if (topAction) topAction();
              }}
            />
          )}
          {viewLogin && (
            <Button
              className="button login-button"
              label={"Login"}
              loading={loadingAction}
              disabled={loadingAction}
              onClick={() => {
                if (loginAction) loginAction();
              }}
            />
          )}
        </div>
      ) : (
        <div className="search-button-subcomponent">
          <Button
            className="button button-search"
            label={"Buscar"}
            loading={loadingAction}
            disabled={loadingAction}
            onClick={() => {
              if (searchAction) searchAction();
            }}
          />
        </div>
      )}
    </Fragment>
  );
};

export default ActionButton;
