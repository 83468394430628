import React from "react";
import { filterI } from "../../utils/utils";

interface FilterOptionProps {
  clickAction: any;
  filterArray: any;
}

const FilterOption: React.FunctionComponent<FilterOptionProps> = ({
  clickAction,
  filterArray,
}: any) => {
  return (
    <div className="filter-option-subcomponent">
      {filterArray.map((filter: filterI) => (
        <div
          key={filter.name}
          className={filter.value ? "filter-option active" : "filter-option"}
          onClick={() => {
            if(!filter.value){
              clickAction(filter.name);
            }
          }}
        >
          {filter.name}
        </div>
      ))}
    </div>
  );
};

export default FilterOption;
