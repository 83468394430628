import React from "react";
import Form from "react-bootstrap/Form";
import { FiSearch } from "react-icons/fi";

interface SearchBoxProps {
  searchValue: any;
  searchAction: any;
}

const SearchBox: React.FunctionComponent<SearchBoxProps> = ({
  searchValue,
  searchAction,
}: any) => {
  return (
    <div className="search-box-subcomponent">
      <Form.Control
        value={searchValue}
        spellCheck={false}
        className={"search-box"}
        as={"input"}
        placeholder={"Buscar"}
        onChange={(e: any) => {
          searchAction(e.target.value);
        }}
      ></Form.Control>
      <FiSearch></FiSearch>
    </div>
  );
};

export default SearchBox;
