import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteProps } from "react-router-dom";
import { RootStore } from "../redux/Store";
import "./style.scss";

import {
  getProductsActualHistoryFB,
  getProductsLastHistoryFB,
} from "../redux/productsHistory/ProductsHistoryAction";
import {
  getRoomsActualHistoryFB,
  getRoomsLastHistoryFB,
} from "../redux/roomsHistory/RoomsHistoryAction";
import {
  getFirstRentFB,
  getSecondRentFB,
  getFirstSellFB,
  getSecondSellFB,
} from "../redux/dashboard/DashboardAction";
import {
  getGeneralBannedFB,
  getGeneralPreferentFB,
  getGeneralProductFB,
  getGeneralRoomFB,
} from "../redux/general/GeneralAction";
import {
  fetchDashboardDTO,
  fetchProductsDTO,
  fetchBannedDTO,
  fetchPreferentsDTO,
  fetchRoomHistoryDTO,
  fetchRoomsDTO,
  fetchUsersDTO,
  GetByLocalRequest,
} from "../utils/requestDto";
import {
  filterI,
  PRODUCT_CATEGORY,
  ROOM_STATUS,
  TT_FILTER_LIST,
  USER_TYPE,
} from "../utils/utils";
import { getPreferentListFB } from "../redux/preferent/PreferentAction";
import { getProductListFB } from "../redux/products/ProductsAction";
import { getBannedListFB } from "../redux/banned/BannedAction";
import { getUserListFB } from "../redux/users/UsersAction";
import { getRoomListFB } from "../redux/rooms/RoomsAction";
import { settingsI } from "../utils/responseEntities";

import Menu from "./Menu";
import Top from "./Top";

interface PrincipalLayotuProps extends RouteProps {
  children: any;
  history: any;
}

const PrincipalLayout: React.FunctionComponent<PrincipalLayotuProps> = ({
  children,
  history,
}: any) => {
  const settingsAuth: settingsI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.settings
  );
  //PRODUCTS REDUX
  const searchProducts: string = useSelector(
    (globalState: RootStore) => globalState.products.search
  );
  const selectorProducts: PRODUCT_CATEGORY = useSelector(
    (globalState: RootStore) => globalState.products.selector
  );
  const filtersProducts: Array<filterI> = useSelector(
    (globalState: RootStore) => globalState.products.filters
  );
  //ROOMS REDUX
  const searchRooms: string = useSelector(
    (globalState: RootStore) => globalState.rooms.search
  );
  const selectorRooms: ROOM_STATUS = useSelector(
    (globalState: RootStore) => globalState.rooms.selector
  );
  const filtersRooms: Array<filterI> = useSelector(
    (globalState: RootStore) => globalState.rooms.filters
  );
  //CLIENTS REDUX
  const searchBanned: string = useSelector(
    (globalState: RootStore) => globalState.banned.search
  );
  const filtersBanned: Array<filterI> = useSelector(
    (globalState: RootStore) => globalState.banned.filters
  );
  const searchPreferent: string = useSelector(
    (globalState: RootStore) => globalState.preferent.search
  );
  const filtersPreferent: Array<filterI> = useSelector(
    (globalState: RootStore) => globalState.preferent.filters
  );
  //USER REDUX
  const searchUsers: string = useSelector(
    (globalState: RootStore) => globalState.users.search
  );
  const selectorUsers: USER_TYPE = useSelector(
    (globalState: RootStore) => globalState.users.selector
  );
  const filtersUsers: Array<filterI> = useSelector(
    (globalState: RootStore) => globalState.users.filters
  );

  const [showMenu, setMenu] = React.useState(false);

  const dispatch = useDispatch();

  //GENERAL - PAGE
  const fetchGeneral = () => {
    dispatch(
      getGeneralProductFB({
        local: settingsAuth?.local_cod,
      } as GetByLocalRequest)
    );
    dispatch(
      getGeneralRoomFB({ local: settingsAuth?.local_cod } as GetByLocalRequest)
    );
    dispatch(getGeneralPreferentFB());
    dispatch(getGeneralBannedFB());
  };
  //DASHBOARD - PAGE
  // const fetchDashboard = () => {
  //   dispatch(
  //     getFirstRentFB(
  //       {
  //         year: new Date().getFullYear(),
  //         local: settingsAuth?.local_cod,
  //       } as fetchDashboardDTO,
  //       () => {
  //         dispatch(
  //           getFirstSellFB({
  //             year: new Date().getFullYear(),
  //             local: settingsAuth?.local_cod,
  //           } as fetchDashboardDTO)
  //         );
  //       }
  //     )
  //   );
  // };
  //PRODUCTS - PAGE
  const fetchProduct = () => {
    dispatch(
      getProductListFB({
        type:
          filtersProducts.find((filter: filterI) => {
            return filter.value === true;
          })?.name || TT_FILTER_LIST.TODOS,
        search: searchProducts,
        categoria: selectorProducts,
        local: settingsAuth?.local_cod,
      } as fetchProductsDTO)
    );
  };
  const fetchProductHistory = () => {
    dispatch(
      getProductsLastHistoryFB({
        change_hour: settingsAuth?.tiempo_cambiodia,
        local: settingsAuth?.local_cod,
      } as fetchRoomHistoryDTO)
    );
    dispatch(
      getProductsActualHistoryFB({
        change_hour: settingsAuth?.tiempo_cambiodia,
        local: settingsAuth?.local_cod,
      } as fetchRoomHistoryDTO)
    );
  };
  //ROOMS - PAGE
  const fetchRoom = () => {
    dispatch(
      getRoomListFB({
        type:
          filtersRooms.find((filter: filterI) => {
            return filter.value === true;
          })?.name || TT_FILTER_LIST.TODOS,
        search: searchRooms,
        status: selectorRooms,
        local: settingsAuth?.local_cod,
      } as fetchRoomsDTO)
    );
  };
  const fetchRoomHistory = () => {
    dispatch(
      getRoomsLastHistoryFB({
        change_hour: settingsAuth?.tiempo_cambiodia,
        local: settingsAuth?.local_cod,
      } as fetchRoomHistoryDTO)
    );
    dispatch(
      getRoomsActualHistoryFB({
        change_hour: settingsAuth?.tiempo_cambiodia,
        local: settingsAuth?.local_cod,
      } as fetchRoomHistoryDTO)
    );
  };
  //CLIENTS - PAGE
  const fetchPreferent = () => {
    dispatch(
      getPreferentListFB({
        type:
          filtersPreferent.find((filter: filterI) => {
            return filter.value === true;
          })?.name || TT_FILTER_LIST.TODOS,
        search: searchPreferent,
      } as fetchPreferentsDTO)
    );
  };
  const fetchBanned = () => {
    dispatch(
      getBannedListFB({
        type:
          filtersBanned.find((filter: filterI) => {
            return filter.value === true;
          })?.name || TT_FILTER_LIST.TODOS,
        search: searchBanned,
      } as fetchBannedDTO)
    );
  };
  //USERS - PAGE
  const fetchUser = () => {
    dispatch(
      getUserListFB({
        type:
          filtersUsers.find((filter: filterI) => {
            return filter.value === true;
          })?.name || TT_FILTER_LIST.TODOS,
        search: searchUsers,
        rol: selectorUsers,
        local: settingsAuth?.local_cod,
      } as fetchUsersDTO)
    );
  };

  React.useEffect(() => {
    fetchGeneral();
    // fetchDashboard();
    fetchProduct();
    fetchProductHistory();
    fetchRoom();
    fetchRoomHistory();
    fetchPreferent();
    fetchBanned();
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    fetchRoomHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsAuth?.tiempo_cambiodia]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      let today = new Date();

      if (
        (today.getHours() === (settingsAuth?.tiempo_cambiodia || 0) ||
          today.getHours() === (settingsAuth?.tiempo_cambiodia || 0) + 12) &&
        today.getMinutes() < 2
      ) {
        fetchProductHistory();
        fetchRoomHistory();
      }
    }, 60000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="layout">
      <Menu history={history} showMenu={showMenu} setMenu={setMenu}></Menu>
      <div className="body-layout">
        <Top history={history} showMenu={showMenu} setMenu={setMenu}></Top>
        <div className="body">{children}</div>
      </div>
    </div>
  );
};

export default PrincipalLayout;
