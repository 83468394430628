import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";

import {
  getLastRentByKeyFB,
  getLastRentByRoomFB,
  releaseRentFB,
  releaseRoomFB,
} from "../../../redux/roomsRent/RoomsRentAction";
import {
  fetchLastRentByKeyDTO,
  fetchLastRentDTO,
  updateRentDTO,
  updateRoomsDTO,
} from "../../../utils/requestDto";
import history from "../../../utils/history";
import { rentI, roomI, settingsI } from "../../../utils/responseEntities";
import { getStatusRoomTileClass, ROOM_STATUS } from "../../../utils/utils";
import { PRIVATE_ROOMS_HISTORY } from "../../../utils/paths";

import { FiArrowRightCircle, FiArrowLeftCircle, FiClock } from "react-icons/fi";
import { BsHouse } from "react-icons/bs";
import { Toast } from "primereact/toast";

interface RoomTileProps {
  room: roomI;
  timer: Date;
}

const RoomTile: React.FunctionComponent<RoomTileProps> = ({
  room,
  timer,
}: RoomTileProps) => {
  let defaultValue: any;
  const toastRef = React.useRef<Toast>(defaultValue);

  const settingsAuth: settingsI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.settings
  );

  const dispatch = useDispatch();

  const getIsOnClean = (releaseTime: any) => {
    if (
      timer.getTime() - releaseTime?.toDate() >=
      (settingsAuth?.tiempo_standby || 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const rentRoom = (key: string) => {
    //RENT ROOM
    history.push("/rooms-form/" + key);
  };

  const releaseRoom = () => {
    //RELEASE ROOM
    if (room.local_cod === settingsAuth?.local_cod) {
      dispatch(
        releaseRoomFB(
          {
            room: {
              ...room,
              estatus: ROOM_STATUS.LIBRE,
              hora_liberado: new Date(),
            },
          } as updateRoomsDTO,
          () => {
            if (room?.rent_key && room?.rent_key !== "") {
              dispatch(
                getLastRentByKeyFB(
                  {
                    key: room?.rent_key || "",
                  } as fetchLastRentByKeyDTO,
                  (rent: rentI) => {
                    dispatch(
                      releaseRentFB(
                        {
                          rent: {
                            ...rent,
                            detalle_alquiler: {
                              ...rent.detalle_alquiler,
                              hora_salida_real: new Date(),
                            },
                          },
                        } as updateRentDTO,
                        () => {
                          toastRef.current.show({
                            severity: "success",
                            summary: "Liberar Habitación",
                            detail: "Liberado satisfactoriamente",
                            life: 3000,
                            closable: false,
                          });
                          // history.push(PRIVATE_ROOMS_HISTORY + "?action=true");
                        }
                      )
                    );
                  }
                )
              );
            } else {
              dispatch(
                getLastRentByRoomFB(
                  {
                    number: room.numero,
                    local: settingsAuth?.local_cod,
                  } as fetchLastRentDTO,
                  (rent: rentI) => {
                    dispatch(
                      releaseRentFB(
                        {
                          rent: {
                            ...rent,
                            detalle_alquiler: {
                              ...rent.detalle_alquiler,
                              hora_salida_real: new Date(),
                            },
                          },
                        } as updateRentDTO,
                        () => {
                          toastRef.current.show({
                            severity: "success",
                            summary: "Liberar Habitación",
                            detail: "Liberado satisfactoriamente",
                            life: 3000,
                            closable: false,
                          });
                          // history.push(PRIVATE_ROOMS_HISTORY + "?action=true");
                        }
                      )
                    );
                  }
                )
              );
            }
          }
        )
      );
    } else {
      toastRef.current.show({
        severity: "error",
        summary: "Liberar Habitación",
        detail: "Ups! Algo salio mal",
        life: 3000,
        closable: false,
      });
    }
  };

  return (
    <React.Fragment>
      {room.estatus === ROOM_STATUS.LIBRE &&
        !getIsOnClean(room.hora_liberado) && (
          <div className={`room-rent-room-tile status-mantenimiento`}>
            <div className={`room-tile-insignia status-mantenimiento`}></div>
            <BsHouse className="room-icon"></BsHouse>
            <div className="room-tile-number">{room.numero}</div>
            <div className="room-tile-type">
              {/* <div className="type-main">{"Tipo:"}</div> */}
              <div className="type-content">{room.tipo}</div>
            </div>
            <div className={`room-tile-action status-mantenimiento`}>
              <div className={`type-content status-mantenimiento`}>
                {"Esperar"}
              </div>
              <FiClock className={"status-mantenimiento"}></FiClock>
            </div>
          </div>
        )}
      {room.estatus === ROOM_STATUS.LIBRE &&
        getIsOnClean(room.hora_liberado) && (
          <div className={`room-rent-room-tile status-libre`}>
            <div className={`room-tile-insignia status-libre`}></div>
            <BsHouse className="room-icon"></BsHouse>
            <div className="room-tile-number">{room.numero}</div>
            <div className="room-tile-type">
              {/* <div className="type-main">{"Tipo:"}</div> */}
              <div className="type-content">{room.tipo}</div>
            </div>
            <div
              className={`room-tile-action status-libre`}
              onClick={() => {
                rentRoom(room.key || "");
              }}
            >
              <div className={`type-content status-libre`}>{"Alquilar"}</div>
              <FiArrowRightCircle
                className={"status-libre"}
              ></FiArrowRightCircle>
            </div>
          </div>
        )}
      {room.estatus === ROOM_STATUS.OCUPADO && (
        <div
          className={`room-rent-room-tile ${getStatusRoomTileClass(
            room.estatus
          )}`}
        >
          <div
            className={`room-tile-insignia ${getStatusRoomTileClass(
              room.estatus
            )}`}
          ></div>
          <BsHouse className="room-icon"></BsHouse>
          <div className="room-tile-number">{room.numero}</div>
          <div className="room-tile-type">
            {/* <div className="type-main">{"Tipo:"}</div> */}
            <div className="type-content">{room.tipo}</div>
          </div>
          <div
            className={`room-tile-action ${getStatusRoomTileClass(
              room.estatus
            )}`}
            onClick={() => {
              releaseRoom();
            }}
          >
            <div
              className={`type-content ${getStatusRoomTileClass(room.estatus)}`}
            >
              {"Liberar"}
            </div>
            <FiArrowLeftCircle
              className={getStatusRoomTileClass(room.estatus)}
            ></FiArrowLeftCircle>
          </div>
        </div>
      )}
      <Toast ref={toastRef} />
    </React.Fragment>
  );
};

export default RoomTile;
