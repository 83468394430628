import { rentI, sellI, usersRI } from "./responseEntities";

// import * as jsPDF from "jspdf";
// import "jspdf-autotable";
// import { UserOptions } from "jspdf-autotable";

//***** REGEXP *****/
export const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);

export const onlyDigits = RegExp(/^[0-9]*$/);

export const onlyLetters = RegExp(/^[a-zA-ZñÑ\s]*$/);

export const noBlankSpaces = RegExp(/^\S+$/);

//***** ARRAY VALUES *****/
export const defaultPermission: Array<defaultPermissionI> = [
  {
    rol: "Administrador",
    permission: [],
  },
  {
    rol: "Recepcionista",
    permission: [],
  },
  {
    rol: "Limpieza",
    permission: [],
  },
];

export const generalPermission: Array<generalPermissionI> = [
  {
    title: "dashboard",
    general: "dashboard",
    permission: [],
  },
  {
    title: "productos general",
    general: "product.general",
    permission: [
      { value: "product.general.addedit", name: "Agregar / Editar productos" },
      { value: "product.general.delete", name: "Eliminar producto" },
      { value: "product.general.stock", name: "Agregar stock" },
    ],
  },
  {
    title: "productos historial",
    general: "product.history",
    permission: [
      { value: "product.history.delete", name: "Eliminar venta" },
      { value: "product.history.detail", name: "Ver detalle de venta" },
    ],
  },
  {
    title: "productos venta",
    general: "product.sell",
    permission: [],
  },
  {
    title: "habitaciones general",
    general: "room.general",
    permission: [
      { value: "room.general.addedit", name: "Agregar / Editar habitación" },
      { value: "room.general.delete", name: "Eliminar habitación" },
      {
        value: "room.general.enable",
        name: "Habilitar / Deshabilitar habitación",
      },
    ],
  },
  {
    title: "habitaciones historial",
    general: "room.history",
    permission: [
      { value: "room.history.addturn", name: "Agregar turno" },
      { value: "room.history.addpunish", name: "Agregar penalidad" },
      { value: "room.history.addpreferent", name: "Agregar preferent" },
      { value: "room.history.addbanned", name: "Agregar baneado" },
      { value: "room.history.freeroom", name: "Liberar habitación" },
      { value: "room.history.delete", name: "Eliminar habitación" },
      { value: "room.history.detail", name: "Ver detalle de renta" },
    ],
  },
  {
    title: "habitaciones renta",
    general: "room.rent",
    permission: [],
  },
  {
    title: "habitaciones busqueda",
    general: "room.search",
    permission: [],
  },
  {
    title: "clientes preferentes",
    general: "client.preferent",
    permission: [
      {
        value: "client.preferent.addedit",
        name: "Agregar / Editar preferente",
      },
      { value: "client.preferent.delete", name: "Eliminar preferente" },
      { value: "client.preferent.clean", name: "Limpiar lista" },
      { value: "client.preferent.ranking", name: "Generar ranking" },
    ],
  },
  {
    title: "clientes baneados",
    general: "client.banned",
    permission: [
      { value: "client.banned.addedit", name: "Agregar / Editar baneado" },
      { value: "client.banned.delete", name: "Eliminar baneado" },
    ],
  },
  {
    title: "usuarios",
    general: "user",
    permission: [
      { value: "user.addedit", name: "Agregar / Editar usuario" },
      { value: "user.delete", name: "Eliminar usuario" },
    ],
  },
];

//***** INTERFACES *****/
// interface jsPDFWithPlugin extends jsPDF {
//   autoTable: (options: UserOptions) => jsPDF;
// }

export interface filterI {
  name: TT_FILTER_LIST;
  value: boolean;
}

export interface customDateI {
  date: Date;
}

export interface defaultPermissionI {
  rol: string;
  permission: Array<string>;
}

export interface generalPermissionI {
  title: string;
  general: string;
  permission: Array<permissionI>;
}

export interface permissionI {
  name: string;
  value: string;
}

export interface timeSearchI {
  name: TIME_SEARCH;
  value: boolean;
}

export interface dashboardFilterI {
  name: DASHBOARD_FILTER;
  value: boolean;
}

export interface rentCountI {
  dni: string;
  usuario: usersRI;
  occurences: number;
}

export interface productSellI {
  key: string;
  nombre: string;
  precio_unitario: string;
  categoria: string;
  cantidad: number;
}

//***** ENTITYS ENUM *****/
export enum PRODUCT_CATEGORY {
  TODOS = "Todos",
  BEBIDA = "Bebida",
  SNACK = "Snack",
  GALLETA = "Galleta",
  CHOCOLATE = "Chocolate",
  CUIDADO = "Cuidado Personal",
  CARAMELO = "Caramelo",
  CIGARRO = "Cigarro",
}

export enum ROOM_STATUS {
  TODOS = "Todos",
  LIBRE = "Libre",
  OCUPADO = "Ocupado",
  MANTENIMIENTO = "En Mantenimiento",
}

export enum ROOM_STATUS_CLASS {
  LIBRE = "room-libre",
  OCUPADO = "room-ocupado",
  MANTENIMIENTO = "room-mantenimiento",
}

export enum ROOM_CONTENT {
  LED = "LED TV",
  SMART = "Smart TV",
  SIMPLE = "TV Simple",
  ESPEJO = "Espejo",
  JACUZZI = "Jacuzzi",
  SILLON = "Sillón Tántrico",
}

export enum ROOM_CONTENT_VALUE {
  LED = "led",
  SMART = "smart",
  SIMPLE = "simple",
  ESPEJO = "espejo",
  JACUZZI = "jacuzzi",
  SILLON = "tantrico",
}

export enum ROOM_CONTENT_CLASS {
  LED = "room-led",
  SMART = "room-smart",
  SIMPLE = "room-simple",
  ESPEJO = "room-espejo",
  JACUZZI = "room-jacuzzi",
  SILLON = "room-sillon",
}

export enum ROOM_TYPE {
  TODOS = "Todos",
  SIMPLE = "Simple",
  SIMPLE_TV = "Simple con TV",
  SMART = "Smart TV",
  SMART_SILLON = "Smart TV + Sillon",
  LED = "TV LED",
  LED_SILLON = "TV LED + Sillon",
  JACUZZI = "Jacuzzi",
  DOBLE = "Doble Cama",
}

export enum SEX_LIST {
  MASCULINO = "masculino",
  FEMENINO = "femenino",
}

export enum LOCAL_LIST {
  HOSTAL_CASABLANCA = "Hostal Casablanca",
  CASABLANCA_SUITE = "Casa Blanca Suite",
  HOSTAL_CAPRICORNIO = "Hostal Capricornio",
  HOSTAL_PARAISO = "Hostal Paraíso",
}

export enum FLOOR_LIST {
  TODOS = "Todos",
  PRIMERO = "1ro",
  SEGUNDO = "2do",
  TERCERO = "3ro",
  CUARTO = "4to",
}

export enum USER_TYPE {
  TODOS = "Todos",
  ADMINISTRADOR = "Administrador",
  RECEPCIONISTA = "Recepcionista",
  LIMPIEZA = "Limpieza",
  DESARROLLADOR = "Desarrollador Web",
}

export enum TT_FILTER_LIST {
  TODOS = "Todos",
  NUMERO = "Número",
  ESTADO = "Estado",
  PISO = "Piso",
  DNI = "DNI",
  HABITACION = "Habitación",
  NOMBRE = "Nombre",
  AP_PATERNO = "Ap. Paterno",
  AP_MATERNO = "Ap. Materno",
  ROL = "Rol",
  PRODUCT_CATEGORY = "Categoría",
}

export enum TT_TURN_SELECTOR {
  ACTUAL = "Actual",
  ANTERIOR = "Anterior",
  CUSTOM = "Custom",
}

export enum BANNED_CATEGORY {
  LADRON = "Ladrón",
  FUMON = "Fumón",
  OTRO = "Otro",
}

export enum TIME_SEARCH {
  TODOS = "Todos",
  WEEK = "Semana",
  MONTH = "Mes",
  YEAR = "Año",
}

export enum DASHBOARD_FILTER {
  INDIVIDUAL = "Individual",
  MULTIPLE = "Multiple",
}

//***** GENERAL FUNCTIONS *****/
export function capitalizeWords(str: string) {
  if (str && str?.length > 0) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  } else {
    return "";
  }
}

export function capitalizeFirstOnly(str: string) {
  return str && str?.length > 0
    ? str[0].toUpperCase() + str.substr(1).toLowerCase()
    : "";
}

export function generateYearsArray() {
  let year = new Date();
  let yearActual = year.getFullYear();
  let dashboardYears = [];
  for (let i = yearActual; i >= 2020; i--) {
    dashboardYears.push(i.toString());
  }
  return dashboardYears;
}

export function getDataByMonthRent(rents: Array<rentI>) {
  let dataArrayRent = [];
  for (let i = 0; i <= 11; i++) {
    let monthAmount = 0;
    // eslint-disable-next-line array-callback-return
    rents.map((rent: rentI) => {
      let inMonth: any = rent.detalle_alquiler.hora_ingreso;
      if (inMonth.toDate().getMonth() === i) {
        monthAmount += Number(rent.penalidad_monto) || 0;
        monthAmount -= Number(rent.descuento_monto) || 0;
        monthAmount += Number(rent.detalle_alquiler.precio_total) || 0;
      }
    });
    dataArrayRent.push(Number(monthAmount.toFixed(0)));
  }
  return dataArrayRent;
}

export function getDataByMonthSell(sells: Array<sellI>) {
  let dataArraySell = [];
  for (let i = 0; i <= 11; i++) {
    let monthAmount = 0;
    // eslint-disable-next-line array-callback-return
    sells.map((sell: sellI) => {
      let inMonth: any = sell.fecha_venta;
      if (inMonth.toDate().getMonth() === i) {
        monthAmount += Number(sell.monto) || 0;
      }
    });
    dataArraySell.push(Number(monthAmount.toFixed(0)));
  }
  return dataArraySell;
}

export const getCategoryClass = (category: any) => {
  switch (category) {
    case PRODUCT_CATEGORY.BEBIDA:
      return "category-class-bebida";
    case PRODUCT_CATEGORY.SNACK:
      return "category-class-snack";
    case PRODUCT_CATEGORY.GALLETA:
      return "category-class-galleta";
    case PRODUCT_CATEGORY.CHOCOLATE:
      return "category-class-chocolate";
    case PRODUCT_CATEGORY.CUIDADO:
      return "category-class-cuidado";
    case PRODUCT_CATEGORY.CARAMELO:
      return "category-class-caramelo";
    case PRODUCT_CATEGORY.CIGARRO:
      return "category-class-cigarro";
    default:
      return "";
  }
};

export const getStatusClass = (status: any) => {
  switch (status) {
    case ROOM_STATUS.LIBRE:
      return "status-class-open";
    case ROOM_STATUS.OCUPADO:
      return "status-class-occupied";
    case ROOM_STATUS.MANTENIMIENTO:
      return "status-class-maintenance";
    default:
      return "";
  }
};

export const getUserTypeClass = (type: any) => {
  switch (type) {
    case USER_TYPE.ADMINISTRADOR:
      return "user-class-administrator";
    case USER_TYPE.LIMPIEZA:
      return "user-class-cleaner";
    case USER_TYPE.RECEPCIONISTA:
      return "user-class-recepcionist";
    default:
      return "";
  }
};

export const getRoomContentLabel = (description: string) => {
  switch (description) {
    case ROOM_CONTENT_VALUE.JACUZZI:
      return ROOM_CONTENT.JACUZZI;
    case ROOM_CONTENT_VALUE.SMART:
      return ROOM_CONTENT.SMART;
    case ROOM_CONTENT_VALUE.LED:
      return ROOM_CONTENT.LED;
    case ROOM_CONTENT_VALUE.SIMPLE:
      return ROOM_CONTENT.SIMPLE;
    case ROOM_CONTENT_VALUE.ESPEJO:
      return ROOM_CONTENT.ESPEJO;
    case ROOM_CONTENT_VALUE.SILLON:
      return ROOM_CONTENT.SILLON;
    default:
      return "";
  }
};

export const getRoomDescriptionLabel = (description: string) => {
  switch (description) {
    case ROOM_CONTENT.JACUZZI:
      return ROOM_CONTENT_VALUE.JACUZZI;
    case ROOM_CONTENT.SMART:
      return ROOM_CONTENT_VALUE.SMART;
    case ROOM_CONTENT.LED:
      return ROOM_CONTENT_VALUE.LED;
    case ROOM_CONTENT.SIMPLE:
      return ROOM_CONTENT_VALUE.SIMPLE;
    case ROOM_CONTENT.ESPEJO:
      return ROOM_CONTENT_VALUE.ESPEJO;
    case ROOM_CONTENT.SILLON:
      return ROOM_CONTENT_VALUE.SILLON;
    default:
      return "";
  }
};

export const getStatusRoomTileClass = (status: any) => {
  switch (status) {
    case ROOM_STATUS.LIBRE:
      return "status-libre";
    case ROOM_STATUS.MANTENIMIENTO:
      return "status-mantenimiento";
    case ROOM_STATUS.OCUPADO:
      return "status-ocupado";
    default:
      return "";
  }
};

export const getUserLevel = (rol: string) => {
  switch (rol) {
    case USER_TYPE.ADMINISTRADOR:
      return 2;
    case USER_TYPE.RECEPCIONISTA:
      return 1;
    case USER_TYPE.LIMPIEZA:
      return 4;
    default:
      return 0;
  }
};

export const getUserLocal_byString = (local_cod: string) => {
  switch (local_cod) {
    case LOCAL_LIST.HOSTAL_CASABLANCA:
      return 1;
    case LOCAL_LIST.CASABLANCA_SUITE:
      return 2;
    case LOCAL_LIST.HOSTAL_CAPRICORNIO:
      return 3;
    case LOCAL_LIST.HOSTAL_PARAISO:
      return 4;
    default:
      return 0;
  }
};

export const getUserLocal_byNumber = (local_cod: number) => {
  switch (local_cod) {
    case 1:
      return LOCAL_LIST.HOSTAL_CASABLANCA;
    case 2:
      return LOCAL_LIST.CASABLANCA_SUITE;
    case 3:
      return LOCAL_LIST.HOSTAL_CAPRICORNIO;
    case 4:
      return LOCAL_LIST.HOSTAL_PARAISO;
    default:
      return "";
  }
};

export const getFloorByFilter = (floor: FLOOR_LIST) => {
  switch (floor) {
    case FLOOR_LIST.PRIMERO:
      return 1;
    case FLOOR_LIST.SEGUNDO:
      return 2;
    case FLOOR_LIST.TERCERO:
      return 3;
    case FLOOR_LIST.CUARTO:
      return 4;
    default:
      return 1;
  }
};

// interface jsPDFWithPlugin extends jsPDF {
//   autoTable: (options: UserOptions) => jsPDF;
// }

// export const generatePDFbody = (rentArrayPDF: Array<any>) => {
//   let body = [];
//   let bodyDetail = [];

//   let montoAlquiler = 0;

//   for (var j = 0; j < rentArrayPDF.length; j++) {
//     bodyDetail = [];

//     bodyDetail.push(
//       rentArrayPDF[j].usuarios.nombres +
//         " " +
//         rentArrayPDF[j].usuarios.apellido_pat +
//         " " +
//         rentArrayPDF[j].usuarios.apellido_mat
//     );

//     bodyDetail.push({
//       content: rentArrayPDF[j].detalle_habitacion.numero,
//       styles: { halign: "center" },
//     });

//     bodyDetail.push({
//       content: rentArrayPDF[j].detalle_alquiler.cantidad_turnos,
//       styles: { halign: "center" },
//     });

//     bodyDetail.push({
//       content:
//         rentArrayPDF[j].detalle_alquiler.hora_ingreso.toDate().getDate() +
//         "/" +
//         (rentArrayPDF[j].detalle_alquiler.hora_ingreso.toDate().getMonth() + 1 <
//         10
//           ? "0"
//           : "") +
//         (rentArrayPDF[j].detalle_alquiler.hora_ingreso.toDate().getMonth() +
//           1) +
//         "/" +
//         rentArrayPDF[j].detalle_alquiler.hora_ingreso.toDate().getFullYear(),
//       styles: { halign: "center" },
//     });

//     if (rentArrayPDF[j].detalle_alquiler.hora_salida_real) {
//       bodyDetail.push({
//         content:
//           rentArrayPDF[j].detalle_alquiler.hora_salida_real.toDate().getDate() +
//           "/" +
//           (rentArrayPDF[j].detalle_alquiler.hora_salida_real
//             .toDate()
//             .getMonth() +
//             1 <
//           10
//             ? "0"
//             : "") +
//           (rentArrayPDF[j].detalle_alquiler.hora_salida_real
//             .toDate()
//             .getMonth() +
//             1) +
//           "/" +
//           rentArrayPDF[j].detalle_alquiler.hora_salida_real
//             .toDate()
//             .getFullYear(),
//         styles: { halign: "center" },
//       });
//     } else {
//       bodyDetail.push({
//         content: "",
//         styles: { halign: "center" },
//       });
//     }

//     if (rentArrayPDF[j].penalidad_monto) {
//       let sub_monto = 0;
//       sub_monto =
//         rentArrayPDF[j].detalle_alquiler.precio_total * 1 +
//         rentArrayPDF[j].penalidad_monto * 1;

//       bodyDetail.push({
//         content: "s/. " + sub_monto.toFixed(2),
//         styles: { halign: "right" },
//       });

//       montoAlquiler = montoAlquiler + sub_monto * 1;
//     } else {
//       bodyDetail.push({
//         content: "s/. " + rentArrayPDF[j].detalle_alquiler.precio_total,
//         styles: { halign: "right" },
//       });

//       montoAlquiler =
//         montoAlquiler + rentArrayPDF[j].detalle_alquiler.precio_total * 1;
//     }

//     body.push(bodyDetail);
//   }

//   bodyDetail = [];

//   bodyDetail.push({
//     content: "",
//     colSpan: 4,
//   });

//   bodyDetail.push({
//     content: "Monto Total:",
//     styles: { halign: "left" },
//     fontStyle: "bold",
//   });

//   bodyDetail.push({
//     content: "s/. " + montoAlquiler.toFixed(2),
//     styles: { halign: "right" },
//   });

//   body.push(bodyDetail);

//   return { body: body };
// };

// export const downloadPDF = (body: Array<any>) => {
//   const doc = new jsPDF("portrait", "px", "a4") as jsPDFWithPlugin;

//   doc.setFontSize(20);
//   doc.text(160, 30, "Hotel Casa Blanca");
//   doc.setFontSize(10);
//   doc.text(195, 45, "Reporte Alquiler");

//   doc.autoTable({
//     theme: "striped",

//     columns: [
//       { header: "Colum1" },
//       { header: "Colum2" },
//       { header: "Colum3" },
//       { header: "Colum4" },
//       { header: "Colum5" },
//       { header: "Colum6" },
//     ],

//     head: [
//       [
//         "Cliente",
//         "Habitación",
//         "Turnos",
//         "Fecha Ingreso",
//         "Fecha Salida",
//         "Monto",
//       ],
//     ],
//     margin: { top: 50 },
//     body: body,
//   });

//   doc.save("pdf");
// };
