import React from "react";
import { useDispatch } from "react-redux";

import { updateProductFB } from "../../../redux/products/ProductsAction";
import { updateProductsDTO } from "../../../utils/requestDto";
import { productI } from "../../../utils/responseEntities";
import { PRODUCT_CATEGORY } from "../../../utils/utils";

import ModalDefault from "../../../components/sub-components/modal-default";
import SelectOption from "../../../components/sub-components/select-option";
import NumberInput from "../../../components/sub-components/input-number";
import InputField from "../../../components/sub-components/input-field";

interface ModalEditProductProps {
  product: productI;
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalEditProduct: React.FunctionComponent<
  ModalEditProductProps
> = ({
  product,
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalEditProductProps) => {
  let defaultValue: any;

  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);
  const [state, setState] = React.useState<productI>({
    key: "",
    nombre: "",
    categoria: "",
    precio_unitario: "",
    stock: defaultValue,
    stock_minimo: defaultValue,
    porc_aviso: defaultValue,
    local_cod: defaultValue,
  });

  const [errorNombre, setErrorNombre] = React.useState(false);
  const [errorCategoria, setErrorCategoria] = React.useState(false);
  const [errorPrecio, setErrorPrecio] = React.useState(false);
  const [errorStock, setErrorStock] = React.useState(false);
  const [errorStockMinimo, setErrorStockMinimo] = React.useState(false);
  const [errorPorcAviso, setErrorPorcAviso] = React.useState(false);

  const dispatch = useDispatch();

  const updateProductData = (name: any, value: any) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateProductData = () => {
    if (
      state.nombre === "" ||
      state.categoria === "" ||
      state.precio_unitario === "" ||
      state.stock === defaultValue ||
      state.stock_minimo === defaultValue ||
      state.porc_aviso === defaultValue
    ) {
      setErrorNombre(state.nombre === "" ? true : false);
      setErrorCategoria(state.categoria === "" ? true : false);
      setErrorPrecio(state.precio_unitario === "" ? true : false);
      setErrorStock(!state.stock ? true : false);
      setErrorStockMinimo(!state.stock_minimo ? true : false);
      setErrorPorcAviso(!state.porc_aviso ? true : false);
      return false;
    } else {
      return true;
    }
  };

  const editProduct = () => {
    setLoadingAction(true);
    //EDIT PRODUCT
    dispatch(
      updateProductFB(
        {
          product: {
            ...state,
            precio_unitario: Number(state.precio_unitario).toFixed(2),
          },
        } as updateProductsDTO,
        () => {
          setLoadingAction(false);
          if (successFun) successFun("Modificado satisfactoriamente");
          setModalShow(false);
        },
        () => {
          setLoadingAction(false);
          if (errorFun) errorFun("Ups! Algo salio mal");
          setModalShow(false);
        }
      )
    );
  };

  React.useEffect(() => {
    if (product) {
      setState((prev) => ({
        ...prev,
        key: product.key,
        nombre: product.nombre,
        categoria: product.categoria,
        precio_unitario: product.precio_unitario,
        stock: product.stock,
        stock_minimo: product.stock_minimo,
        porc_aviso: product.porc_aviso,
        local_cod: product.local_cod,
      }));
    }
  }, [product]);

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-products-edit"
      title={"Editar Producto"}
      showModalHeader={true}
      showModalFooter={true}
      showEdit={true}
      showCancel={true}
      editAction={() => {
        if (validateProductData()) {
          editProduct();
        }
      }}
    >
      <React.Fragment>
        <InputField
          key={"edit-product-nombre"}
          horizontal={false}
          placeholder={""}
          value={state.nombre}
          onChange={(value: any) => {
            updateProductData("nombre", value.toLowerCase());
            setErrorNombre(false);
          }}
          className={"edit-product-input"}
          viewTitle={true}
          viewErrorMessage={errorNombre}
          title={"Nombre"}
          errorMessage={"Incorrecto"}
          height={"!h-[40px]"}
          width={"!w-full"}
          isUppercase={true}
        ></InputField>
        <SelectOption
          key={"edit-product-categoria"}
          horizontal={false}
          className={"edit-product-select"}
          clickAction={(value: string) => {
            updateProductData("categoria", value);
            setErrorCategoria(false);
          }}
          optionArray={[
            PRODUCT_CATEGORY.BEBIDA,
            PRODUCT_CATEGORY.CARAMELO,
            PRODUCT_CATEGORY.CHOCOLATE,
            PRODUCT_CATEGORY.CIGARRO,
            PRODUCT_CATEGORY.CUIDADO,
            PRODUCT_CATEGORY.GALLETA,
            PRODUCT_CATEGORY.SNACK,
          ]}
          selected={state.categoria}
          viewTitle={true}
          viewErrorMessage={errorCategoria}
          title={"Categoria"}
          errorMessage={"Incorrecto"}
        ></SelectOption>
        <NumberInput
          key={"edit-product-precio"}
          horizontal={false}
          placeholder={""}
          value={state.precio_unitario}
          onChange={(value: any) => {
            updateProductData("precio_unitario", value);
            setErrorPrecio(false);
          }}
          className={"edit-product-input"}
          viewTitle={true}
          viewErrorMessage={errorPrecio}
          title={"Precio"}
          errorMessage={"Incorrecto"}
          isCurrency={true}
          decimals={2}
        ></NumberInput>
        <NumberInput
          key={"edit-product-stock"}
          horizontal={false}
          placeholder={""}
          value={state.stock}
          onChange={(value: any) => {
            updateProductData("stock", value);
            setErrorStock(false);
          }}
          className={"edit-product-input"}
          viewTitle={true}
          viewErrorMessage={errorStock}
          title={"Stock"}
          errorMessage={"Incorrecto"}
        ></NumberInput>
        <NumberInput
          key={"edit-product-minimo"}
          horizontal={false}
          placeholder={""}
          value={state.stock_minimo}
          onChange={(value: any) => {
            updateProductData("stock_minimo", value);
            setErrorStockMinimo(false);
          }}
          className={"edit-product-input"}
          viewTitle={true}
          viewErrorMessage={errorStockMinimo}
          title={"Stock Minimo"}
          errorMessage={"Incorrecto"}
        ></NumberInput>
        <NumberInput
          key={"edit-product-aviso"}
          horizontal={false}
          placeholder={""}
          value={state.porc_aviso}
          onChange={(value: any) => {
            updateProductData("porc_aviso", value);
            setErrorPorcAviso(false);
          }}
          className={"edit-product-input"}
          viewTitle={true}
          viewErrorMessage={errorPorcAviso}
          title={"Porcentaje"}
          errorMessage={"Incorrecto"}
          isPorcentage={true}
        ></NumberInput>
      </React.Fragment>
    </ModalDefault>
  );
};

export default ModalEditProduct;
