export const PRODUCTS_LOADING = "PRODUCTS_LOADING";
export const PRODUCTS_FAIL = "PRODUCTS_FAIL";
export const PRODUCTS_UPDATE_FILTER = "PRODUCTS_UPDATE_FILTER";
export const PRODUCTS_UPDATE_SELECTOR = "PRODUCTS_UPDATE_SELECTOR";
export const PRODUCTS_SEARCH = "PRODUCTS_SEARCH";
export const PRODUCTS_LIST = "PRODUCTS_LIST";

export interface ProductsLoading {
  type: typeof PRODUCTS_LOADING;
  payload: any;
}
export interface ProductsFail {
  type: typeof PRODUCTS_FAIL;
  payload: any;
}
export interface ProductsUpdateFilter {
  type: typeof PRODUCTS_UPDATE_FILTER;
  payload: any;
}
export interface ProductsUpdateSelector {
  type: typeof PRODUCTS_UPDATE_SELECTOR;
  payload: any;
}
export interface ProductsSearch {
  type: typeof PRODUCTS_SEARCH;
  payload: any;
}
export interface ProductsList {
  type: typeof PRODUCTS_LIST;
  payload: any;
}

export type ProductsDispatchTypes =
  | ProductsLoading
  | ProductsFail
  | ProductsUpdateFilter
  | ProductsUpdateSelector
  | ProductsSearch
  | ProductsList;
