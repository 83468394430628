import {
  GENERAL_PRODUCT,
  GENERAL_SELL,
  GENERAL_ROOM,
  GENERAL_RENT,
  GENERAL_BANNED,
  GENERAL_PREFERENT,
} from "./GeneralTypes";
import {
  query,
  onSnapshot,
  QuerySnapshot,
  FirestoreError,
  QueryDocumentSnapshot,
  collection,
  CollectionReference,
  where,
  QueryConstraint,
  orderBy,
} from "firebase/firestore";
import {
  bannedI,
  preferentI,
  productI,
  roomI,
} from "../../utils/responseEntities";
import { db } from "../../lib/init-firebase";
import { GetByLocalRequest } from "../../utils/requestDto";

const dbPathProducts = "/productos";
let productRef = collection(
  db,
  dbPathProducts
) as CollectionReference<productI>;

const dbPathRooms = "/habitaciones";
let roomRef = collection(db, dbPathRooms) as CollectionReference<roomI>;

const dbPathPreferents = "/preferentes";
let preferentRef = collection(
  db,
  dbPathPreferents
) as CollectionReference<preferentI>;

const dbPathBanned = "/prohibidos";
let bannedRef = collection(db, dbPathBanned) as CollectionReference<bannedI>;

export const getGeneralProductFB = (data: GetByLocalRequest) => {
  return (dispatch: any) => {
    let queryConst: Array<QueryConstraint> = [];
    queryConst.push(where("local_cod", "==", data.local));

    const q = query(productRef, ...queryConst);

    onSnapshot(
      q,
      (snapshot: QuerySnapshot<productI>) => {
        let auxProducts: Array<productI> = [];

        snapshot.docs.forEach((change: QueryDocumentSnapshot<productI>) => {
          // console.log(change.data());
          auxProducts.push({ key: change.id, ...change.data() });
        });

        dispatch({
          type: GENERAL_PRODUCT,
          payload: auxProducts,
        });
      },
      (error: FirestoreError) => {
        console.log("error on getGeneralProductFB:", error);
      }
    );
  };
};

export const getGeneralRoomFB = (data: GetByLocalRequest) => {
  return (dispatch: any) => {
    let queryConst: Array<QueryConstraint> = [];
    queryConst.push(where("local_cod", "==", data.local));

    const q = query(roomRef, ...queryConst);

    onSnapshot(
      q,
      (snapshot: QuerySnapshot<roomI>) => {
        let auxRooms: Array<roomI> = [];

        snapshot.docs.forEach((change: QueryDocumentSnapshot<roomI>) => {
          // console.log(change.data());
          auxRooms.push({ key: change.id, ...change.data() });
        });

        dispatch({
          type: GENERAL_ROOM,
          payload: auxRooms,
        });
      },
      (error: FirestoreError) => {
        console.log("error on getGeneralRoomFB:", error);
      }
    );
  };
};

export const getGeneralPreferentFB = () => {
  return (dispatch: any) => {
    const q = query(preferentRef);

    onSnapshot(
      q,
      (snapshot: QuerySnapshot<preferentI>) => {
        let auxClientsPreferent: Array<preferentI> = [];

        snapshot.docs.forEach((change: QueryDocumentSnapshot<preferentI>) => {
          // console.log(change.data());
          auxClientsPreferent.push({ key: change.id, ...change.data() });
        });

        dispatch({
          type: GENERAL_PREFERENT,
          payload: auxClientsPreferent,
        });
      },
      (error: FirestoreError) => {
        console.log("error on getGeneralPreferentFB:", error);
      }
    );
  };
};

export const getGeneralBannedFB = () => {
  return (dispatch: any) => {
    const q = query(bannedRef);

    onSnapshot(
      q,
      (snapshot: QuerySnapshot<bannedI>) => {
        let auxClientsBanned: Array<bannedI> = [];

        snapshot.docs.forEach((change: QueryDocumentSnapshot<bannedI>) => {
          // console.log(change.data());
          auxClientsBanned.push({ key: change.id, ...change.data() });
        });

        dispatch({
          type: GENERAL_BANNED,
          payload: auxClientsBanned,
        });
      },
      (error: FirestoreError) => {
        console.log("error on getGeneralBannedFB:", error);
      }
    );
  };
};
