import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { PRIVATE_DASHBOARD } from "./paths";

interface MyRouteProps extends RouteProps {
  Component: any;
  authenticated: boolean;
  allowByRole: boolean;
  redirect: string;
  rest?: any;
}

const PrivateRoute: React.FunctionComponent<MyRouteProps> = ({
  Component,
  authenticated,
  allowByRole,
  redirect,
  ...rest
}: any) => (
  <Route
    {...rest}
    render={(props: any) => {
      if (allowByRole) {
        return <Component {...props} history={props.history}></Component>;
      } else {
        return <Redirect to={redirect} />;
      }
    }}
  />
);

export default PrivateRoute;
