import store from "./Store";
import {
  SET_AUTHENTICATED,
  SET_AUTH_LOADING,
  SET_PERMISSIONS,
  SET_UNAUTHENTICATED,
} from "./auth/AuthTypes";
import { getAuthLicenseFB, getAuthUserFB } from "./auth/AuthAction";
import { rolI, settingsI, userI } from "../utils/responseEntities";
import {
  fetchDashboardDTO,
  fetchSettingsDTO,
  fetchUserByEmailDTO,
} from "../utils/requestDto";
import { getFirstRentFB, getFirstSellFB } from "./dashboard/DashboardAction";
import { USER_TYPE } from "../utils/utils";

export const setAuth = (access_token: string) => {
  localStorage.setItem("access_token", access_token);
};

export const Logout = () => {
  localStorage.removeItem("access_token");
};

export const CheckAuthentication = async () => {
  const authToken = localStorage.access_token;
  store.dispatch({
    type: SET_AUTH_LOADING,
    authLoading: true,
  });

  if (authToken) {
    const authToken = localStorage.access_token;
    const decodedToken: userI = JSON.parse(authToken);

    store.dispatch(
      getAuthUserFB(
        { email: decodedToken.correo } as fetchUserByEmailDTO,
        (userLogged: userI) => {
          if (!decodedToken.superAdmin)
            store.dispatch(
              getAuthLicenseFB(
                { local: decodedToken.local_cod } as fetchSettingsDTO,
                (licenseLogged: settingsI) => {
                  let auxPermissions: Array<string> = [];
                  auxPermissions =
                    licenseLogged.roles.find((rol: rolI) => {
                      return rol.rol === userLogged.rol;
                    })?.permission || [];

                  // if (
                  //   auxPermissions.includes("dashboard") ||
                  //   userLogged?.rol === USER_TYPE.DESARROLLADOR
                  // ) {
                  //   store.dispatch(
                  //     getFirstRentFB(
                  //       {
                  //         year: new Date().getFullYear(),
                  //         local: licenseLogged?.local_cod,
                  //       } as fetchDashboardDTO,
                  //       () => {
                  //         store.dispatch(
                  //           getFirstSellFB(
                  //             {
                  //               year: new Date().getFullYear(),
                  //               local: licenseLogged?.local_cod,
                  //             } as fetchDashboardDTO,
                  //             () => {
                  //               store.dispatch({
                  //                 type: SET_PERMISSIONS,
                  //                 payload: auxPermissions,
                  //               });
                  //               store.dispatch({
                  //                 type: SET_AUTHENTICATED,
                  //                 superAdmin: false,
                  //                 authenticated: true,
                  //               });
                  //             }
                  //           )
                  //         );
                  //       }
                  //     )
                  //   );
                  // } else {
                  store.dispatch({
                    type: SET_PERMISSIONS,
                    payload: auxPermissions,
                  });
                  store.dispatch({
                    type: SET_AUTHENTICATED,
                    superAdmin: false,
                    authenticated: true,
                  });
                  // }
                }
              )
            );
          else {
            store.dispatch({
              type: SET_AUTHENTICATED,
              superAdmin: true,
              authenticated: true,
            });
          }
        },
        () => {
          Logout();
          store.dispatch({
            type: "USER_LOGGED_OUT",
          });
          store.dispatch({
            type: SET_UNAUTHENTICATED,
          });
          store.dispatch({
            type: SET_AUTH_LOADING,
            authLoading: false,
          });
        }
      )
    );
  } else {
    Logout();
    store.dispatch({
      type: "USER_LOGGED_OUT",
    });
    store.dispatch({
      type: SET_UNAUTHENTICATED,
    });
    store.dispatch({
      type: SET_AUTH_LOADING,
      authLoading: false,
    });
  }
};
