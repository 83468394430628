import React from "react";
import { RouteProps } from "react-router-dom";
import { Calendar } from "primereact/calendar";
import "../style.scss";
interface InputDateProps extends RouteProps {
  horizontal?: boolean;
  className?: string;
  placeholder: string;
  value: any;
  disabled?: boolean;
  onChange: any;
  viewTitle?: boolean;
  viewErrorMessage?: boolean;
  title?: any;
  errorMessage?: any;
  width?: string;
  height?: string;
}

const InputDate: React.FunctionComponent<InputDateProps> = ({
  horizontal = true,
  className,
  placeholder,
  value,
  disabled = false,
  onChange,
  viewTitle = false,
  viewErrorMessage = false,
  title = "",
  errorMessage = "",
  width,
  height,
}: any) => {
  return (
    <div
      className={
        horizontal
          ? "input-date-horizontal " + className
          : "input-date-vertical " + className
      }
    >
      {viewTitle && <div className="input-date-title">{title}</div>}
      <Calendar
        id="basic"
        appendTo={document.body}
        className={`${viewErrorMessage ? "error-mode" : ""} ${width} ${height}`}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        dateFormat={"dd/mm/yy"}
        onChange={(data: any) => {
          onChange(data.value);
        }}
      />
      {viewErrorMessage && (
        <div className="input-date-error">{errorMessage}</div>
      )}
    </div>
  );
};

export default InputDate;
