import {
  bannedI,
  preferentI,
  productI,
  rentI,
  roomI,
  sellI,
} from "../../utils/responseEntities";

export const GENERAL_PREFERENT = "GENERAL_PREFERENT";
export const GENERAL_BANNED = "GENERAL_BANNED";

export const GENERAL_PRODUCT = "GENERAL_PRODUCT";
export const GENERAL_SELL = "GENERAL_SELL";

export const GENERAL_ROOM = "GENERAL_ROOM";
export const GENERAL_RENT = "GENERAL_RENT";

export interface GeneralPreferent {
  type: typeof GENERAL_PREFERENT;
  payload: Array<preferentI>;
}
export interface GeneralBanned {
  type: typeof GENERAL_BANNED;
  payload: Array<bannedI>;
}
export interface GeneralProduct {
  type: typeof GENERAL_PRODUCT;
  payload: Array<productI>;
}
export interface GeneralSell {
  type: typeof GENERAL_SELL;
  payload: Array<sellI>;
}
export interface GeneralRoom {
  type: typeof GENERAL_ROOM;
  payload: Array<roomI>;
}
export interface GeneralRent {
  type: typeof GENERAL_RENT;
  payload: Array<rentI>;
}

export type GeneralDispatchTypes =
  | GeneralPreferent
  | GeneralBanned
  | GeneralProduct
  | GeneralSell
  | GeneralRoom
  | GeneralRent;
