import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";

import { topPreferentFB } from "../../../redux/preferent/PreferentAction";
import { preferentI, settingsI } from "../../../utils/responseEntities";
import { topPreferentsDTO } from "../../../utils/requestDto";

import ModalDefault from "../../../components/sub-components/modal-default";

interface ModalTopPreferentProps {
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalTopPreferent: React.FunctionComponent<
  ModalTopPreferentProps
> = ({
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalTopPreferentProps) => {
  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);

  const settingsAuth: settingsI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.settings
  );
  const preferentGeneral: Array<preferentI> = useSelector(
    (globalState: RootStore) => globalState.general.preferentList
  );

  const dispatch = useDispatch();

  const topPreferent = () => {
    setLoadingAction(true);
    //TOP 10 PREFERENT
    dispatch(
      topPreferentFB(
        {
          preferents: preferentGeneral,
          time: settingsAuth?.preferent_periodo,
          qty: settingsAuth?.preferent_cantidad,
        } as topPreferentsDTO,
        () => {
          setLoadingAction(false);
          if (successFun)
            successFun(
              "Top " + settingsAuth?.preferent_cantidad + " satisfactorio"
            );
          setModalShow(false);
        }
      )
    );
  };

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-preferent-top"
      title={`Agregar Top ${settingsAuth?.preferent_cantidad}`}
      showModalHeader={true}
      showModalFooter={true}
      showAdd={true}
      showCancel={true}
      addAction={() => {
        topPreferent();
      }}
    >
      <p>
        {`¿Está seguro de agregar al `}
        <strong>{`TOP ${settingsAuth?.preferent_cantidad}`}</strong>
        {`?`}
      </p>
    </ModalDefault>
  );
};

export default ModalTopPreferent;
