import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { capitalizeWords } from "../../utils/utils";

interface SelectOptionProps {
  horizontal?: boolean;
  className?: string;
  clickAction: any;
  optionArray: any;
  selected: any;
  viewTitle?: boolean;
  viewErrorMessage?: boolean;
  title?: any;
  errorMessage?: any;
}

const SelectOption: React.FunctionComponent<SelectOptionProps> = ({
  horizontal = true,
  className,
  clickAction,
  optionArray,
  selected,
  viewTitle = false,
  viewErrorMessage = false,
  title = "",
  errorMessage = "",
}: any) => {
  const [viewOptions, setViewOptions] = useState(false);

  return (
    <div
      className={
        horizontal
          ? "select-box-horizontal " + className
          : "select-box-vertical " + className
      }
    >
      {viewTitle && <div className="select-box-title">{title}</div>}
      <div
        className={
          viewErrorMessage
            ? "select-option-subcomponent error-mode"
            : "select-option-subcomponent"
        }
        onClick={() => {
          setViewOptions(!viewOptions);
        }}
      >
        <div className="selected-option">
          {selected ? capitalizeWords(selected) : "--Selecciona--"}
        </div>
        <IoIosArrowDown></IoIosArrowDown>
        {viewOptions && (
          <div className="option-list">
            {optionArray.map((option: any) => (
              <div
                key={option}
                className={
                  option === selected
                    ? "option-element active"
                    : "option-element"
                }
                onClick={() => {
                  clickAction(option);
                }}
              >
                {capitalizeWords(option)}
              </div>
            ))}
          </div>
        )}
        {viewOptions && (
          <div
            className="hidden-wall"
            onClick={() => {
              setViewOptions(!viewOptions);
            }}
          ></div>
        )}
      </div>
      {viewErrorMessage && (
        <div className="select-box-error">{errorMessage}</div>
      )}
    </div>
  );
};

export default SelectOption;
