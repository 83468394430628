import { BANNED_LOADING, BANNED_FAIL, BANNED_LIST } from "./BannedTypes";
import {
  CollectionReference,
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  QuerySnapshot,
  FirestoreError,
  QueryConstraint,
  QueryDocumentSnapshot,
  deleteDoc,
  doc,
  updateDoc,
  getDocs,
} from "firebase/firestore";
import { bannedI } from "../../utils/responseEntities";
import { db } from "../../lib/init-firebase";
import {
  addBannedDTO,
  fetchBannedDTO,
  updateBannedDTO,
  deleteBannedDTO,
  GetByDniRequest,
} from "../../utils/requestDto";
import { TT_FILTER_LIST } from "../../utils/utils";

const dbPathBanned = "/prohibidos";
let bannedRef = collection(db, dbPathBanned) as CollectionReference<bannedI>;

export const getBannedListFB = (
  data: fetchBannedDTO,
  funDone?: any,
  funError?: any
) => {
  console.log("getBannedListFB DATA:", data);
  return (dispatch: any) => {
    dispatch({ type: BANNED_LOADING });

    let queryConst: Array<QueryConstraint> = [];

    if (data.type === TT_FILTER_LIST.NOMBRE) {
      queryConst.push(where("nombre", ">=", data.search.toLowerCase()));
      queryConst.push(where("nombre", "<=", data.search.toLowerCase() + "~"));
    } else if (data.type === TT_FILTER_LIST.DNI) {
      queryConst.push(where("dni", ">=", data.search));
      queryConst.push(where("dni", "<=", data.search + "~"));
    }

    const q = query(bannedRef, ...queryConst);

    onSnapshot(
      q,
      (snapshot: QuerySnapshot<bannedI>) => {
        let auxClientsBanned: Array<bannedI> = [];

        snapshot.docs.forEach((change: QueryDocumentSnapshot<bannedI>) => {
          // console.log(change.data());
          auxClientsBanned.push({ key: change.id, ...change.data() });
        });

        dispatch({ type: BANNED_LIST, payload: auxClientsBanned });
        dispatch({ type: BANNED_FAIL });

        if (funDone) {
          funDone();
        }
      },
      (error: FirestoreError) => {
        console.log("error on getBannedListFB:", error);
        dispatch({ type: BANNED_FAIL });

        if (funError) {
          funError();
        }
      }
    );
  };
};

export const addBannedFB = (
  data: addBannedDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("addBannedFB DATA:", data);
    await addDoc(bannedRef, data.banned)
      .catch((error: any) => {
        console.log("error on addBannedFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("addBannedFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const updateBannedFB = (
  data: updateBannedDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("updateBannedFB DATA:", data);
    await updateDoc(doc(db, dbPathBanned + "/" + data.banned.key), {
      nombre: data.banned.nombre,
      dni: data.banned.dni,
      categoria: data.banned.categoria,
      fecha_nacimiento: data.banned.fecha_nacimiento,
    })
      .catch((error: any) => {
        console.log("error on updateBannedFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("updateBannedFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const deleteBannedFB = (
  data: deleteBannedDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("deleteBannedFB DATA:", data);
    await deleteDoc(doc(db, dbPathBanned, data.key))
      .catch((error: any) => {
        console.log("error on deleteBannedFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("deleteBannedFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const checkDniBannedFB = (data: GetByDniRequest, funDone?: any) => {
  return async (dispatch: any) => {
    console.log("checkDniBannedFB DATA:", data);
    let queryConst: Array<QueryConstraint> = [];

    queryConst.push(where("dni", "==", data.dni));

    const q = query(bannedRef, ...queryConst);

    const querySnapshot = await getDocs(q);

    let auxBanned: Array<bannedI> = [];
    querySnapshot.forEach((doc: QueryDocumentSnapshot<bannedI>) => {
      auxBanned.push({ key: doc.id, ...doc.data() });
    });

    if (funDone) {
      funDone(auxBanned.length > 0);
    }
  };
};
