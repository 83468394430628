import {
  addDoc,
  collection,
  CollectionReference,
  doc,
  increment,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../lib/init-firebase";
import { addSellDTO, reduceStockDTO } from "../../utils/requestDto";
import { productI, sellI } from "../../utils/responseEntities";

const dbPathProducts = "/productos";
let productRef = collection(
  db,
  dbPathProducts
) as CollectionReference<productI>;

const dbPathSell = "/ventas";
let sellRef = collection(db, dbPathSell) as CollectionReference<sellI>;

export const reduceStockFB = (
  data: reduceStockDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("reduceStockFB DATA:", data);
    await updateDoc(doc(db, dbPathProducts + "/" + data.key), {
      stock: increment(data.reduceStock),
    })
      .catch((error: any) => {
        console.log("error on reduceStockFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("reduceStockFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};

export const registerSellFB = (
  data: addSellDTO,
  funDone?: any,
  funError?: any
) => {
  return async () => {
    console.log("registerSellFB DATA:", data);
    await addDoc(sellRef, data.sell)
      .catch((error: any) => {
        console.log("error on registerSellFB:", error);
        if (funError) {
          funError();
        }
      })
      .then(
        () => {
          if (funDone) {
            funDone();
          }
        },
        (reason: any) => {
          console.log("registerSellFB onrejected", reason);
          if (funError) {
            funError();
          }
        }
      );
  };
};
