import React from "react";
import { BrowserRouter } from "react-router-dom";
import SwitchRoute from "./utils/switchRoute";
import { Provider } from "react-redux";
import Store from "./redux/Store";

import { CheckAuthentication, Logout } from "./redux/CheckAuth";
import { SET_UNAUTHENTICATED } from "./redux/auth/AuthTypes";

const App: React.FunctionComponent = () => {
  React.useEffect(() => {
    CheckAuthentication();
  }, []);

  window.addEventListener("storage", function (event) {
    if (event.key === "logoutEvent") {
      Logout();
      Store.dispatch({
        type: SET_UNAUTHENTICATED,
      });
    }
  });

  return (
    <div className="App">
      <Provider store={Store}>
        <BrowserRouter>
          <SwitchRoute></SwitchRoute>
        </BrowserRouter>
      </Provider>
    </div>
  );
};

export default App;
