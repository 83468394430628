export const BANNED_LOADING = "BANNED_LOADING";
export const BANNED_FAIL = "BANNED_FAIL";
export const BANNED_UPDATE_FILTER = "BANNED_UPDATE_FILTER";
export const BANNED_SEARCH = "BANNED_SEARCH";
export const BANNED_LIST = "BANNED_LIST";

export interface BannedLoading {
  type: typeof BANNED_LOADING;
}
export interface BannedFail {
  type: typeof BANNED_FAIL;
  payload: any;
}
export interface BannedUpdateFilter {
  type: typeof BANNED_UPDATE_FILTER;
  payload: any;
}
export interface BannedSearch {
  type: typeof BANNED_SEARCH;
  payload: any;
}
export interface BannedList {
  type: typeof BANNED_LIST;
  payload: any;
}
export type BannedDispatchTypes =
  | BannedLoading
  | BannedFail
  | BannedUpdateFilter
  | BannedSearch
  | BannedList;
