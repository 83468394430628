import React from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { GoBell } from "react-icons/go";
import { FiAlertTriangle } from "react-icons/fi";
import { useSelector } from "react-redux";
import { RootStore } from "../../redux/Store";
import { productI } from "../../utils/responseEntities";
import { capitalizeWords } from "../../utils/utils";

const ProductsOverlay: React.FunctionComponent = () => {
  const productRO: Array<productI> = useSelector(
    (globalState: RootStore) => globalState.general.productList
  );

  const [actualList, setActualList] = React.useState<Array<productI>>([]);

  const op = React.useRef<OverlayPanel>(null);

  React.useEffect(() => {
    let auxActualList: Array<productI> = [];

    auxActualList = productRO.filter((product: productI) => {
      return (product.stock * 100) / product.stock_minimo <= product.porc_aviso;
    });

    setActualList(auxActualList);
  }, [productRO]);

  return (
    <React.Fragment>
      <div
        className="product-resume"
        onClick={(e: any) => {
          op.current?.toggle(e);
        }}
        aria-haspopup
        aria-controls="overlay_panel"
      >
        <GoBell
          className={`${actualList.length > 0 ? "fill-[#ff3b3bb3]" : ""}`}
        ></GoBell>
      </div>
      <OverlayPanel
        ref={op}
        id="overlay_panel"
        className="products-overlaypanel"
      >
        <div className="product-title">
          <p>{`Hay [${actualList.length}] productos con BAJO stock`}</p>
        </div>
        <div className="product-list">
          {
            // eslint-disable-next-line array-callback-return
            actualList.map((product: productI) => {
              return (
                <div className="product-row" key={product.key}>
                  <FiAlertTriangle></FiAlertTriangle>
                  <p>{`${capitalizeWords(product.nombre)} [${
                    product.stock
                  }]`}</p>
                </div>
              );
            })
          }
        </div>
      </OverlayPanel>
    </React.Fragment>
  );
};

export default ProductsOverlay;
