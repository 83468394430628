import {
  FLOOR_LIST,
  ROOM_CONTENT,
  ROOM_STATUS,
  ROOM_TYPE,
} from "../../utils/utils";
import {
  ROOMS_RENT_LOADING,
  ROOMS_RENT_FAIL,
  ROOMS_RENT_UPDATE_TYPE_SELECTOR,
  ROOMS_RENT_UPDATE_STATE_FILTER,
  ROOMS_RENT_UPDATE_FLOOR_SELECTOR,
  ROOMS_RENT_UPDATE_CONTENT_FILTER,
  RoomsRentDispatchTypes,
} from "./RoomsRentTypes";

interface RoomsRentState {
  loading: boolean;
  selectorRoom: ROOM_TYPE;
  selectOptionRoom: any[];
  selectorFloor: FLOOR_LIST;
  selectOptionFloor: any[];
  filterState: Array<ROOM_STATUS>;
  filterContent: Array<ROOM_CONTENT>;
}

const defaultState: RoomsRentState = {
  loading: false,
  selectorRoom: ROOM_TYPE.TODOS,
  selectOptionRoom: [
    ROOM_TYPE.TODOS,
    ROOM_TYPE.SIMPLE,
    ROOM_TYPE.SIMPLE_TV,
    ROOM_TYPE.SMART,
    ROOM_TYPE.SMART_SILLON,
    ROOM_TYPE.LED,
    ROOM_TYPE.LED_SILLON,
    ROOM_TYPE.JACUZZI,
    ROOM_TYPE.DOBLE,
  ],
  selectorFloor: FLOOR_LIST.TODOS,
  selectOptionFloor: [
    FLOOR_LIST.TODOS,
    FLOOR_LIST.PRIMERO,
    FLOOR_LIST.SEGUNDO,
    FLOOR_LIST.TERCERO,
    FLOOR_LIST.CUARTO,
  ],
  filterState: [],
  filterContent: [],
};

const roomsRentReducer = (
  state: RoomsRentState = defaultState,
  action: RoomsRentDispatchTypes
): RoomsRentState => {
  switch (action.type) {
    case ROOMS_RENT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ROOMS_RENT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case ROOMS_RENT_UPDATE_TYPE_SELECTOR:
      return {
        ...state,
        selectorRoom: action.payload,
      };
    case ROOMS_RENT_UPDATE_FLOOR_SELECTOR:
      return {
        ...state,
        selectorFloor: action.payload,
      };
    case ROOMS_RENT_UPDATE_STATE_FILTER:
      return {
        ...state,
        filterState: action.payload,
      };
    case ROOMS_RENT_UPDATE_CONTENT_FILTER:
      return {
        ...state,
        filterContent: action.payload,
      };
    default:
      return state;
  }
};

export default roomsRentReducer;
