import {
  fetchSettingsDTO,
  fetchUserByEmailDTO,
  GetByIdRequest,
  LoginRequest,
} from "../../utils/requestDto";
import { settingsI, userI } from "../../utils/responseEntities";
import { db } from "../../lib/init-firebase";
import {
  CollectionReference,
  collection,
  query,
  where,
  onSnapshot,
  QuerySnapshot,
  FirestoreError,
  QueryConstraint,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { SET_AUTH, SET_SETTINGS, SET_USER } from "./AuthTypes";

const dbPath = "/configuracion";
let settingsRef = collection(db, dbPath) as CollectionReference<settingsI>;

const dbPathUsers = "/usuarios";
let userRef = collection(db, dbPathUsers) as CollectionReference<userI>;

export const doLogin = (data: LoginRequest, fun?: any, errorFun?: any) => {
  console.log("dataLogin", data);
  return (dispatch: any) => {
    let queryConst: Array<QueryConstraint> = [];

    queryConst.push(where("correo", "==", data.email.toLowerCase()));
    queryConst.push(where("password", "==", data.password));

    const q = query(userRef, ...queryConst);

    onSnapshot(
      q,
      (snapshot: QuerySnapshot<userI>) => {
        let auxUser: userI = {} as userI;

        snapshot.docs.forEach((change: QueryDocumentSnapshot<userI>) => {
          // console.log(change.data());
          auxUser = { key: change.id, ...change.data() };
        });

        if (!auxUser.key) {
          if (fun) fun(false);
        } else {
          dispatch({
            type: SET_AUTH,
            access_token: JSON.stringify(auxUser),
          });
          if (fun) fun(true);
        }
      },
      (error: FirestoreError) => {
        console.log("error get user list", error);
        if (errorFun) errorFun();
      }
    );
  };
};

export const getAuthUserFB = (
  data: fetchUserByEmailDTO,
  fun?: any,
  errorFun?: any
) => {
  console.log("dataAuthUser", data);
  return (dispatch: any) => {
    let queryConst: Array<QueryConstraint> = [];

    queryConst.push(where("correo", "==", data.email));

    const q = query(userRef, ...queryConst);

    onSnapshot(
      q,
      (snapshot: QuerySnapshot<userI>) => {
        let auxUser: userI = {} as userI;

        snapshot.docs.forEach((change: QueryDocumentSnapshot<userI>) => {
          // console.log(change.data());
          auxUser = { key: change.id, ...change.data() };
        });

        if (!auxUser.key) {
          // if (fun) fun(false);
        } else {
          dispatch({
            type: SET_USER,
            payload: auxUser,
          });
          if (fun) fun(auxUser);
        }
      },
      (error: FirestoreError) => {
        console.log("error get user list", error);
        if (errorFun) errorFun();
      }
    );
  };
};

export const getAuthLicenseFB = (
  data: fetchSettingsDTO,
  fun?: any,
  errorFun?: any
) => {
  console.log("dataAuthLicense", data);
  return (dispatch: any) => {
    let queryConst: Array<QueryConstraint> = [];

    queryConst.push(where("local_cod", "==", data.local));

    const q = query(settingsRef, ...queryConst);

    onSnapshot(
      q,
      (snapshot: QuerySnapshot<settingsI>) => {
        let auxSettings: settingsI = {} as settingsI;

        snapshot.docs.forEach((change: QueryDocumentSnapshot<settingsI>) => {
          console.log(change.data());
          auxSettings = { key: change.id, ...change.data() };
        });

        if (!auxSettings.key) {
          // if (fun) fun(false);
        } else {
          dispatch({
            type: SET_SETTINGS,
            payload: auxSettings,
          });
          if (fun) fun(auxSettings);
        }
      },
      (error: FirestoreError) => {
        console.log("error get user list", error);
        if (errorFun) errorFun();
      }
    );
  };
};
