import { settingsI, userI } from "../../utils/responseEntities";
import { setAuth } from "../CheckAuth";
import {
  SET_WINDOW_SIZE,
  SET_AUTH,
  SET_AUTH_LOADING,
  SET_UNAUTHENTICATED,
  SET_AUTHENTICATED,
  SET_USER,
  SET_SETTINGS,
  AuthDispatchTypes,
  SET_PERMISSIONS,
} from "./AuthTypes";

const hasWindow = typeof window !== "undefined";
const getWindowDimensions = () => {
  const width = hasWindow ? window.innerWidth : null;
  if (width && width < 1200) {
    return "MOBILE";
  } else {
    return "WEB";
  }
};

interface AuthState {
  windowSize: "WEB" | "MOBILE";
  authLoading: boolean;
  authenticated: boolean;
  superAdmin: boolean;
  user?: userI;
  settings?: settingsI;
  permissions: Array<string>;
}

const defaultState: AuthState = {
  windowSize: getWindowDimensions(),
  authLoading: false,
  authenticated: false,
  superAdmin: false,
  user: undefined,
  settings: undefined,
  permissions: [],
};

const authReducer = (
  state: AuthState = defaultState,
  action: AuthDispatchTypes
): AuthState => {
  switch (action.type) {
    case SET_WINDOW_SIZE:
      return {
        ...state,
        windowSize: action.payload,
      };
    case SET_AUTH:
      setAuth(action.access_token);
      return {
        ...state,
        authLoading: false,
      };
    case SET_AUTH_LOADING:
      return {
        ...state,
        authLoading: action.authLoading,
      };
    case SET_UNAUTHENTICATED:
      return {
        ...state,
        authLoading: false,
        authenticated: false,
        superAdmin: false,
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        authLoading: false,
        authenticated: action.authenticated,
        superAdmin: action.superAdmin,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
