import { filterI, TT_FILTER_LIST } from "../../utils/utils";
import { preferentI } from "../../utils/responseEntities";
import {
  PREFERENT_LOADING,
  PREFERENT_FAIL,
  PREFERENT_UPDATE_FILTER,
  PREFERENT_SEARCH,
  PREFERENT_LIST,
  //=====DISPATCH TYPES
  PreferentDispatchTypes,
} from "./PreferentTypes";

interface PreferentState {
  loading: boolean;
  filters: Array<filterI>;
  search: string;
  preferents: Array<preferentI>;
}

const defaultState: PreferentState = {
  loading: false,
  filters: [
    { name: TT_FILTER_LIST.NOMBRE, value: true },
    { name: TT_FILTER_LIST.AP_PATERNO, value: false },
    { name: TT_FILTER_LIST.AP_MATERNO, value: false },
    { name: TT_FILTER_LIST.DNI, value: false },
  ],
  search: "",
  preferents: [],
};

const preferentReducer = (
  state: PreferentState = defaultState,
  action: PreferentDispatchTypes
): PreferentState => {
  switch (action.type) {
    //=====CLIENTS PREFERENTS VIEW
    case PREFERENT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PREFERENT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case PREFERENT_UPDATE_FILTER:
      return {
        ...state,
        filters: action.payload,
      };
    case PREFERENT_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case PREFERENT_LIST:
      return {
        ...state,
        preferents: action.payload,
      };
    default:
      return state;
  }
};

export default preferentReducer;
