import React, { Suspense } from "react";
import reportWebVitals from "./reportWebVitals";
import ReactDOM from "react-dom";
import "./index.scss";
import "./index.css";
import App from "./App";

import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(
  <Suspense fallback={null}>
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode> */}
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
