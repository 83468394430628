import React from "react";
import { Router, Redirect, Switch } from "react-router-dom";

import history from "./history";
import {
  /*DASHBOARD*/
  PRIVATE_DASHBOARD,
  /*PRODUCTS*/
  PRIVATE_PRODUCTS,
  PRIVATE_PRODUCTS_HISTORY,
  PRIVATE_PRODUCTS_SELL,
  /*ROOMS*/
  PRIVATE_ROOMS,
  PRIVATE_ROOMS_HISTORY,
  PRIVATE_ROOMS_RENT,
  PRIVATE_ROOMS_SEARCH,
  PRIVATE_ROOMS_FORM,
  /*CLIENTS*/
  PRIVATE_CLIENTS_PREFERENT,
  PRIVATE_CLIENTS_BANNED,
  /*USERS*/
  PRIVATE_USERS,
  /*SETTINGS*/
  PRIVATE_SETTINGS_GENERAL,
  PRIVATE_SETTINGS_PERMISSION,
  PRIVATE_SETTINGS_LOCAL,
  PUBLIC_LOGIN,
} from "./paths";

import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
import Products from "../pages/products";
import ProductsHistory from "../pages/productsHistory";
import ProductsSell from "../pages/productsSell";
import Rooms from "../pages/rooms";
import RoomsHistory from "../pages/roomsHistory";
import RoomsRent from "../pages/roomsRent";
import RoomsSearch from "../pages/roomsSearch";
import RoomsForm from "../pages/roomsForm";
import ClientsPreferent from "../pages/clientsPreferent";
import ClientsBanned from "../pages/clientsBanned";
import Users from "../pages/users";
import SettingsGeneral from "../pages/settingsGeneral";
import SettingsRol from "../pages/settingsRol";

import PrincipalLayout from "../components/PrincipalLayout";
import PrivateRoute from "./privateRoute";
import { useSelector } from "react-redux";
import { RootStore } from "../redux/Store";
import Loader from "../components/sub-components/loader";
import { userI } from "./responseEntities";
import { USER_TYPE } from "./utils";

const SwitcherRoute: React.FunctionComponent = () => {
  const authLoading: boolean = useSelector(
    (globalState: RootStore) => globalState.auth.authLoading
  );
  const authenticated: boolean = useSelector(
    (globalState: RootStore) => globalState.auth.authenticated
  );
  const authPermissions: Array<string> = useSelector(
    (globalState: RootStore) => globalState.auth.permissions
  );
  const authUser: userI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.user
  );

  const getRedirect = () => {
    // if (
    //   authPermissions.includes("dashboard") ||
    //   authUser?.rol === USER_TYPE.DESARROLLADOR
    // ) {
    //   return PRIVATE_DASHBOARD;
    // } else
    if (authPermissions.includes("product.general")) {
      return PRIVATE_PRODUCTS;
    } else if (authPermissions.includes("product.history")) {
      return PRIVATE_PRODUCTS_HISTORY;
    } else if (authPermissions.includes("product.sell")) {
      return PRIVATE_PRODUCTS_SELL;
    } else if (authPermissions.includes("room.general")) {
      return PRIVATE_ROOMS;
    } else if (authPermissions.includes("room.history")) {
      return PRIVATE_ROOMS_HISTORY;
    } else if (authPermissions.includes("room.rent")) {
      return PRIVATE_ROOMS_RENT;
    } else if (authPermissions.includes("room.search")) {
      return PRIVATE_ROOMS_SEARCH;
    } else if (authPermissions.includes("room.preferent")) {
      return PRIVATE_CLIENTS_PREFERENT;
    } else if (authPermissions.includes("client.banned")) {
      return PRIVATE_CLIENTS_BANNED;
    } else if (authPermissions.includes("room.search")) {
      return PRIVATE_USERS;
    } else {
      return PRIVATE_PRODUCTS;
      // return PRIVATE_DASHBOARD;
    }
  };

  return authLoading ? (
    <Loader
      hasDashboard={
        authPermissions.includes("dashboard") ||
        authUser?.rol === USER_TYPE.DESARROLLADOR
      }
    ></Loader>
  ) : (
    <Router history={history}>
      {/* HERE WE CHECK IF USER IS AUTHENTICATED */}
      {authenticated && (
        <PrincipalLayout history={history}>
          <Switch>
            {/* ***** DASHBOARD ***** */}
            {/* {(authPermissions.includes("dashboard") ||
              authUser?.rol === USER_TYPE.DESARROLLADOR) && (
              <PrivateRoute
                exact
                path={PRIVATE_DASHBOARD}
                authenticated={authenticated}
                Component={Dashboard}
                allowByRole={true}
                redirect={getRedirect()}
              />
            )} */}
            {/* ***** PRODUCTS ***** */}
            {(authPermissions.includes("product.general") ||
              authUser?.rol === USER_TYPE.DESARROLLADOR) && (
              <PrivateRoute
                exact
                path={PRIVATE_PRODUCTS}
                authenticated={authenticated}
                Component={Products}
                allowByRole={true}
                redirect={getRedirect()}
              />
            )}
            {(authPermissions.includes("product.history") ||
              authUser?.rol === USER_TYPE.DESARROLLADOR) && (
              <PrivateRoute
                exact
                path={PRIVATE_PRODUCTS_HISTORY}
                authenticated={authenticated}
                Component={ProductsHistory}
                allowByRole={true}
                redirect={getRedirect()}
              />
            )}
            {(authPermissions.includes("product.sell") ||
              authUser?.rol === USER_TYPE.DESARROLLADOR) && (
              <PrivateRoute
                exact
                path={PRIVATE_PRODUCTS_SELL}
                authenticated={authenticated}
                Component={ProductsSell}
                allowByRole={true}
                redirect={getRedirect()}
              />
            )}
            {/* ***** ROOMS ***** */}
            {(authPermissions.includes("room.general") ||
              authUser?.rol === USER_TYPE.DESARROLLADOR) && (
              <PrivateRoute
                exact
                path={PRIVATE_ROOMS}
                authenticated={authenticated}
                Component={Rooms}
                allowByRole={true}
                redirect={getRedirect()}
              />
            )}
            {(authPermissions.includes("room.history") ||
              authUser?.rol === USER_TYPE.DESARROLLADOR) && (
              <PrivateRoute
                exact
                path={PRIVATE_ROOMS_HISTORY}
                authenticated={authenticated}
                Component={RoomsHistory}
                allowByRole={true}
                redirect={getRedirect()}
              />
            )}
            {(authPermissions.includes("room.rent") ||
              authUser?.rol === USER_TYPE.DESARROLLADOR) && (
              <PrivateRoute
                exact
                path={PRIVATE_ROOMS_RENT}
                authenticated={authenticated}
                Component={RoomsRent}
                allowByRole={true}
                redirect={getRedirect()}
              />
            )}
            {(authPermissions.includes("room.search") ||
              authUser?.rol === USER_TYPE.DESARROLLADOR) && (
              <PrivateRoute
                exact
                path={PRIVATE_ROOMS_SEARCH}
                authenticated={authenticated}
                Component={RoomsSearch}
                allowByRole={true}
                redirect={getRedirect()}
              />
            )}
            {(authPermissions.includes("room.rent") ||
              authUser?.rol === USER_TYPE.DESARROLLADOR) && (
              <PrivateRoute
                exact
                path={PRIVATE_ROOMS_FORM}
                authenticated={authenticated}
                Component={RoomsForm}
                allowByRole={true}
                redirect={getRedirect()}
              />
            )}
            {/* ***** CLIENTS ***** */}
            {(authPermissions.includes("client.preferent") ||
              authUser?.rol === USER_TYPE.DESARROLLADOR) && (
              <PrivateRoute
                path={PRIVATE_CLIENTS_PREFERENT}
                authenticated={authenticated}
                Component={ClientsPreferent}
                allowByRole={true}
                redirect={getRedirect()}
              />
            )}
            {(authPermissions.includes("client.banned") ||
              authUser?.rol === USER_TYPE.DESARROLLADOR) && (
              <PrivateRoute
                exact
                path={PRIVATE_CLIENTS_BANNED}
                authenticated={authenticated}
                Component={ClientsBanned}
                allowByRole={true}
                redirect={getRedirect()}
              />
            )}
            {/* ***** USERS ***** */}
            {(authPermissions.includes("room.search") ||
              authUser?.rol === USER_TYPE.DESARROLLADOR) && (
              <PrivateRoute
                exact
                path={PRIVATE_USERS}
                authenticated={authenticated}
                Component={Users}
                allowByRole={true}
                redirect={getRedirect()}
              />
            )}
            {/* ***** SETTINGS ***** */}
            <PrivateRoute
              exact
              path={PRIVATE_SETTINGS_GENERAL}
              authenticated={authenticated}
              Component={SettingsGeneral}
              allowByRole={true}
              redirect={getRedirect()}
            />
            <PrivateRoute
              exact
              path={PRIVATE_SETTINGS_PERMISSION}
              authenticated={authenticated}
              Component={SettingsRol}
              allowByRole={true}
              redirect={getRedirect()}
            />
            <PrivateRoute
              exact
              path={PRIVATE_SETTINGS_LOCAL}
              authenticated={authenticated}
              Component={SettingsGeneral}
              allowByRole={true}
              redirect={getRedirect()}
            />
            {/* ***** REDIRECT ***** */}
            <Redirect to={getRedirect()}></Redirect>
          </Switch>
        </PrincipalLayout>
      )}
      {!authenticated && (
        <Switch>
          <PrivateRoute
            exact
            path={PUBLIC_LOGIN}
            authenticated={authenticated}
            Component={Login}
            allowByRole={true}
            redirect={PUBLIC_LOGIN}
          />
          <Redirect to={PUBLIC_LOGIN}></Redirect>
        </Switch>
      )}
    </Router>
  );
};

export default SwitcherRoute;
