import React from "react";
import { RootStore } from "../../../redux/Store";
import { useSelector } from "react-redux";

import { productI } from "../../../utils/responseEntities";
import { productSellI, PRODUCT_CATEGORY } from "../../../utils/utils";

import {
  GiWaterBottle,
  GiCandyCanes,
  GiChocolateBar,
  GiCigarette,
  GiHealthCapsule,
  GiCookie,
  GiFrenchFries,
} from "react-icons/gi";
import TopToolbar from "./TopToolbar";

interface ListProps {
  sellArray: Array<productSellI>;
  setSellArray: any;
}

const List: React.FunctionComponent<ListProps> = ({
  sellArray,
  setSellArray,
}: ListProps) => {
  const [productList, setProductList] = React.useState<Array<productI>>([]);

  const productG: Array<productI> = useSelector(
    (globalState: RootStore) => globalState.general.productList
  );
  const searchS: string = useSelector(
    (globalState: RootStore) => globalState.productsSell.search
  );
  const selectorS: PRODUCT_CATEGORY = useSelector(
    (globalState: RootStore) => globalState.productsSell.selector
  );

  const updateSellArray = (product: productI) => {
    let auxPos = sellArray.findIndex((prod: productSellI) => {
      return prod.key === product.key;
    });

    let auxSellArray = [...sellArray];

    if (auxPos === -1) {
      auxSellArray.push({
        key: product.key || "",
        nombre: product.nombre,
        precio_unitario: product.precio_unitario,
        categoria: product.categoria,
        cantidad: 1,
      });
    } else {
      auxSellArray[auxPos].cantidad++;
    }

    setSellArray(auxSellArray);
  };

  const getCategoryBack = (category: string) => {
    switch (category) {
      case PRODUCT_CATEGORY.BEBIDA:
        return <GiWaterBottle></GiWaterBottle>;
      case PRODUCT_CATEGORY.CARAMELO:
        return <GiCandyCanes></GiCandyCanes>;
      case PRODUCT_CATEGORY.CHOCOLATE:
        return <GiChocolateBar></GiChocolateBar>;
      case PRODUCT_CATEGORY.CIGARRO:
        return <GiCigarette></GiCigarette>;
      case PRODUCT_CATEGORY.CUIDADO:
        return <GiHealthCapsule></GiHealthCapsule>;
      case PRODUCT_CATEGORY.GALLETA:
        return <GiCookie></GiCookie>;
      case PRODUCT_CATEGORY.SNACK:
        return <GiFrenchFries></GiFrenchFries>;
      default:
        return <></>;
    }
  };

  React.useEffect(() => {
    let auxProducts: Array<productI> = productG.filter((product: productI) => {
      return product.stock && product.stock !== 0;
    });

    if (selectorS !== PRODUCT_CATEGORY.TODOS) {
      auxProducts = auxProducts.filter((product: productI) => {
        return product.categoria === selectorS;
      });
    }

    if (searchS !== "") {
      auxProducts = auxProducts.filter((product: productI) => {
        return product.nombre.includes(searchS.toLowerCase());
      });
    }

    setProductList(auxProducts);
  }, [productG, searchS, selectorS]);

  return (
    <React.Fragment>
      <div className="products-sell-list">
        <TopToolbar></TopToolbar>
        <div className="product-list">
          {
            // eslint-disable-next-line array-callback-return
            productList
              .sort((a: productI, b: productI) =>
                a.nombre.toLowerCase() > b.nombre.toLowerCase()
                  ? 1
                  : b.nombre.toLowerCase() > a.nombre.toLowerCase()
                  ? -1
                  : 0
              )
              .map((product: productI) => {
                let cantidad = 0;
                let auxPos = sellArray.findIndex((prod: productSellI) => {
                  return prod.key === product.key;
                });

                if (auxPos !== -1) {
                  cantidad = sellArray[auxPos].cantidad;
                }

                if (cantidad < product.stock) {
                  return (
                    <div
                      key={product.key}
                      className={`product-element ${
                        auxPos !== -1 ? "element-selected" : ""
                      }`}
                      onClick={() => {
                        updateSellArray(product);
                      }}
                    >
                      <div className="side-color">
                        <div
                          className={`flag side-color-${product.categoria.toLowerCase()}`}
                        ></div>
                      </div>

                      <p className="name">{product.nombre.toUpperCase()}</p>
                      <p className="price-list">{`s/. ${product.precio_unitario}`}</p>
                      <p>
                        {"Stock: "}
                        <span className="stock">
                          {auxPos !== -1
                            ? product.stock - cantidad
                            : product.stock}
                        </span>
                      </p>
                      {getCategoryBack(product.categoria)}
                    </div>
                  );
                }
              })
          }
        </div>
      </div>
    </React.Fragment>
  );
};
export default List;
