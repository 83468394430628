import { detailSI, sellI } from "../../utils/responseEntities";
import { productSellI, TT_TURN_SELECTOR } from "../../utils/utils";

import {
  PRODUCTS_HISTORY_LOADING_LAST,
  PRODUCTS_HISTORY_FAIL_LAST,
  PRODUCTS_HISTORY_LOADING_ACTUAL,
  PRODUCTS_HISTORY_FAIL_ACTUAL,
  PRODUCTS_HISTORY_LOADING_CUSTOM,
  PRODUCTS_HISTORY_FAIL_CUSTOM,
  PRODUCTS_HISTORY_TURN,
  PRODUCTS_HISTORY_RANGE,
  PRODUCTS_HISTORY_LIST_LAST,
  PRODUCTS_HISTORY_LIST_ACTUAL,
  PRODUCTS_HISTORY_LIST_CUSTOM,
  ProductsHistoryDispatchTypes,
  PRODUCTS_HISTORY_DETAIL_LIST,
} from "./ProductsHistoryTypes";

let defaultValue: any;

interface ProductsHistoryState {
  loadingHistoryLast: boolean;
  loadingHistoryActual: boolean;
  loadingHistoryCustom: boolean;
  turnHistory: TT_TURN_SELECTOR;
  customDateHistory: Date;
  productsHistoryLast: Array<sellI>;
  productsHistoryActual: Array<sellI>;
  productsHistoryCustom: Array<sellI>;
  productsHistoryDetails: Array<sellI>;
}

const defaultState: ProductsHistoryState = {
  loadingHistoryLast: false,
  loadingHistoryActual: false,
  loadingHistoryCustom: false,
  turnHistory: TT_TURN_SELECTOR.ACTUAL,
  customDateHistory: defaultValue,
  productsHistoryLast: [],
  productsHistoryActual: [],
  productsHistoryCustom: [],
  productsHistoryDetails: [],
};

const productsHistoryReducer = (
  state: ProductsHistoryState = defaultState,
  action: ProductsHistoryDispatchTypes
): ProductsHistoryState => {
  switch (action.type) {
    case PRODUCTS_HISTORY_LOADING_LAST:
      return {
        ...state,
        loadingHistoryLast: true,
      };
    case PRODUCTS_HISTORY_FAIL_LAST:
      return {
        ...state,
        loadingHistoryLast: false,
      };
    case PRODUCTS_HISTORY_LOADING_ACTUAL:
      return {
        ...state,
        loadingHistoryActual: true,
      };
    case PRODUCTS_HISTORY_FAIL_ACTUAL:
      return {
        ...state,
        loadingHistoryActual: false,
      };
    case PRODUCTS_HISTORY_LOADING_CUSTOM:
      return {
        ...state,
        loadingHistoryCustom: true,
      };
    case PRODUCTS_HISTORY_FAIL_CUSTOM:
      return {
        ...state,
        loadingHistoryCustom: false,
      };
    case PRODUCTS_HISTORY_TURN:
      return {
        ...state,
        turnHistory: action.payload,
      };
    case PRODUCTS_HISTORY_RANGE:
      return {
        ...state,
        customDateHistory: action.payload.date,
      };
    case PRODUCTS_HISTORY_LIST_LAST:
      return {
        ...state,
        productsHistoryLast: action.payload,
      };
    case PRODUCTS_HISTORY_LIST_ACTUAL:
      return {
        ...state,
        productsHistoryActual: action.payload,
      };
    case PRODUCTS_HISTORY_LIST_CUSTOM:
      return {
        ...state,
        productsHistoryCustom: action.payload,
      };
    case PRODUCTS_HISTORY_DETAIL_LIST:
      return {
        ...state,
        productsHistoryDetails: action.payload,
      };  
    default:
      return state;
  }
};

export default productsHistoryReducer;
