export const USERS_LOADING = "USERS_LOADING";
export const USERS_FAIL = "USERS_FAIL";
export const USERS_UPDATE_FILTER = "USERS_UPDATE_FILTER";
export const USERS_UPDATE_SELECTOR = "USERS_UPDATE_SELECTOR";
export const USERS_SEARCH = "USERS_SEARCH";
export const USERS_LIST = "USERS_LIST";

export interface UsersLoading {
  type: typeof USERS_LOADING;
}
export interface UsersFail {
  type: typeof USERS_FAIL;
}
export interface UsersUpdateFilter {
  type: typeof USERS_UPDATE_FILTER;
  payload: any;
}
export interface UsersUpdateSelector {
  type: typeof USERS_UPDATE_SELECTOR;
  payload: any;
}
export interface UsersSearch {
  type: typeof USERS_SEARCH;
  payload: any;
}
export interface UsersList {
  type: typeof USERS_LIST;
  payload: any;
}

export type UsersDispatchTypes =
  | UsersLoading
  | UsersFail
  | UsersUpdateFilter
  | UsersUpdateSelector
  | UsersSearch
  | UsersList;
