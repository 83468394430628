import React from "react";
import { useDispatch } from "react-redux";

import {
  getUserLevel,
  onlyLetters,
  SEX_LIST,
  USER_TYPE,
  validEmailRegex,
} from "../../../utils/utils";
import { updateUserFB } from "../../../redux/users/UsersAction";
import { updateUsersDTO } from "../../../utils/requestDto";
import { userI } from "../../../utils/responseEntities";

import ModalDefault from "../../../components/sub-components/modal-default";
import SelectOption from "../../../components/sub-components/select-option";
import InputField from "../../../components/sub-components/input-field";

interface ModalEditUserProps {
  user: userI;
  modalShow: any;
  setModalShow: any;
  successFun(message: string): void;
  errorFun(message: string): void;
}

export const ModalEditUser: React.FunctionComponent<ModalEditUserProps> = ({
  user,
  modalShow,
  setModalShow,
  successFun,
  errorFun,
}: ModalEditUserProps) => {
  let defaultValue: any;

  const [loadingAction, setLoadingAction] = React.useState<boolean>(false);
  const [state, setState] = React.useState<userI>({
    key: "",
    nombre: "",
    password: "",
    correo: "",
    rol: "",
    sexo: "",
    nivel: defaultValue,
    fecha_creacion: defaultValue,
    fecha_modificacion: new Date(),
    local_cod: defaultValue,
  });

  const [errorNombre, setErrorNombre] = React.useState<boolean>(false);
  const [errorPassword, setErrorPassword] = React.useState<boolean>(false);
  const [errorCorreo, setErrorCorreo] = React.useState<boolean>(false);
  const [errorRol, setErrorRol] = React.useState<boolean>(false);
  const [errorSexo, setErrorSexo] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const updateUserData = (name: any, value: any) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateUserData = () => {
    if (
      state.nombre === "" ||
      state.password === "" ||
      !validEmailRegex.test(state.correo) ||
      state.rol === "" ||
      state.sexo === "" ||
      !state.local_cod
    ) {
      setErrorNombre(state.nombre === "" ? true : false);
      setErrorPassword(state.password === "" ? true : false);
      setErrorCorreo(!validEmailRegex.test(state.correo) ? true : false);
      setErrorRol(state.rol === "" ? true : false);
      setErrorSexo(state.sexo === "" ? true : false);
      return false;
    } else {
      return true;
    }
  };

  const editUser = () => {
    setLoadingAction(true);
    //EDIT USER
    dispatch(
      updateUserFB(
        {
          user: state,
        } as updateUsersDTO,
        () => {
          setLoadingAction(false);
          if (successFun) successFun("Modificado satisfactoriamente");
          setModalShow(false);
        },
        () => {
          setLoadingAction(false);
          if (errorFun) errorFun("Ups! Algo salio mal");
          setModalShow(false);
        }
      )
    );
  };

  React.useEffect(() => {
    if (user) {
      setState((prev) => ({
        ...prev,
        key: user.key,
        nombre: user.nombre.toLowerCase(),
        password: user.password,
        correo: user.correo.toLowerCase(),
        rol: user.rol,
        sexo: user.sexo,
        nivel: user.nivel,
        fecha_creacion: user.fecha_creacion,
        local_cod: user.local_cod,
      }));
    }
  }, [user]);

  return (
    <ModalDefault
      loadingAction={loadingAction}
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      customClass="modal-users-edit"
      title={"Editar Usuario"}
      showModalHeader={true}
      showModalFooter={true}
      showEdit={true}
      showCancel={true}
      editAction={() => {
        if (validateUserData()) {
          editUser();
        }
      }}
    >
      <React.Fragment>
        <InputField
          key={"edit-user-nombre"}
          horizontal={false}
          placeholder={""}
          value={state.nombre}
          onChange={(value: string) => {
            updateUserData("nombre", value.toLowerCase());
            setErrorNombre(false);
          }}
          className={"edit-user-input"}
          regExp={onlyLetters}
          viewTitle={true}
          viewErrorMessage={errorNombre}
          title={"Nombre"}
          errorMessage={"Incorrecto"}
          height={"!h-[40px]"}
          width={"!w-full"}
          isUppercase={true}
        ></InputField>
        <InputField
          key={"edit-user-password"}
          horizontal={false}
          placeholder={""}
          value={state.password}
          onChange={(value: any) => {
            updateUserData("password", value);
            setErrorPassword(false);
          }}
          className={"edit-user-input"}
          viewTitle={true}
          viewErrorMessage={errorPassword}
          title={"Password"}
          errorMessage={"Incorrecto"}
          height={"!h-[40px]"}
          width={"!w-full"}
        ></InputField>
        <InputField
          key={"edit-user-correo"}
          disabled
          horizontal={false}
          placeholder={""}
          value={state.correo}
          onChange={(value: any) => {
            updateUserData("correo", value.toLowerCase());
            setErrorCorreo(false);
          }}
          className={"edit-user-input"}
          viewTitle={true}
          viewErrorMessage={errorCorreo}
          title={"Correo"}
          errorMessage={"Incorrecto"}
          height={"!h-[40px]"}
          width={"!w-full"}
          isUppercase={true}
        ></InputField>
        <SelectOption
          key={"edit-user-rol"}
          horizontal={false}
          className={"edit-user-select"}
          clickAction={(value: string) => {
            updateUserData("rol", value);
            updateUserData("nivel", getUserLevel(value));
            setErrorRol(false);
          }}
          optionArray={[
            USER_TYPE.ADMINISTRADOR,
            USER_TYPE.RECEPCIONISTA,
            USER_TYPE.LIMPIEZA,
          ]}
          selected={state.rol}
          viewTitle={true}
          viewErrorMessage={errorRol}
          title={"Rol"}
          errorMessage={"Incorrecto"}
        ></SelectOption>
        <SelectOption
          key={"edit-user-sexo"}
          horizontal={false}
          className={"edit-user-select"}
          clickAction={(value: string) => {
            updateUserData("sexo", value);
            setErrorSexo(false);
          }}
          optionArray={[SEX_LIST.MASCULINO, SEX_LIST.FEMENINO]}
          selected={state.sexo}
          viewTitle={true}
          viewErrorMessage={errorSexo}
          title={"Sexo"}
          errorMessage={"Incorrecto"}
        ></SelectOption>
      </React.Fragment>
    </ModalDefault>
  );
};

export default ModalEditUser;
