import React from "react";
import { RootStore } from "../../../redux/Store";
import { useSelector } from "react-redux";

import { roomI, userI } from "../../../utils/responseEntities";
import { ROOM_STATUS, USER_TYPE } from "../../../utils/utils";

import {
  dateRentInTemplate,
  statusTemplate,
  textCapitalizeTemplate,
  textMoneyTemplate,
  textTemplate,
} from "../../../components/sub-components/table-components";
import ActionOverlay from "../../../components/sub-components/actions-overlay";
import DetailTable from "../../../components/sub-components/detail-table";
import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ModalDeleteRoom from "./ModalDeleteRoom";
import ModalEnableRoom from "./ModalEnableRoom";
import ModalEditRoom from "./ModalEditRoom";
import { Toast } from "primereact/toast";

const List: React.FunctionComponent = () => {
  let defaultValue: any;
  const toastRef = React.useRef<Toast>(defaultValue);

  const [modalShowEdit, setModalShowEdit] = React.useState(false);
  const [roomEdit, setRoomEdit] = React.useState<roomI>(defaultValue);

  const [modalShowDelete, setModalShowDelete] = React.useState(false);
  const [roomDelete, setRoomDelete] = React.useState<roomI>(defaultValue);

  const [modalShowEnable, setModalShowEnable] = React.useState(false);
  const [roomEnable, setRoomEnable] = React.useState<roomI>(defaultValue);

  const permissionsAuth: Array<string> = useSelector(
    (globalState: RootStore) => globalState.auth.permissions
  );
  const userAuth: userI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.user
  );
  const roomR: Array<roomI> = useSelector(
    (globalState: RootStore) => globalState.rooms.rooms
  );
  const loadingR: boolean = useSelector(
    (globalState: RootStore) => globalState.rooms.loading
  );

  const actionsTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    return (
      <React.Fragment>
        <div className="table-action-buttons">
          <ActionOverlay
            showEnable={
              (rowData.estatus === ROOM_STATUS.MANTENIMIENTO ||
                rowData.estatus === ROOM_STATUS.LIBRE) &&
              (permissionsAuth.includes("room.general.enable") ||
                userAuth?.rol === USER_TYPE.DESARROLLADOR)
            }
            isEnable={rowData.estatus === ROOM_STATUS.MANTENIMIENTO}
            enableAction={() => {
              if (
                rowData.estatus === ROOM_STATUS.MANTENIMIENTO ||
                rowData.estatus === ROOM_STATUS.LIBRE
              ) {
                let auxRoom: roomI =
                  roomR.find(
                    (room: roomI) => rowData[field || ""] === room.key
                  ) || defaultValue;
                setRoomEnable({ ...auxRoom } as roomI);
                setModalShowEnable(true);
              }
            }}
            showEdit={
              permissionsAuth.includes("room.general.addedit") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            editAction={() => {
              let auxRoom: roomI =
                roomR.find(
                  (room: roomI) => rowData[field || ""] === room.key
                ) || defaultValue;
              setRoomEdit({ ...auxRoom } as roomI);
              setModalShowEdit(true);
            }}
            showDelete={
              (rowData.estatus === ROOM_STATUS.MANTENIMIENTO ||
                rowData.estatus === ROOM_STATUS.LIBRE) &&
              (permissionsAuth.includes("room.general.delete") ||
                userAuth?.rol === USER_TYPE.DESARROLLADOR)
            }
            deleteAction={() => {
              let auxRoom: roomI =
                roomR.find(
                  (room: roomI) => rowData[field || ""] === room.key
                ) || defaultValue;
              setRoomDelete({ ...auxRoom } as roomI);
              setModalShowDelete(true);
            }}
          ></ActionOverlay>
        </div>
      </React.Fragment>
    );
  };

  const detailRoomTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    return (
      <DetailTable
        key={`general-${rowData["key"]}`}
        data={rowData[field || ""]}
        isArray={true}
      ></DetailTable>
    );
  };

  return (
    <React.Fragment>
      <div className="room-list">
        <DataTable
          //GENERAL
          dataKey="_id"
          loading={loadingR}
          className="p-datatable-main"
          value={roomR}
          //SORTING
          sortField="numero"
          sortMode="single"
          sortOrder={1}
          //SCROLLING
          scrollable
          scrollDirection="vertical"
          //PAGINATION
          alwaysShowPaginator
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
        >
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="numero"
            header="Número"
            body={textTemplate}
            headerClassName="text-align-left numero-field"
            className="text-align-left numero-field"
            sortable
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="piso"
            header="Piso"
            body={textTemplate}
            headerClassName="text-align-center piso-field"
            className="text-align-center piso-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="precio"
            header="Precio"
            body={textMoneyTemplate}
            headerClassName="text-align-left precio-field"
            className="text-align-left precio-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="tipo"
            header="Tipo"
            body={textCapitalizeTemplate}
            headerClassName="text-align-left tipo-field"
            className="text-align-left tipo-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="estatus"
            header="Estado"
            body={statusTemplate}
            headerClassName="text-align-left status-field"
            className="text-align-left status-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="descripcion"
            header="Descripción"
            body={detailRoomTemplate}
            headerClassName="text-align-center descripcion-field"
            className="text-align-center descripcion-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="hora_liberado"
            header="Hora liberado"
            body={dateRentInTemplate}
            headerClassName="text-align-center liberado-field"
            className="text-align-center liberado-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="key"
            header="Acciones"
            body={actionsTemplate}
            headerClassName="text-align-center acciones-field"
            className="text-align-center acciones-field"
          ></Column>
        </DataTable>
      </div>
      {modalShowEnable && (
        <ModalEnableRoom
          room={roomEnable}
          modalShow={modalShowEnable}
          setModalShow={setModalShowEnable}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Habitaciones - Habilitar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            console.log("message");
            toastRef.current.show({
              severity: "error",
              summary: "Habitaciones - Habilitar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalEnableRoom>
      )}
      {modalShowEdit && (
        <ModalEditRoom
          room={roomEdit}
          modalShow={modalShowEdit}
          setModalShow={setModalShowEdit}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Habitaciones - Editar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            console.log("message");
            toastRef.current.show({
              severity: "error",
              summary: "Habitaciones - Editar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalEditRoom>
      )}
      {modalShowDelete && (
        <ModalDeleteRoom
          room={roomDelete}
          modalShow={modalShowDelete}
          setModalShow={setModalShowDelete}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Habitaciones - Eliminar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            console.log("message");
            toastRef.current.show({
              severity: "error",
              summary: "Habitaciones - Eliminar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalDeleteRoom>
      )}
      <Toast ref={toastRef} />
    </React.Fragment>
  );
};

export default List;
