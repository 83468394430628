import React from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";

import {
  dateCreateTemplate,
  textCapitalizeTemplate,
  textTemplate,
} from "../../../components/sub-components/table-components";
import { preferentI, userI } from "../../../utils/responseEntities";

import ActionOverlay from "../../../components/sub-components/actions-overlay";
import ModalDeletePreferent from "./ModalDeletePreferent";
import { Column, ColumnBodyOptions } from "primereact/column";
import ModalEditPreferent from "./ModalEditPreferent";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { USER_TYPE } from "../../../utils/utils";

const List: React.FunctionComponent = () => {
  let defaultValue: any;
  const toastRef = React.useRef<Toast>(defaultValue);

  const [modalShowEdit, setModalShowEdit] = React.useState(false);
  const [preferentEdit, setPreferentEdit] =
    React.useState<preferentI>(defaultValue);

  const [modalShowDelete, setModalShowDelete] = React.useState(false);
  const [preferentDelete, setPreferentDelete] =
    React.useState<preferentI>(defaultValue);

  const permissionsAuth: Array<string> = useSelector(
    (globalState: RootStore) => globalState.auth.permissions
  );
  const userAuth: userI | undefined = useSelector(
    (globalState: RootStore) => globalState.auth.user
  );
  const preferentsP: Array<preferentI> = useSelector(
    (globalState: RootStore) => globalState.preferent.preferents
  );
  const loadingP: boolean = useSelector(
    (globalState: RootStore) => globalState.preferent.loading
  );

  const actionsTemplate = (rowData: any, { field }: ColumnBodyOptions) => {
    return (
      <React.Fragment>
        <div className="table-action-buttons">
          <ActionOverlay
            showEdit={
              permissionsAuth.includes("client.preferent.addedit") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            editAction={() => {
              let auxClient: preferentI =
                preferentsP.find(
                  (client: preferentI) => rowData[field || ""] === client.key
                ) || defaultValue;
              setPreferentEdit({ ...auxClient } as preferentI);
              setModalShowEdit(true);
            }}
            showDelete={
              permissionsAuth.includes("client.preferent.delete") ||
              userAuth?.rol === USER_TYPE.DESARROLLADOR
            }
            deleteAction={() => {
              let auxClient: preferentI =
                preferentsP.find(
                  (client: preferentI) => rowData[field || ""] === client.key
                ) || defaultValue;
              setPreferentDelete({ ...auxClient } as preferentI);
              setModalShowDelete(true);
            }}
          ></ActionOverlay>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="client-preferent-list">
        <DataTable
          //GENERAL
          dataKey="_id"
          loading={loadingP}
          className="p-datatable-main"
          value={preferentsP}
          //SORTING
          sortField="nombres"
          sortMode="single"
          sortOrder={1}
          //SCROLLING
          scrollable
          scrollDirection="vertical"
          //PAGINATION
          alwaysShowPaginator
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
        >
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="nombres"
            header="Nombre"
            body={textCapitalizeTemplate}
            headerClassName="text-align-left nombre-field"
            className="text-align-left nombre-field"
            sortable
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="apellido_pat"
            header="Ap. Paterno"
            body={textCapitalizeTemplate}
            headerClassName="text-align-left apellido_pat-field"
            className="text-align-left apellido_pat-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="apellido_mat"
            header="Ap. Materno"
            body={textCapitalizeTemplate}
            headerClassName="text-align-left apellido_mat-field"
            className="text-align-left apellido_mat-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="dni"
            header="DNI"
            body={textTemplate}
            headerClassName="text-align-left dni-field"
            className="text-align-center dni-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="fecha_nacimiento"
            header="Nacimiento"
            body={dateCreateTemplate}
            headerClassName="text-align-left fecha_nacimiento-field"
            className="text-align-center fecha_nacimiento-field"
          ></Column>
          <Column
            column={defaultValue}
            rowIndex={defaultValue}
            field="key"
            header="Acciones"
            body={actionsTemplate}
            headerClassName="text-align-center acciones-field"
            className="text-align-center acciones-field"
          ></Column>
        </DataTable>
      </div>
      {modalShowEdit && (
        <ModalEditPreferent
          preferent={preferentEdit}
          modalShow={modalShowEdit}
          setModalShow={setModalShowEdit}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Preferentes - Editar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            console.log("message");
            toastRef.current.show({
              severity: "error",
              summary: "Preferentes - Editar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalEditPreferent>
      )}
      {modalShowDelete && (
        <ModalDeletePreferent
          preferent={preferentDelete}
          modalShow={modalShowDelete}
          setModalShow={setModalShowDelete}
          successFun={(message: string) => {
            toastRef.current.show({
              severity: "success",
              summary: "Preferentes - Eliminar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
          errorFun={(message: string) => {
            console.log("message");
            toastRef.current.show({
              severity: "error",
              summary: "Preferentes - Eliminar",
              detail: message,
              life: 3000,
              closable: false,
            });
          }}
        ></ModalDeletePreferent>
      )}
      <Toast ref={toastRef} />
    </React.Fragment>
  );
};

export default List;
